import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AuctionModelService, AuctionReportLineItem } from '../../model/auction.model';
import { NbDialogService } from '@nebular/theme';
import { AuctionReportAdjustDialogComponent } from '../auction-report-adjust-dialog/auction-report-adjust-dialog.component'
import { AuctionReportUpdateWinningBuyerDialogComponent } from '../auction-report-update-winning-buyer-dialog/auction-report-update-winning-buyer-dialog.component';
import { AuctionStatus, SaleStatus } from 'src/app/API.service';

@Component({
  selector: '[app-auction-report-lot-detail]',
  templateUrl: './auction-report-lot-detail.component.html',
  styleUrls: ['./auction-report-lot-detail.component.scss']
})
export class AuctionReportLotDetailComponent implements OnInit {

  @Input() lot: AuctionReportLineItem = new AuctionReportLineItem();
  @Input() auctionStatus: AuctionStatus = AuctionStatus.LIVE;
  @Input() saleStatus: SaleStatus = SaleStatus.UNSOLD;
  @Output() messageEvent = new EventEmitter<any>();

  public selectedItem: string;

  public paymentStatusOptions: any = [
    { key: 'UNPAID', value: 'Unpaid' },
    { key: 'DIRECT_PAID', value: 'Direct-Paid' },
    { key: 'PAID_SALE_REP', value: 'Paid Sale Rep' },
    { key: 'UNCOLLECTIBLE', value: 'Uncollectible' },
  ];

  public soldStatusOptions: any = [
    { key: 'SOLD', value: 'Sold' },
    { key: 'UNSOLD', value: 'Unsold' },
  ];

  public items: any = [
    { title: 'View Invoice' },
    { title: 'Resend Invoice' }
  ]

  constructor(private dialogService: NbDialogService,
    private auctionModel: AuctionModelService) { }

  ngOnInit(): void {


  }

  public adjustReport(): void {
    this.dialogService.open(AuctionReportAdjustDialogComponent, {
      hasScroll: false,
      closeOnBackdropClick: true,
      closeOnEsc: true,
      context: {
        lineItem: this.lot
      },
      dialogClass: 'modal-large'
    });
  }

  public adjustWinningBuyerInfo(): void {
    this.dialogService.open(AuctionReportUpdateWinningBuyerDialogComponent, {
      hasScroll: false,
      closeOnBackdropClick: true,
      closeOnEsc: true,
      context: {
        lineItem: this.lot
      },
      dialogClass: 'modal-medium'
    });
  }

  public async onUpdateSaleStatus(): Promise<void> {
    try {
      await this.auctionModel.activeAuctionReport.updateSaleStatus(this.lot);
    } catch (e) {
      console.log('Error occurred updating sale status');
    }
  }

  public async onUpdatePaymentStatus(): Promise<void> {
    this.messageEvent.emit({ 'message': 'updatePaymentStatus', 'lot': this.lot });
  }

  public isAuctionSettled() {
    return this.auctionModel.activeAuction.status === AuctionStatus.SETTLED;
  }

}
