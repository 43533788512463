import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-user-wins-lot-detail',
  templateUrl: './user-wins-lot-detail.component.html',
  styleUrls: ['./user-wins-lot-detail.component.scss']
})
export class UserWinsLotDetailComponent implements OnInit {

  @Input() lot=undefined;
  @Output() messageEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }
  
  getCurrency(value:number){
    return "$" + value.toFixed(2);
  }
  

}
