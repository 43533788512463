<div class="loading-shade" *ngIf="processing"></div>

<div class="container-fluid">

    <div class="row mb-2">
        <div class="col-md-12">
            <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12">
            <div *ngIf="successMsg.length > 0"><span class="successText">{{successMsg}}</span></div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-6">
            <button nbButton size="small" shape="rectangle" (click)="onViewLotList()" [appearance]="getAppearance(true)" [status]="getStatus(true)">VIEW LOTS</button>
            <button nbButton size="small" shape="rectangle" (click)="onNewLot()" [appearance]="getAppearance(false)" [status]="getStatus(false)">ADD LOTS</button>
        </div>
        <div class="col-md-6 right-justify center r-top">
            <button [nbSpinner]="processingLotsUpload" nbButton size="small" status="primary" appearance="outline" (click)="fileUpload.click()">
                <nb-icon icon="cloud-upload-outline"></nb-icon>
                    UPLOAD CSV
                <input  type="file"
                        id="uploadLotsFile"
                        (change)="processUploadFileDetails($event)"
                        #fileUpload
                        style="display:none;">                
            </button>
        </div>
    </div>

    <div class="row mt-4 mb-5">
        <div class="col-md-12">
            <app-auction-lot-list #auctionlotlist [rows]="auctionModel.activeAuction.lots" [style]="lotList ? 'display:block' : 'display:none'" (messageEvent)="receiveMessage($event)"></app-auction-lot-list>
            <app-auction-lot-edit-home #auctionlotedit *ngIf="lotToEdit != undefined" [lotToEdit]="lotToEdit" [style]="!lotList ? 'display:block' : 'display:none'" (messageEvent)="receiveMessage($event)"></app-auction-lot-edit-home>
        </div>
    </div>
    
</div> <!-- container-fluid -->
