import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Location } from '@angular/common';
import { AuctionModelService, AuctionModel } from '../../model/auction.model';
import { SellerService, Seller } from '../../model/seller.model';

@Component({
  selector: 'app-sellerinfo',
  templateUrl: './sellerinfo.component.html',
  styleUrls: ['./sellerinfo.component.scss']
})
export class SellerinfoComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;
  
  _TAB_DETAILS = "DETAILS";
  _TAB_SALES   = "SALES";
  
  pagelist = {
    "DETAILS":"1",
    "SALES":"2"
  }
  
  sellerAuctionList:AuctionModel[] = [];
  
  details:boolean = true;
  
  @ViewChild('sellerinfodetails') sellerinfodetails;  
  @ViewChild('auctionlist') auctionlist;  
  @Output() messageEvent = new EventEmitter<any>();

  constructor(private sellerService: SellerService,
              private auctionModel: AuctionModelService,
              private location: Location) { }

  /**
   * angular (ng) initializers
   **/
  ngOnInit(): void {
  }
  
  /**
   * server (api) calls
   **/
  async loadAuctionsBySeller(forcereload=false){
    this.processing = true;
    try {
      this.sellerAuctionList = await this.auctionModel.loadAuctionsBySeller(this.sellerService.activeSeller.accountId,forcereload);
      this.auctionlist.rows = this.sellerAuctionList;
      this.auctionlist.filteredList = this.sellerAuctionList;

      // this.openAuctionList = this.auctionModel.openAuctionsList.sort(this.compareStartTimeAsc);
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving open auctions list');
    }
  }
  
  /**
   * html page handlers
   **/
  selectTab(tabTitle) {
    var urlParms = this.location.path().split('/');
    urlParms[4] = this.pagelist[tabTitle];
    var newUrl = urlParms.join('/');
    this.location.go(newUrl);

    if(tabTitle===this._TAB_DETAILS){
      this.details = true;
    }
    else if(tabTitle===this._TAB_SALES){
      this.details = false;
    }
  }
  
  setTab(tabNumber:string){
    tabNumber === "1" ? this.selectTab(this._TAB_DETAILS) : this.selectTab(this._TAB_SALES);
  }

  /*
  * Other
  */
   loadPageData(){
     this.sellerinfodetails.loadPageData();
     this.loadAuctionsBySeller(true);
   }
  
  async receiveMessage($event) {
      this.messageEvent.emit($event);
  }
  
  newSeller(){
    var returnValue = false;
    if(this.sellerService.activeSeller != undefined && this.sellerService.activeSeller.accountId == undefined){
      returnValue = true;
    } 
    else if(this.sellerService.activeSeller.accountId.length == 0){
      returnValue = true;
    }
    return returnValue;
  }

}
