<div class="container-fluid">

    <div class="row no-gutters table-header-border">

	    <div class="col-md-2 d-md-block d-none">
	      <span class="table-header-text-link"></span>
	    </div>
    	
	    <div class="col-md-3 d-md-block d-none">
	      <span class="table-header-text-link"> LOT # AND NAME </span>
	    </div>
	    
	    <div class="col-md-2 d-md-block d-none">
	      <span class="table-header-text-link"> QUANTITY </span>
	    </div>
	    
	    <div class="col-md-2 d-md-block d-none">
	      <div><span class="table-header-text-link"> PRICE </span></div>
	    </div>

	    <div class="col-md-2 d-md-block d-none">
	      <div><span class="table-header-text-link"> SUBTOTAL </span></div>
	    </div>

	    <div class="col-md-1 d-md-block d-none">
	    </div>
    </div> <!-- header -->

    <div class="row no-gutters">
    	<div class="col-md-12">
		  <div *ngFor="let item of user['lots']">
		     <app-user-wins-lot-detail [lot]="item" (messageEvent)="receiveMessage($event)"></app-user-wins-lot-detail>
		  </div>
		</div>
	</div>

</div> <!-- container-fluid -->
