<div class="container-fluid">

    <div class="row no-gutters table-header-border">
	    <div class="col-md-1 justify-content-center align-self-center mt-2 mb-2">
	      <div><span class="table-column-text"><nb-icon status="basic" icon="more-vertical-outline" class="more-icon"></nb-icon></span></div>
	    </div>
    	
	    <div class="col-md-1 justify-content-center align-self-center mt-2 mb-2">
	      <div><span class="table-column-text"> {{ lot.lotOrder }} </span></div>
	    </div>
	    
	    <div class="col-md-1 justify-content-center align-self-center mt-2 mb-2">
            <input nbInput 
                   fullWidth
                   style="width:80%" 
                   type="text" 
                   (change)="notifyLotNumChange($event)"
                   [(ngModel)]="lot.lotNum">            
	      <!--<div><span class="table-column-text">{{ lot.lotNum }} </span></div>-->
	    </div>

	    <div class="col-md-3 justify-content-center align-self-center mt-2 mb-2">
	      <div><span class="table-column-text"> {{ lot.title }}</span></div>
	    </div>

	    <div class="col-md-3 justify-content-center align-self-center mt-2 mb-2">
	      <div><span class="table-column-text"> {{ lot.getLotCategoryFriendlyName() }}</span></div>
	    </div>

	    <div class="col-md-1 justify-content-center align-self-center mt-2 mb-2">
	      <div *ngIf="!importLotError()">
            <nb-icon icon="edit-outline" class="details-icon"></nb-icon>
            <a [routerLink]="" class="link-text ml-1" (click)="onEditLot()">Details</a>
	      </div>
	      <div *ngIf="importLotError()">
            <nb-icon icon="alert-triangle-outline" status="danger" class="details-icon"></nb-icon>
            <a [routerLink]="" class="link-text ml-1" (click)="onEditLot()">Details</a>
	      </div>
	    </div>

	    <div class="col-md-1 justify-content-center align-self-center mt-2 mb-2">
	      <div>
            <nb-icon icon="copy-outline" class="details-icon"></nb-icon>
            <a [routerLink]="" class="link-text ml-1" (click)="onCloneLot()">Clone</a>
	      </div>
	    </div>

	    <div class="col-md-1 justify-content-center align-self-center mt-2 mb-2">
	      <div>
            <nb-icon icon="trash-2-outline" class="details-icon"></nb-icon>
            <a [routerLink]="" class="link-text ml-1" (click)="onDeleteLot()">Delete</a>
	      </div>
	    </div>

    </div> <!-- header -->
</div>
