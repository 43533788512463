import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { AuthhomeComponent } from '../auth/authhome/authhome.component';
import { PortalUser } from '../model/portaluser.model';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {


  @Output() messageEvent = new EventEmitter<any>();

  constructor( private authService: AuthService,
               private dialogService: NbDialogService,  
               private router: Router) { }

  ngOnInit(): void {
    this.isLoggedOn();
  }
  
  processMsg(event){
    // console.log('processMsg:',event);
    if(event != undefined){
      var msg = event['message'];
      if(msg == "forgotpassword"){
        this.openAuth("forgotpassword");
      }
      else if(msg== "signup"){
        this.openAuth("signup");
      }
      else if(msg== "changepassword"){
        this.openAuth("changepassword");
      }
      else if(msg== "signin"){
        this.openAuth("signin");
      }
      else if(msg== "signupcomplete"){
        this.openAuth("signin");
      }
      else if(msg== "signinsuccessful"){
        this.routeToPage(this.authService.activeUser);
      }
    }
  }
  
  openAuth(pageName){
    console.log('pageName',pageName);
    let localThis = this;
    this.dialogService.open(AuthhomeComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context:{
          authPageName:pageName
        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }
  
  receiveMessage($event) {
    var msg = $event['message'];
  }

  routeToPage(portalUser:PortalUser){
    // console.log('routeToPage');
    if(portalUser.isAdmin()){
      this.router.navigate(['/dashboard']);
    }
    else {
      this._logout();
      this.openAuth("signin");
    }

  }

  async isLoggedOn(){
    // console.log('isLoggedOn');
    let user = await this.authService.getAuthenticatedUser();
    if(user==undefined){
      this.openAuth("signin");
    }
    else{
      this.routeToPage(this.authService.activeUser);
    }
  }
  
  _logout() {
    this.authService.logout();
  }

}
