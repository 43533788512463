<div class="container-fluid">

    <div class="row mb-1">
        <div class="col-md-12 title-text center" style="font-size:12px">
            Production EPDs
        </div>
    </div>
    
    <div class="row mb-2">
        <div class="col-md-12">
            <ng-container *ngFor="let item of EPDS_PRODUCTION;let i=index">
                <div class="mr-2" style="display:inline-block">
                    <div class="epdrectangle">
                        <span class="epdtext">{{ item.measure }}</span>
                    </div>
                    <div>
                        <input nbInput 
                               fullWidth
                               style="width:70px" 
                               type="text" 
                               id="{{ item.measure }}" 
                               name="{{ item.measure }}" 
                               [(ngModel)]="lot.epdMeasures[i+EPDS_PRODUCTION_START]['value']">            
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12 title-text center" style="font-size:12px">
            Maternal EPDs
        </div>
    </div>
    
    <div class="row mb-2">
        <div class="col-md-12">
            <ng-container *ngFor="let item of EPDS_MATERNAL;let i=index">
                <div class="mr-2" style="display:inline-block">
                    <div class="epdrectangle">
                        <span class="epdtext">{{ item.measure }}</span>
                    </div>
                    <div>
                        <input nbInput 
                               fullWidth
                               style="width:70px" 
                               type="text" 
                               id="{{ item.measure }}" 
                               name="{{ item.measure }}" 
                               [(ngModel)]="lot.epdMeasures[i+EPDS_MATERNAL_START]['value']">            
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12 title-text center" style="font-size:12px">
            Management EPDs
        </div>
    </div>
    
    <div class="row mb-2">
        <div class="col-md-12">
            <ng-container *ngFor="let item of EPDS_MANAGEMENT;let i=index">
                <div class="mr-2" style="display:inline-block">
                    <div class="epdrectangle">
                        <span class="epdtext">{{ item.measure }}</span>
                    </div>
                    <div>
                        <input nbInput 
                               fullWidth
                               style="width:70px" 
                               type="text" 
                               id="{{ item.measure }}" 
                               name="{{ item.measure }}" 
                               [(ngModel)]="lot.epdMeasures[i+EPDS_MANAGEMENT_START]['value']">            
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12 title-text center" style="font-size:12px">
            Carcass EPDs
        </div>
    </div>
    
    <div class="row mb-2">
        <div class="col-md-12">
            <ng-container *ngFor="let item of EPDS_CARCASS;let i=index">
                <div class="mr-2" style="display:inline-block">
                    <div class="epdrectangle">
                        <span class="epdtext">{{ item.measure }}</span>
                    </div>
                    <div>
                        <input nbInput 
                               fullWidth
                               style="width:70px" 
                               type="text" 
                               id="{{ item.measure }}" 
                               name="{{ item.measure }}" 
                               [(ngModel)]="lot.epdMeasures[i+EPDS_CARCASS_START]['value']">            
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    
    <div class="row mb-2">
        <div class="col-md-12 title-text center" style="font-size:12px">
            $Values Index
        </div>
    </div>
    
    <div class="row mb-2">
        <div class="col-md-12">
            <ng-container *ngFor="let item of EPDS_$VALUES;let i=index">
                <div class="mr-2" style="display:inline-block">
                    <div class="epdrectangle">
                        <span class="epdtext">{{ item.measure }}</span>
                    </div>
                    <div>
                        <input nbInput 
                               fullWidth
                               style="width:70px" 
                               type="text" 
                               id="{{ item.measure }}" 
                               name="{{ item.measure }}" 
                               [(ngModel)]="lot.epdMeasures[i+EPDS_$VALUES_START]['value']">            
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

</div> <!-- container-fluid -->
