<div class="container-fluid">
    
    <div class="row mb-2" *ngIf="errorMsg.length > 0">
        <div class="col-md-12">
            <div><span class="errorText">{{errorMsg}}</span></div>
        </div>
    </div>
    
    <div class="row mb-4" style="height:48px">
        <div class="col-md-12">
            <nb-tabset (changeTab)="selectTab($event.tabTitle)" class="tabstyles">
                <nb-tab class="tabfont" tabTitle="DETAILS" [active]="details"></nb-tab>
                <nb-tab class="tabfont" tabTitle="SALES" *ngIf="!newSeller()" [active]="!details">
                    <span  class="mb-5" *ngIf="sellerAuctionList.length == 0" class="content-text">No sales yet.</span>
                </nb-tab>
            </nb-tabset>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12">
            <app-sellerinfodetails #sellerinfodetails [style]="details ? 'display:block' : 'display:none'" (messageEvent)="receiveMessage($event)"></app-sellerinfodetails>
            <app-auctionslist #auctionlist [hidden]="details || sellerAuctionList.length == 0" (messageEvent)="receiveMessage($event)" type="seller"></app-auctionslist>
        </div>
    </div>
    

</div>