<div class="upload">
  <div class="upload__box upload_box--full-width" appDnd (fileDropped)="onFileDropped($event)">
    <input type="file"
            id="fileDropRef"
            [attr.multiple]="multipleFiles"
            [attr.accept]="accept"
            #fileDropRef
            (change)="fileBrowseHandler($event.target.files)"
            style="display:none">

    <nb-icon icon="download-outline" class="upload__drag-image"></nb-icon>
    <div class="upload__text">Drag {{multipleFiles ? "File(s)" : "a File"}} Here</div>
  </div>
  <div class="upload_file-picker">
    <span class="upload__label">OR&nbsp;</span>
    <a [routerLink]="" class="link-text" (click)="fileDropRef.click()">CHOOSE A FILE</a>
  </div>
</div>

<div *ngIf="showList">
  <table class="images" style="margin-top:1.5rem" [sortablejs]="files" [sortablejsOptions]="reorderOptions">
      <tr *ngFor="let item of files;let i=index">
          <td class="images">
    	      <div><span class="table-column-text"><nb-icon status="basic" icon="more-vertical-outline" class="more-icon"></nb-icon></span></div>
          </td>
          <td class="images">
              <img *ngIf="item.format==='image'" [ngStyle]="getImageStyle(item)" [src]="getImageUrl(item)" (error)="imgError($event,item.id)">
              <video *ngIf="item.format==='video'" [ngStyle]="getImageStyle(item)" [src]="getImageUrl(item)" (error)="imgError($event,item.id)"></video>
              <img *ngIf="item.format!='image' && item.format!='video'" [ngStyle]="getImageStyle(item)" [src]="getImageUrl(item)" (error)="imgError($event,item.id)">
          </td>
          <td class="images">

            <div class="row mb-1" *ngIf="item.name.length > 0">
                <div class="col-md-12">
                    <span class="info-text">{{ item.name}} </span>
                </div>
            </div>
            
            <div class="row mb-1" *ngIf="coverPhoto==true">
                <div class="col-md-12">
                  <nb-checkbox type="checkbox"  
                               disabled
                               [(ngModel)]="item.cover">Cover Photo
                  </nb-checkbox>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 auction-label-text">
                    CAPTION
                </div>
            </div>
            
            <div class="row mb-1">
                <div class="col-md-12">
                    <input nbInput 
                           fullWidth
                           style="width:100%" 
                           type="text" 
                           [(ngModel)]="item.caption">            
                </div>
            </div>
            
            <div class="row mb-1">
                <div class="col-md-12">
                  <a class="link-text" [routerLink]="" (click)="removeFile(i)">
                      <nb-icon status="primary" icon="trash-2-outline" class="trash-icon"></nb-icon>
                      <span class="ml-1" style="vertical-align:bottom"> Remove</span>
                  </a>
                </div>
            </div>
            
          </td>
      </tr>
  </table>
</div>