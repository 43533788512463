import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PortalUser } from '../../model/portaluser.model';

@Component({
  selector: 'app-userdetail',
  templateUrl: './userdetail.component.html',
  styleUrls: ['./userdetail.component.scss']
})
export class UserdetailComponent implements OnInit {

  @Input() user:PortalUser=undefined;
  @Output() messageEvent = new EventEmitter<any>();
  
  admin:boolean;
  bidder:boolean;
  seller:boolean;

  constructor() { }

  ngOnInit(): void {
    this.setRoles();
  }
  
  setRoles(){
    this.admin = this.user.isAdmin();
    this.bidder = this.user.isBuyer();
    this.seller = this.user.isSeller();
  }
  
  updateAdmin($event){
    this.user.setAdmin(this.admin);
    this.updateRole();
  }

  updateBidder($event){
    this.user.setBuyer(this.bidder);
    this.updateRole();
  }
  
  updateSeller($event){
    this.user.setSeller(this.seller);
    this.updateRole();
  }
  
  onEditUser(){
    var eventMsg = {}
    eventMsg['message'] = 'edituser';
    eventMsg["parms"] = {
      "accountId" : this.user.accountId
    };
    this.messageEvent.emit(eventMsg);
  }
  
  updateRole(){
    var eventMsg = {}
    eventMsg['message'] = 'updateroles';
    eventMsg["parms"] = {
      "user" : this.user
    };
    this.messageEvent.emit(eventMsg);
  }
  
  

}
