<div class="loading-shade" *ngIf="processing"></div>

<div class="container-fluid">

    <div class="row mb-2">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Auction Style
        </div>
        <div class="col-md-6 right-justify center r-top">
            <button [nbSpinner]="processing" nbButton size="small" status="primary" (click)="onSaveAuction()" [disabled]="savedisabled || !validateFormData()">SAVE</button>
        </div>
    </div>

    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            TYPE
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-6">
            <nb-select  style="width:100%"
                        *ngIf="auctionTypes.length > 0"
                        placeholder="Select Type"
                        name="auctiontype"
                        id="auctiontype"
                        [(ngModel)]="auctionModel.activeAuction.type">
                <nb-option *ngFor="let item of auctionTypes" [value]="item.key">{{ item.value }}</nb-option>
            </nb-select>
        </div>
    </div>

    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            CLOSING EXTENSIONS ENABLED
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <nb-radio-group class="horizontal-radio-group" [(ngModel)]="auctionModel.activeAuction.closingExtensionEnabled">
              <nb-radio [value]="false">No</nb-radio>
              <nb-radio [value]="true">Yes</nb-radio>
            </nb-radio-group>
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            EXTENSION PERIOD INTERVALS
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2">
        <div class="col-md-6">
            <nb-select  style="width:100%"
                        *ngIf="extensionPeriodIntervals.length > 0"
                        placeholder="Select Extension Interval"
                        name="auctiontype"
                        id="auctiontype"
                        [disabled]="!auctionModel.activeAuction.closingExtensionEnabled"
                        [(ngModel)]="auctionModel.activeAuction.closingExtensionInterval">
                <nb-option *ngFor="let item of extensionPeriodIntervals" [value]="item.key">{{ item.value }}</nb-option>
            </nb-select>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Pricing
        </div>
    </div>

    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            STARTING PRICE
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-3">
            <nb-form-field>
                <nb-icon nbPrefix icon="dollar-sign" pack="fas" status="primary"></nb-icon>
                <input nbInput
                       fullWidth
                       style="width:100%"
                       type="number"
                       id="startbid"
                       name="startbid"
                       placeholder="Starting Bid Price"
                       [(ngModel)]="auctionModel.activeAuction.startBid">
            </nb-form-field>
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            FLOOR PRICE
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-2">
        <div class="col-md-12">
            <nb-icon status="info" icon="info-outline" class="trash-icon"></nb-icon>
            <span class="help-info-text">The floor price will not be displayed to the bidder, set it to create a ceiling for our auto price discovery.</span>
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-3">
            <nb-form-field>
                <nb-icon nbPrefix icon="dollar-sign" pack="fas" status="primary"></nb-icon>
                <input nbInput
                       fullWidth
                       style="width:100%"
                       type="number"
                       id="floorPrice"
                       name="floorPrice"
                       placeholder="Floor Price"
                       [(ngModel)]="auctionModel.activeAuction.floorPrice">
            </nb-form-field>

        </div>
        <div class="col"></div>
    </div>
    
    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            STARTING INCREMENT
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-2">
        <div class="col-md-12">
            <nb-icon status="info" icon="info-outline" class="trash-icon"></nb-icon>
            <span class="help-info-text">Setting bidding increments at the auction level will set the value as the default for each lot</span>
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-3">
            <nb-form-field>
                <nb-icon nbPrefix icon="dollar-sign" pack="fas" status="primary"></nb-icon>
                <input nbInput
                       fullWidth
                       style="width:100%"
                       type="number"
                       id="bidincrement"
                       name="bidincrement"
                       placeholder="Bid Increment"
                       [(ngModel)]="auctionModel.activeAuction.bidIncrement">
            </nb-form-field>
        </div>
        <div class="col"></div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Notes
        </div>
    </div>

    <div class="row ml-2">
        <div class="col-md-12 auction-label-text">
            AUCTION NOTES (APPEARS AS ADDITIONAL NOTES IN THE SALE INFORMATION SECTION)
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-12">
            <textarea nbInput
                      fullWidth
                      style="height:100px"
                      id="sellerinfonotes"
                      name="sellerinfonotes"
                      [(ngModel)]="auctionModel.activeAuction.salesInfoNotes"
                      placeholder="Place your text">
            </textarea>
        </div>
        <div class="col"></div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Angus Live G2 Sale Management Contacts
        </div>
    </div>

    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            NAME
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="salesrepname"
                   name="salesrepname"
                   placeholder="Name"
                   [(ngModel)]="auctionModel.activeAuction.salesRepName">
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            EMAIL
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="salesrepemail"
                   name="salesrepemail"
                   placeholder="Email"
                   #salerepemailentry="ngModel"
                   [ngClass]="{'inputError':!validEmail() && salerepemailentry.touched}"
                   [(ngModel)]="auctionModel.activeAuction.salesRepEmail">
            <div class="errorText" *ngIf="!validEmail() && salerepemailentry.touched">Please provide a valid email address.</div>
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            PHONE NUMBER
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-3">
            <input mask="(000) 000-0000"
                   [dropSpecialCharacters]="false"
                   nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="salesrepcontact"
                   name="salesrepcontact"
                   placeholder="Phone Number"
                   #phoneentry="ngModel"
                   [ngClass]="{'inputError':!validPhone() && phoneentry.touched}"
                   maxlength="14"
                   [(ngModel)]="auctionModel.activeAuction.salesRepContact">
            <div class="errorText" *ngIf="!validPhone() && phoneentry.touched">Please provide a valid phone number.</div>
        </div>
        <div class="col"></div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Terms & Conditions
        </div>
    </div>

    <div class="row ml-2 mb-2">
        <div class="col-md-2">
            <nb-checkbox type="checkbox"
                         id="hasstandardtermsandconditions"
                         name="hasstandardtermsandconditions"
                         disabled
                         [(ngModel)] ="auctionModel.activeAuction.hasStandardTermsAndConditions">STANDARD
            </nb-checkbox>
        </div>
        <div class="col-md-1 justify-content-center align-self-center">
            <a class="link-text" style="font-weight:700" [href]="auctionModel.activeAuction.getStandardTermsAndConditionsUrl()" target="_blank">VIEW</a>
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-2">
        <div class="col-md-3">
            <nb-checkbox type="checkbox"
                         id="hascustomtermsandconditions"
                         name="hascustomtermsandconditions"
                         (checkedChange)="onCustomTermsAndConditionsCheckbox($event)"
                         [(ngModel)] ="auctionModel.activeAuction.hasCustomTermsAndConditions">CUSTOM
            </nb-checkbox>
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-4 mb-3" *ngIf="auctionModel.activeAuction.hasCustomTermsAndConditions">
        <div class="col-md-12">
            <textarea nbInput
                      fullWidth
                      style="height:100px"
                      id="customtermsandconditions"
                      name="customtermsandconditions"
                      [(ngModel)]="auctionModel.activeAuction.customTermsAndConditions"
                      placeholder="Place your text">
            </textarea>
        </div>
        <div class="col"></div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Delivery & Payment
        </div>
    </div>

    <div class="row ml-2 mb-2">
        <div class="col-md-2">
            <nb-checkbox type="checkbox"
                         id="hasstandarddeliveryandpaymentterms"
                         name="hasstandarddeliveryandpaymentterms"
                         disabled
                         [(ngModel)] ="auctionModel.activeAuction.hasStandardDeliveryAndPaymentTerms">STANDARD
            </nb-checkbox>
        </div>
        <div class="col-md-1 justify-content-center align-self-center">
            <a class="link-text" style="font-weight:700" [href]="auctionModel.activeAuction.getStandardDeliverAndTermsUrl()" target="_blank">VIEW</a>
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-2">
        <div class="col-md-3">
            <nb-checkbox type="checkbox"
                         id="hascustomdeliveryandpaymentterms"
                         name="hascustomdeliveryandpaymentterms"
                         (checkedChange)="onCustomDeliveryAndPaymentTermsCheckbox($event)"
                         [(ngModel)] ="auctionModel.activeAuction.hasCustomDeliveryAndPaymentTerms">CUSTOM
            </nb-checkbox>
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-4 mb-5">
        <div class="col-md-12" *ngIf="auctionModel.activeAuction.hasCustomDeliveryAndPaymentTerms">
            <textarea nbInput
                      fullWidth
                      style="height:100px"
                      id="customdeliveryandpaymentterms"
                      name="customdeliveryandpaymentterms"
                      [(ngModel)]="auctionModel.activeAuction.customDeliveryAndPaymentTerms"
                      placeholder="Place your text">
            </textarea>
        </div>
        <div class="col"></div>
    </div>

</div> <!-- container-fluid -->
