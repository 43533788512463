<div class="container-fluid">
    
      <div class="row mt-4">
        <div class="col-md-12">
            <div class="flex-center">
                <a [routerLink]="menu.route" class="link-text ml-1">{{ menu.title }}</a>
            </div>
        </div>
      </div> 
      
</div>
