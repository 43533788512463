import { Injectable } from '@angular/core';

import API, { graphqlOperation } from '@aws-amplify/api';

import {
  downloadInvoice,
  downloadInvoices,
  invoice,
} from '../../graphql/queries'

import {
  createPaymentIntent
} from '../../graphql/mutations'

import {
         InvoiceQuery,
         InvoiceQueryVariables,
         CreatePaymentIntentMutationVariables,
         CreatePaymentIntentMutation,
         PaymentType,
         ResultStatus,
         DownloadInvoiceQueryVariables,
         DownloadInvoiceQuery,
         DownloadInvoicesQueryVariables,
         DownloadInvoicesQuery
        } from '../API.service';

export interface Invoice {
  auctionId: string | null,
  auctionName: string | null,
  auctionStartDate: string | null,
  invoiceId: string | null,
  invoiceNumber: string | null,
  invoiceDate: string | null,
  paymentDueDate: string | null,
  bannerImageKey: string | null,
  buyerName: string | null,
  buyerAddress: Array< string | null > | null,
  buyerPhoneNumber: string | null,
  buyerEmail: string | null,
  saleAuctionName: string | null,
  saleAuctionClosingDate: string | null,
  sellerName: string | null,
  sellerPhoneNumber: string | null,
  sellerEmail: string | null,
  sellerWebsite: string | null,
  sellerAddress: Array< string | null > | null,
  sellerContactName: string | null,
  sellerCity: string | null,
  sellerState: string | null,
  sellerZip: string | null,
  sellerNotes: string | null,
  totalAmountPaid: number | null,
  totalAmountDue: number | null,
  datePaid: string | null,
  paid: boolean | null,
  paymentMethod: string | null,
  paymentInvoiceId: string | null,
  paymentCustomerId: string | null,
  paymentInvoicePdf: string | null,
  paymentUrl: string | null,
  lineItems: Array<InvoiceLineItem> | null
}

export interface InvoiceLineItem {
  auctionId: string | null,
  invoiceLineItemId: string | null,
  lotNum: string | null,
  lotName: string | null,
  lotCategory: string | null,
  quantity: number | null,
  unitPrice: number | null,
  totalPrice: number | null,
  additionalInfo: Array< string | null > | null,
}

@Injectable()
export class InvoiceModelService {

  activeInvoice: Invoice;

  constructor() {

  }

  async loadInvoice(invoiceId: string) {
    let queryVars: InvoiceQueryVariables = {} as InvoiceQueryVariables;
    queryVars.invoiceId = invoiceId;

    try {
      const response = (await API.graphql(graphqlOperation(invoice, queryVars))) as {
        data: InvoiceQuery;
      };
      this.activeInvoice = response.data.invoice;
    }
    catch(err) {
      console.log('Error loading invoice', err);
      throw(err)
    }
    return this.activeInvoice;
  }

  async getPaymentClientSecret(invoiceId: string) {
    let queryVars: CreatePaymentIntentMutationVariables = {} as CreatePaymentIntentMutationVariables;
    queryVars.invoiceId = invoiceId;
    queryVars.paymentType = PaymentType.CREDIT_CARD;

    let result: { code: string };
    try {
      const response = (await API.graphql(graphqlOperation(createPaymentIntent, queryVars))) as {
        data: CreatePaymentIntentMutation;
      };
      result = response.data.createPaymentIntent;
    }
    catch(err) {
      console.log('Error loading invoice', err);
      throw(err)
    }
    return result.code;
  }

  async downloadInvoice(invoiceId: string) {
    let queryVars: DownloadInvoiceQueryVariables = {
      invoiceId: invoiceId
    };

    try {
      const response = (await API.graphql(graphqlOperation(downloadInvoice, queryVars))) as {
        data: DownloadInvoiceQuery;
      };
      return response.data.downloadInvoice;
    }
    catch(err) {
      console.log('Error downloading invoice', err);
      throw(err)
    }
  }

  async downloadInvoices(auctionId: string) {
    let queryVars: DownloadInvoicesQueryVariables = {
      auctionId: auctionId
    };

    try {
      const response = (await API.graphql(graphqlOperation(downloadInvoices, queryVars))) as {
        data: DownloadInvoicesQuery;
      };
      return response.data.downloadInvoices;
    }
    catch(err) {
      console.log('Error downloading invoice', err);
      throw(err)
    }
  }
}
