import { EventEmitter, Injectable, Output } from '@angular/core';

export enum ConnectivityState {
  OFFLINE,
  ONLINE
}

declare var Offline: any;

@Injectable()
export class ConnectivityService {

  constructor() {
    this.startOfflineChecks();
  }

  @Output() message$: EventEmitter<ConnectivityState> = new EventEmitter();

  private startOfflineChecks() {
    Offline.options = {checks: {xhr: {url: '/'}}};

    Offline.on("up", () => {
      this.message$.emit(ConnectivityState.ONLINE);
    })
    Offline.on("down", () => {
      this.message$.emit(ConnectivityState.OFFLINE);
    })

    window.addEventListener('online', () => {
      this.message$.emit(ConnectivityState.ONLINE);
    });

    window.addEventListener('offline', () => {
      Offline.check();
      this.message$.emit(ConnectivityState.OFFLINE);
    });
  }

}