<div class="modal-scroll auth-card">
    <nb-card>
            <nb-card-header style="padding:1rem 0px 1rem 0px">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12 no-gutters">
                            <div class="login-center">
                                <div style="margin-right:auto;visibility:hidden">
                                    <nb-icon icon="close"></nb-icon>
                                </div>
                                <img class="login-header-img" src="../../assets/brand/angusg2_ver2_logo.png">
                                <div style="margin-left:auto;margin-top:-20px">
                                     <a [routerLink]="" (click)="close()" class="button-link"><nb-icon icon="close"></nb-icon></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-12">
                            <div class="login-center"><span class="login-header-text">Forgot Password</span></div>
                        </div>
                    </div>
                    
                </div> <!--END: container-fluid -->
            </nb-card-header>

            <!-- forgot password details -->
            <nb-card-body *ngIf="displayMode==1" style="padding:1rem 0px 1rem 0px" class="maincontent">
              <form #f="ngForm" (ngSubmit)="onForgotPassword()">            
                <div class="container-fluid">
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <div class="msg-text" style="text-align:center">
                                Enter the email associated with the account you are trying to log into.
                            </div>
                        </div>
                    </div>
    
                    <div class="row">
                        <div class="col-md-12">
                            <div class="label-text">EMAIL ADDRESS</div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-md-12">
                            <div class="form-control-group">
                                <input type="email"
                                       fullWidth
                                       nbInput
                                       id="email"
                                       name="email"
                                       placeholder="Type your email address"
                                       #emailentry="ngModel"
                                       [(ngModel)]="email"
                                       [ngClass]="{'inputError':!emailentry.valid && emailentry.touched}"
                                       required email>
                                <div class="errorText" *ngIf="!emailentry.valid && emailentry.touched">Please provide a valid email address.</div>
                            </div>
                        </div>
                    </div>
    
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <button type="submit" [nbSpinner]="processing" nbSpinnerStatus="control" nbButton status="primary"  [disabled]="!f.valid || processing">SUBMIT</button>
                        </div>
                    </div>
                    
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
                        </div>
                    </div>
                    
                </div> <!-- container-fluid -->
              </form>
            </nb-card-body>

            <nb-card-body *ngIf="displayMode==2" style="padding:1rem 0px 1rem 0px" class="maincontent">
              <form #f="ngForm" (ngSubmit)="onResetPassword()">            
                <div class="container-fluid">

                    <div class="row mb-4">
                        <div class="col-md-12">
                            <div class="msg-text" style="text-align:center">Provide the verification code sent to your email.</div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-12">
                            <div class="label-text">VERIFICATION CODE</div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <div class="form-control-group">
                                <input type="text"
                                       nbInput
                                       fullWidth
                                       id="verificationcode"
                                       name="verificationcode"
                                       placeholder="Type your verification code"
                                       [(ngModel)]="verificationcode"
                                       [ngClass]="{'inputError':!verificationcodeentry.valid && verificationcodeentry.touched}"
                                       #verificationcodeentry="ngModel"
                                       required>
                                <div class="errorText" *ngIf="!verificationcodeentry.valid && verificationcodeentry.touched">Please provide verification code.</div>
                            </div> <!-- form-control-group -->
                        </div> <!-- col-md-12 -->
                    </div> <!-- row -->
                    
                    
                    <div class="row">
                        <div class="col-md-12">
                            <div class="label-text">NEW PASSWORD</div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <div class="form-control-group">
                                <nb-form-field>
                                    <input type="password"
                                           nbInput
                                           fullWidth
                                           id="newpassword"
                                           name="newpassword"
                                           data-toggle="password"
                                           placeholder="Type your password"
                                           [type]="showNewPasswordText ? 'text' : 'password'"
                                           [(ngModel)]="newpassword"
                                           [ngClass]="{'inputError':!newpasswordentry.valid && newpasswordentry.touched}"
                                           #newpasswordentry="ngModel"
                                           required>
                                    <button type="button" nbSuffix nbButton ghost (click)="onToggleShowNewPassword()">
                                       <nb-icon [icon]="showNewPasswordText ? 'eye-outline' : 'eye-off-2-outline'" pack="eva">
                                       </nb-icon>
                                    </button>  
                                </nb-form-field>
                                <div class="errorText" *ngIf="!newpasswordentry.valid && newpasswordentry.touched">Please provide a new password.</div>
                            </div> <!-- form-control-group -->
                        </div> <!-- col-md-12 -->
                    </div> <!-- row -->
                    
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <table>
                                <tr>
                                    <td style="width:1.5rem">
                                        <nb-icon *ngIf="passwordCharLength()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                    </td>
                                    <td>
                                        <span class="msg-text">8 character minimum</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
            
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <table>
                                <tr>
                                    <td style="width:1.5rem">
                                        <nb-icon *ngIf="passwordUpperCase()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                    </td>
                                    <td>
                                        <span class="msg-text">1 upper case letter</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
            
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <table>
                                <tr>
                                    <td style="width:1.5rem">
                                        <nb-icon *ngIf="passwordLowerCase()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                    </td>
                                    <td>
                                        <span class="msg-text">1 lower case letter</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-md-12">
                            <table>
                                <tr>
                                    <td style="width:1.5rem">
                                        <nb-icon *ngIf="passwordNumber()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                    </td>
                                    <td>
                                        <span class="msg-text">1 number</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-md-12">
                            <table>
                                <tr>
                                    <td style="width:1.5rem">
                                        <nb-icon *ngIf="passwordSpecialChars()" icon="checkmark-outline" style="vertical-align:bottom;color: #00e096"></nb-icon>
                                    </td>
                                    <td>
                                        <span class="msg-text">1 special character: !@#$%^&*()</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="label-text">RE-ENTER NEW PASSWORD</div>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col-md-12">
                            <div class="form-control-group">
                                <nb-form-field>
                                    <input type="password"
                                           nbInput
                                           fullWidth
                                           id="confirmpassword"
                                           name="confirmpassword"
                                           data-toggle="password"
                                           placeholder="Type your password"
                                           [type]="showConfirmPasswordText ? 'text' : 'password'"
                                           [(ngModel)]="confirmpassword"
                                           [ngClass]="{'inputError':!confirmpasswordentry.valid && confirmpasswordentry.touched}"
                                           #confirmpasswordentry="ngModel"
                                           required>
                                    <button *ngIf="!confirmPasswordCheck()" type="button" nbSuffix nbButton ghost (click)="onToggleShowConfirmPassword()">
                                       <nb-icon [icon]="showConfirmPasswordText ? 'eye-outline' : 'eye-off-2-outline'" pack="eva">
                                       </nb-icon>
                                    </button>  
                                    <nb-icon *ngIf="confirmPasswordCheck()" nbSuffix style="color: #00e096" icon="checkmark-outline" pack="eva"></nb-icon>
                                </nb-form-field>
                                <div class="errorText" *ngIf="!confirmpasswordentry.valid && confirmpasswordentry.touched">Please confirm your new password.</div>
                            </div> <!-- form-control-group -->
                        </div> <!-- col-md-12 -->
                    </div> <!-- row -->
                    
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <button type="submit" [nbSpinner]="processing" nbSpinnerStatus="control" nbButton status="primary"  [disabled]="!f.valid || processing || !confirmPasswordCheck()">RESET</button>
                        </div>
                    </div>
                    
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
                        </div>
                    </div>
                    
                    <ng-container *ngIf="errorMsg.length > 0">
                        <div class="row mb-2">
                            <div class="col-md-12">
                                <div class="msg-text">Need to create an account? <a [routerLink]="" (click)="onSignUp()" class="login-link">Sign Up</a></div>
                            </div>
                        </div>
                    </ng-container>
                    
                </div> <!-- container-fluid -->
              </form>
            </nb-card-body>
    </nb-card>
</div>