import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-user-wins-lot-list',
  templateUrl: './user-wins-lot-list.component.html',
  styleUrls: ['./user-wins-lot-list.component.scss']
})
export class UserWinsLotListComponent implements OnInit {

  @Input() user=undefined;
  @Output() messageEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

}
