import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-select-user-detail',
  templateUrl: './select-user-detail.component.html',
  styleUrls: ['./select-user-detail.component.scss']
})
export class SelectUserDetailComponent implements OnInit {

  @Input() user;

  constructor() { }

  ngOnInit(): void {
  }

}
