import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PortalUser } from '../../model/portaluser.model';

@Component({
  selector: 'app-userslist',
  templateUrl: './userslist.component.html',
  styleUrls: ['./userslist.component.scss']
})
export class UserslistComponent implements OnInit {

  @Input() rows:PortalUser[] = [];
  @Output() messageEvent = new EventEmitter<any>();
  
  filteredList:PortalUser[] = [];
  p:number = 1; 
  sortHeader = {
    "element":0,
    "sortDesc":false
  };
  
  constructor() { }

  ngOnInit(): void {
    this.filteredList = this.rows.slice();
  }

  async filter(filterstr:string) {
    let tempList = this.rows.slice();

    return new Promise(resolve => {

      if(filterstr.length > 0){
        tempList = tempList.filter(function (el) {
          let familyName = el.familyName.toUpperCase();
          let givenName = el.givenName.toUpperCase();
          return (familyName.includes(filterstr.toUpperCase()) || givenName.includes(filterstr.toUpperCase()));
        });
      }

      this.filteredList = tempList.slice();

    });
  }
  
  async receiveMessage($event) {
    this.messageEvent.emit($event);
  }
  
  loadPageData(userList:PortalUser[]){
    this.filteredList = userList.slice();
  }
  
}
