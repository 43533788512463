import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { PortalUser } from '../../model/portaluser.model';
import { SellerService } from '../../model/seller.model'

@Component({
  selector: 'app-userselectiondialog',
  templateUrl: './userselectiondialog.component.html',
  styleUrls: ['./userselectiondialog.component.scss']
})
export class UserselectiondialogComponent implements OnInit {
  errorMsg = "";
  processing = false;
  
  userList:PortalUser[];
  userSelectionList = [];
  @Output() messageEvent = new EventEmitter<any>();
  
  newuser = false;
  email:string;
  firstname:string;
  lastname:string;
  phone:string;
  
  constructor(protected ref: NbDialogRef<UserselectiondialogComponent>,
              private sellerService: SellerService){}

  /**
   * angular (ng) initializers
   **/
  ngOnInit(): void {
    this.userSelectionList = [];
    for(let item of this.userList){
      let selectedUser = {
        "isSelected":false,
        "user":item
      }
      this.userSelectionList.push(selectedUser);
    }
  }
  
  validPhone(){
    var returnValue = false;
    if(this.phone != undefined && this.phone.length == 14){
      returnValue = /^\(\d{3}\)\s\d{3}-\d{4}/g.test(this.phone);
    }
    return returnValue;
  }
  
  validEmail() {
    var returnValue = false;
    if(this.email != undefined && this.email.length > 0){
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      returnValue = re.test(String(this.email).toLowerCase());
      
    }
    return returnValue;
  }  
  
  requiredFields(){
    var returnValue = this.validPhone() && 
                      this.validEmail() &&
                      this.firstname != undefined &&
                      this.firstname.length > 0 &&
                      this.lastname != undefined &&
                      this.lastname.length > 0;
    return returnValue;
  }
  
  /**
   * server (api) calls
   **/
  async confirmCreateNewUser(){
    this.processing = true;
    try {
      let returnValue = await this.sellerService.createNewUser(this.email,this.firstname,this.lastname,this.phone);
      this.addUser(returnValue);
      this.newuser = false;
      this.processing = false;
      return returnValue;
    }
    catch(err){
      this.processing = false;
      console.log('error while creating user',err);
      this.errorMsg = err['errors'][0]['message'];
    }
  }
  
  /**
   * html page handlers
   **/
  onClose(){
    this.closeDialog();
  }
  
  onSendSelection(){
    var selections = [];
    for(let i=0;i<this.userSelectionList.length;i++){
      if(this.userSelectionList[i].isSelected){
        selections.push(this.userList[i]);
      }
    }
    
    this.closeDialog(selections);
  }
  
  onNoSelections(){
    let returnValue = true;
    for(let i=0;i<this.userSelectionList.length;i++){
      if(this.userSelectionList[i].isSelected){
        returnValue = false;
        break;
      }
    }
    return returnValue;    
  }
  
  onCreateNew(){
    this.email = "";
    this.phone = "";
    this.firstname = "";
    this.lastname = "";
    this.newuser = true;
  }

  async onConfirmCreateNew(){
    await this.confirmCreateNewUser();
  }
  
  onCancelCreateNew(){
    this.newuser = false;  
  }
  
  /**
   * other
   **/
  closeDialog(selections=[]){
    var eventMsg = {}
    eventMsg['message'] = 'closeuserselection';
    eventMsg['parms'] = selections;
    this.ref.close(eventMsg);
  }
  
  addUser(item){
      let newUser = {
        "isSelected":false,
        "user":item
      }
      this.userList.push(item);
      this.userSelectionList.push(newUser);
  }

}
