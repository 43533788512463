import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Timezones } from '../../auth/timezones';
import { environment } from '../../../environments/environment';

import moment from 'moment';

@Component({
  selector: 'app-user-wins-detail',
  templateUrl: './user-wins-detail.component.html',
  styleUrls: ['./user-wins-detail.component.scss']
})
export class UserWinsDetailComponent implements OnInit {
  
  @Input() user=undefined;
  @Output() messageEvent = new EventEmitter<any>();

  showLots:boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  getStartDateAsString(){
    var returnValue = Timezones.birthday_moment(this.user["startDate"]);
    return returnValue;
  }
  
  getCurrency(value:number){
    return "$" + value.toFixed(2);
  }
  
  hasInvoice(){
    return this.user["invoiceId"] != undefined && this.user["invoiceId"] != "";
  }
  
  onToggleShowLots(){
    this.showLots = !this.showLots;
  }
  
  public onViewInvoice() {
    window.open(
      `https://${environment.awsconfig.buyer_portal_path_url}/invoice/${this.user["invoiceId"]}`,
      '_blank'
    );
  }
  
  
}
