<div class="loading-shade" *ngIf="processing"></div>

<div>
    <nb-card>
        <nb-card-body style="padding:1rem 1rem 1rem 1rem" *ngIf="!newuser">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <span style="float:right">
                            <a [routerLink]="" (click)="onClose()" class="button-link"><nb-icon icon="close"></nb-icon></a>
                        </span>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-12 center">
                        <span class="content-text" style="font-weight:600">Search Users</span>
                    </div>
                </div>
                
                <div class="row mb-1">
                    <div class="col-md-4 auction-label-text">
                        SEARCH BY EMAIL ADDRESS
                    </div>
                </div> 
                
                <div class="row mb-2">
                    <div class="col-md-4">
                        <input nbInput 
                               fullWidth
                               style="width:100%" 
                               type="email" 
                               id="searchemail" 
                               name="searchemail" 
                               placeholder="Search Email"
                               [(ngModel)]="searchEmail"
                               email>            
                    </div>
                    <div class="col-md-2 justify-content-center align-self-center center r-top">
                        <button [nbSpinner]="processing" nbButton size="small" status="primary" (click)="onSearchEmail()">Search</button>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-4 label-text justify-content-center align-self-center flex-center">
                        OR
                    </div>
                    <div class="col"></div>
                </div>
                
                <div class="row mb-1">
                    <div class="col-md-12 auction-label-text">
                        SEARCH BY LAST NAME
                    </div>
                </div> 
                
                <div class="row mb-4">
                    <div class="col-md-4">
                        <input nbInput 
                               fullWidth
                               style="width:100%" 
                               type="text" 
                               id="searchlast" 
                               name="searchlast" 
                               placeholder="Search Last Name"
                               [(ngModel)]="searchLast">            
                    </div>
                    <div class="col-md-1 justify-content-center align-self-center center r-top">
                        <button [nbSpinner]="processing" nbButton size="small" status="primary" (click)="onSearchName()">Search</button>
                    </div>
                </div>
                
                <div class="row mb-2">
                    <div class="col-md-12">
                        <app-select-users-list *ngIf="userList.length > 0" [rows]="userList"></app-select-users-list>
                    </div>
                </div>

                <div class="row mb-5">
                    <div class="col-md-12 center r-top">
                        <button nbButton size="small" status="primary" (click)="onAddSelectedUsers()" [disabled]="!hasSelections()">ADD SELECTED USERS</button>
                        <button class="ml-2" nbButton size="small" status="primary" (click)="onCreateNew()">+ NEW USER</button>
                        <button class="ml-2" nbButton size="small" status="primary" appearance="ghost" (click)="onClose()">Close</button>
                    </div>
                </div>
                
            </div>
        </nb-card-body>
        
        <nb-card-body *ngIf="newuser">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <span style="float:right">
                            <a [routerLink]="" (click)="onClose()" class="button-link"><nb-icon icon="close"></nb-icon></a>
                        </span>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12 title-text center" style="font-size:15px">
                        Create New User
                    </div>
                </div>
                
                <form #f="ngForm">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="auction-label-text">FIRST NAME *</div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-6">
                            <div class="form-control-group">
                                <input type="text"
                                       nbInput
                                       fullWidth
                                       id="firstname"
                                       name="firstname"
                                       placeholder="Type your first name"
                                       [(ngModel)]="firstname"
                                       [ngClass]="{'inputError':!firstnameentry.valid && firstnameentry.touched}"
                                       #firstnameentry="ngModel"
                                       required>
                                <div class="errorText" *ngIf="!firstnameentry.valid && firstnameentry.touched">Please provide first name.</div>
                            </div> <!-- form-control-group -->
                        </div> <!-- col-md-6 -->
                        <div class="col"></div>
                    </div> <!-- row -->
                    
                    <div class="row">
                        <div class="col-md-12">
                            <div class="auction-label-text">LAST NAME *</div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-6">
                            <div class="form-control-group">
                                <input type="text"
                                       nbInput
                                       fullWidth
                                       id="lastname"
                                       name="lastname"
                                       placeholder="Type your last name"
                                       [(ngModel)]="lastname"
                                       [ngClass]="{'inputError':!lastnameentry.valid && lastnameentry.touched}"
                                       #lastnameentry="ngModel"
                                       required>
                                <div class="errorText" *ngIf="!lastnameentry.valid && lastnameentry.touched">Please provide last name.</div>
                            </div> <!-- form-control-group -->
                        </div> <!-- col-md-6 -->
                        <div class="col"></div>
                    </div> <!-- row -->
            
                    <div class="row">
                        <div class="col-md-12">
                            <div class="auction-label-text">EMAIL ADDRESS *</div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-6">
                            <div class="form-control-group">
                                <input type="email"
                                       fullWidth
                                       nbInput
                                       id="email"
                                       name="email"
                                       placeholder="Type your email address"
                                       #emailentry="ngModel"
                                       [(ngModel)]="email"
                                       [ngClass]="{'inputError':!validEmail() && emailentry.touched}"
                                       required email>
                                <div class="errorText" *ngIf="!validEmail() && emailentry.touched">Please provide a valid email address.</div>
                            </div>
                        </div> <!-- col-md-6 -->
                        <div class="col"></div>
                    </div>
            
                    <div class="row">
                        <div class="col-md-12">
                            <div class="auction-label-text">PHONE NUMBER *</div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <div class="form-control-group">
                                <input mask="(000) 000-0000"
                                       [dropSpecialCharacters]="false"
                                       type="text"
                                       nbInput
                                       fullWidth
                                       id="text"
                                       name="phone"
                                       placeholder="Type your phone number"
                                       #phoneentry="ngModel"
                                       maxlength="14"
                                       [ngClass]="{'inputError':!validPhone() && phoneentry.touched}"
                                       [(ngModel)]="phone"
                                       required>
                                <div class="errorText" *ngIf="!validPhone() && phoneentry.touched">Please provide a valid phone number.</div>
                            </div> <!-- form-control-group -->
                        </div> <!-- col-md-6 -->
                        <div class="col"></div>
                    </div> <!-- row -->
                    
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <button [nbSpinner]="processing" nbButton (click)="onConfirmCreateNew()" status="primary" [disabled]="!requiredFields()">+ CREATE USER</button>                                
                            <button class="ml-2" nbButton (click)="onCancelCreateNew()" status="basic">CANCEL</button>                                
                        </div>
                    </div>
                    
                    <div class="row mb-2" *ngIf="errorMsg.length > 0">
                        <div class="col-md-12">
                            <div><span class="errorText">{{errorMsg}}</span></div>
                        </div>
                    </div>

                </form> <!-- create user form -->  
            </div> <!-- container-fluid -->
        </nb-card-body>
        
        
    </nb-card>
</div>
