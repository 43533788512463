<div class="container-fluid">

    <div class="row no-gutters table-header-border">
	    <div class="col-md-1 mt-3 mb-2 justify-content-center align-self-center">
	      <div class="table-column-text">
                <nb-checkbox type="checkbox"  
                             id="user-{{user['user'].accountId}}"
                             name="user-{{user['user'].accountId}}"
                             [(ngModel)] ="user['isSelected']">
                </nb-checkbox>
	      </div>
	    </div>
        
	    <div class="col-md-3 mb-2 justify-content-center align-self-center">
	      <div><span class="table-column-text"> {{ user['user'].getFullName() }} </span></div>
	    </div>
    	
	    <div class="col-md-4 mb-2 justify-content-center align-self-center">
	      <div><span class="table-column-text"> {{ user['user'].email }} </span></div>
	    </div>

	    <div class="col-md-4 mb-2 justify-content-center align-self-center">
	      <div><span class="table-column-text"> {{ user['user'].phone != undefined ? user['user'].phone : ''  }} </span></div>
	    </div>

    </div> <!-- header -->
</div>
