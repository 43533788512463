import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { NbDialogService } from '@nebular/theme';
import { Seller } from '../../model/seller.model';
import { AuctionModelService, LotModel } from '../../model/auction.model';
import { ConfirmdialogComponent } from '../../confirmdialog/confirmdialog.component';
import { FileTransferService } from '../../services/filetransfer.service';
import { GlobalStateService, EditModelType } from '../../services/globalstate.service';


@Component({
  selector: 'app-auction-lots-home',
  templateUrl: './auction-lots-home.component.html',
  styleUrls: ['./auction-lots-home.component.scss']
})
export class AuctionLotsHomeComponent implements OnInit {
  errorMsg = "";
  successMsg = "";
  processing: boolean = false;
  processingLotsUpload: boolean = false;

  lotList:boolean = true;
  lotToEdit:LotModel = undefined;
  taskSubscription = {};

  @ViewChild('auctionlotlist') auctionlotlist;
  @ViewChild('auctionlotedit') auctionlotedit;
  @ViewChild('fileUpload') fileUpload: ElementRef;
  @Output() messageEvent = new EventEmitter<any>();

  constructor(public auctionModel: AuctionModelService,
              private dialogService: NbDialogService,
              private fileTransferService : FileTransferService,
              private location: Location,
              private globalstate: GlobalStateService) { }

  /**
   * angular (ng) initializers
   **/
  ngOnInit(): void {
    this.lotToEdit = undefined;
    // this.lotList = true;
  }

  ngOnDestroy() {
  }
  
  async saveLotFromMessage(localThis=this){
    var returnValue = true;
    try{
      var lot = await localThis.auctionlotedit.saveLot();
      localThis.globalstate.editNewInProgress = false;
      localThis.globalstate.editNewIsValid = true;
    }
    catch(err){
      returnValue = false;
    }
    return returnValue;
  }

  /**
   * server based api calls
   **/
  async loadLot(lot:LotModel){
    this.processing = true;
    this.errorMsg = "";
    this.successMsg = "";
    let lotToLoad:LotModel = new LotModel();
    try {
      await lotToLoad.get(lot.auctionId,lot.lotId);
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error saving lot: " + err;
      lotToLoad = undefined;
    }
    return lotToLoad;
  }

  async cloneAuctionLot(lot,lotOrder){
    this.processing = true;
    this.errorMsg = "";
    let auctionToClone = new LotModel();
    try {
      await auctionToClone.cloneAuctionLotAndAssets(lot.auctionId,lot.lotId);
      auctionToClone.lotOrder = lotOrder;
      auctionToClone.lotNum = "" + lotOrder;
      await auctionToClone.update();
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error cloning auction lot: " + err;
    }
    return auctionToClone;
  }

  async deleteAuctionLot(lot){
    var returnValue = false;
    this.processing = true;
    this.errorMsg = "";
    try {
      let response = await lot.delete();
      returnValue = true;
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error removing auction lot: " + err;
    }
    return returnValue;
  }

  async updateLotOrder(){
    this.processing = true;
    this.errorMsg = "";
    for(var i=0;i<this.auctionModel.activeAuction.lots.length;i++){
      this.auctionModel.activeAuction.lots[i].lotOrder = i+1;
    }

    try {
      await this.auctionModel.activeAuction.updateLotOrder();
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error reordering auction lots: " + err;
    }

  }

  async updateLotNumber(lot:LotModel){
    this.processing = true;
    this.errorMsg = "";

    try {
      let response = await lot.updateLotNum();
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error updating auction lot number: " + err;
    }
  }

  async uploadFile(file,signedurl){
    this.processing = true;
    this.errorMsg = "";
    this.fileTransferService.uploadSignedUrl(file,signedurl)
      .subscribe(
        data => {
            this.processing = false;
            return data;
      },
        (err) => {
          this.processing = true;
          this.errorMsg = "Error uploading file.";
          throw(err);
      }
    );
  }

  async processUploadedFile(fileName:string){
    this.processing = true;
    this.errorMsg = "";
    this.taskSubscription = {};

    try {
      var task = await this.auctionModel.activeAuction.processUploadedLotsCsv(fileName);
      this.taskSubscription["taskId"] = task["id"];
      this.startPollingLotsUploadComplete();
      this.processingLotsUpload = true;
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error processing lots csv file: " + err;
    }
  }

  async startPollingLotsUploadComplete(){
    try {
      var localThis = this;
      this.taskSubscription["subscriptionKey"] = await this.auctionModel.subscribeToTask(this.taskSubscription["taskId"],localThis,this.handleTaskSubscription);
    }
    catch(err){
      this.errorMsg = "System error while starting bid subscription.";
      console.log('error while starting bid subscription',err);
    }
  }

  async stopPollingLotsUploadComplete(){
    this.auctionModel.unsubscribeToTaskUpdate(this.taskSubscription["taskId"]);
  }

  async handleTaskSubscription(taskData,localThis){
    localThis.successMsg = "";
    localThis.errorMsg = "";
    if(taskData["status"] == "COMPLETE"){
      localThis.successMsg = "File successfully processed.";
      localThis.notifyNewLotsLoaded();
    }
    else{
      localThis.errorMsg = "Error while processing file.";
    }

    localThis.processingLotsUpload = false;
    localThis.stopPollingLotsUploadComplete();

  }

  notifyNewLotsLoaded(){
    var eventMsg = {}
    eventMsg['message'] = 'newlotsloaded';
    this.messageEvent.emit(eventMsg);
  }
  
  /**
   * html page handlers
   **/
   onViewLotList(){
     if(this.globalstate.editNewInProgress){
        this.globalstate.routeCallbackThis = this;
        this.globalstate.routeCallback = this.viewLotList;    
        this.lotConfirmation("unsavedchanges");
     }
     else{
        this.viewLotList();
     }
   }
   
   viewLotList(localThis=this){
     localThis.globalstate.editNewInProgress = false;
     localThis.globalstate.editNewIsValid = true;
     localThis.updateURL("0","0");
     localThis.lotToEdit = undefined;
     localThis.lotList = true;
   }

  onNewLot(){
     this.updateURL("1","0");
     this.lotToEdit = undefined;
     this.globalstate.editNewInProgress = true;
     this.globalstate.editNewIsValid = true;
     this.globalstate.saveCallbackThis = this;
     this.globalstate.saveCallback = this.saveLotFromMessage;
     this.globalstate.editType = EditModelType.LOT;
     setTimeout((auctionId,localThis) => {
       localThis.lotToEdit = localThis.auctionModel.initNewLot(auctionId);
       localThis.lotList = false;
     },1000,this.auctionModel.activeAuction.auctionId,this);
  }
  
  /**
   * other
   **/
  async receiveMessage($event) {
    // console.log('AuctionLotsHomeComponent:receiveMessage',$event);
    if($event['message']=='lot-reorder'){
      this.updateLotOrder();
    }
    else if($event['message']=='newlot'){
      this.globalstate.editNewInProgress = false;
      this.globalstate.editNewIsValid = true;
      let newLot = $event['parms'];
      this.auctionModel.activeAuction.lots.push(newLot);
      this.updateURL("1",newLot.lotId);
    }
    else if($event['message']=='initnewlot'){
     this.lotToEdit = undefined;
     this.globalstate.editNewInProgress = true;
     this.globalstate.editNewIsValid = true;
     this.globalstate.saveCallbackThis = this;
     this.globalstate.saveCallback = this.saveLotFromMessage;
     this.globalstate.editType = EditModelType.LOT;
     this.updateURL("1","0");
     setTimeout((auctionId,localThis) => {
       localThis.lotToEdit = localThis.auctionModel.initNewLot(auctionId);
       localThis.lotList = false;
     },1000,this.auctionModel.activeAuction.auctionId,this);

    }
    else if($event['message']=='updatelot'){
      let lot = $event['parms'];
      let lotIndex = this.auctionModel.activeAuction.lots.findIndex((el)=>{ return el.lotId == lot.lotId });
      this.auctionModel.activeAuction.lots[lotIndex] = lot;
    }
    else if($event['message']=='editlot'){
      let lot:LotModel = $event['parms'];
      this.lotToEdit = await this.loadLot(lot);
      this.globalstate.editNewInProgress = false;
      this.globalstate.editNewIsValid = true;
      this.lotList = false;
      this.updateURL("1",this.lotToEdit.lotId);
    }
    else if($event['message']=='clonelot'){
      let lot:LotModel = $event['parms'];
      let maxLotOrder = this.auctionModel.activeAuction.lots.slice(-1)[0].lotOrder;
      this.lotToEdit = undefined;
      this.lotToEdit = await this.cloneAuctionLot(lot,maxLotOrder+1);
      if(this.lotToEdit != undefined){
        this.auctionModel.activeAuction.lots.push(this.lotToEdit);
        this.lotList = false;
        this.updateURL("1",this.lotToEdit.lotId);
      }
    }
    else if($event['message']=='deletelot'){
      let lot:LotModel = $event['parms'];
      this.deleteAuctionLotConfirmation(lot);
    }
    else if($event['message']=='lotnumchanged'){
      let lot:LotModel = $event['parms'];
      this.updateLotNumber(lot);
    }
  }

  initialize(lotTab:number,lotId:string){
    if(lotTab === 0){
      this.viewLotList();
    }
    else if(lotTab === 1 && lotId === "0"){
      this.onNewLot(); 
    }
    else if(lotTab === 1 && lotId != "0"){
      var eventMsg = {}
      let lotToLoad:LotModel = new LotModel();
      lotToLoad.auctionId = this.auctionModel.activeAuction.auctionId;
      lotToLoad.lotId = lotId;
      eventMsg['message'] = 'editlot';
      eventMsg['parms'] = lotToLoad;
      this.receiveMessage(eventMsg);
    }
  }

  deleteAuctionLotConfirmation(lot){
    let localThis = this;
    this.dialogService.open(ConfirmdialogComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context:{
          type:'removelot',
          lot: lot
        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }

  async processMsg(event){
    // console.log('processMsg',event);
    if(event != undefined){
      var msg = event['message'];
      if(msg== "removelot"){
        let lot = event['parms'];
        let deleteSuccess = await this.deleteAuctionLot(lot);
        if(deleteSuccess){
          let lotIndex = this.auctionModel.activeAuction.lots.findIndex((el)=>{ return el.lotId == lot.lotId });
          this.auctionModel.activeAuction.lots.splice(lotIndex,1);
        }
        this.lotList = true;
      }
    }
  }

  getAppearance(status){
    return this.lotList == status ? "filled" : "hero";
  }

  getStatus(status){
    return this.lotList == status ? "primary" : "basic";
  }

  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds){
        break;
      }
    }
  }

  async processUploadFileDetails(e) {
    let item = undefined;
    if(e.target.files != undefined && e.target.files.length > 0){
      item = e.target.files[0];
      let fileURLs = await this.auctionModel.activeAuction.getFileURL(item.name,false);
      await this.uploadFile(item,fileURLs['uploadUrl']);
      await this.processUploadedFile(fileURLs["fileKey"]);
      this.fileUpload.nativeElement.value = "";
    }
  }
  
  lotConfirmation(type:string) {
    let localThis = this;
    this.dialogService.open(ConfirmdialogComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context:{
          type:type,
          editNewIsValid: this.globalstate.editNewIsValid,
          saveCallbackThis: this.globalstate.saveCallbackThis,
          routeCallbackThis: this.globalstate.routeCallbackThis,
          saveCallback: this.globalstate.saveCallback,
          routeCallback: this.globalstate.routeCallback
        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }
  
  updateURL(lotTab:string,lotId:string){
     var urlParms = this.location.path().split('/');
     urlParms[7] = lotTab;
     urlParms[8] = lotId;
     var newUrl = urlParms.join('/');
     this.location.go(newUrl);
  }
  
  
}
