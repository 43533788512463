import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AuctionModel } from '../../model/auction.model';
import { AuctionStatus} from '../../API.service';

import * as moment from 'moment';

@Component({
  selector: 'app-auctionslist',
  templateUrl: './auctionslist.component.html',
  styleUrls: ['./auctionslist.component.scss']
})
export class AuctionslistComponent implements OnInit {

  _TYPE_AUCTION = "auction";
  _TYPE_SELLER  = "seller";

  rows:AuctionModel[] = [];
  @Input() filterStatus:number = 0;
  @Input() type:string = "auction";
  @Output() messageEvent = new EventEmitter<any>();

  filteredList:AuctionModel[] = [];
  p:number = 1;

  sortHeader = {
    "element":0,
    "sortDesc":false
  };

  statusValues = {
    1:"SETUP",
    2:"POSTED",
    3:"LIVE",
    4:"CLOSED",
    5:"SETTLED"
  };

  constructor() { }

  ngOnInit(): void {
    this.filteredList = this.rows.slice();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rows) {
      this.filteredList = changes.rows.currentValue;
    }
  }

  onSort(element){
    // console.log('onSort:',element);
    if(this.sortHeader['element'] == element){
      this.sortHeader['sortDesc'] = !this.sortHeader['sortDesc'];
    }
    else {
      this.sortHeader['element'] = element;
      this.sortHeader['sortDesc'] = false;
    }
    this.sortList();

  }

  isSortDesc(element){
    return (this.sortHeader['element'] == element && this.sortHeader['sortDesc'])
  }

  isSortAsc(element){
    return (this.sortHeader['element'] == element && !this.sortHeader['sortDesc'])
  }

  sortList(){
    let element = this.sortHeader['element'];
    let sortDescending = this.sortHeader['sortDesc'];

    switch(element){
      case 1:
        this.filteredList.sort(sortDescending ? this.compareDescBusiness : this.compareAscBusiness);
        break;
      case 2:
        this.filteredList.sort(sortDescending ? this.compareDescTitle : this.compareAscTitle);
        break;
      case 3:
        this.filteredList.sort(sortDescending ? this.compareDescDate : this.compareAscDate);
        break;
      case 4:
        this.filteredList.sort(sortDescending ? this.compareDescStatusValue : this.compareAscStatusValue);
        break;
      default:
        break;
    }
  }

  compareAscBusiness(a:AuctionModel,b:AuctionModel){
    let valueA = a.businessName.toUpperCase();
    let valueB = b.businessName.toUpperCase();
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
  }

  compareDescBusiness(a:AuctionModel,b:AuctionModel){
    let valueA = a.businessName.toUpperCase();
    let valueB = b.businessName.toUpperCase();
    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
  }

  compareAscTitle(a:AuctionModel,b:AuctionModel){
    let valueA = a.title.toUpperCase();
    let valueB = b.title.toUpperCase();
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
  }

  compareDescTitle(a:AuctionModel,b:AuctionModel){
    let valueA = a.title.toUpperCase();
    let valueB = b.title.toUpperCase();
    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
  }

  compareAscDate(a:AuctionModel,b:AuctionModel){
    let returnValue = 0;
    let valueA = moment(a.startTime,'YYYY-MM-DD HH:mm:ss');
    let valueB = moment(b.startTime,'YYYY-MM-DD HH:mm:ss');
    if(valueA.isValid() && valueB.isValid()){
      returnValue = valueA.isBefore(valueB) ? -1 : valueB.isBefore(valueA) ? 1 : 0;
    }
    return returnValue;
  }

  compareDescDate(a:AuctionModel,b:AuctionModel){
    let returnValue = 0;
    let valueA = moment(a.startTime,'YYYY-MM-DD HH:mm:ss');
    let valueB = moment(b.startTime,'YYYY-MM-DD HH:mm:ss');
    if(valueA.isValid() && valueB.isValid()){
      returnValue = valueA.isAfter(valueB) ? -1 : valueB.isAfter(valueA) ? 1 : 0;
    }
    return returnValue;
  }

  compareAscStatus(a:AuctionModel,b:AuctionModel){
    let valueA = a.status.toUpperCase();
    let valueB = b.status.toUpperCase();
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
  }

  compareDescStatus(a:AuctionModel,b:AuctionModel){
    let valueA = a.status.toUpperCase();
    let valueB = b.status.toUpperCase();
    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
  }

  compareAscStatusValue(a,b){
    let valueA = Object.keys(AuctionStatus).indexOf(a.status);
    let valueB = Object.keys(AuctionStatus).indexOf(b.status);
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
  }

  compareDescStatusValue(a,b){
    let valueA = Object.keys(AuctionStatus).indexOf(a.status);
    let valueB = Object.keys(AuctionStatus).indexOf(b.status);
    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
  }

  async initialSort(element) {
      setTimeout(() => {
        this.onSort(element);
      },100);
  }

  async filter(filterstr:string) {
    let tempList = this.rows.slice();

    return new Promise(resolve => {

      if(filterstr.length > 0){
        tempList = tempList.filter(function (el) {
          let title = el.title != undefined ? el.title.toUpperCase() : "";
          let businessName = el.businessName != undefined ? el.businessName.toUpperCase() : "";
          let filterString = filterstr.toUpperCase();
          return (title.includes(filterString) || businessName.includes(filterString));
        });
      }

      this.filteredList = tempList.slice();

    });
  }

  async filterByStatus(filtervalue:number) {
    if(filtervalue == 0){
      this.filteredList = this.rows.slice();
      return;
    }

    let tempList = this.rows.slice();
    let filterstr = this.statusValues[filtervalue];
    return new Promise(resolve => {

      if(filterstr.length > 0){
        tempList = tempList.filter(function (el) {
          let status = el.status != undefined ? el.status.toUpperCase() : "";
          return (status.includes(filterstr.toUpperCase()));
        });
      }

      this.filteredList = tempList.slice();

    });
  }

  async receiveMessage($event) {
    this.messageEvent.emit($event);
  }

  isAuction(){
    return this.type==this._TYPE_AUCTION;
  }

  isSeller(){
    return this.type==this._TYPE_SELLER;
  }
  
  loadPageData(){
    this.initialSort(4);
    this.filterByStatus(this.filterStatus);
  }

  setPage(page:number){
    this.p = page;
  }
}
