import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-lot-file-chooser',
  templateUrl: './lot-file-chooser.component.html',
  styleUrls: ['./lot-file-chooser.component.scss']
})
export class LotFileChooserComponent implements OnInit {

  @Input() multipleFiles = false;
  @Input() acceptFileTypes: string;
  @Input() showList = true;
  @Input() imageWidth:string = "32px";
  @Input() imageHeight:string = "32px";
  @Input() noDisplay = false;
  @Input() coverPhoto = false;

  @Output() messageEvent = new EventEmitter<any>();
  files = [];
  acceptFilesRegex;

  reorderOptions;

  _IMAGE_TO_UPLOAD = {"width":"24px","height":"24px","vertical-align":"middle"};
  imageToDisplay = {};

  MAX_RETRIES = 10;
  imageRetries = {};

  public accept: string;

  constructor() {
    this.reorderOptions = {
        onUpdate: (event: any) => {
          this.notifyLotReorder();
        }
      };
  }

  ngOnInit(): void {
    if (this.acceptFileTypes === 'image') {
      this.accept = 'image/*';
    }
    else if (this.acceptFileTypes === 'video') {
      this.accept = 'video/*'
    }
    else if (this.acceptFileTypes) {
      this.accept = this.acceptFileTypes.split(',').map((x) => `.${x}`).join(',');
    }
    else {
      this.accept = '*';
    }
    this.acceptFilesRegex = new RegExp(this.accept);
    this.imageToDisplay = {"width:":this.imageWidth,"height":this.imageHeight,"vertical-align":"middle"};
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  prepareFilesList(files: Array<any>) {
    // console.log('prepareFilesList:',files);
    if(!this.multipleFiles && this.files.length > 0){
      return;
    }

    for (let i=0;i < files.length;i++) {
      var item = files[i];
      item.progress = 0;
      item.new = 1;
      if(this.coverPhoto==true && this.files.length==0){
        item.cover = i==0 ? true : false;
      }
      // console.log(item);
      if(!this.acceptFilesRegex.test(item.type)){
        continue;
      }
      if(item.type.indexOf('image') > -1){
        item.format = 'image';
      }
      else if (item.type.indexOf('video') > -1){
        item.format = 'video';
      }
      // this.files.push(item);
      this.previewFile(item);
    }
  }

  previewFile(item){
    var reader = new FileReader();
    reader.readAsDataURL(item);
    reader.onload = (_event) => {
      item.imageUrl = reader.result;
      this.files.push(item);
    }
  }

  removeFile(index){
    this.files.splice(index,1);
  }

  getImageUrl(item){
    // console.log('getImageUrl:',item);
    if(item.imageUrl == undefined){
      return"../../assets/icons/file-add-outline.png";
    }
    else if(item.imageUrl != undefined && this.noDisplay){
      return"../../assets/icons/file-text-outline.png";
    }
    else{
      return item.imageUrl;
    }
  }

  getImageStyle(item){
    return item.imageUrl != undefined ? this.imageToDisplay : this._IMAGE_TO_UPLOAD;
  }

  imgError($event,id){
    if (!id) return;
    if(this.imageRetries[id] == undefined){
      this.imageRetries[id] = 1;
    }
    if(this.imageRetries[id] < this.MAX_RETRIES){
      this.imageRetries[id] = this.imageRetries[id] + 1;
      let saveSrc = $event.target.src;
      $event.target.src = '';
      this.sleep(1000);
      $event.target.src = saveSrc;
    }
    else{
      this.imageRetries[id] = undefined;
      $event.target.src = '';
    }
  }

  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds){
        break;
      }
    }
  }

  notifyLotReorder(){
    for (let i=0; i<this.files.length;i++) {
      this.files[i].cover = i==0 ? true : false;
    }
  }


} // end component
