import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import moment from 'moment';
import { PaymentStatus } from 'src/app/API.service';
import { AuctionModelService, AuctionReportPayment } from 'src/app/model/auction.model';

@Component({
  selector: 'app-auction-report-partial-payment-dialog',
  templateUrl: './auction-report-partial-payment-dialog.component.html',
  styleUrls: ['./auction-report-partial-payment-dialog.component.scss']
})
export class AuctionReportPartialPaymentDialogComponent implements OnInit {

  public totalPaymentsCollectedToDate: number = 0.0;
  public totalSellerPayoutsToDate: number = 0.0;
  public fee: number;
  public partialPaymentAmount: number;
  public processing: boolean = false;

  constructor(
    public ref: NbDialogRef<AuctionReportPartialPaymentDialogComponent>,
    public auctionModel: AuctionModelService) { }

  ngOnInit(): void {
    this.calculatePaymentsCollectedToDate();
    this.calculateTotalSellerPayoutsToDate();
  }

  private calculatePaymentsCollectedToDate() {
    this.totalPaymentsCollectedToDate = (this.auctionModel.activeAuctionReport.lineItems || [])
      .map(item => {
        if (item.paymentStatus === PaymentStatus.AUTOMATED_PAID || item.paymentStatus === PaymentStatus.PAID_SALE_REP) {
          return item.finalPrice;
        } else {
          return 0;
        }
      })
      .reduce((subtotal, currentValue) => subtotal + currentValue, 0);
  }

  private calculateTotalSellerPayoutsToDate() {
    this.totalSellerPayoutsToDate = (this.auctionModel.activeAuctionReport.payments || [])
      .map(item => item.amount)
      .reduce((subtotal, currentValue) => subtotal + currentValue, 0);
  }

  public async saveAuctionReportPartialPayment() {
    let payments: AuctionReportPayment[] = this.auctionModel.activeAuctionReport.payments || [];
    payments.push({ paymentDate: moment().toISOString(), fees: this.fee, amount: this.partialPaymentAmount });

    try {
      this.processing = true;
      await this.auctionModel.activeAuctionReport.updatePayments(payments);
      this.ref.close();
    } catch (e) {
      // TODO: Present an error message.
    } finally {
      this.processing = false;
    }
  }

  public calculatedPartialPaymentAmount() {
    return (this.partialPaymentAmount || 0) - (this.fee || 0);
  }

  public paymentLargerThanCollected() {
    return this.calculatedPartialPaymentAmount() + this.totalSellerPayoutsToDate > (this.totalPaymentsCollectedToDate || 0);
  }
}
