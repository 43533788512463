import moment from 'moment-timezone'

export class Timezones {

static timezones = [
    {"timezone":"US/Eastern","label":"US/Eastern","short":"EST","offset":"-05:00"},
    {"timezone":"US/Central","label":"US/Central","short":"CST","offset":"-06:00"},
    {"timezone":"US/Mountain","label":"US/Mountain","short":"MST","offset":"-07:00"},
    {"timezone":"US/Pacific","label":"US/Pacific","short":"PST","offset":"-08:00"},
    {"timezone":"Canada/Atlantic","label":"Canada/Atlantic","short":"AST","offset":"-04:00"},
    {"timezone":"Canada/Newfoundland","label":"Canada/Newfoundland","short":"NST","offset":"-03:30"}
  ];
  
  static getZoneInfo(timezone){
    return Timezones.timezones.find((el)=>{return el.timezone == timezone});
  }
  
  static getDateWithTimezoneApplied_moment(isoDateTime,timezone){
    return moment.utc(isoDateTime).tz(timezone);  
  }
  
  static yyyymmdd(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      var d = date.getDate();
      return '' + y + '-' + (m < 10 ? '0' : '') + m + '-' + (d < 10 ? '0' : '') + d;
  } 

  static yyyymmdd_moment(moment) {
    return moment.format("YYYY-MM-DD");
  } 

  static mmddyyyy_moment(moment) {
    return moment.format("MM/DD/YYYY");
  } 
  
  static hhmm(date) {
      var h = date.getHours();
      var m = date.getMinutes();
      return '' + (h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m;
  }  

  static hhmm_moment(moment) {
    return moment.format("HH:mm");
  }  

  static hhmm_ampm_moment(moment) {
    return moment.format("h:mm a");
  }  
  
  static ampm_moment(moment,timezoneRec) {
    return moment.format("h:mm a") + ' ' + timezoneRec.short;
  }  
  
  static getTimeISOString(datetimestr:string,timezoneRec){
    return moment.tz(datetimestr, timezoneRec['timezone']).utc().toISOString()   
  }
  
  static dateDifferenceDays_moment(isoStartDate,isoEndDate){
    var start = moment.utc(isoStartDate);
    var end = moment.utc(isoEndDate);
    return start.diff(end,'days');
  }

  static daysToDate_moment(isoEndDate){
    console.log('daysToDate');
    var start = moment.utc();
    var end = moment.utc(isoEndDate);
    return end.diff(start,'days');
  }

  static currentHour_moment(){
    return moment().format("HH:00:00");
  }

  static convert_mmddyyyyDatestrToISO_moment(datestr){
    return moment(datestr,'MM-DD-YYYY').format("YYYY-MM-DD");
  }

  static convert_ampmTimestrToISO_moment(timestr){
    return moment(timestr,'h:mm A').format("HH:mm");
  }
  
  static isDateStr_mmddyyyy(datestr){
    console.log('isDateStr_mmddyyyy:',datestr,":",/^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/g.test(datestr));
    return /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/g.test(datestr);
  }

  static isTimeStr_ampm(timestr){
    console.log('isTimeStr_ampm:',timestr,":",/^((1[0-2]|0?[0-9]):[0-5]?[0-9]\s([AaPp][Mm])|((2[0-3]|[0-1]?[0-9]):[0-5][0-9]))$/g.test(timestr));
    return /^((1[0-2]|0?[0-9]):[0-5]?[0-9]\s([AaPp][Mm])|((2[0-3]|[0-1]?[0-9]):[0-5][0-9]))$/g.test(timestr);
  }
  
  static birthday_moment(dateStr) {
    return moment(dateStr).format("MMM DD, YYYY");
  }

}

