
<div class="loading-shade" *ngIf="processing"></div>
<div>
    <nb-card>
        <nb-card-body>
            <nb-icon icon="close-outline" class="float-right close-button" (click)="ref.close()"></nb-icon>
            <div class="container-fluid p-1 p-sm-5">
                <h6>Edit Winning Buyer</h6>
                <p>Edit the Winning Buyer to change where the invoice is sent.</p>
                <form #f="ngForm" *ngIf="newLineItem">
                  <div class="row mb-1">
                      <div class="col auction-label-text">
                          FIRST NAME
                      </div>
                  </div>

                  <div class="row mb-2">
                      <div class="col-sm-6">
                          <input nbInput
                            fullWidth
                            name="first_name"
                            [(ngModel)]="newLineItem.winningBidderFirstName"
                            required>
                      </div>
                  </div>

                  <div class="row mb-1">
                      <div class="col auction-label-text">
                          LAST NAME
                      </div>
                  </div>

                  <div class="row mb-2">
                      <div class="col-sm-6">
                          <input nbInput
                            fullWidth
                            name="last_name"
                            [(ngModel)]="newLineItem.winningBidderLastName"
                            required>
                      </div>
                  </div>

                  <div class="row mb-1">
                      <div class="col auction-label-text">
                          EMAIL
                      </div>
                  </div>

                  <div class="row mb-3">
                      <div class="col-sm-6">
                          <input nbInput
                            type="email"
                            name="email"
                            fullWidth
                            [(ngModel)]="newLineItem.winningBidderEmail"
                            required>
                      </div>
                  </div>

                  <div class="row mb-1">
                    <div class="col auction-label-text">
                        PHONE
                    </div>
                  </div>

                  <div class="row mb-3">
                      <div class="col-sm-6">
                        <input mask="(000) 000-0000"
                        [dropSpecialCharacters]="false"
                        type="text"
                        nbInput
                        fullWidth
                        id="text"
                        name="phone"
                        placeholder="Type your phone number"
                        #phoneentry="ngModel"
                        maxlength="14"
                        [ngClass]="{'inputError':!validPhone() && phoneentry.touched}"
                        [(ngModel)]="newLineItem.winningBidderPhoneNumber"
                        required>
                      <div class="errorText" *ngIf="!validPhone() && phoneentry.touched">Please provide a valid phone number.</div>                      </div>
                  </div>

                  <div class="row mb-1">
                    <div class="col auction-label-text">
                        ADDRESS LINE 1
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-sm-6">
                      <input nbInput
                        fullWidth
                        name="address1"
                        [(ngModel)]="newLineItem.winningBidderAddress[0]"
                        required>
                    </div>
                  </div>

                  <div class="row mb-1">
                    <div class="col auction-label-text">
                        ADDRESS LINE 2
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-sm-6">
                      <input nbInput
                        fullWidth
                        name="address2"
                        [(ngModel)]="newLineItem.winningBidderAddress[1]"
                        required>
                    </div>
                  </div>

                  <div class="row">
                      <div class="col align-items-center">
                          <button type="submit" [nbSpinner]="processing" nbButton size="medium" status="primary" (click)="saveAuctionReportLineItem()" [disabled]="!f.valid">Save</button>
                          <a class="back-link-text ml-2" (click)="ref.close()">Cancel</a>
                      </div>
                  </div>

                  <div *ngIf="errorMessage" class="errorText mt-2">
                    {{ errorMessage }}
                  </div>
                </form>
            </div>
        </nb-card-body>
    </nb-card>
</div>
