import { Component } from '@angular/core';
import { VisibilityService, VisibilityState } from './services/visibility.service';
import { EventService } from './services/event.service';
import { NbIconLibraries } from '@nebular/theme';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'angus-g2-admin';

  private timerWorker: Worker;

  constructor(
    private iconLibraries: NbIconLibraries,
    private visibilityService: VisibilityService,
    private eventService: EventService) {}

    ngOnInit() {
       this.iconLibraries.registerFontPack('fas', { packClass: 'fas', iconClassPrefix: 'fa' });
       this.iconLibraries.registerFontPack('far', { packClass: 'far', iconClassPrefix: 'fa' });
       this.iconLibraries.registerFontPack('fab', { packClass: 'fab', iconClassPrefix: 'fa' });

      this.visibilityService.message$.subscribe(event => {
        if (event === VisibilityState.PAGE_HIDDEN) {
          this.ngOnDestroy();
        }
        if (event === VisibilityState.PAGE_VISIBLE) {
          this.setupTimer();
        }
      });
      this.setupTimer();
    }

    ngOnDestroy() {
      if (this.timerWorker) this.timerWorker.terminate();
    }

    private setupTimer() {
      if (typeof Worker !== 'undefined') {
        // Create a centralized timer that works in spite of tab refreshes
        this.timerWorker = new Worker("./timer.worker", { type: "module" });
        this.timerWorker.onmessage = ({ data }) => {
          this.eventService.createEvent({ message: "update-timers" });
        };
        this.timerWorker.postMessage({ data: { refreshIntervalMilliseconds: 1000 }});
      } else {
        console.log("Web workers not supported, please upgrade your browser to take advantage of it.");
      }
    }
}
