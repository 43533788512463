import { Component, OnInit, Input, AfterViewInit, ElementRef, EventEmitter, Output } from '@angular/core';
import { Seller } from '../../model/seller.model';

@Component({
  selector: 'app-sellerdetail',
  templateUrl: './sellerdetail.component.html',
  styleUrls: ['./sellerdetail.component.scss']
})
export class SellerdetailComponent implements OnInit {

  @Input() seller:Seller=undefined;
  @Output() messageEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }
  
  onViewDetails(){
    var eventMsg = {}
    eventMsg['message'] = 'viewsellerdetails';
    let parms = {
      "accountId":this.seller.accountId
    }
    eventMsg['parms'] = parms;
    this.messageEvent.emit(eventMsg);
  }
  
  getPhone(){
    var returnValue = "";
    if(this.seller.businessPhoneNumbers != undefined && this.seller.businessPhoneNumbers.length >0){
      returnValue = this.seller.businessPhoneNumbers[0].phone;
      for(let item of this.seller.businessPhoneNumbers){
          if(item.preferred == true && item.phone.length > 0){
            returnValue = item.phone;
            break;
          }
      }
    }
    return returnValue;
  }
  

}
