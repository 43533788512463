import { Component, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { AuctionModel, AuctionModelService } from '../../model/auction.model';

@Component({
  selector: 'app-auctionconfigure',
  templateUrl: './auctionconfigure.component.html',
  styleUrls: ['./auctionconfigure.component.scss']
})
export class AuctionconfigureComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;
  @Output() messageEvent = new EventEmitter<any>();
  @Input() savedisabled:boolean = false;

  auctionTypes = [];
  extensionPeriodIntervals = [];
  
  closingExtensionStyle:boolean;

  constructor(public auctionModel:AuctionModelService) { }

  /**
   * angular (ng) initializers
   **/
  ngOnInit(): void {
    this.auctionTypes = AuctionModel.AUCTION_TYPES;
    this.extensionPeriodIntervals = AuctionModel.EXTENSION_PERIOD_INTERVALS;
  }
  
  /**
   * html page handlers
   **/
  onSaveAuction(){
    var eventMsg = {}
    eventMsg['message'] = 'saveauction';
    this.messageEvent.emit(eventMsg);
  }
  
  validPhone(){
    var phone = this.auctionModel.activeAuction.salesRepContact;
    var returnValue = false;
    if(phone == undefined || phone.length == 0){
      returnValue = true;
    }
    else if(phone.length == 14){
      returnValue = /^\(\d{3}\)\s\d{3}-\d{4}/g.test(phone);
    }
    return returnValue;
  }
  
  validEmail() {
    var returnValue = true;
    if(this.auctionModel.activeAuction.salesRepEmail != undefined && this.auctionModel.activeAuction.salesRepEmail.length > 0){
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      returnValue = re.test(String(this.auctionModel.activeAuction.salesRepEmail).toLowerCase());
      
    }
    return returnValue;
  }  
  
  validateFormData(){
    var returnValue = this.validPhone() && this.validEmail();
    return returnValue;    
  }
  
  onCustomTermsAndConditionsCheckbox($event){
    this.auctionModel.activeAuction.customTermsAndConditions = null;
  }

  onCustomDeliveryAndPaymentTermsCheckbox($event){
    this.auctionModel.activeAuction.customDeliveryAndPaymentTerms = null;
  }

}
