import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { PortalUser } from '../../model/portaluser.model';
import { SellerService, Seller } from '../../model/seller.model';
import { NbDialogService } from '@nebular/theme';
import { UserselectiondialogComponent } from '../userselectiondialog/userselectiondialog.component';
import { Timezones } from '../../auth/timezones';
import { States } from '../../auth/states';

@Component({
  selector: 'app-sellerinfodetails',
  templateUrl: './sellerinfodetails.component.html',
  styleUrls: ['./sellerinfodetails.component.scss']
})
export class SellerinfodetailsComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;

  streetAddressOne:string;
  streetAddressTwo:string;
  searchEmail:string;
  searchFirst:string;
  searchLast:string;

  proxyUserList = [];
  searchList:PortalUser[];
  timezones;
  states:string[] = [];

  @Output() messageEvent = new EventEmitter<any>();

  constructor(public sellerService: SellerService,
              private dialogService: NbDialogService) { }

  /**
   * angular (ng) initializers
   **/
  ngOnInit(): void {
      this.states = States.states;
      this.timezones = Timezones.timezones;
  }

  /**
   * server (api) calls
   **/
  async searchUserByEmail(searchEmail){
    let returnValue = [];
    this.processing = true;
    try {
      let localUser = new PortalUser();
      await localUser.load(this.searchEmail);
      this.processing = false;
      (localUser.accountId != undefined && localUser.accountId.length > 0) ? returnValue.push(localUser) : "";
      return returnValue;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving user by email',err);
    }
  }

  async searchUserByLast(searchLastName){
    let returnValue = [];
    this.processing = true;
    try {
      let localUser = new PortalUser();
      returnValue = await localUser.searchByLastFirst(this.searchLast);
      this.processing = false;
      return returnValue;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving users by lastname',err);
    }
  }

  /**
   * html page handlers
   **/
  onSaveSeller(){
    this.savePageData();
    var eventMsg = {}
    eventMsg['message'] = 'saveseller';
    this.messageEvent.emit(eventMsg);
  }

  onRemovePhone(index){
    this.sellerService.activeSeller.businessPhoneNumbers.splice(index,1);
  }

  onAddBlankPhoneNumber(phone,preferred,mobile){
    let phoneNumber = {
      "phone":"",
      "preferred":preferred,
      "mobile":mobile
    };
    this.sellerService.activeSeller.businessPhoneNumbers.push(phoneNumber);
  }

  async onSearchName(){
    this.searchList = [];
    if(this.searchLast != undefined && this.searchLast.length > 0){
      this.searchList = await this.searchUserByLast(this.searchLast);
    }
    this.openSelectionDialog(this.searchList);
  }

  async onSearchEmail(){
    this.searchList = [];
    if(this.searchEmail != undefined && this.searchEmail.length > 0){
      this.searchList = await this.searchUserByEmail(this.searchEmail);
    }
    this.openSelectionDialog(this.searchList);
  }

  /**
   * other
   **/
   loadPageData(){
     this.loadAddress();
     this.loadProxySellerList();
   }

   savePageData(){
     this.saveAddress();
     this.saveProxySellers();
     this.savePhones();
     this.saveEmail();
   }

  saveAddress(){
    this.sellerService.activeSeller.physicalAddress = [];
    if(this.streetAddressOne != undefined && this.streetAddressOne.length > 0){
      this.sellerService.activeSeller.physicalAddress.push(this.streetAddressOne);
    }

    if(this.streetAddressTwo != undefined && this.streetAddressTwo.length > 0){
      this.sellerService.activeSeller.physicalAddress.push(this.streetAddressTwo);
    }

  }

  loadAddress(){
    this.streetAddressOne = (this.sellerService.activeSeller.physicalAddress != undefined &&
                             this.sellerService.activeSeller.physicalAddress[0] != undefined) ?
                             this.sellerService.activeSeller.physicalAddress[0] : "";

    this.streetAddressTwo = (this.sellerService.activeSeller.physicalAddress != undefined &&
                             this.sellerService.activeSeller.physicalAddress[1] != undefined) ?
                             this.sellerService.activeSeller.physicalAddress[1] : "";
  }

  loadProxySellerList(){
    let seller = {};
    this.proxyUserList = [];

    if(this.sellerService.activeSeller.proxyUsers != undefined){
      for(let item of this.sellerService.activeSeller.proxyUsers){
        this.proxyUserList.push(item);
      }
    }
  }

  saveProxySellers() {
    this.sellerService.activeSeller.modifiedProxyUserIds = [];
    for(let item of this.proxyUserList){
      this.sellerService.activeSeller.modifiedProxyUserIds.push(item.accountId);
    }
  }

  savePhones() {
    var phones = this.sellerService.activeSeller.businessPhoneNumbers.slice();
    this.sellerService.activeSeller.businessPhoneNumbers = []

    for(let item of phones){
      if(item.phone.length > 0){
        this.sellerService.activeSeller.businessPhoneNumbers.push(item);
      }
    }
  }

  saveEmail(){
    this.sellerService.activeSeller.email = this.sellerService.activeSeller.email != undefined && this.sellerService.activeSeller.email.length > 0 ? this.sellerService.activeSeller.email : null;
  }

  validateBusinesPhoneNumbers(){
    var returnValue = true;
    if(this.sellerService.activeSeller != undefined && this.sellerService.activeSeller.businessPhoneNumbers != undefined){
      for(let item of this.sellerService.activeSeller.businessPhoneNumbers ){
        returnValue = /^\(\d{3}\)\s\d{3}-\d{4}/g.test(item.phone);
        if(returnValue == false){
          break;
        }
      }
    }
    return returnValue;
  }

  validateFormData(){
    var returnValue = this.validateBusinesPhoneNumbers() &&
                      this.validEmail() &&
                      this.validZip() &&
                      this.validWebAddress() &&
                      this.sellerService.activeSeller.givenName != undefined &&
                      this.sellerService.activeSeller.familyName != undefined &&
                      this.sellerService.activeSeller.businessName != undefined &&
                      this.sellerService.activeSeller.givenName.length > 0 &&
                      this.sellerService.activeSeller.familyName.length > 0 &&
                      this.sellerService.activeSeller.businessName.length > 0;
           
    var eventMsg = {}
    eventMsg['message'] = 'sellerrequiredfields';
    eventMsg['parms'] = {
      'requiredfields':returnValue
    };
    this.messageEvent.emit(eventMsg);
    
    return returnValue;
  }


  onRemoveProxyUser(index){
    this.proxyUserList.splice(index,1);
  }

  onAddProxyUser(user){
    this.proxyUserList.push(user);
  }

  getFullName(user):string {
    var returnValue = "";
    if(user['givenName'].length===0 && user['familyName'].length===0){
      returnValue = "";
    }
    else{
      var separator = (user['givenName'].length > 0) ? ' ' : ''
      returnValue =  user['givenName'] + separator + user['familyName'];
    }
    return returnValue;
  }

  getEmail(user){
    return user['email'] != undefined ? user['email'] : "";
  }

  getPhone(user){
    return user['phone'] != undefined ? user['phone'] : "";
  }

  openSelectionDialog(selectionList){
    let localThis = this;
    this.dialogService.open(UserselectiondialogComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context:{
          userList:selectionList
        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }

  processMsg(event){
    if(event != undefined){
      var msg = event['message'];
      if(msg== "closeuserselection"){
        let userList = event['parms'];
        for(let item of userList){
          let index = this.proxyUserList.findIndex((el)=>{return el.accountId == item.accountId});
          index == -1 ? this.proxyUserList.push(item) : "";
        }
      }
    }
  }

  validPhone(index){
    var phone = this.sellerService.activeSeller.businessPhoneNumbers[index].phone;
    var returnValue = false;
    if(phone.length == 0){
      returnValue = true;
    }
    else if(phone.length == 14){
      returnValue = /^\(\d{3}\)\s\d{3}-\d{4}/g.test(phone);
    }
    return returnValue;
  }

  validZip(){
    var returnValue = true;
    if(this.sellerService.activeSeller.physicalZip != undefined && this.sellerService.activeSeller.physicalZip.length > 0){
      returnValue = /^\d{5}/g.test(this.sellerService.activeSeller.physicalZip);
    }
    return returnValue;
  }

  validEmail() {
    var returnValue = false;
    if(this.sellerService.activeSeller.email != undefined && this.sellerService.activeSeller.email.length > 0){
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      returnValue = re.test(String(this.sellerService.activeSeller.email).toLowerCase());

    }
    return returnValue;
  }

  validWebAddress() {
    var returnValue = true;
    if(this.sellerService.activeSeller.businessWebSite != undefined && this.sellerService.activeSeller.businessWebSite.length > 0){
      const re = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}/g;

      returnValue = re.test(this.sellerService.activeSeller.businessWebSite);
    }
    return returnValue;
  }

}
