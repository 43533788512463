import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Seller } from '../../model/seller.model';

@Component({
  selector: 'app-sellerslist',
  templateUrl: './sellerslist.component.html',
  styleUrls: ['./sellerslist.component.scss']
})
export class SellerslistComponent implements OnInit {

  @Input() rows:Seller[] = [];
  @Output() messageEvent = new EventEmitter<any>();
  
  filteredList:Seller[] = [];
  p:number = 1; 
  sortHeader = {
    "element":0,
    "sortDesc":false
  };

  constructor() { }

  ngOnInit(): void {
    this.filteredList = this.rows.slice();
    this.initialSort(1); 
  }

  async filter(filterstr:string) {
    let tempList = this.rows.slice();

    return new Promise(resolve => {

      if(filterstr.length > 0){
        tempList = tempList.filter(function (el) {
          let familyName = el.familyName.toUpperCase();
          let givenName = el.givenName.toUpperCase();
          let businessName = el.businessName.toUpperCase();
          return (familyName.includes(filterstr.toUpperCase()) || givenName.includes(filterstr.toUpperCase()) || businessName.includes(filterstr.toUpperCase()));
        });
      }

      this.filteredList = tempList.slice();

    });
  }
  
  onSort(element){  
    // console.log('onSort:',element);
    if(this.sortHeader['element'] == element){
      this.sortHeader['sortDesc'] = !this.sortHeader['sortDesc'];
    }
    else {
      this.sortHeader['element'] = element;
      this.sortHeader['sortDesc'] = false;
    }
    this.sortList();
    
  }
  
  isSortDesc(element){
    return (this.sortHeader['element'] == element && this.sortHeader['sortDesc'])
  }

  isSortAsc(element){
    return (this.sortHeader['element'] == element && !this.sortHeader['sortDesc'])
  }
  
  sortList(){
    let element = this.sortHeader['element'];
    let sortDescending = this.sortHeader['sortDesc'];

    switch(element){
      case 1:
        this.filteredList.sort(sortDescending ? this.compareDescLastName : this.compareAscLastName);
        break;
      case 2:
        this.filteredList.sort(sortDescending ? this.compareDescFirstName : this.compareAscFirstName);
        break;
      case 3:
        this.filteredList.sort(sortDescending ? this.compareDescBusinessName : this.compareAscBusinessName);
        break;
      case 4:
        this.filteredList.sort(sortDescending ? this.compareDescPhone : this.compareAscPhone);
        break;
      case 5:
        this.filteredList.sort(sortDescending ? this.compareDescEmail : this.compareAscEmail);
        break;
      default:
        break;
    }
  }
  
  async initialSort(element) { 
      setTimeout(() => {
        this.onSort(element);
      },100);
  }  

  compareAscBusinessName(a:Seller,b:Seller){
    let valueA = a.businessName.toUpperCase();
    let valueB = b.businessName.toUpperCase();
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
  }

  compareDescBusinessName(a:Seller,b:Seller){
    let valueA = a.businessName.toUpperCase();
    let valueB = b.businessName.toUpperCase();
    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
  }
  
  compareAscLastName(a:Seller,b:Seller){
    let valueA = a.familyName.toUpperCase();
    let valueB = b.familyName.toUpperCase();
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
  }

  compareDescLastName(a:Seller,b:Seller){
    let valueA = a.familyName.toUpperCase();
    let valueB = b.familyName.toUpperCase();
    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
  }

  compareAscFirstName(a:Seller,b:Seller){
    let valueA = a.givenName.toUpperCase();
    let valueB = b.givenName.toUpperCase();
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
  }

  compareDescFirstName(a:Seller,b:Seller){
    let valueA = a.givenName.toUpperCase();
    let valueB = b.givenName.toUpperCase();
    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
  }

  compareAscPhone(a:Seller,b:Seller){
    let valueA = a.businessPhoneNumbers != undefined && a.businessPhoneNumbers.length > 0 ? a.businessPhoneNumbers[0].phone : "";
    let valueB = b.businessPhoneNumbers != undefined && b.businessPhoneNumbers.length > 0 ? b.businessPhoneNumbers[0].phone : "";
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
  }

  compareDescPhone(a:Seller,b:Seller){
    let valueA = a.businessPhoneNumbers != undefined && a.businessPhoneNumbers.length > 0 ? a.businessPhoneNumbers[0].phone : "";
    let valueB = b.businessPhoneNumbers != undefined && b.businessPhoneNumbers.length > 0 ? b.businessPhoneNumbers[0].phone : "";
    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
  }

  compareAscEmail(a:Seller,b:Seller){
    let valueA = a.email.toUpperCase();
    let valueB = b.email.toUpperCase();
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
  }

  compareDescEmail(a:Seller,b:Seller){
    let valueA = a.email.toUpperCase();
    let valueB = b.email.toUpperCase();
    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
  }

  async receiveMessage($event) {
    this.messageEvent.emit($event);
  }
  
  setPage(page:number){
    this.p = page;
  }
  

}
