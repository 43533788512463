<div class="container-fluid">

    <div class="row mb-2">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Primary Contact
        </div>
        <div class="col-md-6 right-justify center r-top">
            <button [nbSpinner]="processing" nbButton size="small" status="primary" (click)="onSaveSeller()" [disabled]="!validateFormData()">SAVE</button>
        </div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            TITLE
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="nametitle"
                   name="nametitle"
                   placeholder="Title i.e. Mr., Mrs, Miss"
                   [(ngModel)]="sellerService.activeSeller.nameTitle">
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            FIRST NAME *
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <div class="form-control-group">
                <input nbInput
                       fullWidth
                       style="width:100%"
                       type="text"
                       id="givenname"
                       name="givenname"
                       placeholder="First Name"
                       #givennameentry="ngModel"
                       required
                       [ngClass]="{'inputError':!givennameentry.valid && givennameentry.touched}"
                       [(ngModel)]="sellerService.activeSeller.givenName">
                <div class="errorText" *ngIf="!givennameentry.valid && givennameentry.touched">Please provide first name.</div>
            </div>
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            LAST NAME *
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <div class="form-control-group">
                <input nbInput
                       fullWidth
                       style="width:100%"
                       type="text"
                       id="familyname"
                       name="familyname"
                       #familynameentry="ngModel"
                       placeholder="Last Name"
                       required
                       [ngClass]="{'inputError':!familynameentry.valid && familynameentry.touched}"
                       [(ngModel)]="sellerService.activeSeller.familyName">
                <div class="errorText" *ngIf="!familynameentry.valid && familynameentry.touched">Please provide last name.</div>
            </div>

        </div>
        <div class="col"></div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Business Details
        </div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            BUSINESS NAME *
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-6">
            <div class="form-control-group">
                <input nbInput
                       fullWidth
                       style="width:100%"
                       type="text"
                       id="businessname"
                       name="businessname"
                       placeholder="Business Name"
                       #businessnameentry="ngModel"
                       required
                       [ngClass]="{'inputError':!businessnameentry.valid && businessnameentry.touched}"
                       [(ngModel)]="sellerService.activeSeller.businessName">
                <div class="errorText" *ngIf="!businessnameentry.valid && businessnameentry.touched">Please provide business name.</div>
            </div>
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 title-text center" style="font-size:13px">
            Business Address
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            STREET ADDRESS 1
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="streetaddressone"
                   name="streetaddressone"
                   placeholder="Street Address One"
                   [(ngModel)]="streetAddressOne">
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            STREET ADDRESS 2
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="streetaddresstwo"
                   name="streetaddresstwo"
                   placeholder="Street Address Two"
                   [(ngModel)]="streetAddressTwo">
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            CITY
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="city"
                   name="city"
                   placeholder="City"
                   [(ngModel)]="sellerService.activeSeller.physicalCity">
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            STATE
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <nb-select  style="width:100%"
                        placeholder="Select State"
                        name="state"
                        id="state"
                        [(ngModel)]="sellerService.activeSeller.physicalState">
                <nb-option *ngFor="let item of states" [value]="item">{{ item }}</nb-option>
            </nb-select>
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            ZIP
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="zip"
                   name="zip"
                   placeholder="Zip"
                   #zipentry="ngModel"
                   maxlength="5"
                   [ngClass]="{'inputError':!validZip() && zipentry.touched}"
                   [(ngModel)]="sellerService.activeSeller.physicalZip">
            <div class="errorText" *ngIf="!validZip() && zipentry.touched">Please enter a 5-digit zip code.</div>
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            BUSINESS WEB SITE
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="businesswebsite"
                   name="businesswebsite"
                   placeholder="https://www.mywebsite.com"
                   #businesswebsiteentry="ngModel"
                   [ngClass]="{'inputError':!validWebAddress() && businesswebsiteentry.touched}"
                   [(ngModel)]="sellerService.activeSeller.businessWebSite">
            <div class="errorText" *ngIf="!validWebAddress() && businesswebsiteentry.touched">Please enter a valid web site address with a protocol (Format: https://www.google.com).</div>
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            TIME ZONE
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2">
        <div class="col-md-4">
            <nb-select  style="width:100%"
                        *ngIf="timezones.length > 0"
                        placeholder="Select"
                        name="businesstimezone"
                        id="businesstimezone"
                        [(ngModel)]="sellerService.activeSeller.businessTimezone">
                <nb-option *ngFor="let item of timezones" [value]="item.timezone">{{ item.label }}</nb-option>
            </nb-select>
        </div>
        <div class="col"></div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Business Contact Details and Preferences
        </div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            EMAIL ADDRESS *
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="email"
                   id="businessemail"
                   name="businessemail"
                   placeholder="Business Email"
                   #businessemailentry="ngModel"
                   [ngClass]="{'inputError':!validEmail() && businessemailentry.touched}"
                   [(ngModel)]="sellerService.activeSeller.email">
            <div class="errorText" *ngIf="!validEmail() && businessemailentry.touched">Please provide a valid email address.</div>
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            PHONE NUMBER *
        </div>
        <div class="col-md-2 auction-label-text">
            PREFERRED
        </div>
        <div class="col-md-2 auction-label-text">
            MOBILE
        </div>
        <div class="col"></div>
    </div>

    <ng-container *ngFor="let item of sellerService.activeSeller.businessPhoneNumbers;let i=index">
        <div class="row ml-2">
            <div class="col-md-4">
                <input mask="(000) 000-0000"
                       [dropSpecialCharacters]="false"
                       nbInput
                       fullWidth
                       style="width:100%"
                       type="text"
                       id="businesscontactphone-{{i}}"
                       name="businesscontactphone-{{i}}"
                       placeholder="Business Phone Number"
                       #phoneentry="ngModel"
                       [ngClass]="{'inputError':!validPhone(i) && phoneentry.touched}"
                       maxlength="14"
                       [(ngModel)]="sellerService.activeSeller.businessPhoneNumbers[i].phone">
                <div class="errorText" *ngIf="!validPhone(i) && phoneentry.touched">Please provide a valid phone number.</div>
            </div>

            <div class="col-md-2 justify-content-center align-self-center">
                <nb-checkbox type="checkbox"
                             id="businesscontactphonepreferred-{{i}}"
                             name="businesscontactphonepreferred-{{i}}"
                             [(ngModel)] ="sellerService.activeSeller.businessPhoneNumbers[i].preferred">YES
                </nb-checkbox>
            </div>

            <div class="col-md-2 justify-content-center align-self-center">
                <nb-checkbox type="checkbox"
                             id="businesscontactphonemobile-{{i}}"
                             name="businesscontactphonemobile-{{i}}"
                             [(ngModel)] ="sellerService.activeSeller.businessPhoneNumbers[i].mobile">YES
                </nb-checkbox>
            </div>

            <div class="col-md-1 justify-content-center align-self-center" *ngIf="i != 0">
                <a [routerLink]="" (click)="onRemovePhone(i)" ><nb-icon status="primary" icon="trash-2-outline" class="trash-icon"></nb-icon></a>
            </div>

            <div class="col-md-1 justify-content-center align-self-center" *ngIf="i == 0">
                <button class="ml-2" nbButton size="small" status="primary" outline (click)="onAddBlankPhoneNumber('',false,false)">Add Phone</button>
            </div>
        </div>
        <div class="row ml-2 mb-2">
            <div class="col"></div>
        </div>
    </ng-container>

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Payment Info
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Notes and Comments
        </div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-12">
            <textarea nbInput
                      fullWidth
                      style="height:100px"
                      id="businessnotes"
                      name="businessnotes"
                      [(ngModel)]="sellerService.activeSeller.businessNotes"
                      placeholder="Place your text">
            </textarea>
        </div>
        <div class="col"></div>
    </div>


    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Search for and Add Users to this Seller Business Account
        </div>
    </div>

    <div class="row ml-2 mb-1">
        <div class="col-md-4 auction-label-text">
            SEARCH BY EMAIL ADDRESS
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-4 auction-label-text">
            SEARCH BY LAST NAME
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="email"
                   id="searchemail"
                   name="searchemail"
                   placeholder="Search Email"
                   [(ngModel)]="searchEmail"
                   email>
        </div>
        <div class="col-md-2 justify-content-center align-self-center center r-top">
            <button [nbSpinner]="processing" nbButton size="small" status="primary" (click)="onSearchEmail()">Search</button>
        </div>
        <div class="col-md-1 label-text justify-content-center align-self-center center">
            OR
        </div>
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="searchlast"
                   name="searchlast"
                   placeholder="Search Last Name"
                   [(ngModel)]="searchLast">
        </div>
        <div class="col-md-1 justify-content-center align-self-center center r-top">
            <button [nbSpinner]="processing" nbButton size="small" status="primary" (click)="onSearchName()">Search</button>
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2 mb-2">
        <div class="col-md-12">
            <nb-icon status="info" icon="info-outline" class="trash-icon"></nb-icon>
            <!--<span class="help-info-text">A user login account will be generated for each proxy user. The proxy user will have access to the this Seller’s portal and any auction information.</span>-->
            <span class="help-info-text">
                Users added to a seller’s business account will have access to the Seller Portal and any auction information.
            </span>
        </div>
        <div class="col"></div>
    </div>

    <ng-container *ngIf="proxyUserList.length > 0">
        <div class="row ml-2">
            <div class="col-md-10 r-top center">
                <table width="100%">
                    <tr>
                        <th class="table-header-text">NAME</th>
                        <th class="table-header-text">EMAIL</th>
                        <th class="table-header-text">PHONE NUMBER</th>
                        <th></th>
                    </tr>
                    <tr *ngFor="let item of proxyUserList;let i=index">
                        <td class="table-column-text"> {{ getFullName(item) }}</td>
                        <td class="table-column-text"> {{ getEmail(item) }}</td>
                        <td class="table-column-text"> {{ getPhone(item) }}</td>
                        <td><a [routerLink]="" (click)="onRemoveProxyUser(i)" ><nb-icon status="primary" icon="trash-2-outline" class="trash-icon"></nb-icon></a></td>
                    </tr>
                </table>
            </div>
            <div class="col"></div>
        </div>
    </ng-container>

    <ng-container *ngIf="proxyUserList.length == 0">
        <div class="row ml-2">
            <div class="col-md-10 r-top center">
                <table width="100%">
                    <tr>
                        <th class="table-header-text">NAME</th>
                        <th class="table-header-text">EMAIL</th>
                        <th class="table-header-text">PHONE NUMBER</th>
                        <th></th>
                    </tr>
                    <tr>
                        <td colspan="2" class="auction-label-text" style="font-style:italic;font-size:10px;font-weight:normal">
                            No users are associated with this seller business account
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col"></div>
        </div>
    </ng-container>

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

</div> <!-- container-fluid -->
