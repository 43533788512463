import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-videoplayer',
  templateUrl: './videoplayer.component.html',
  styleUrls: ['./videoplayer.component.scss']
})
export class VideoplayerComponent implements OnInit {
  @Input() videoUrls = [];
  
  videoItems = [];
  activeIndex = 0;
  currentVideo;
  data;

  constructor() { }

  ngOnInit(): void {
    var ctr = 0;
    for(let item of this.videoUrls){
      ctr++;
      let vid = {};
      vid['name'] = "name #:" + ctr;
      vid['src'] = item;
      vid['type'] = "video/mp4";
      this.videoItems.push(vid);
    }
    this.currentVideo = this.videoItems[this.activeIndex];
  }
  
  videoPlayerInit(data) {
    this.data = data;
    this.data.getDefaultMedia().subscriptions.loadedMetadata.subscribe(this.initVdo.bind(this));
    this.data.getDefaultMedia().subscriptions.ended.subscribe(this.nextVideo.bind(this));
  }

  nextVideo() {
    this.activeIndex = this.activeIndex+1===this.videoItems.length ? 0 : this.activeIndex+1;
    this.currentVideo = this.videoItems[this.activeIndex];
  }

  previousVideo() {
    this.activeIndex = this.activeIndex=== 0 ? this.videoItems.length-1 : this.activeIndex-1;
    this.currentVideo = this.videoItems[this.activeIndex];
  }

  initVdo() {
    this.data.play();
  }

  startPlaylistVdo(item, index: number) {
    this.activeIndex = index;
    this.currentVideo = item;
  }  

}
