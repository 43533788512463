import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WinItem } from '../../model/portaluser.model';
import { PaymentStatus } from 'src/app/API.service';

@Component({
  selector: 'app-user-wins-list',
  templateUrl: './user-wins-list.component.html',
  styleUrls: ['./user-wins-list.component.scss']
})
export class UserWinsListComponent implements OnInit {

  @Input() rows:WinItem[] = [];
  @Output() messageEvent = new EventEmitter<any>();
  filteredList = [];

  constructor() { }

  ngOnInit(): void {
    this.loadPageData(this.rows);
  }
  
  loadPageData(winsList:WinItem[]){
    this.filteredList = this.load(winsList);
  }
  
  load(winsList:WinItem[]){
    var auctionId = "";
    var auctionItem = {};
    var returnList = [];
    
    for(var item of winsList){
      if(item.auctionId != auctionId && auctionId == ""){
        auctionItem = this.initAuctionItem(item);
        auctionId = item.auctionId;
      }
      else if(item.auctionId == auctionId){
        var totalPaid = this.isPaid(item.paymentStatus) ? (item.finalPrice * item.quantity) : 0;
        auctionItem["totalPaid"] = auctionItem["totalPaid"] + totalPaid;
        
        var total = item.finalPrice * item.quantity;
        auctionItem["total"] = auctionItem["total"] + total;
        var lot = {
          "lotNum":item.lotNum,
          "title":item.title,
          "quantity":item.quantity,
          "price":item.finalPrice,
          "subtotal": item.finalPrice * item.quantity
        }
        auctionItem["lots"].push(lot);
      }
      else if(item.auctionId != auctionId && auctionId != ""){
        returnList.push(auctionItem);
        auctionItem = this.initAuctionItem(item);
        auctionId = item.auctionId;
      }
    }
    returnList.push(auctionItem);
    return returnList;
  }
  
  isPaid(paymentStatus:PaymentStatus){
    return (paymentStatus == PaymentStatus.AUTOMATED_PAID ||
            paymentStatus == PaymentStatus.DIRECT_PAID ||
            paymentStatus == PaymentStatus.PAID_SALE_REP);
  }
  
  initAuctionItem(item:WinItem){
    var auctionItem = {}
    var totalPaid = this.isPaid(item.paymentStatus) ? (item.finalPrice * item.quantity) : 0;
    var total = item.finalPrice * item.quantity;
    auctionItem = {
      "name":item.auctionName,
      "startDate":item.auctionStartDate,
      "total": total,
      "totalPaid": totalPaid,
      "invoiceId": item.invoiceId,
      "lots":[]
    };
    var lot = {
      "lotNum":item.lotNum,
      "title":item.title,
      "quantity":item.quantity,
      "price":item.finalPrice,
      "subtotal": item.finalPrice * item.quantity
    }
    auctionItem["lots"].push(lot);
    return auctionItem;
  }
  
  async receiveMessage($event) {
    this.messageEvent.emit($event);
  }
  
}
