import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SellerService, Seller } from '../../model/seller.model';

@Component({
  selector: 'app-managesellers',
  templateUrl: './managesellers.component.html',
  styleUrls: ['./managesellers.component.scss']
})
export class ManagesellersComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;

  searchseller:string;
  sellersList:Seller[] = [];
  
  @ViewChild('mysellerslist') mySellersList;  
  
  constructor(private sellerService: SellerService,
              private router: Router) { }

  /**
   * angular (ng) initializers
   **/
  ngOnInit(): void {
    this.loadSellers();
  }
  
  /**
   * server based api calls
   **/
  async loadSellers(){
    this.processing = true;
    try {
      this.sellersList = await this.sellerService.loadSellers();
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving sellers list');
    }
  }
  
  /**
   * html page handlers
   **/
  async onUpdateFilter($event){
    this.mySellersList.setPage(1);
    await this.mySellersList.filter(this.searchseller);
  }
  
  onNewSeller(){
    this.router.navigate(['/editseller', "NEW","NEW","1"]);
  }
  
  /**
   * other
   **/
  async receiveMessage($event) {
    // console.log($event);
    if($event['message']=='viewsellerdetails'){
    	this.router.navigate(['/editseller', "EDIT",$event['parms']['accountId'],"1"]);
    }
    else if($event['message']=='deleteseller'){
      let accountId = $event['parms']['accountId'];
      let index = this.sellersList.findIndex((el)=>{return el.accountId == accountId});
      this.sellersList.splice(index,1);
    }
  }
  
  getRecordsLength(){
   return this.sellersList.length;
  }
  

}
