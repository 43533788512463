import { Component, OnInit, Input } from '@angular/core';
import { LotModel } from '../../model/auction.model';


@Component({
  selector: 'app-lot-lineage',
  templateUrl: './lot-lineage.component.html',
  styleUrls: ['./lot-lineage.component.scss']
})
export class LotLineageComponent implements OnInit {
  
  @Input() lot:LotModel = undefined;

  constructor() { }

  ngOnInit(): void {
    
  }
  
}
