<div class="loading-shade" *ngIf="processing"></div>

<div class="container-fluid">

    <div [nbSpinner]="processing" nbSpinnerStatus="primary" nbSpinnerSize="giant">
        <div class="row mt-2 mb-4">
            <div class="col-md-12 mb-4">
                <nb-icon icon="arrow-back-outline" class="back-icon"></nb-icon>
                <a [routerLink]="" (click)="onBackToSellerList()" class="back-link-text ml-1">Sellers</a>
            </div> 
        </div> 
    
        <div class="row mb-3">
            <div class="col-md-6 title-text center" style="font-size:22px">
                {{ getBusinessName() }}
            </div>
            <div class="col-md-6 right-justify center r-top" *ngIf="isEdit()">
                <button nbButton size="small" status="basic" outline (click)="onDeleteSeller()">DELETE SELLER</button>
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="col-md-12 right-justify center r-top" *ngIf="isEdit()">
            <button nbButton size="small" status="primary" outline (click)="onNewAuction()">+ NEW AUCTION</button>
        </div>
    </div>        
    
    <div class="row mb-2">
        <div class="col-md-12">
            <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12">
            <div *ngIf="successMsg.length > 0"><span class="successText">{{successMsg}}</span></div>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <app-sellerinfo #sellerinfo (messageEvent)="receiveMessage($event)"></app-sellerinfo>
        </div>
    </div>
    
</div> <!-- container-fluid -->
