<div class="loading-shade" *ngIf="processing"></div>

<div class="container-fluid">

    <div class="row mt-2 mb-4">
        <div class="col-md-12 mb-4">
            <nb-icon icon="arrow-back-outline" class="back-icon"></nb-icon>
            <a [routerLink]="" (click)="onBackToUserList()" class="back-link-text ml-1">Users</a>
        </div>
    </div>

    <div class="row mb-4" [nbSpinner]="processing" nbSpinnerStatus="primary" nbSpinnerSize="giant">
        <div class="col-md-6 title-text center" style="font-size:22px" *ngIf="user != undefined">
            {{ getUserFullName() }}
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12">
            <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12">
            <div *ngIf="successMsg.length > 0"><span class="successText">{{successMsg}}</span></div>
        </div>
    </div>

    <div class="row mb-3" style="height:48px">
        <div class="col-md-12">
            <nb-tabset (changeTab)="onSelectTab($event)">
                <nb-tab tabTitle="DETAILS"></nb-tab>
                <nb-tab *ngIf="editmode == 'EDIT'" tabTitle="WINS"></nb-tab>
            </nb-tabset>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12" *ngIf="user != undefined">
            <app-user-edit #useredit [user]=user [style]="getPage()==1 ? 'display:block' : 'display:none'" (messageEvent)="receiveMessage($event)"></app-user-edit>
            <app-user-wins *ngIf="editmode == 'EDIT'" #userwins [user]=user [style]="getPage()==2 ? 'display:block' : 'display:none'" (messageEvent)="receiveMessage($event)"></app-user-wins>
        </div>
    </div>

</div> <!-- container-fluid -->
