import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { AccountService, PortalUser } from '../../model/portaluser.model';

@Component({
  selector: 'app-user-wins',
  templateUrl: './user-wins.component.html',
  styleUrls: ['./user-wins.component.scss']
})
export class UserWinsComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;

  _NUMBER_OF_WINS_TO_DISPLAY = 10;
  @Input() user:PortalUser;
  @ViewChild('mywinslist') myWinsList;  

  constructor(public accountService: AccountService) { }

  ngOnInit(): void {
    this.loadWins();
  }
  
  /**
   * server based api calls
   **/
  async loadWins(){
    this.processing = true;
    this.errorMsg = "";
    try {
      await this.accountService.getNextWins(this.user.email,this._NUMBER_OF_WINS_TO_DISPLAY,true);
      if(this.myWinsList != undefined){
        this.myWinsList.loadPageData(this.accountService.winsList);
      }
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error while retrieving wins list. " + err;
      console.log('error while retrieving wins list. ',err);
    }
  }
  
  async nextWins(){
    this.processing = true;
    this.errorMsg = "";
    try {
      await this.accountService.getNextWins(this.user.email,this._NUMBER_OF_WINS_TO_DISPLAY);
      if(this.myWinsList != undefined){
        this.myWinsList.loadPageData(this.accountService.winsList);
      }
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error while retrieving wins list. " + err;
      console.log('error while retrieving wins list. ',err);
    }
  }

  async prevWins(){
    this.processing = true;
    this.errorMsg = "";
    try {
      await this.accountService.getPreviousWins(this.user.email,this._NUMBER_OF_WINS_TO_DISPLAY);
      if(this.myWinsList != undefined){
        this.myWinsList.loadPageData(this.accountService.winsList);
      }
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error while retrieving wins list. " + err;
      console.log('error while retrieving wins list. ',err);
    }
  }
  
  previousAvail(){
    return this.accountService.previousWinsAvailable();
  }

  nextAvail(){
    return this.accountService.moreWinsAvailable();
  }
  
  
  
  /**
   * html page handlers
   **/
  onPreviousWins(){
    this.prevWins();
  }

  onNextWins(){
    this.nextWins();
  }
  

}
