import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { AdjustmentLineItem, AuctionReportLineItem, LotModel } from '../../model/auction.model';
import { AuctionModelService } from '../../model/auction.model';
import {NgForm} from '@angular/forms';

const LINE_ITEM_COUNT = 2;
@Component({
  selector: 'app-auction-report-adjust-dialog',
  templateUrl: './auction-report-adjust-dialog.component.html',
  styleUrls: ['./auction-report-adjust-dialog.component.scss']
})
export class AuctionReportAdjustDialogComponent implements OnInit {
  public lineItem: AuctionReportLineItem;
  public processing: boolean = false;
  public lotInterestCategories = LotModel.INTEREST_CATEGORIES;

  constructor(
    public ref: NbDialogRef<AuctionReportAdjustDialogComponent>,
    private auctionModel: AuctionModelService) { }


  ngOnInit(): void {
    let additionalLineItems = this.lineItem.additionalLineItems || [];
    if (additionalLineItems.length < LINE_ITEM_COUNT) {
      for (let i = additionalLineItems.length; i < LINE_ITEM_COUNT; i++) {
        additionalLineItems.push({
          amount: null,
          note: null
        })
      }
    }
    this.lineItem.additionalLineItems = additionalLineItems
  }

  public onUpdateDiscount() {
    this.refreshFinalPrice();
  }

  public onUpdateQuantity() {
    this.refreshFinalPrice();
  }

  private refreshFinalPrice() {
    let finalPrice = this.lineItem.quantity * this.lineItem.finalBid;
    this.lineItem.additionalLineItems.forEach(x => finalPrice += x.amount)
    this.lineItem.finalPrice = finalPrice;
  }

  public async saveAuctionReportLineItem(f: NgForm) {
    this.processing = true;
    try {
      this.lineItem = await this.auctionModel.activeAuctionReport.adjustLineItemAmounts(this.lineItem);
      this.ref.close();
    } catch (e) {
      console.log("Error saving the auction report line item.")
    } finally {
      this.processing = false;
    }

  }
}
