
<div class="loading-shade" *ngIf="processing"></div>
<div class="modal-scroll">
    <nb-card>
        <nb-card-body>
            <nb-icon icon="close-outline" class="float-right close-button" (click)="ref.close()"></nb-icon>
            <div class="container-fluid p-1 p-sm-5">
                <h6>Adjust Auction Report Line Item</h6>
                <p>Adjustments will be reflected in the Settlement Sheet and the invoice.</p>
                <form #f="ngForm" (ngSubmit)="saveAuctionReportLineItem(f)">
                  <div class="row mb-1">
                      <div class="col auction-label-text">
                          LOT #
                      </div>
                  </div>

                  <div class="row mb-3">
                      <div class="col">
                          {{ lineItem.lotNum }}
                      </div>
                  </div>

                  <div class="row mb-1">
                      <div class="col auction-label-text">
                          NAME
                      </div>
                  </div>

                  <div class="row mb-3">
                      <div class="col">
                          {{ lineItem.title }}
                      </div>
                  </div>

                  <div class="row mb-1">
                      <div class="col auction-label-text">
                          WINNING BUYER
                      </div>
                  </div>

                  <div class="row mb-3">
                      <div class="col">
                          {{ lineItem.winningBidderFirstName }} {{ lineItem.winningBidderLastName }}
                      </div>
                  </div>

                  <div class="row mb-1">
                      <div class="col auction-label-text">
                          FINAL BID
                      </div>
                  </div>

                  <div class="row mb-3">
                      <div class="col">
                          ${{ lineItem.finalBid }}
                      </div>
                  </div>

                  <div class="row mb-1">
                    <div class="col auction-label-text">
                        INTEREST
                    </div>
                  </div>
                  <p class="price-alert"><nb-icon icon="alert-circle-outline"></nb-icon> Changing the Interest does not change the final price.</p>

                  <div class="row mb-3">
                      <div class="col-2">
                        <nb-select  style="width:100%"
                            name="interest"
                            id="interest"
                            [(ngModel)]="lineItem.interest">
                              <nb-option *ngFor="let item of lotInterestCategories" [value]="item.key">{{ item.value }}</nb-option>
                        </nb-select>
                      </div>
                  </div>

                  <hr>

                  <div class="row mb-1">
                      <div class="col auction-label-text">
                          QUANTITY
                      </div>
                  </div>

                  <div class="row mb-3">
                      <div class="col-2">
                          <input nbInput
                                [(ngModel)]="lineItem.quantity"
                                name="quantity"
                                fullWidth
                                (ngModelChange)="onUpdateQuantity()"
                                required>
                      </div>
                  </div>

                  <div class="row mb-1">
                    <div class="col-3">
                      <div class="row mb-1">
                        <div class="col auction-label-text">
                            ADD LINE ITEM
                        </div>
                      </div>

                      <div class="row mb-2" *ngFor="let item of lineItem.additionalLineItems; let i = index;">
                          <div class="col">
                              <input nbInput
                                    type="number"
                                    [(ngModel)]="lineItem.additionalLineItems[i].amount"
                                    name="amount{{i}}"
                                    fullWidth
                                    (ngModelChange)="refreshFinalPrice()">
                          </div>
                      </div>

                    </div>
                    <div class="col-9">
                      <div class="row mb-1">
                        <div class="col auction-label-text">
                            NOTE (FOR INVOICE)
                        </div>
                      </div>

                      <div class="row mb-2" *ngFor="let item of lineItem.additionalLineItems; let i = index;">
                          <div class="col">
                              <input nbInput
                                    fullWidth
                                    name="note{{i}}"
                                    [(ngModel)]="lineItem.additionalLineItems[i].note">
                          </div>
                      </div>

                    </div>
                  </div>

                  <p class="price-alert"><nb-icon icon="alert-circle-outline"></nb-icon> Use "-" for discounts</p>

                  <div class="row mb-1">
                    <div class="col-3">
                      <div class="row mb-1">
                        <div class="col auction-label-text">
                            FINAL PRICE
                        </div>
                      </div>

                      <div class="row mb-2">
                          <div class="col">
                              <span [attr.class]="f.dirty ? 'field-changed' : null">${{ lineItem.finalPrice }}</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                      <div class="col align-items-center">
                          <button type="submit" [nbSpinner]="processing" nbButton size="medium" status="primary" [disabled]="!f.valid">Save</button>
                          <a class="back-link-text ml-2" (click)="ref.close()">Cancel</a>
                      </div>
                  </div>
                </form>
            </div>
        </nb-card-body>
    </nb-card>
</div>
