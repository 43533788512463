<div class="loading-shade" *ngIf="processing"></div>

<div class="container-fluid">

    <div class="row">
        <div class="col-md-12">
            <app-user-wins-list #mywinslist *ngIf="accountService.winsList.length > 0" [rows]="accountService.winsList" (messageEvent)="receiveMessage($event)"></app-user-wins-list>
        </div>
    </div>
    
    <div class="row mt-2 mb-2">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <button nbButton size="small" shape="rectangle" (click)="onPreviousWins()" [disabled]="!previousAvail()">
                <nb-icon *ngIf="previousAvail()" status="primary" icon="arrow-ios-back-outline"></nb-icon>
                <nb-icon *ngIf="!previousAvail()" icon="arrow-ios-back-outline"></nb-icon>
            </button>
            <button nbButton size="small" shape="rectangle" (click)="onNextWins()" [disabled]="!nextAvail()">
                <nb-icon *ngIf="nextAvail()" status="primary" icon="arrow-ios-forward-outline"></nb-icon>
                <nb-icon *ngIf="!nextAvail()" icon="arrow-ios-forward-outline"></nb-icon>
            </button>
        </div>
    </div>

</div> <!-- container-fluid -->
