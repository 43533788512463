<div class="container-fluid">
    
      <div class="row mb-4 mt-4">
        <div class="col-md-12">
            <div class="label-text flex-center">
                {{ status.title }}
            </div>
        </div>
      </div> 
      
      <div class="row mb-3">
        <div class="col-md-12"></div>
      </div> 

      <div class="row">
        <div class="col-md-12">
            <div class="flex-center">
                <a [routerLink]="" (click)="onClickRoute()" class="link-text ml-1">{{ status.value }}</a>
            </div>
        </div>
      </div> 
      
</div>
