<div class="container-fluid">

    <div class="row no-gutters table-header-border">
	    <div class="col-md-2 mt-3 mb-3">
	      <div><span class="table-column-text"> {{ seller.familyName != undefined ? seller.familyName : '-' }} </span></div>
	    </div>
    	
	    <div class="col-md-2 mt-3 mb-3">
	      <div><span class="table-column-text"> {{ seller.givenName != undefined ? seller.givenName : '-' }} </span></div>
	    </div>
	    
	    <div class="col-md-2 mt-3 mb-3">
	      <div><span class="table-column-text"> {{ seller.businessName != undefined ? seller.businessName : '-' }} </span></div>
	    </div>

	    <div class="col-md-2 mt-3 mb-3">
	      <div><span class="table-column-text"> {{ getPhone() }} </span></div>
	    </div>

	    <div class="col-md-2 mt-3 mb-3">
	      <div><span class="table-column-text"> {{ seller.email != undefined ? seller.email : '-' }} </span></div>
	    </div>

	    <div class="col-md-2 mt-3 mb-3">
	      <div>
            <nb-icon icon="edit-outline" class="details-icon"></nb-icon>
            <a [routerLink]="" class="link-text ml-1" (click)="onViewDetails()">Details</a>
	      </div>
	    </div>

    </div> <!-- header -->
</div>
