import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { AuthhomeComponent } from '../auth/authhome/authhome.component';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() messageEvent = new EventEmitter<any>();

  constructor(private router:Router,
              private dialogService: NbDialogService,
              public authService:AuthService) { }

  ngOnInit(): void {

  }

  onLogout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }
  
  onSignin(){
    this.openAuth("signin");
  }

  onChangePassword(){
    this.openAuth("changepassword");
  }

  processMsg(event){
    // console.log('processMsg:',event);
    if(event != undefined){
      var msg = event['message'];
      if(msg == "forgotpassword"){
        this.openAuth("forgotpassword");
      }
      else if(msg== "signup"){
        this.openAuth("signup");
      }
      else if(msg== "changepassword"){
        this.openAuth("changepassword");
      }
      else if(msg== "signin"){
        this.openAuth("signin");
      }
      else if(msg== "signupcomplete"){
      }
      else if(msg== "signinsuccessful"){
      }
    }
  }
  
  openAuth(pageName){
    console.log('pageName',pageName);
    let localThis = this;
    this.dialogService.open(AuthhomeComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context:{
          authPageName:pageName
        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }
  
  getFirstName():string{
    var returnValue = "";
    if(this.authService.activeUser != undefined && this.authService.activeUser.givenName != undefined){
      returnValue = this.authService.activeUser.givenName;
    }
    return returnValue;
  }
  
}
