<div class="container-fluid">

    <div class="row no-gutters table-header-border">
	    <div class="col-md-2 mt-2 ">
	        <div><span class="table-column-text"></span></div>
	    </div>
        
	    <div class="col-md-3 d-md-block d-none mt-3">
	      <div>
	          <span class="table-column-text"> Lot #{{ lot['lotNum'] }} 
	              <span class="ml-2">{{ lot['title'] }}</span>
	          </span>
	      </div>
	    </div>

	    <div class="col-md-2 d-md-block d-none mt-3 ">
	      <div><span class="table-column-text"> {{ lot['quantity'] }} </span></div>
	    </div>

	    <div class="col-md-2 d-md-block d-none mt-3 ">
	      <div><span class="table-column-text"> {{ getCurrency(lot['price']) }} </span></div>
	    </div>

	    <div class="col-md-2 d-md-block d-none mt-3 ">
	      <div><span class="table-column-text"> {{ getCurrency(lot['subtotal']) }} </span></div>
	    </div>

	    <div class="col-md-2 d-md-block d-none mt-3 ">
	      <div><span class="table-column-text"> </span></div>
	    </div>
    	
    </div>
    
</div>
