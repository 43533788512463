import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NbDialogService } from '@nebular/theme';
import { ConfirmdialogComponent } from '../../confirmdialog/confirmdialog.component';
import { SellerService, Seller } from '../../model/seller.model';
import { AuctionModel } from '../../model/auction.model';
import { GlobalStateService, EditModelType } from '../../services/globalstate.service';


@Component({
  selector: 'app-selleredithome',
  templateUrl: './selleredithome.component.html',
  styleUrls: ['./selleredithome.component.scss']
})
export class SelleredithomeComponent implements OnInit {
  errorMsg = "";
  successMsg = "";
  processing: boolean = false;

  sub;
  editmode:string = "EDIT";
  accountId:string = undefined;
  sellerDetailsTab:string = "1";

  @Output() messageEvent = new EventEmitter<any>();
  @ViewChild('sellerinfo') sellerinfo;  

  constructor(private activatedRoute:ActivatedRoute,
              private sellerService: SellerService,
              private dialogService: NbDialogService,
              private router: Router,
              private location: Location,
              private globalstate: GlobalStateService) { }

  /**
   * angular (ng) initializers
   **/
  ngOnInit(): void {
    this.sub=this.activatedRoute.paramMap.subscribe(params => { 
          this.editmode = params.get('editmode'); 
          this.accountId = params.get('id');
          this.sellerDetailsTab = params.get('tab');
          if(this.editmode == "EDIT"){
            this.sellerService.activeSeller = new Seller();
            this.loadBusiness();
            this.globalstate.editNewInProgress = false;
            this.globalstate.editNewIsValid = true;
          }
          else{
            this.sellerService.activeSeller = new Seller();
            this.sellerService.activeSeller.initNewBusiness();
            this.globalstate.editNewInProgress = true;
            this.globalstate.editNewIsValid = false;
            this.globalstate.saveCallbackThis = this;
            this.globalstate.saveCallback = this.saveBusinessFromMessage;
            this.globalstate.editType = EditModelType.SELLER;
          }
      });
  }
  
  ngOnDestroy() { 
     this.sub.unsubscribe();
  }  
  
  /**
   * server based api calls
   **/
  async loadBusiness(){
    this.processing = true;
    try {
      await this.sellerService.activeSeller.loadBusiness(this.accountId);
      this.sellerinfo.loadPageData();
      this.sellerinfo.setTab(this.sellerDetailsTab);
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving seller',err);
    }
  }
  
  async saveBusiness(){
    var returnValue = true;
    this.processing = true;
    this.errorMsg = "";
    this.successMsg = "";
    try {
      await this.sellerService.activeSeller.updateBusiness();
      this.updateURL();
      this.sellerinfo.loadPageData();
      this.successMsg = "Successfully saved seller";
      this.processing = false;
      this.globalstate.editNewInProgress = false;
      this.globalstate.editNewIsValid = true;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "We found some errors in the fields below, please correct prior to saving.";
      console.log("error while updating seller:",err);
      returnValue = false;
    }
    return returnValue;
  }
  
  async saveBusinessFromMessage(localThis=this){
      var returnValue = await localThis.saveBusiness();
      return returnValue;
  }
  
  async cloneAuction(auctionId:string){
    this.processing = true;
    this.errorMsg = "";
    let returnValue = undefined
    try {
      let auctionToClone = new AuctionModel();
      let clonedAuctionId = await auctionToClone.cloneAuctionAndAssets(auctionId);
      this.processing = false;
      returnValue = clonedAuctionId;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error cloning auction: " + err;
    }
    return returnValue;
  }
  
  /**
   * html page handlers
   **/
  onDeleteSeller(){
    let localThis = this;
    this.dialogService.open(ConfirmdialogComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context:{
          type:"deleteseller"
        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }
  
  confirmSaveSeller(){
    let localThis = this;
    this.dialogService.open(ConfirmdialogComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context:{
          type:"saveseller"
        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }

  sellerConfirmation(type:string) {
    let localThis = this;
    this.dialogService.open(ConfirmdialogComponent, {
        hasScroll:false,
        closeOnBackdropClick:false,
        closeOnEsc:false,
        context:{
          type:type,
          editNewIsValid: this.globalstate.editNewIsValid,
          saveCallbackThis: this.globalstate.saveCallbackThis,
          routeCallbackThis: this.globalstate.routeCallbackThis,
          saveCallback: this.globalstate.saveCallback,
          routeCallback: this.globalstate.routeCallback
        }
      }).onClose.subscribe(event => localThis.processMsg(event));
  }

  async processMsg(event){
    if(event != undefined){
      var msg = event['message'];
      if(msg== "deleteseller"){
        var eventMsg = {}
        eventMsg['message'] = "deleteseller";
        this.messageEvent.emit(eventMsg);
        this.onBackToSellerList();
      }
      else if(msg=="saveseller"){
        await this.saveBusiness();
      }
    }
  }
  
  onNewAuction(){
    this.router.navigate(['/editauction', "NEW","NEW",0,this.sellerService.activeSeller.accountId,1,0,0]);
  }
  
  onBackToSellerList() {
      if(this.globalstate.editNewInProgress){
        this.globalstate.routeCallbackThis = this;
        this.globalstate.routeCallback = this.backToSellerList;
        this.sellerConfirmation("unsavedchanges");
      }
      else{
        this.backToSellerList();
      }
  }

  backToSellerList(localThis=this){
    	localThis.router.navigate(['/managesellers']);
  }
  
  
  /**
   * Other
   **/
  async receiveMessage($event) {
    // console.log('selleredithome:receiveMessage',$event);
    if($event['message']=='saveseller'){
      if(this.sellerService.activeSeller.modifiedProxyUserIds == undefined || this.sellerService.activeSeller.modifiedProxyUserIds.length == 0){
        this.confirmSaveSeller();
      }
      else{
        await this.saveBusiness();
      }
    }
    else if($event['message']=='cloneauction'){
      // console.log($event);
      let clonedAuctionId = await this.cloneAuction($event['parms']['auctionId']);
      if(clonedAuctionId != undefined){
    	  this.router.navigate(['/editauction', "EDIT",clonedAuctionId,0,0,1,0,0]);
      }
    }
    else if($event['message']=='viewauctiondetails'){
    	this.router.navigate(['/editauction', "EDIT",$event['parms']['auctionId'],0,0,1,0,0]);
    }
    else if($event['message']=='sellerrequiredfields'){
      this.globalstate.editNewIsValid = $event['parms']['requiredfields'];
    }
  }
  
  isEdit(){
    return (this.sellerService.activeSeller.accountId != undefined && this.sellerService.activeSeller.accountId.length > 0)
  }
  
  getBusinessName(){
    var returnValue = "";
    if(this.sellerService.activeSeller.accountId == undefined || this.sellerService.activeSeller.accountId.length == 0){
      returnValue = "New Seller";
    }
    else if(this.sellerService.activeSeller.businessName != undefined || this.sellerService.activeSeller.businessName.length > 0){
      returnValue = this.sellerService.activeSeller.businessName;
    }
    return returnValue;
  }
  
  updateURL(){
    if(this.accountId == "NEW"){
      this.accountId = this.sellerService.activeSeller.accountId;
      var urlParms = this.location.path().split('/');
      urlParms[2] = "EDIT";
      urlParms[3] = this.accountId;
      var newUrl = urlParms.join('/');
      this.location.go(newUrl);
    }
  }
  
}
