<div class="container-fluid">

    <div class="row no-gutters table-header-border">
	    <div class="col-md-1 justify-content-center align-self-center mt-3 mb-2">
	      <div *ngIf="!this.showLots"><span class="table-column-text"><a [routerLink]="" (click)="onToggleShowLots()"><nb-icon status="primary" icon="plus-outline"></nb-icon></a></span></div>
	      <div *ngIf="this.showLots"><span class="table-column-text"><a [routerLink]="" (click)="onToggleShowLots()"><nb-icon status="primary" icon="minus-outline"></nb-icon></a></span></div>
	    </div>
        
	    <div class="col-md-3 d-md-block d-none mt-3 mb-2">
	      <div><span class="table-column-text"> {{ user['name'] }} </span></div>
	    </div>

	    <div class="col-md-2 d-md-block d-none mt-3 mb-2">
	      <div><span class="table-column-text"> {{ getStartDateAsString() }} </span></div>
	    </div>

	    <div class="col-md-2 d-md-block d-none mt-3 mb-2">
	      <div><span class="table-column-text"> {{ getCurrency(user['total']) }} </span></div>
	    </div>

	    <div class="col-md-2 d-md-block d-none mt-3 mb-2">
	      <div><span class="table-column-text"> {{ getCurrency(user['totalPaid']) }} </span></div>
	    </div>

	    <div class="col-md-2 d-md-block d-none mt-3 mb-2">
	      <div>
	          <span class="table-column-text"> 
                <a *ngIf="hasInvoice()"  [routerLink]="" class="link-text ml-1" (click)="onViewInvoice()">Invoice</a>
	          </span>
	      </div>
	    </div>
    	
    </div>
    
    <div *ngIf="this.showLots" class="mt-3">
        <div class="row">
            <div class="col-md-12">
                <app-user-wins-lot-list [user]="user" (messageEvent)="receiveMessage($event)"></app-user-wins-lot-list>
            </div>
        </div>
        
    </div>    
    
</div>
