<div class="container-fluid">

    <div class="row no-gutters table-header-border">
    	
	    <div class="col-md-2 d-md-block d-none">
	      <span class="auction-label-text">FIRST NAME</span>
	    </div>
	    
	    <div class="col-md-2 d-md-block d-none">
	      <span class="auction-label-text">LAST NAME</span>
	    </div>
	    
	    <div class="col-md-2 d-md-block d-none">
	      <div><span class="auction-label-text"> ADMIN </span></div>
	    </div>

	    <div class="col-md-2 d-md-block d-none">
	      <div><span class="auction-label-text"> BIDDER </span></div>
	    </div>

	    <div class="col-md-2 d-md-block d-none">
	      <div><span class="auction-label-text"> SELLER </span></div>
	    </div>

	    <div class="col-md-2 d-md-block d-none">
	      <div><span class="auction-label-text">  </span></div>
	    </div>
    </div> <!-- header -->

    <div class="row no-gutters">
    	<div class="col-md-12">
		  <div *ngFor="let item of filteredList">
		     <app-userdetail [user]="item" (messageEvent)="receiveMessage($event)"></app-userdetail>
		     <div class="d-md-none mb-3"></div>
		  </div>
		</div>
	</div>

</div> <!-- container-fluid -->
