import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuctionModelService, AuctionModel } from '../../model/auction.model';
import { AuctionTimeStatistics } from '../../services/auction-time-statistics'
import { VisibilityService, VisibilityState } from 'src/app/services/visibility.service';
import { EventService } from 'src/app/services/event.service';

const DO_NOT_REFRESH: number = 0
const REFRESH_DAILY_INTERVAL: number = 86400000
const REFRESH_EVERY_MINUTE_INTERVAL: number = 60000
const REFRESH_EVERY_SECOND_INTERVAL: number = 1000
const MINS_BEFORE_COUNTDOWN_IN_SECS = 5


export interface TimeRemainingStatus {
  status: string;
  timeRemaining: string;
  secondsRemaining: number;
}
@Component({
  selector: 'app-auction-time-remaining-counter',
  templateUrl: './auction-time-remaining-counter.component.html',
  styleUrls: ['./auction-time-remaining-counter.component.scss']
})
export class AuctionTimeRemainingCounterComponent implements OnInit {
  private auctionTimeStatistics: AuctionTimeStatistics;
  private auctionIntervalHandle: number;
  private timeRemainingRefreshInterval: number;
  public timeRemainingMessage: TimeRemainingStatus;
  public visible: boolean = true;

  @Output() public messageEvent = new EventEmitter<any>();

  constructor(private auctionModel: AuctionModelService,
              private visibilityService: VisibilityService,
              private eventService: EventService) {
    if (auctionModel.activeAuction) {
      this.auctionTimeStatistics = auctionModel.activeAuction.timeStatistics;
    }
  }

  public ngOnInit(): void {
    this.updateTimeRemainingMessage();

    this.eventService.message$.subscribe(event => {
      if (event.message === "update-timers") {
        this.updateTimeRemainingMessage();
      }
      if (event.message === "hide-timers") {
        this.visible = false;
      }
      if (event.message === "show-timers") {
        this.visible = true;
      }
    });
  }

  public ngOnDestroy(): void {

  }

  public setUpTimeRemainingTimer() {
    const refreshInterval = this.updateTimeRemainingMessage();
    if (refreshInterval > 0) {
      const that = this;
      const interval = window.setInterval(function() { that.updateTimeRemainingMessage() }, refreshInterval);
      this.auctionIntervalHandle = interval;
    } else {
      clearInterval(this.auctionIntervalHandle);
    }
  }

  public updateTimeRemainingMessage() {
    let timeRemainingRefreshInterval: number = REFRESH_EVERY_SECOND_INTERVAL
    let timeRemainingMessage: TimeRemainingStatus = { status: "Refreshing...", timeRemaining: null, secondsRemaining: 0 }

    const auction = this.auctionModel.activeAuction;
    this.auctionTimeStatistics = auction.timeStatistics;
    if (auction && auction.startTime && auction.endTime && this.auctionTimeStatistics) {
      if (!this.auctionTimeStatistics.isClosed()) {
        timeRemainingMessage = this.generateAuctionTimeRemainingMessage();
        timeRemainingRefreshInterval = this.determineTimeRefreshInterval();
        if (this.timeRemainingRefreshInterval != timeRemainingRefreshInterval) {
          this.timeRemainingMessage = timeRemainingMessage;
          this.timeRemainingRefreshInterval = timeRemainingRefreshInterval;
        }
      } else {
        timeRemainingMessage = { status: "Auction Ended", timeRemaining: null, secondsRemaining: 0 };
        timeRemainingRefreshInterval = DO_NOT_REFRESH;
      }

      this.triggerEventsIfNeeded();
    }
    this.timeRemainingMessage = timeRemainingMessage;
    this.timeRemainingRefreshInterval = timeRemainingRefreshInterval;
    return timeRemainingRefreshInterval;
  }

  public determineTimeRefreshInterval() {
    if (this.auctionTimeStatistics.multipleDaysRemainBeforeStartOrEnd()) {
      return REFRESH_DAILY_INTERVAL
    } else if ((this.auctionTimeStatistics.isBeforeAuction() && this.auctionTimeStatistics.numMinutesBeforeAuctionStart() > MINS_BEFORE_COUNTDOWN_IN_SECS)
            || (this.auctionTimeStatistics.auctionCurrentlyActive() && this.auctionTimeStatistics.numMinutesBeforeAuctionEnd() > MINS_BEFORE_COUNTDOWN_IN_SECS)) {
      return REFRESH_EVERY_MINUTE_INTERVAL
    } else {
      return REFRESH_EVERY_SECOND_INTERVAL
    }
  }

  public triggerEventsIfNeeded() {
    if (this.auctionTimeStatistics.numSecondsBeforeAuctionStart() <= 0 && this.auctionTimeStatistics.numSecondsBeforeAuctionStart() > -2) {
      this.messageEvent.emit({ message: "auction-started"});
    } else if (this.auctionTimeStatistics.numSecondsBeforeAuctionEnd() < -120) {
      this.messageEvent.emit({ message: "auction-closed"});
    }
  }

  public generateAuctionTimeRemainingMessage() {
    let amountTimeRemaining: string = "";
    if (this.auctionTimeStatistics.multipleDaysRemainBeforeStartOrEnd()) {
      amountTimeRemaining = this.auctionTimeStatistics.auctionCurrentlyActive() ? this.auctionTimeStatistics.numDaysBeforeAuctionEnd().toString() : this.auctionTimeStatistics.numDaysBeforeAuctionStart().toString()
    } else if ((this.auctionTimeStatistics.isBeforeAuction() && this.auctionTimeStatistics.numMinutesBeforeAuctionStart() > MINS_BEFORE_COUNTDOWN_IN_SECS)
            || (this.auctionTimeStatistics.auctionCurrentlyActive() && this.auctionTimeStatistics.numMinutesBeforeAuctionEnd() > MINS_BEFORE_COUNTDOWN_IN_SECS)) {
      amountTimeRemaining = this.auctionTimeStatistics.auctionCurrentlyActive() ? this.auctionTimeStatistics.numHoursMinutesBeforeAuctionEnd() : this.auctionTimeStatistics.numHoursMinutesBeforeAuctionStart()
    } else {
      amountTimeRemaining = this.auctionTimeStatistics.auctionCurrentlyActive() ? this.auctionTimeStatistics.numMinutesSecondsBeforeAuctionEnd() : this.auctionTimeStatistics.numMinutesSecondsBeforeAuctionStart()
    }

    if (this.auctionTimeStatistics.closingExtensionActive()) {
      return {
        status: "Bidding Extended:",
        timeRemaining: amountTimeRemaining,
        secondsRemaining: this.auctionTimeStatistics.numSecondsBeforeAuctionEnd()
      };
    } else if (this.auctionTimeStatistics.auctionCurrentlyActive()) {

      return {
        status: "Time Left:",
        timeRemaining: `${amountTimeRemaining} ${this.determineTimeUnits()}`,
        secondsRemaining: this.auctionTimeStatistics.numSecondsBeforeAuctionEnd()
      };
    } else {
      return {
        status: "Auction Starts:",
        timeRemaining: `${amountTimeRemaining} ${this.determineTimeUnits()}`,
        secondsRemaining: this.auctionTimeStatistics.numSecondsBeforeAuctionStart()
      };
    }
  }

  public determineTimeUnits() {
    if (!this.auctionTimeStatistics.multipleDaysRemainBeforeStartOrEnd()) return "";

    if (this.auctionTimeStatistics.isBeforeAuction() && this.auctionTimeStatistics.numDaysBeforeAuctionStart() > 1
      || this.auctionTimeStatistics.auctionCurrentlyActive() && this.auctionTimeStatistics.numDaysBeforeAuctionEnd() > 1) {
      return "Days";
    } else {
      return "Day";
    }
  }

  public inLastMinutesOfNextEvent() {
    return this.timeRemainingMessage.secondsRemaining <= 300 && this.timeRemainingMessage.secondsRemaining > 60 && this.auctionTimeStatistics.auctionCurrentlyActive();
  }

  public inLastMinuteOfNextEvent() {
    return this.timeRemainingMessage.secondsRemaining <= 60 && this.timeRemainingMessage.secondsRemaining > 0 && this.auctionTimeStatistics.auctionCurrentlyActive();
  }

  public loadPageData() {
    this.updateTimeRemainingMessage();
  }

  public isAuctionActive(){
    return this.auctionTimeStatistics != undefined ? this.auctionTimeStatistics.auctionCurrentlyActive() : false;
  }
}
