import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-statuscard',
  templateUrl: './statuscard.component.html',
  styleUrls: ['./statuscard.component.scss']
})
export class StatuscardComponent implements OnInit {

  @Input() status;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  
  getRoute(){
    var route = [];
    route.push(this.status.route);
    this.status.status != undefined ? route.push(this.status.status) : "";
    return route;
  }
  
  onClickRoute(){
    	this.router.navigate([this.status.route, this.status.status]);
  }
  

}
