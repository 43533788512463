import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LotModel } from '../../model/auction.model';

@Component({
  selector: 'app-auction-lot-list',
  templateUrl: './auction-lot-list.component.html',
  styleUrls: ['./auction-lot-list.component.scss']
})
export class AuctionLotListComponent implements OnInit {

  @Input() rows:LotModel[];
  @Output() messageEvent = new EventEmitter<any>();
  
  options;

  constructor() { 
    this.options = {
        onUpdate: (event: any) => {
          this.notifyLotReorder();
        }
      };    
  }

  /**
   * angular (ng) initializers
   **/
  ngOnInit(): void {
    this.rows = this.rows != undefined ? this.rows : [];
  }
  
  /*
  * Other
  */
  notifyLotReorder(){
    var eventMsg = {}
    eventMsg['message'] = "lot-reorder";
    this.messageEvent.emit(eventMsg);
  }
  
  async receiveMessage($event) {
    this.messageEvent.emit($event);
  }
  
}
