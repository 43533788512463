import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { SellerService, Seller } from '../model/seller.model';


@Component({
  selector: 'app-confirmdialog',
  templateUrl: './confirmdialog.component.html',
  styleUrls: ['./confirmdialog.component.scss']
})
export class ConfirmdialogComponent implements OnInit {
  errorMsg = "";
  processing = false;

  _TYPE_DELETE_SELLER     = "deleteseller";
  _TYPE_DELETE_AUCTION    = "deleteauction";
  _TYPE_CLOSE_AUCTION     = "closeauction";
  _TYPE_SETTLE_AUCTION    = "settleauction";
  _TYPE_DIRECT_SETTLEMENT = "directsettlement";
  _TYPE_REMOVE_LOT        = "removelot";
  _TYPE_SAVE_SELLER       = "saveseller";
  _TYPE_UNSAVED_CHANGES   = "unsavedchanges";
  _TYPE_REMOVE_BID        = "removebid";

  type:string;
  editNewIsValid:boolean;
  saveCallbackThis;
  routeCallbackThis;
  saveCallback;
  routeCallback;
  lot;

  constructor(protected ref: NbDialogRef<ConfirmdialogComponent>,
              private sellerService: SellerService){}

  /**
   * angular (ng) initializers
   **/
  ngOnInit(): void {
    
  }

  /**
   * server (api) calls
   **/
  async deleteSeller(){
    this.processing = true;
    try {
      let returnValue = await this.sellerService.activeSeller.deleteBusiness();
      this.processing = false;
      this.closeDialog(this._TYPE_DELETE_SELLER);
    }
    catch(err){
      this.processing = false;
      console.log('error while deleting business',err);
      this.errorMsg = err['errors'][0]['message'];
    }

  }

  /**
   * html page handlers
   **/
  onClose(){
    this.closeDialog();
  }

  onDeleteSeller(){
    this.deleteSeller();
  }

  onDeleteAuction(){
    this.closeDialog(this._TYPE_DELETE_AUCTION);
  }

  onCloseAuction(){
    this.closeDialog(this._TYPE_CLOSE_AUCTION);
  }

  onDirectSettlement(){
    this.closeDialog(this._TYPE_DIRECT_SETTLEMENT);
  }

  onSettleAuction(){
    this.closeDialog(this._TYPE_SETTLE_AUCTION);
  }

  onRemoveLot(){
    this.closeDialog(this._TYPE_REMOVE_LOT);
  }

  onRemoveBid(){
    this.closeDialog(this._TYPE_REMOVE_BID);
  }

  onSaveSeller(){
    this.closeDialog(this._TYPE_SAVE_SELLER);
  }
  
  onContinueWithoutSaving(){
    this.closeDialog();
    this.routeCallback(this.routeCallbackThis);
  }

  async onSaveUnsavedChanges(){
    this.closeDialog();
    var returnValue = await this.saveCallback(this.saveCallbackThis);
    if(returnValue){
      this.routeCallback(this.routeCallbackThis);
    }
  }

  /**
   * other
   **/
  closeDialog(message=""){
    var eventMsg = {}
    eventMsg['message'] = message;
    this.lot != undefined ? eventMsg['parms'] = this.lot : "";
    this.ref.close(eventMsg);
  }


}
