import { Component, OnInit, Input } from '@angular/core';
import { LotModel } from '../../model/auction.model';
import { Timezones } from '../../auth/timezones';


@Component({
  selector: 'app-lot-card',
  templateUrl: './lot-card.component.html',
  styleUrls: ['./lot-card.component.scss']
})
export class LotCardComponent implements OnInit {
  
  @Input() lot:LotModel=undefined;
  @Input() timezone:string;
  @Input() bidder:string;
  timezones;
  timezoneRecord;

  constructor() { }

  ngOnInit(): void {
    this.timezones = Timezones.timezones;
    this.timezoneRecord = this.timezones.find((el)=>{return el.timezone == this.timezone});
    if(this.timezoneRecord == undefined){
      this.timezoneRecord = this.timezones.find((el)=>{return el.timezone == "US/Eastern"});
    }
  }

  getTime(datetimestr:string){
    let date = Timezones.getDateWithTimezoneApplied_moment(datetimestr,this.timezoneRecord.timezone);
    return Timezones.ampm_moment(date,this.timezoneRecord);
  }
  
  formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ampm + ' ' + this.timezoneRecord.short;
    return strTime;
  }  
  
  getBidPrice(){
    return this.lot.currentBid != undefined ? "$" + this.numberWithCommas(this.lot.currentBid) : "";
  }
  
  numberWithCommas(x:number) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }  

  getBidCount(){
    return this.lot.bidCount != undefined ? this.lot.bidCount : 0;
  }

  getBidder(){
    return this.bidder != undefined ? this.bidder : "";
  }

}
