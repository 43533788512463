<div class="container-fluid">

    <div class="row no-gutters table-header-border">
        
	    <div class="col-md-1">
	      <div><span class="table-header-text">  </span></div>
	    </div>
        
	    <div class="col-md-1">
          <span class="table-header-text-link">ORD #</span>
	    </div>

	    <div class="col-md-1">
          <span class="table-header-text-link">LOT #</span>
	    </div>

	    <div class="col-md-3">
          <span class="table-header-text-link">NAME</span>
	    </div>

	    <div class="col-md-3">
          <span class="table-header-text-link">LOT CATEGORY</span>
	    </div>
	    
	    <div class="col-md-1">
	      <div><span class="table-header-text">  </span></div>
	    </div>

	    <div class="col-md-1">
	      <div><span class="table-header-text">  </span></div>
	    </div>
	    
	    <div class="col-md-1">
	      <div><span class="table-header-text">  </span></div>
	    </div>
	    
    </div> <!-- header -->

    <div class="row no-gutters">
    	<div class="col-md-12" [sortablejs]="rows" [sortablejsOptions]="options">
		  <div *ngFor="let item of rows">
		     <app-auction-lot-detail [lot]="item" (messageEvent)="receiveMessage($event)"></app-auction-lot-detail>
		     <div class="d-md-none mb-3"></div>
		   </div>
		</div>
	</div>

</div> <!-- container-fluid -->
