import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountService, PortalUser } from '../../model/portaluser.model';


@Component({
  selector: 'app-manageusers',
  templateUrl: './manageusers.component.html',
  styleUrls: ['./manageusers.component.scss']
})
export class ManageusersComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;

  _NUMBER_OF_USERS_TO_DISPLAY = 10;
  searchUser:string = "";
  searchType = null;

  sub;
  reloadPage = "0"
  @ViewChild('myuserslist') myUsersList;  
  menuCards = [];


  constructor(public accountService: AccountService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.sub=this.activatedRoute.paramMap.subscribe(params => { 
          this.reloadPage = params.get('reload');
          if(this.reloadPage == "0" || 
             this.accountService.usersList == undefined || 
             this.accountService.usersList.length == 0){
            this.loadUsers();
          }

      });    
    
    // this.loadMenuCards();
  }
  
  /**
   * server based api calls
   **/
  async loadUsers(){
    this.processing = true;
    this.errorMsg = "";
    try {
      await this.accountService.getNextUsers(this._NUMBER_OF_USERS_TO_DISPLAY,true);
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error while retrieving users list. " + err;
      console.log('error while retrieving users list. ',err);
    }
  }
  
  async nextUsers(){
    this.processing = true;
    this.errorMsg = "";
    try {
      await this.accountService.getNextUsers(this._NUMBER_OF_USERS_TO_DISPLAY);
      if(this.myUsersList != undefined){
        this.myUsersList.loadPageData(this.accountService.usersList);
      }
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error while retrieving users list. " + err;
      console.log('error while retrieving users list. ',err);
    }
  }

  async prevUsers(){
    this.processing = true;
    this.errorMsg = "";
    try {
      await this.accountService.getPreviousUsers(this._NUMBER_OF_USERS_TO_DISPLAY);
      if(this.myUsersList != undefined){
        this.myUsersList.loadPageData(this.accountService.usersList);
      }
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error while retrieving users list. " + err;
      console.log('error while retrieving users list. ',err);
    }
  }
  
  async updateUserRoles(user:PortalUser){
    this.processing = true;
    this.errorMsg = "";
    try {
      await user.updateRoles()
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error while updating user roles. " + err;
      console.log('error while updating users roles.');
    }
  }
  
  loadMenuCards(){
    this.menuCards = [];
    var menuCard = {
      "title": "+ REPORTS",
      "route": "/reports"
    }
    this.menuCards.push(menuCard);
    var menuCard = {
      "title": "+ ISSUE RESOLUTION",
      "route": "/issueresolution"
    }
    this.menuCards.push(menuCard);
    var menuCard = {
      "title": "+ INVOICING",
      "route": "/invoicing"
    }
    this.menuCards.push(menuCard);
  }
  
  async receiveMessage($event) {
    if($event['message']=='edituser'){
    	this.router.navigate(['/userdetails', "EDIT",$event['parms']['accountId']]);
    }
    else if($event['message']=='updateroles'){
      this.updateUserRoles($event['parms']['user']);
    }
  }
  
  onNewUser(){
    this.router.navigate(['/userdetails', "NEW","NEW"]);
  }
  
  previousAvail(){
    return this.accountService.previousUsersAvailable();
  }

  nextAvail(){
    return this.accountService.moreUsersAvailable();
  }
  
  onSelectedSearchChange($event){
  }
  
  /**
   * html page handlers
   **/
  onPreviousUsers(){
    this.prevUsers();
  }

  onNextUsers(){
    this.nextUsers();
  }
  
  onSearch(){
    if(this.searchType=="1"){
      this.searchUserByLast(this.searchUser);
    }
    else if(this.searchType=="2"){
      this.searchUserByEmail(this.searchUser);
    }
  }
  
  onClearSearchType(){
    this.searchType = null;
    this.searchUser = "";
    if(this.myUsersList != undefined){
      this.myUsersList.loadPageData(this.accountService.usersList);
    }
  }
  
  searchDisabled(){
    if(this.searchType == null){
      return true;
    }
    else if(this.searchUser.length == 0){
      return true;      
    }
    return false;
  }

  searchTextFieldDisabled(){
    return (this.searchType == null);
  }
  
  /**
   * server (api) calls
   **/
  async searchUserByEmail(searchEmail){
    let returnValue = [];
    this.processing = true;
    try {
      let localUser = new PortalUser();
      await localUser.load(searchEmail);
      this.processing = false;
      (localUser.accountId != undefined && localUser.accountId.length > 0) ? returnValue.push(localUser) : "";
      if(this.myUsersList != undefined){
        this.myUsersList.loadPageData(returnValue);
      }
      return returnValue;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving user by email',err);
    }
  }

  async searchUserByLast(searchLastName){
    let returnValue = [];
    this.processing = true;
    try {
      let localUser = new PortalUser();
      returnValue = await localUser.searchByLastFirst(searchLastName);
      if(this.myUsersList != undefined){
        this.myUsersList.loadPageData(returnValue);
      }
      this.processing = false;
      return returnValue;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving users by lastname',err);
    }
  }
  
}
