<div class="loading-shade" *ngIf="processing"></div>

<div class="container-fluid">

    <div class="row mt-2 mb-4">
        <div class="col-md-12 mb-4">
            <nb-icon icon="arrow-back-outline" class="back-icon"></nb-icon>
            <a [routerLink]="" (click)="onBackToAuctionList()" class="back-link-text ml-1">Auctions</a>
        </div>
    </div>

    <div class="row mb-4">
        <div class="col-md-6 title-text center" style="font-size:22px">
            {{ auctionTitle }}
        </div>
    </div>

    <div class="row mb-4" [nbSpinner]="processing" nbSpinnerStatus="primary" nbSpinnerSize="giant">
        <div class="col-md-12">
            <app-auctionstatusflow #auctionstatusflow (messageEvent)="receiveMessage($event)"></app-auctionstatusflow>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12">
            <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12">
            <div *ngIf="successMsg.length > 0"><span class="successText">{{successMsg}}</span></div>
        </div>
    </div>

    <div class="row mb-3" style="height:48px">
        <div class="col-md-12">
            <nb-tabset (changeTab)="onSelectTab($event.tabTitle)">
                <nb-tab tabTitle="SALE INFORMATION" [active]="getPage()==1"></nb-tab>
                <nb-tab tabTitle="CONFIGURE" [active]="getPage()==2"></nb-tab>
                <ng-container *ngIf="auctionExists()">
                    <nb-tab tabTitle="LOTS" [active]="getPage()==3"></nb-tab>
                    <nb-tab tabTitle="BIDS" [active]="getPage()==4"></nb-tab>
                    <nb-tab tabTitle="AUCTION REPORT" [active]="getPage()==5"></nb-tab>
                </ng-container>
            </nb-tabset>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <app-auctionsaleinfo #auctionsaleinfo [style]="getPage()==1 ? 'display:block' : 'display:none'" (messageEvent)="receiveMessage($event)" [savedisabled]="savedisabled['auctionsaleinfo']"></app-auctionsaleinfo>
            <app-auctionconfigure #auctionconfigure [style]="getPage()==2 ? 'display:block' : 'display:none'" (messageEvent)="receiveMessage($event)" [savedisabled]="savedisabled['auctionconfigure']"></app-auctionconfigure>
            <app-auction-lots-home #lots [style]="getPage()==3 ? 'display:block' : 'display:none'" (messageEvent)="receiveMessage($event)"></app-auction-lots-home>
            <app-auction-bids-home #bidhome [style]="getPage()==4 ? 'display:block' : 'display:none'" (messageEvent)="receiveMessage($event)"></app-auction-bids-home>
            <app-auctionreport #auctionreport [style]="getPage()==5 ? 'display:block' : 'display:none'" (messageEvent)="receiveMessage($event)"></app-auctionreport>
        </div>
    </div>

</div> <!-- container-fluid -->
