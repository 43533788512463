import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { PasswordCheck } from '../passwordcheck';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {
  errorMsg = "";
  processing = false;

  showNewPasswordText:boolean = false;
  showConfirmPasswordText:boolean = false;

  email:string = "";
  verificationcode:string = "";
  newpassword:string = "";
  confirmpassword:string = "";

  showForgotPassword:boolean = true;
  showResetPassword:boolean = false;

  displayMode:number = 1;
  displayModes = {
    "forgotpassword":1,
    "resetpassword":2
  };

  @Output() messageEvent = new EventEmitter<any>();
  @Input() resetEmail:string;

  constructor(private authService: AuthService){}

  ngOnInit(): void {
    if(this.resetEmail != undefined && this.resetEmail.length > 0){
      this.email = this.resetEmail;
      this.onForgotPassword();
    }
    else{
      this.displayMode = 1;
    }
  }
  
  passwordCharLength(){
    return PasswordCheck.passwordCharLength(this.newpassword);
  }

  passwordUpperCase(){
    return PasswordCheck.passwordUpperCase(this.newpassword);
  }

  passwordLowerCase(){
    return PasswordCheck.passwordLowerCase(this.newpassword);
  }

  passwordSpecialChars(){
    return PasswordCheck.passwordSpecialChars(this.newpassword);
  }

  passwordNumber(){
    return PasswordCheck.passwordNumber(this.newpassword);
  }
  
  validPassword(){
    return PasswordCheck.validPassword(this.newpassword);
  }
  
  confirmPasswordCheck(){
    return (this.newpassword.length > 0) && (this.newpassword == this.confirmpassword);
  }
  
  close(){
    var eventMsg = {}
    eventMsg['message'] = 'closelogin';
    this.messageEvent.emit(eventMsg);
  }
  
  onToggleShowNewPassword(){
    this.showNewPasswordText = !this.showNewPasswordText;
  }
  
  onToggleShowConfirmPassword(){
    this.showConfirmPasswordText = !this.showConfirmPasswordText;
  }
  
  onCancelForgotPasswordClicked(){
    var eventMsg = {}
    eventMsg['message'] = 'cancelforgotpassword';
    this.messageEvent.emit(eventMsg);
  }

  onForgotPassword(){
    this.errorMsg = "";
    this.processing = true;
    this.authService.forgotPassword(this.email).then(response => {
         this._showResetPassword();
        this.processing = false;
        return;
    }, error => {
        this.processing = false;
        console.log(error);
        this.errorMsg = "We don’t recognize that email. Please try again or Sign Up.";
        return;
    });

  }

  _showResetPassword(){
    this.displayMode = this.displayModes["resetpassword"];
  }

  onResetPassword(){
    this.errorMsg = "";

    if(this.newpassword != this.confirmpassword){
      this.errorMsg = "New password and confirmation password do not match";
      return;
    }

    this.processing = true;
    this.authService.confirmPasswordChangeAfterForgotPassword(this.verificationcode,this.newpassword,this.email).then(response => {
        this.errorMsg = "";
        this.processing = false;
        this.onSignIn();
    }, error => {
        console.log(error);
        this.processing = false;
        this.errorMsg = error.message;
        return;
    });

  }

  onSignIn(){
    this.processing = true;
    this.errorMsg = "";
    this.authService.signinUser(this.email, this.newpassword).then(response => {
        this.processing = false;
        if(this.authService.isAdmin()){
          this.signinSuccessful();
        }
        else{
          this._logout();
          this.errorMsg = "You do not have the appropriate permissions to use the administrator portal.";
        }
    }, error => {
        this.processing = false;
        this.errorMsg = error==undefined ? "" : error.message;
        return;
    });
  }

  signinSuccessful(){
    var eventMsg = {}
    eventMsg['message'] = 'signinsuccessful';
    eventMsg['portaluser'] = this.authService.activeUser;
    this.messageEvent.emit(eventMsg);
  }

  _logout() {
    this.authService.logout();
  }
  
  onSignUp(){
    var eventMsg = {}
    eventMsg['message'] = 'signup';
    this.messageEvent.emit(eventMsg);
  }
  

}
