<div class="loading-shade" *ngIf="processing"></div>
<div class="container-fluid mt-5">
    
    <div class="row">
      <!-- wide -->
      <!--<div class="col-md-3 d-sm-block d-none">-->
      <!--    <app-settingssidebar (messageEvent)="receiveMessage($event)"></app-settingssidebar>-->
      <!--</div>-->
      
      <div class="col-md-12">
        <div class="row">
            <div class="col-md-12">
                <div class="ml-3" *ngIf="pageId==1">
                    <app-accountsettings (messageEvent)="receiveMessage($event)"></app-accountsettings>
                </div>
            </div>
        </div>

        <!--<div class="row">-->
        <!--    <div class="col-md-12">-->
        <!--        <div class="ml-3" *ngIf="pageId==2">-->
        <!--            <app-bidsandsaves (messageEvent)="receiveMessage($event)"></app-bidsandsaves>-->
        <!--        </div> -->
        <!--    </div>-->
        <!--</div>-->

        <!--<div class="row">-->
        <!--    <div class="col-md-12">-->
        <!--        <div class="ml-3" *ngIf="pageId==3">-->
        <!--            <app-following (messageEvent)="receiveMessage($event)"></app-following>-->
        <!--        </div>-->
        <!--    </div>-->
        <!--</div>-->

        <!--<div class="row">-->
        <!--    <div class="col-md-12">-->
        <!--        <div class="ml-3" *ngIf="pageId==4">-->
        <!--            <app-mysales (messageEvent)="receiveMessage($event)"></app-mysales>-->
        <!--        </div>-->
        <!--    </div>-->
        <!--</div>-->
        
    </div>
        
</div>


