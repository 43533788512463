import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SettingshomeComponent } from './accounts/settingshome/settingshome.component';
import { AuthGuard, AuthAdminGuard } from './services/auth-guard.service';
import { ManageauctionsComponent } from './auctions/manageauctions/manageauctions.component';
import { ManagesellersComponent } from './sellers/managesellers/managesellers.component';
import { ManageusersComponent } from './users/manageusers/manageusers.component';
import { AuctionedithomeComponent } from './auctions/auctionedithome/auctionedithome.component';
import { SelleredithomeComponent } from './sellers/selleredithome/selleredithome.component';
import { UserDetailsHomeComponent } from './users/user-details-home/user-details-home.component';
import { NotFoundComponent } from './not-found/not-found.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthAdminGuard]  },
  { path: 'accountsettings/:id', component: SettingshomeComponent, canActivate: [AuthAdminGuard]  },
  { path: 'manageauctions/:status', component: ManageauctionsComponent, canActivate: [AuthAdminGuard]  },
  { path: 'managesellers', component: ManagesellersComponent, canActivate: [AuthAdminGuard]  },
  { path: 'manageusers/:reload', component: ManageusersComponent, canActivate: [AuthAdminGuard]  },
  { path: 'editauction/:editmode/:id/:filterStatus/:sellerId/:auctiontab/:lottab/:lotId', component: AuctionedithomeComponent, canActivate: [AuthAdminGuard]  },
  { path: 'editseller/:editmode/:id/:tab', component: SelleredithomeComponent, canActivate: [AuthAdminGuard]  },
  { path: 'userdetails/:editmode/:id', component: UserDetailsHomeComponent, canActivate: [AuthAdminGuard]  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

