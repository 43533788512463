<div class="container-fluid">

    <div class="row mb-2">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Who
        </div>
        <div class="col-md-6 right-justify center r-top">
            <button [nbSpinner]="processing" nbButton size="small" status="primary" (click)="onSaveAuction()" [disabled]="!requiredFields() || savedisabled">SAVE</button>
        </div>
    </div> 

    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            SELECT
        </div>
        <div class="col"></div>
    </div> 

    <div class="row ml-2 mb-4">
        <div class="col-md-4">
            <nb-select  style="width:100%"
                        *ngIf="sellersList.length > 0"
                        placeholder="Select Seller"
                        name="sellerid"
                        id="sellerid"
                        [(ngModel)]="sellerAccountId">
                <nb-option *ngFor="let item of sellersList" [value]="item.accountId">{{ item.businessName }}</nb-option>
            </nb-select>
        </div>
        <div class="col-md-8">
            <button nbButton size="small" status="primary" (click)="onSeller()" style="width:80px">ADD</button>
            <button class="ml-2" nbButton size="small" status="primary" outline (click)="onNewSeller()">+ NEW SELLER</button>
        </div>
    </div> 

    <div class="row ml-2 mb-2">
        <div class="col-md-12 auction-label-text">
            SELLER BUSINESS NAME
        </div>
    </div> 

    <ng-container *ngFor="let item of sellerBusinessNameList">
        <div class="row ml-2 mb-2">
            <div class="col-md-3">
                <span class="content-text">{{ item.businessName }}</span>
            </div>
            <div class="col-md-1">
                <a [routerLink]="" (click)="onRemoveSeller(item.accountId)" ><nb-icon status="primary" icon="trash-2-outline" class="trash-icon"></nb-icon></a>
            </div>
            <div class="col"></div>
        </div> 
    </ng-container>
    
    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div> 
    
    <div class="row mt-2 mb-2">
        <div class="col-md-12 title-text center" style="font-size:15px">
            When
        </div>
    </div> 
    
    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            START DATE *
        </div>
        <div class="col-md-4 auction-label-text">
            END DATE *
        </div>
        <div class="col"></div>
    </div> 

    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            
            <ng-container *ngIf="isDateHtmlElementSupported()">                   
                <input type="date" 
                       nbInput
                       id="startdate" 
                       name="startdate"
                       style="width:100%"
                       [(ngModel)]="startDate">
            </ng-container>                
                   
            <ng-container *ngIf="!isDateHtmlElementSupported()">                   
                <input type="text" 
                       nbInput
                       id="startdate" 
                       name="startdate"
                       placeholder="mm/dd/yyyy"
                       #startdateentry="ngModel"
                       [ngClass]="{'inputError':!validStartDate() && startdateentry.touched}"
                       style="width:100%"
                       [(ngModel)]="startDate">
                <div class="errorText" *ngIf="!validStartDate() && startdateentry.touched">Please enter a valid date of format mm/dd/yyyy.</div>
            </ng-container>                
        </div>

        <div class="col-md-4">
            <ng-container *ngIf="isDateHtmlElementSupported()">                   
                <input type="date" 
                       nbInput
                       id="enddate" 
                       name="enddate"
                       style="width:100%"
                       [(ngModel)]="endDate">
            </ng-container>                

            <ng-container *ngIf="!isDateHtmlElementSupported()">                   
                <input type="text" 
                       nbInput
                       id="enddate" 
                       name="enddate"
                       placeholder="mm/dd/yyyy"
                       #enddateentry="ngModel"
                       [ngClass]="{'inputError':!validEndDate() && enddateentry.touched}"
                       style="width:100%"
                       [(ngModel)]="endDate">
                <div class="errorText" *ngIf="!validEndDate() && enddateentry.touched">Please enter a valid date of format mm/dd/yyyy.</div>
            </ng-container>                
                   
        </div>
        <div class="col"></div>
    </div> 

    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            START TIME *
        </div>
        <div class="col-md-4 auction-label-text">
            END TIME *
        </div>
        <div class="col"></div>
    </div> 

    <div class="row ml-2 mb-3">
        <div class="col-md-4">
            <ng-container *ngIf="isTimeHtmlElementSupported()">                   
                <input nbInput 
                       style="width:100%" 
                       type="time" 
                       id="starttime" 
                       name="starttime" 
                       (click)="onStartTimeClick()"
                       [(ngModel)]="startTime"
                       required>    
            </ng-container>                

            <ng-container *ngIf="!isTimeHtmlElementSupported()">                   
                <input nbInput 
                       style="width:100%" 
                       type="text" 
                       id="starttime" 
                       name="starttime" 
                       placeholder="hh:mm AM"
                       #starttimeentry="ngModel"
                       [ngClass]="{'inputError':!validStartTime() && starttimeentry.touched}"
                       [(ngModel)]="startTime"
                       required>   
                    <div class="errorText" *ngIf="!validStartTime() && starttimeentry.touched">Please enter a valid am/pm time of format 11:00 AM.</div>
            </ng-container>                
                   
        </div>

        <div class="col-md-4">
            <ng-container *ngIf="isTimeHtmlElementSupported()">                   
                <input nbInput 
                       style="width:100%" 
                       type="time" 
                       id="endtime" 
                       name="endtime" 
                       [(ngModel)]="endTime"
                       (click)="onEndTimeClick()"
                       required>  
            </ng-container>                
                   
            <ng-container *ngIf="!isTimeHtmlElementSupported()">                   
                <input nbInput 
                       style="width:100%" 
                       type="text" 
                       id="endtime" 
                       name="endtime" 
                       #endtimeentry="ngModel"
                       placeholder="hh:mm AM"
                       [(ngModel)]="endTime"
                       [ngClass]="{'inputError':!validEndTime() && endtimeentry.touched}"
                       required>   
                    <div class="errorText" *ngIf="!validEndTime() && endtimeentry.touched">Please enter a valid am/pm time of format 11:00 AM.</div>
            </ng-container>                
                   
        </div>
        <div class="col"></div>
    </div> 

    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            TIME ZONE
        </div>
        <div class="col"></div>
    </div> 

    <div class="row ml-2">
        <div class="col-md-4">
            <nb-select  style="width:100%"
                        *ngIf="timezones.length > 0"
                        placeholder="Select"
                        name="starttimezone"
                        id="starttimezone"
                        [(ngModel)]="auctionModel.activeAuction.timezone">
                <nb-option *ngFor="let item of timezones" [value]="item.timezone">{{ item.label }}</nb-option>
            </nb-select>
        </div>
        <div class="col"></div>
    </div>
    
    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div> 
    
    <div class="row mt-2 mb-2">
        <div class="col-md-12 title-text center" style="font-size:15px">
            Where
        </div>
    </div> 
    
    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            HERD LOCATION
        </div>
        <div class="col"></div>
    </div> 
    
    <div class="row ml-2 mb-1">
        <div class="col-md-4">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="locationcity" 
                   name="locationcity" 
                   placeholder="City"
                   [(ngModel)]="auctionModel.activeAuction.sellerCity">            
        </div>
        <div class="col-md-8">
            <nb-select  style="width:95%"
                        placeholder="Select State"
                        name="locationstate"
                        id="locationstate"
                        [(ngModel)]="auctionModel.activeAuction.sellerState">
                <nb-option *ngFor="let item of states" [value]="item">{{ item }}</nb-option>
            </nb-select>
            <button type="button" nbSuffix nbButton ghost (click)="onClearState()">
               <nb-icon [icon]="'close-circle-outline'"
                        pack="eva">
               </nb-icon>
            </button>
        </div>
    </div>
    
    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div> 
    
    <div class="row mt-2 mb-2">
        <div class="col-md-12 title-text center" style="font-size:15px">
            What
        </div>
    </div> 
    
    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            SALE NAME *
        </div>
        <div class="col"></div>
    </div> 
    
    <div class="row ml-2 mb-3">
        <div class="col-md-12">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="salename" 
                   name="salename" 
                   [(ngModel)]="auctionModel.activeAuction.title">            
        </div>
    </div>

    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            SALE OR FARM DESCRIPTION
        </div>
        <div class="col"></div>
    </div> 
    
    <div class="row ml-2">
        <div class="col-md-12">
            <md-editor name="saleoverview"
              style="width:100%"
              [(ngModel)]="saleOverview" 
              [height]="'200px'" 
              mode="editor" 
              maxlength="1000">
            </md-editor>    
        </div>
    </div>
    
    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            SALE OR FARM HIGHTLIGHT PHOTOS
        </div>
        <div class="col"></div>
    </div> 
    
    <div class="row ml-3">
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-12">
                  <app-file-chooser #farmhighlightphotos [imageHeight]="'140px'" [imageWidth]="'215px'" [acceptFileTypes]="'image'" [multipleFiles]="true" (messageEvent)="dropFileHandler($event)"></app-file-chooser>
                </div>
            </div>
        </div>
        <div class="col"></div>        
    </div>

    <div class="row mt-3 mb-3">
        <div class="col-md-12">
            <hr>
        </div>
    </div> 
    
    <div class="row mt-2 mb-2">
        <div class="col-md-12 title-text center" style="font-size:15px">
            Sale Contact Info
        </div>
    </div> 
    
    <div class="row ml-3 mb-3">
        <div class="col-md-12">
            <nb-checkbox type="checkbox"  
                         id="usebusinesscontactdetails"
                         name="usebusinesscontactdetails"
                         (checkedChange)="onBusinessContactDetails($event)"
                         [(ngModel)]="auctionModel.activeAuction.useSellerContactInfo">USE BUSINESS CONTACT DETAILS
            </nb-checkbox>
        </div>
        <div class="col"></div>
    </div>
    
    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            NAME
        </div>
        <div class="col"></div>
    </div> 
    
    <div class="row ml-2 mb-3">
        <div class="col-md-6">
            <input nbInput
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="sellercontactname" 
                   name="sellercontactname" 
                   placeholder="Seller Contact Name"
                   [disabled]="auctionModel.activeAuction.useSellerContactInfo"
                   [(ngModel)]="auctionModel.activeAuction.sellerContactName">            
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            EMAIL ADDRESS
        </div>
        <div class="col"></div>
    </div> 
    
    <div class="row ml-2 mb-3">
        <div class="col-md-6">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="sellercontactemail" 
                   name="sellercontactemail" 
                   placeholder="Seller Contact Email"
                   #selleremailentry="ngModel"
                   [ngClass]="{'inputError':!validSellerEmail() && selleremailentry.touched}"
                   [disabled]="auctionModel.activeAuction.useSellerContactInfo"
                   [(ngModel)]="auctionModel.activeAuction.sellerEmail">    
            <div class="errorText" *ngIf="!validSellerEmail() && selleremailentry.touched">Please provide a valid email address.</div>
        </div>
        <div class="col"></div>
    </div>

    <div class="row ml-2">
        <div class="col-md-4 auction-label-text">
            PHONE NUMBER
        </div>
        <div class="col-md-2 auction-label-text">
            PREFERRED
        </div>
        <div class="col-md-2 auction-label-text">
            MOBILE
        </div>
        <div class="col-md-1 flex-center">
            <button *ngIf="!auctionModel.activeAuction.useSellerContactInfo" nbButton size="small" status="primary" outline (click)="onAddBlankSellerPhoneNumber('',false,false)" [disabled]="auctionModel.activeAuction.useSellerContactInfo">Add Phone</button>
        </div>
        <div class="col"></div>
    </div> 
    
    <ng-container *ngFor="let item of auctionModel.activeAuction.sellerPhoneNumbers;let i=index">
        <div class="row ml-2">

            <div class="col-md-4">
                <input mask="(000) 000-0000"
                       [dropSpecialCharacters]="false"
                       nbInput 
                       fullWidth
                       style="width:100%" 
                       type="text" 
                       id="sellercontactphone-{{i}}" 
                       name="sellercontactphone-{{i}}" 
                       placeholder="Seller Phone Number"
                       [disabled]="auctionModel.activeAuction.useSellerContactInfo"
                       #phoneentry="ngModel"
                       [ngClass]="{'inputError':!validPhone(i) && phoneentry.touched}"
                       maxlength="14"
                       [(ngModel)]="auctionModel.activeAuction.sellerPhoneNumbers[i].phone">    
                <div class="errorText" *ngIf="!validPhone(i) && phoneentry.touched">Please provide a valid phone number.</div>
            </div>

            <div class="col-md-2 justify-content-center align-self-center">
                <nb-checkbox type="checkbox"  
                             id="sellercontactphonepreferred-{{i}}"
                             name="sellercontactphonepreferred-{{i}}"
                             [disabled]="auctionModel.activeAuction.useSellerContactInfo"
                             [(ngModel)] ="auctionModel.activeAuction.sellerPhoneNumbers[i].preferred">YES
                </nb-checkbox>
            </div>
        
            <div class="col-md-2 justify-content-center align-self-center">
                <nb-checkbox type="checkbox"  
                             id="sellercontactphonemobile-{{i}}"
                             name="sellercontactphonemobile-{{i}}"
                             [disabled]="auctionModel.activeAuction.useSellerContactInfo"
                             [(ngModel)] ="auctionModel.activeAuction.sellerPhoneNumbers[i].mobile">YES
                </nb-checkbox>
            </div>
        
            <div class="col-md-1 flex-center" *ngIf="!auctionModel.activeAuction.useSellerContactInfo">
                <a [routerLink]="" (click)="onRemovePhone(i)" ><nb-icon status="primary" icon="trash-2-outline" class="trash-icon"></nb-icon></a>
            </div>
            
        </div>
        <div class="row ml-2 mb-2">
            <div class="col"></div>
        </div>
    </ng-container>

    <div class="row mt-3 mb-3">
        <div class="col-md-12">
            <hr>
        </div>
    </div> 
    
    <div class="row mt-2 mb-2">
        <div class="col-md-12 title-text center" style="font-size:15px">
            Cover Image
        </div>
    </div> 
    
    <div class="row ml-3">
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-12">
                  <app-file-chooser #coverimage [imageHeight]="'140px'" [imageWidth]="'140px'" [acceptFileTypes]="'image'" [multipleFiles]="false" (messageEvent)="dropFileHandler($event)"></app-file-chooser>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-12">
                    <ul>
                      <li><span class="info-data-text">PNG or JPG formats supported</span></li>
                      <li><span class="info-data-text">Make sure the image is close to a square format (same size height and width), and at least 325px for the best fit</span></li>
                    </ul>                    
                </div>                
            </div>
        </div>        
    </div>

    <div class="row mt-3 mb-3">
        <div class="col-md-12">
            <hr>
        </div>
    </div> 
    
    <div class="row mt-2 mb-2">
        <div class="col-md-12 title-text center" style="font-size:15px">
            Catalog
        </div>
    </div> 
    
    <div class="row ml-3">
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-12">
                  <app-file-chooser #catalog [noDisplay]="true" [acceptFileTypes]="'pdf'" [multipleFiles]="false" (messageEvent)="dropFileHandler($event)"></app-file-chooser>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-12">
                    <ul>
                      <li><span class="info-data-text">PDF formats supported</span></li>
                    </ul>                    
                </div>                
            </div>
        </div>        
    </div>
    
</div> <!-- container-fluid -->
