<div class="container-fluid">

    <ng-container *ngIf="importLotError()">
        <div class="row mb-2" *ngFor="let item of lotToEdit.importErrors">
            <div class="col-md-12">
                <span class="errorText">{{item}}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="errorMsg.length > 0">
        <div class="row mb-2">
            <div class="col-md-12">
                <span class="errorText">{{errorMsg}}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="successMsg.length > 0">
        <div class="row mb-2">
            <div class="col-md-12">
                <span class="successText">{{successMsg}}</span>
            </div>
        </div>
    </ng-container>

    <div class="row mb-2" *ngIf="lotToEdit.lotId == undefined">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Add Lot
        </div>
        <div class="col-md-6 right-justify center r-top">
            <button [nbSpinner]="processing" nbButton size="small" status="primary" (click)="onSave()">SAVE</button>
        </div>
    </div>

    <div class="row mb-2" *ngIf="lotToEdit.lotId != undefined">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Edit Lot
        </div>
        <div class="col-md-6 right-justify center r-top">
            <button [nbSpinner]="processing" nbButton size="small" status="primary" (click)="onSave()">SAVE</button>
            <button class="ml-2" nbButton size="small" status="primary" appearance="outline" (click)="onClone()">CLONE</button>
            <button class="ml-2" nbButton size="small" status="primary" appearance="outline" (click)="onNewLot()">NEW LOT</button>
            <button class="ml-2" nbButton size="small" status="primary" appearance="outline" (click)="onDelete()"><nb-icon icon="trash-2-outline"></nb-icon>DELETE</button>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-6 title-text center" style="font-size:15px">
            Lot Basic Information
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-12">
            <nb-checkbox type="checkbox"
                         id="disablebidding"
                         name="disablebidding"
                         [(ngModel)]="lotToEdit.biddingDisabled">Disable bidding on this lot
            </nb-checkbox>
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-2">
        <div class="col-md-2 auction-label-text">
            LOT#
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-3">
        <div class="col-md-2">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="lotnum"
                   name="lotnum"
                   [(ngModel)]="lotToEdit.lotNum">
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12 auction-label-text">
            NAME
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-9">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="title"
                   name="title"
                   [(ngModel)]="lotToEdit.title">
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12 auction-label-text">
            FOOTNOTE
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-12">
            <textarea nbInput
                      fullWidth
                      style="height:100px"
                      id="footnote"
                      name="footnoes"
                      [(ngModel)]="lotToEdit.footnote[0]"
                      placeholder="Place your text">
            </textarea>
        </div>
        <div class="col"></div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12 title-text center" style="font-size:15px">
            Lot Pricing
        </div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            STARTING PRICE
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-3">
        <div class="col-md-3">
            <nb-form-field>
                <nb-icon nbPrefix icon="dollar-sign" pack="fas" status="primary"></nb-icon>
                <input nbInput
                       fullWidth
                       style="width:100%"
                       type="number"
                       id="lotstartbid"
                       name="lotstartbid"
                       placeholder="Starting Bid Price"
                       [(ngModel)]="lotToEdit.startBid">
            </nb-form-field>
        </div>
        <div class="col"></div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            FLOOR PRICE
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12">
            <nb-icon status="info" icon="info-outline" class="trash-icon"></nb-icon>
            <span class="help-info-text">The floor price will not be displayed to the bidder, set it to create a ceiling for our auto price discovery.</span>
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-3">
        <div class="col-md-3">
            <nb-form-field>
                <nb-icon nbPrefix icon="dollar-sign" pack="fas" status="primary"></nb-icon>
                <input nbInput
                       fullWidth
                       style="width:100%"
                       type="number"
                       id="lotfloorprice"
                       name="lotfloorprice"
                       placeholder="Floor Price"
                       [(ngModel)]="lotToEdit.floorPrice">
            </nb-form-field>
        </div>
        <div class="col"></div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            STARTING INCREMENT
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12">
            <nb-icon status="info" icon="info-outline" class="trash-icon"></nb-icon>
            <span class="help-info-text">Setting bidding increments at the auction level will set the value as the default for each lot</span>
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-3">
        <div class="col-md-3">
            <nb-form-field>
                <nb-icon nbPrefix icon="dollar-sign" pack="fas" status="primary"></nb-icon>
                <input nbInput
                       fullWidth
                       style="width:100%"
                       type="number"
                       id="lotbidincrement"
                       name="lotbidincrement"
                       placeholder="Bid Increment"
                       [(ngModel)]="lotToEdit.bidIncrement">
            </nb-form-field>
        </div>
        <div class="col"></div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12 title-text center" style="font-size:15px">
            Lot Photos and Videos
        </div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            PHOTOS
        </div>
        <div class="col"></div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-12">
                  <app-lot-file-chooser #lotphotos [coverPhoto]="true" [imageHeight]="'140px'" [imageWidth]="'215px'" [acceptFileTypes]="'image'" [multipleFiles]="true" (messageEvent)="dropFileHandler($event)"></app-lot-file-chooser>
                </div>
            </div>
        </div>
        <div class="col"></div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            VIDEOS
        </div>
        <div class="col"></div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-12">
                  <app-lot-file-chooser #lotvideos [coverPhoto]="false" [imageHeight]="'140px'" [imageWidth]="'215px'" [acceptFileTypes]="'video'" [multipleFiles]="true" (messageEvent)="dropFileHandler($event)"></app-lot-file-chooser>
                </div>
            </div>
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12 auction-label-text">
            VIDEO LINK (FOR EXTERNALLY-HOSTED VIDEOS)
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-9">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="externalvideourl"
                   name="externalvideourl"
                   [(ngModel)]="lotToEdit.externalVideoUrl">
        </div>
        <div class="col"></div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12 title-text center" style="font-size:15px">
            Seller and Contact Info
        </div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            SELLER NAME AND CONTACT NAME
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-3">
        <div class="col-md-12">
            <nb-checkbox type="checkbox"
                         id="usesellercontact"
                         name="usesellercontact"
                         disabled
                         [(ngModel)]="usersellercontact">Use Auction Seller Name and Contact
            </nb-checkbox>
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-3" *ngIf="sellerBusinessNameList.length > 0">
        <div class="col-md-12">
            <nb-select  style="width:100%"
                        placeholder="Select Seller"
                        name="businessaccountid"
                        id="businessaccountid"
                        [ngClass]="{'inputError':consignorImportError()}"
                        [(ngModel)]="lotToEdit.businessAccountId">
                <nb-option *ngFor="let item of sellerBusinessNameList" [value]="item.accountId">{{ item.businessName }}</nb-option>
            </nb-select>
            <nb-icon class="ml-2 alert-icon" *ngIf="consignorImportError()" icon="alert-triangle-outline" status="danger"></nb-icon>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12 title-text center" style="font-size:15px">
            Additional Custom Fields
        </div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            CUSTOM FIELD LABEL
        </div>
        <div class="col-md-8 auction-label-text">
            CUSTOM FIELD VALUE
        </div>
    </div>

    <div *ngIf="lotToEdit.customFields != undefined">
        <ng-container *ngFor="let item of lotToEdit.customFields;let i=index">
            <div class="row mb-3">
                <div class="col-md-4">
                    <input nbInput
                           fullWidth
                           style="width:100%"
                           type="text"
                           id="customlabel-{{i}}"
                           name="customlabel-{{i}}"
                           placeholder="Custom Label"
                           [(ngModel)]="lotToEdit.customFields[i].customLabel">
                </div>
                <div class="col-md-7">
                    <input nbInput
                           fullWidth
                           style="width:100%"
                           type="text"
                           id="customvalue-{{i}}"
                           name="customvalue-{{i}}"
                           placeholder="Custom Value"
                           [(ngModel)]="lotToEdit.customFields[i].customValue">
                </div>
                <div class="col-md-1 justify-content-center align-self-center">
                    <a [routerLink]="" (click)="onRemoveCustomField(i)" ><nb-icon status="primary" icon="trash-2-outline" class="trash-icon"></nb-icon></a>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="row">
        <div class="col-md-4 ml-2 auction-label-text">
            <a [routerLink]="" class="link-text" style="font-weight:700" (click)="onAddCustomField()">+ Add Custom Field</a>
        </div>
        <div class="col"></div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12 title-text center" style="font-size:15px">
            Lot Details
        </div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            LOT CATEGORY
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-3">
        <div class="col-md-4">
            <nb-select  style="width:95%"
                        placeholder="Select Category"
                        name="lotcategory"
                        id="lotcategory"
                        [(ngModel)]="lotToEdit.category">
                <nb-option *ngFor="let item of lotCategories" [value]="item.key">{{ item.value }}</nb-option>
            </nb-select>
            <button type="button" nbSuffix nbButton ghost (click)="onClearLotCategory()">
               <nb-icon [icon]="'close-circle-outline'"
                        pack="eva">
               </nb-icon>
            </button>
        </div>
        <div class="col"></div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            REG #
        </div>
        <div class="col-md-4 auction-label-text">
            REG # LINK
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="regnum"
                   name="regnum"
                   placeholder="Reg #"
                   [(ngModel)]="lotToEdit.regNum">
        </div>
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="regurl"
                   name="regurl"
                   placeholder="Reg # Link"
                   [(ngModel)]="lotToEdit.regUrl">
        </div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            TATTOO
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-3">
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="tatoo"
                   name="tatoo"
                   placeholder="Tattoo"
                   [(ngModel)]="lotToEdit.tattoo">
        </div>
        <div class="col"></div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            DATE OF BIRTH
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-3">
        <div class="col-md-4">
            <input type="date"
                   nbInput
                   id="dob"
                   name="dob"
                   style="width:95%"
                   [(ngModel)]="lotToEdit.dob">
            <button type="button" nbSuffix nbButton ghost (click)="onClearDOB()">
               <nb-icon [icon]="'close-circle-outline'"
                        pack="eva">
               </nb-icon>
            </button>
        </div>
        <div class="col"></div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            SEX
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-3">
        <div class="col-md-4">
            <nb-select  style="width:95%"
                        placeholder="Select Sex"
                        name="sexcategory"
                        id="sexcategory"
                        [(ngModel)]="lotToEdit.sexedCategory">
                <nb-option *ngFor="let item of lotSexedCategories" [value]="item.key">{{ item.value }}</nb-option>
            </nb-select>
            <button type="button" nbSuffix nbButton ghost (click)="onClearSex()">
               <nb-icon [icon]="'close-circle-outline'"
                        pack="eva">
               </nb-icon>
            </button>
        </div>
        <div class="col"></div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            QUANTITY
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-3">
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="number"
                   id="quantity"
                   name="quantity"
                   placeholder="Quantity"
                   [(ngModel)]="lotToEdit.quantity">
        </div>
        <div class="col"></div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            INTEREST
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-3">
        <div class="col-md-4">
            <nb-select  style="width:95%"
                        placeholder="Interest"
                        name="interest"
                        id="interest"
                        [(ngModel)]="lotToEdit.interest">
                <nb-option *ngFor="let item of lotInterestCategories" [value]="item.key">{{ item.value }}</nb-option>
            </nb-select>
            <button type="button" nbSuffix nbButton ghost (click)="onClearInterest()">
               <nb-icon [icon]="'close-circle-outline'"
                        pack="eva">
               </nb-icon>
            </button>
        </div>
        <div class="col"></div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            EMBRYO TYPE
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-3">
        <div class="col-md-4">

            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="embryotype"
                   name="embryotype"
                   placeholder="Embryo Type"
                   [(ngModel)]="lotToEdit.embryoType">
        </div>
        <div class="col"></div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            EXPORTABLE
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-3">
        <div class="col-md-12">
            <nb-select  style="width:95%"
                        placeholder="Select Exportable"
                        name="exportable"
                        id="exportable"
                        [(ngModel)]="lotToEdit.exportable">
                <nb-option *ngFor="let item of lotExportableTypes" [value]="item.key">{{ item.value }}</nb-option>
            </nb-select>
            <button type="button" nbSuffix nbButton ghost (click)="onClearExportable()">
               <nb-icon [icon]="'close-circle-outline'"
                        pack="eva">
               </nb-icon>
            </button>
        </div>
        <!--<div class="col"></div>-->
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            DONOR NAME
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-3">
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="donorname"
                   name="donorname"
                   placeholder="Donor Name"
                   [(ngModel)]="lotToEdit.donorName">
        </div>
        <div class="col"></div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            DONOR SIRE
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-3">
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="donorsire"
                   name="donorsire"
                   placeholder="Donor Sire"
                   [(ngModel)]="lotToEdit.donorSire">
        </div>
        <div class="col"></div>
    </div>

    <div class="row">
        <div class="col-md-4 auction-label-text">
            DONOR DAM
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-3">
        <div class="col-md-4">
            <input nbInput
                   fullWidth
                   style="width:100%"
                   type="text"
                   id="donordam"
                   name="donordam"
                   placeholder="Donor Dam"
                   [(ngModel)]="lotToEdit.donorDam">
        </div>
        <div class="col"></div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12 title-text center" style="font-size:15px">
            Lineage
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-12">
            <app-lot-lineage [lot]="lotToEdit"></app-lot-lineage>
        </div>
    </div>

    <div class="row mt-4">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12 title-text center" style="font-size:15px">
            EPDs
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-12 title-text center" style="font-size:15px">
            <form>
              <nb-radio-group class="horizontal-radio-group" name="epdTypeGroup" [(ngModel)]="lotToEdit.actualEPD">
                <nb-radio value="ACTUAL" [value]="true"><span class="column-text">Actual EPDs</span></nb-radio>
                <nb-radio value="PROJECTED" [value]="false"><span class="column-text">Projected EPDs</span></nb-radio>
              </nb-radio-group>
            </form>
        </div>
    </div>

    <div class="row mb-5">
        <div class="col-md-12">
            <app-lot-epds [lot]="lotToEdit"></app-lot-epds>
        </div>
    </div>


</div> <!-- container-fluid -->
