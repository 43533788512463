import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';


@Component({
  selector: 'app-settingshome',
  templateUrl: './settingshome.component.html',
  styleUrls: ['./settingshome.component.scss']
})
export class SettingshomeComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;
  pageId:number = 0;
  
  accountsettings: boolean = true;
  bidsandsaves: boolean = false;
  following: boolean = false;
  mysales: boolean = false;
  sub;

  constructor(private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {
    this.sub=this.activatedRoute.paramMap.subscribe(params => { 
          this.pageId = +params.get('id'); 
      });    
  }
  
  ngOnDestroy() {
     this.sub.unsubscribe();
  }  
  
  async receiveMessage($event) {
    console.log($event);
    if($event['message']=='accountsettings'){
      console.log($event);
    }
    else if($event['message']=='mybidsandsaves'){
      console.log($event);
    }
    if($event['message']=='following'){
      console.log($event);
    }
    if($event['message']=='mysales'){
      console.log($event);
    }
  }
  

}
