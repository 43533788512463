<div class="container-fluid">
    <div class="row mb-3">
      <div class="col-md-8 center">
          <ng-container *ngIf="auctionModel.activeAuction.isSetup()">
            <span><button type="button" class="statusButton statusSetupButton" disabled>Setup</button></span>
          </ng-container>
          <ng-container *ngIf="auctionModel.activeAuction.isCalendarPosted() || auctionModel.activeAuction.isSaleInfoPosted() || auctionModel.activeAuction.isLotsPosted()">
            <nb-icon icon="arrow-back-outline" class="back-icon"></nb-icon>
            <a [routerLink]="" class="link-text ml-1" (click)="onSetup()">Setup</a>
            <span class="ml-2 mr-2"><nb-icon icon="arrow-ios-forward-outline" class="details-icon"></nb-icon></span>
          </ng-container>
          <ng-container *ngIf="auctionModel.activeAuction.isCalendarPosted()">
            <span><button type="button" class="statusButton statusCalendarPostedButton" disabled>Calendar Posted</button></span>
          </ng-container>
          <ng-container *ngIf="auctionModel.activeAuction.isSaleInfoPosted() || auctionModel.activeAuction.isLotsPosted()">
            <nb-icon icon="arrow-back-outline" class="back-icon"></nb-icon>
            <a [routerLink]="" class="link-text ml-1" (click)="onPostCalendar()">Calendar Posted</a>
          </ng-container>
          <ng-container *ngIf="auctionModel.activeAuction.isSaleInfoPosted()">
            <span class="ml-2 mr-2"><nb-icon icon="arrow-ios-forward-outline" class="details-icon"></nb-icon></span>
            <span><button type="button" class="statusButton statusSalePostedInfoButton" disabled>Sale Posted Info</button></span>
          </ng-container>
          <ng-container *ngIf="auctionModel.activeAuction.isLotsPosted()">
            <span class="ml-2 mr-2"><nb-icon icon="arrow-ios-forward-outline" class="details-icon"></nb-icon></span>
            <nb-icon icon="arrow-back-outline" class="back-icon"></nb-icon>
            <a [routerLink]="" class="link-text ml-1" (click)="onPostSaleInfo()">Sale Info Posted</a>
            <span class="ml-2 mr-2"><nb-icon icon="arrow-ios-forward-outline" class="details-icon"></nb-icon></span>
            <span><button type="button" class="statusButton statusSalePostedInfoButton" disabled>Lots Posted</button></span>
          </ng-container>
          <ng-container *ngIf="auctionModel.activeAuction.isLive()">
            <span><button type="button" class="statusButton statusLiveButton" disabled>Live</button></span>
          </ng-container>
          <ng-container *ngIf="auctionModel.activeAuction.isClosed()">
            <span><button type="button" class="statusButton statusClosedButton" disabled>Closed</button></span>
          </ng-container>
          <ng-container *ngIf="auctionModel.activeAuction.isSettled()">
            <span><button type="button" class="statusButton statusSettledButton" disabled>Settled</button></span>
          </ng-container>
      </div>

      <div class="col-md-4 right-justify center r-top">

        <!--<ng-container *ngIf="auctionModel.activeAuction.isSetup()">-->
        <!--  <button nbButton size="medium" status="basic" outline (click)="onDeleteAuction()">DELETE AUCTION</button>-->
        <!--</ng-container>-->

        <ng-container *ngIf="auctionModel.activeAuction.isSetup() || auctionModel.activeAuction.isCalendarPosted() || auctionModel.activeAuction.isSaleInfoPosted() || auctionModel.activeAuction.isLotsPosted()">
          <a class="mr-5" [href]="auctionModel.activeAuction.getPreviewUrl()" target="_blank" class="link-text" style="font-size:12px"><nb-icon icon="external-link-outline" class="back-icon"></nb-icon> Preview Auction</a>
        </ng-container>

        <ng-container *ngIf="auctionModel.activeAuction.isSettled() && false">
          <button nbButton size="small" status="primary" (click)="onGenerateSettlementSheet()">GENERATE SETTLEMENT SHEET</button>
        </ng-container>

      </div>

    </div>

    <div class="row mb-3" *ngIf="auctionModel.activeAuction.isSetup()">
        <div class="col-md-8 center"></div>
        <div class="col-md-4 right-justify center r-top">
            <button nbButton size="medium" status="basic" outline (click)="onDeleteAuction()">DELETE AUCTION</button>
        </div>
    </div>

    <div class="row mb-1 align-items-center">
        <div class="col-md-5 center">
          <div [hidden]="!(auctionModel.activeAuction.isCalendarPosted() || auctionModel.activeAuction.isSaleInfoPosted() || auctionModel.activeAuction.isLotsPosted() || auctionModel.activeAuction.isLive())">
            <app-auction-time-remaining-counter #apptimecounter (messageEvent)="receiveMessage($event)"></app-auction-time-remaining-counter>
          </div>
          <ng-container *ngIf="auctionModel.activeAuction.isClosed()">
            <button nbButton outline size="medium" (click)="onDirectSettlement()">DIRECT SETTLEMENT</button>
            <button nbButton outline size="medium" (click)="onPartialPayment()" class="ml-2">PARTIAL PAYMENT</button>
          </ng-container>
        </div>

        <div class="col-md-7 right-justify center r-top">
          <ng-container *ngIf="auctionModel.activeAuction.isSetup()">
            <button nbButton size="medium" status="primary" (click)="onPostCalendar()">POST TO CALENDAR</button>
          </ng-container>
          <ng-container *ngIf="auctionModel.activeAuction.isCalendarPosted()">
            <button nbButton size="medium" status="primary" (click)="onPostSaleInfo()">POST SALE INFO</button>
          </ng-container>
          <ng-container *ngIf="auctionModel.activeAuction.isSaleInfoPosted()">
            <button nbButton size="medium" status="primary" (click)="onPostLots()">POST LOTS</button>
          </ng-container>
          <ng-container *ngIf="auctionModel.activeAuction.isLotsPosted()">
            <button nbButton size="medium" status="basic" outline (click)="onStartAuction()">START AUCTION</button>
          </ng-container>
          <ng-container *ngIf="auctionModel.activeAuction.isLive()">
            <ng-container *ngIf="auctionModel.activeAuction.closingExtensionEnabled">
              <span class="auction-label-text mr-3">AUTO-EXTEND BY</span>
              <nb-select  placeholder="Select interval"
                          name="extension-interval"
                          id="extension-interval"
                          class="align-self-center"
                          size="large"
                          (selectedChange)="onExtensionIntervalChanged($event)"
                          [(ngModel)]="auctionModel.activeAuction.closingExtensionInterval">
                <nb-option *ngFor="let item of extensionPeriodIntervals" [value]="item.key">{{ item.value }}</nb-option>
              </nb-select>
            </ng-container>
            <button nbButton size="medium" (click)="onCloseAuction()" class="ml-3">CLOSE AUCTION</button>
          </ng-container>
          <ng-container *ngIf="auctionModel.activeAuction.isClosed()">
            <button nbButton size="medium" (click)="onSettleAuction()">SETTLE AUCTION</button>
          </ng-container>
        </div>
    </div>
</div>