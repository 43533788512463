import { Component, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { AuctionModel, AuctionModelService } from '../../model/auction.model';
import { Router } from '@angular/router';
import { Seller } from '../../model/seller.model';
import { Timezones } from '../../auth/timezones';
import { FileTransferService } from '../../services/filetransfer.service';
import { States } from '../../auth/states';


@Component({
  selector: 'app-auctionsaleinfo',
  templateUrl: './auctionsaleinfo.component.html',
  styleUrls: ['./auctionsaleinfo.component.scss']
})
export class AuctionsaleinfoComponent implements OnInit {
  errorMsg = "";
  processing: boolean = false;

  sellerAccountId;
  sellerBusinessNameList = [];
  sellersList:Seller[] = [];
  saleOverview:string = "";
  
  startDate;
  showStartDate:boolean = false;
  endDate;
  showEndDate:boolean = false;
  startTime;
  endTime;
  timezones;
  states:string[] = [];

  @ViewChild('farmhighlightphotos') farmhighlightphotos;  
  @ViewChild('coverimage') coverimage;  
  @ViewChild('catalog') catalog; 
  @Output() messageEvent = new EventEmitter<any>();
  @Input() savedisabled:boolean = false;

  constructor(public auctionModel:AuctionModelService,
              private router: Router,
              private fileTransferService : FileTransferService) { }

  /**
   * angular (ng) initializers
   **/
  ngOnInit(): void {
    this.states = States.states;
    this.timezones = Timezones.timezones;
  }
  
  /**
   * server based api calls
   **/

  async saveFiles(){
    await this.saveInfoBanners();
    await this.saveCoverImage();
    await this.saveCatalog();
  }
  
  async saveInfoBanners(){
    this.auctionModel.activeAuction.salesInfoBannerKeys = [];
    for(let item of this.farmhighlightphotos.files){
      if(item.new == 1){
        let fileURLs = await this.auctionModel.activeAuction.getFileURL(item.name);
        this.auctionModel.activeAuction.salesInfoBannerKeys.push(fileURLs['fileKey']);
        await this.uploadFile(item,fileURLs['uploadUrl']);
      }
      else {
        this.auctionModel.activeAuction.salesInfoBannerKeys.push(item.fileKey);
      }
    }
  }
  
  async saveCoverImage(){
    if(this.coverimage.files.length == 0){
      this.auctionModel.activeAuction.bannerImageKey = "";
    }
    else {
      for(let item of this.coverimage.files){
        if(item.new == 1){
          let fileURLs = await this.auctionModel.activeAuction.getFileURL(item.name);
          this.auctionModel.activeAuction.bannerImageKey = fileURLs['fileKey'];
          await this.uploadFile(item,fileURLs['uploadUrl']);
        }
        break;
      }
    }
  }
  
  async saveCatalog(){
    if(this.catalog.files.length == 0){
      this.auctionModel.activeAuction.catalogKey = "";
    }
    else {
      for(let item of this.catalog.files){
        if(item.new == 1){
          let fileURLs = await this.auctionModel.activeAuction.getFileURL(item.name);
          this.auctionModel.activeAuction.catalogKey = fileURLs['fileKey'];
          await this.uploadFile(item,fileURLs['uploadUrl']);
        }
        break;
      }
    }
  }
  
  async uploadFile(file,signedurl){
    this.fileTransferService.uploadSignedUrl(file,signedurl)
      .subscribe(
        data => {
            return data;
      },
        (err) => {
          throw(err);
      }
    );
  }
  
  /**
   * html page handlers
   **/
  onSeller(){
    if(this.sellerAccountId != undefined){
      let sellerExists = this.sellerBusinessNameList.find((el)=>{return el.accountId==this.sellerAccountId});
      if(sellerExists == undefined){
        let activeSeller = this.sellersList.find((el)=>{return el.accountId==this.sellerAccountId});
        this.sellerBusinessNameList.push(activeSeller);
        this.sellerAccountId = undefined;
        this.initSellerDetails();        
        // this.updateSellers();
      }
    }
  }

  onNewSeller(){
    this.router.navigate(['/editseller', "NEW","NEW","1"]);
  }
  
  onRemoveSeller(accountId){
    let index = this.sellerBusinessNameList.findIndex((el)=>{return el.accountId==accountId});
    this.sellerBusinessNameList.splice(index,1);
    this.initSellerDetails();
  }
  
  onRemovePhone(index){
    if(index == 0 && this.auctionModel.activeAuction.sellerPhoneNumbers.length == 1){
      let phoneNumber = {
        "phone":"",
        "preferred":true,
        "mobile":false
      };
      this.auctionModel.activeAuction.sellerPhoneNumbers[0] = phoneNumber;
    }
    else{
      this.auctionModel.activeAuction.sellerPhoneNumbers.splice(index,1);
    }
  }
  
  onAddBlankSellerPhoneNumber(phone,preferred,mobile){
    let phoneNumber = {
      "phone":"",
      "preferred":preferred,
      "mobile":mobile
    };
    this.auctionModel.activeAuction.sellerPhoneNumbers.push(phoneNumber);   
  }
  
  onSaveAuction(){
    var eventMsg = {}
    eventMsg['message'] = 'saveauction';
    this.messageEvent.emit(eventMsg);
  }
  
  onBusinessContactDetails($event){
    this.auctionModel.activeAuction.useSellerContactInfo = $event;
    this.initSellerDetails();
  }
  
  onClearState(){
    this.auctionModel.activeAuction.sellerState = null;
  }
  
  /**
   * other
   **/
  saveSellers(){
    /* Update primary seller */
    if(this.sellerBusinessNameList.length > 0 ){
      this.auctionModel.activeAuction.businessAccountId = this.sellerBusinessNameList[0].accountId;
    }
    else {
      this.auctionModel.activeAuction.businessAccountId = undefined;
    }

    /* Update other sellers */
    this.auctionModel.activeAuction.businessModifiedAccountIds = [];
    if(this.sellerBusinessNameList.length > 1 ){
      for(let i=1;i<this.sellerBusinessNameList.length;i++){
        this.auctionModel.activeAuction.businessModifiedAccountIds.push(this.sellerBusinessNameList[i].accountId);
      }
    }
  }
  
  savePhones(){
    var phoneNumbers = this.auctionModel.activeAuction.sellerPhoneNumbers.slice();
    var goodPhoneNumbers = []; 
    for(let item of phoneNumbers){
      if(item.phone.length > 0){
        goodPhoneNumbers.push(item);
      }
    }
    this.auctionModel.activeAuction.sellerPhoneNumbers = goodPhoneNumbers.length > 0 ? goodPhoneNumbers.slice() : null;
  }
  
  saveSellerEmail(){
    this.auctionModel.activeAuction.sellerEmail = this.auctionModel.activeAuction.sellerEmail != undefined && this.auctionModel.activeAuction.sellerEmail.length > 0 ? this.auctionModel.activeAuction.sellerEmail : null;
  }
  
  dropFileHandler($event) {
  }
  
  loadPageData(){
    this.loadAuctionSellers();
    this.loadTimes();
    this.loadSalesOverView();
    this.loadFiles();
    this.validateAtLeastOneSellerPhoneNumber()
  }
  
  loadSalesOverView(){
    if(this.auctionModel.activeAuction.saleOverview != undefined && this.auctionModel.activeAuction.saleOverview[0] != undefined){
      this.saleOverview = this.auctionModel.activeAuction.saleOverview[0];      
    }
  }

  loadAuctionSellers(){
    let seller = {};
    this.sellerBusinessNameList = [];
    if(this.auctionModel.activeAuction.primaryBusiness != undefined){
        let seller = this.sellersList.find((el)=>{return el.accountId==this.auctionModel.activeAuction.primaryBusiness.accountId});
        seller != undefined ? this.sellerBusinessNameList.push(seller) : "";
    }
    
    if(this.auctionModel.activeAuction.businesses != undefined){    
      for(let item of this.auctionModel.activeAuction.businesses){
        let seller = this.sellersList.find((el)=>{return el.accountId==item.accountId});
        seller != undefined ? this.sellerBusinessNameList.push(seller) : "";
      }
    }
  }
  
  validateAtLeastOneSellerPhoneNumber(){
    if(this.auctionModel.activeAuction.sellerPhoneNumbers.length == 0){
      this.onAddBlankSellerPhoneNumber("",true,false);
    }
  }
  
  save(){

    if(!this.isDateHtmlElementSupported()){
      var sDate = Timezones.convert_mmddyyyyDatestrToISO_moment(this.startDate);
      var sTime = Timezones.convert_ampmTimestrToISO_moment(this.startTime);
      var eDate = Timezones.convert_mmddyyyyDatestrToISO_moment(this.endDate);
      var eTime = Timezones.convert_ampmTimestrToISO_moment(this.endTime);
      this.auctionModel.activeAuction.startTime = this.getTime(sDate + "T" + sTime + ":00.000");
      this.auctionModel.activeAuction.endTime = this.getTime(eDate + "T" + eTime + ":00.000");
    }
    else{
      this.auctionModel.activeAuction.startTime = this.getTime(this.startDate + "T" + this.startTime + ":00.000");
      this.auctionModel.activeAuction.endTime = this.getTime(this.endDate + "T" + this.endTime + ":00.000");
    }
    
    this.auctionModel.activeAuction.saleOverview = [];
    this.auctionModel.activeAuction.saleOverview.push(this.saleOverview);
    this.savePhones();
    this.saveSellers();
    this.saveSellerEmail();
  }
  
  getTime(datetimestr:string){
    var timezoneRecord = this.timezones.find((el)=>{return el.timezone == this.auctionModel.activeAuction.timezone});
    if(timezoneRecord == undefined){
      timezoneRecord = this.timezones.find((el)=>{return el.timezone == "US/Eastern"});
      this.auctionModel.activeAuction.timezone = "US/Eastern";
    }
    return Timezones.getTimeISOString(datetimestr,timezoneRecord);
  }
  
  loadTimes(){
    var timezoneRecord = this.timezones.find((el)=>{return el.timezone == this.auctionModel.activeAuction.timezone});
    if(timezoneRecord == undefined){
      this.auctionModel.activeAuction.timezone = "US/Eastern";
    }
    
    if(!this.isDateHtmlElementSupported()){
      if(this.auctionModel.activeAuction.startTime != undefined && this.auctionModel.activeAuction.startTime.length > 0){
        let start = Timezones.getDateWithTimezoneApplied_moment(this.auctionModel.activeAuction.startTime,this.auctionModel.activeAuction.timezone);
        this.startDate = Timezones.mmddyyyy_moment(start);
        this.startTime = Timezones.hhmm_ampm_moment(start);
        console.log('startDate',this.startDate);
        console.log('startTime',this.startTime);
      }
      
      if(this.auctionModel.activeAuction.endTime != undefined && this.auctionModel.activeAuction.endTime.length > 0){
        let end = Timezones.getDateWithTimezoneApplied_moment(this.auctionModel.activeAuction.endTime,this.auctionModel.activeAuction.timezone);
        this.endDate = Timezones.mmddyyyy_moment(end);
        this.endTime = Timezones.hhmm_ampm_moment(end);
        console.log('endDate',this.endDate);
        console.log('endTime',this.endTime);
      }
    }
    else{
      if(this.auctionModel.activeAuction.startTime != undefined && this.auctionModel.activeAuction.startTime.length > 0){
        let start = Timezones.getDateWithTimezoneApplied_moment(this.auctionModel.activeAuction.startTime,this.auctionModel.activeAuction.timezone);
        this.startDate = Timezones.yyyymmdd_moment(start);
        this.startTime = Timezones.hhmm_moment(start);
      }
      
      if(this.auctionModel.activeAuction.endTime != undefined && this.auctionModel.activeAuction.endTime.length > 0){
        let end = Timezones.getDateWithTimezoneApplied_moment(this.auctionModel.activeAuction.endTime,this.auctionModel.activeAuction.timezone);
        this.endDate = Timezones.yyyymmdd_moment(end);
        this.endTime = Timezones.hhmm_moment(end);
      }
    }
  }

  loadFiles(){
    this.farmhighlightphotos.files = [];
    if(this.auctionModel.activeAuction.salesInfoBannerKeys != undefined){
      for(let value of this.auctionModel.activeAuction.salesInfoBannerKeys){
        let item = {
          "imageUrl":this.auctionModel.activeAuction.getSalesLogoUrl(value),
          "format":"image",
          "name":"",
          "size":"",
          "new":0,
          "fileKey": value
        }
        this.farmhighlightphotos.files.push(item);
      }
    }
    
    this.coverimage.files = [];
    if(this.auctionModel.activeAuction.bannerImageKey != undefined && this.auctionModel.activeAuction.bannerImageKey.length > 0){
      let item = {
        "imageUrl":this.auctionModel.activeAuction.getLogoUrl(),
        "format":"image",
        "name":"",
        "size":"",
        "new":0
      }
      this.coverimage.files.push(item);
    }
    
    this.catalog.files = [];
    if(this.auctionModel.activeAuction.catalogKey != undefined && this.auctionModel.activeAuction.catalogKey.length > 0){
      let item = {
        "imageUrl":this.auctionModel.activeAuction.getCatalogUrl(),
        "name":this.auctionModel.activeAuction.catalogKey,
        "format":"pdf",
        "size":"",
        "new":0
      }
      this.catalog.files.push(item);
    }
  }
  
  compareAscBusiness(a:Seller,b:Seller){
    let valueA = a.businessName.toUpperCase();
    let valueB = b.businessName.toUpperCase();
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
  }
  
  isNewAuction(){
    return this.auctionModel.activeAuction.auctionId == undefined || this.auctionModel.activeAuction.auctionId.length==0;
  }
  
  initSellerDetails(){
    console.log('initSellerDetails');
    if(this.sellerBusinessNameList.length > 0 && this.auctionModel.activeAuction.useSellerContactInfo){
      this.auctionModel.activeAuction.timezone = this.sellerBusinessNameList[0].businessTimezone;
      this.auctionModel.activeAuction.sellerCity = this.sellerBusinessNameList[0].physicalCity;
      this.auctionModel.activeAuction.sellerState = this.sellerBusinessNameList[0].physicalState;
      this.auctionModel.activeAuction.sellerContactName = this.sellerBusinessNameList[0].getFullName();
      this.auctionModel.activeAuction.sellerEmail = this.sellerBusinessNameList[0].email;
      this.auctionModel.activeAuction.sellerPhoneNumbers = [];
      for(let item of this.sellerBusinessNameList[0].businessPhoneNumbers){
        this.auctionModel.activeAuction.sellerPhoneNumbers.push(item);
      }
      
      this.validateAtLeastOneSellerPhoneNumber();
    }
    else if(this.sellerBusinessNameList.length == 0){
      this.auctionModel.activeAuction.timezone = "";
      this.auctionModel.activeAuction.sellerCity = "";
      this.auctionModel.activeAuction.sellerState = "";
      this.auctionModel.activeAuction.sellerContactName = "";
      this.auctionModel.activeAuction.sellerEmail = "";
      this.auctionModel.activeAuction.useSellerContactInfo = true;
      this.auctionModel.activeAuction.sellerPhoneNumbers = [];      
      this.onAddBlankSellerPhoneNumber("",true,false);
    }
    else {
      
    }
  }
  
  requiredFields(){
    var returnValue = true;
    if(this.auctionModel.activeAuction.title ==undefined || this.auctionModel.activeAuction.title.length == 0){
      returnValue = false;
    }
    
    if(this.startDate == undefined || this.startDate.length == 0){
      returnValue = false;
    }
    
    if(this.startTime == undefined || this.startTime.length == 0){
      returnValue = false;
    }
    
    if(this.endDate == undefined || this.endDate.length == 0){
      returnValue = false;
    }
    
    if(this.endTime == undefined || this.endTime.length == 0){
      returnValue = false;
    }
    
    if(!this.validSellerEmail()){
      returnValue = false;
    }
    
    if(!this.validateSellerPhoneNumbers()){
      returnValue = false;
    }
    
    if(!this.isDateHtmlElementSupported() && (!this.validStartDate() || !this.validEndDate())){
      returnValue = false;
    }

    if(!this.isTimeHtmlElementSupported() && (!this.validStartTime() || !this.validEndTime())){
      returnValue = false;
    }

    var eventMsg = {}
    eventMsg['message'] = 'auctionsaleinforequiredfields';
    eventMsg['parms'] = {
      'requiredfields':returnValue
    };
    this.messageEvent.emit(eventMsg);
    
    return returnValue;
  }
  
  validSellerEmail() {
    var returnValue = true;
    if(this.auctionModel.activeAuction.sellerEmail != undefined && this.auctionModel.activeAuction.sellerEmail.length > 0){
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      returnValue = re.test(String(this.auctionModel.activeAuction.sellerEmail).toLowerCase());
    }
    return returnValue;
  }  
  
  validateSellerPhoneNumbers(){
    var returnValue = true;
    if(this.auctionModel.activeAuction != undefined && this.auctionModel.activeAuction.sellerPhoneNumbers != undefined){
      for(let item of this.auctionModel.activeAuction.sellerPhoneNumbers ){
        returnValue = item.phone.length > 0 ? /^\(\d{3}\)\s\d{3}-\d{4}/g.test(item.phone) : true;
        if(returnValue == false){
          break;
        }
      }
    }
    return returnValue;
  }
  
  validPhone(index){
    var phone = this.auctionModel.activeAuction.sellerPhoneNumbers[index].phone;
    var returnValue = false;
    if(phone.length == 0){
      returnValue = true;
    }
    else if(phone.length == 14){
      returnValue = /^\(\d{3}\)\s\d{3}-\d{4}/g.test(phone);
    }
    return returnValue;
  }
  
  isEmpty(text: any) {
    return (typeof text === 'undefined') || text === '' || text === null
  }
  
  onStartTimeClick(){
    if(this.startTime == undefined || this.startTime.length == 0){
      this.startTime = "00:00";
    }
  }

  onEndTimeClick(){
    if(this.endTime == undefined || this.endTime.length == 0){
      this.endTime = "00:00";
    }
  }
  
  isDateHtmlElementSupported(){
    var input = document.createElement('input');
  	var value = 'a';
  	input.setAttribute('type', 'date');
  	input.setAttribute('value', value);
  	return (input.value !== value);    
  }

  isTimeHtmlElementSupported(){
    var input = document.createElement('input');
  	var value = 'a';
  	input.setAttribute('type', 'time');
  	input.setAttribute('value', value);
  	return (input.value !== value);    
  }
  
  validStartDate(){
    return Timezones.isDateStr_mmddyyyy(this.startDate); 
  }

  validEndDate(){
    return Timezones.isDateStr_mmddyyyy(this.endDate); 
  }

  validStartTime(){
    return Timezones.isTimeStr_ampm(this.startTime);
  }

  validEndTime(){
    return Timezones.isTimeStr_ampm(this.endTime);
  }

  initSeller(sellerAccountId){
    // console.log('initSeller',sellerAccountId);
    let sellerExists = this.sellerBusinessNameList.find((el)=>{return el.accountId==sellerAccountId});
    if(sellerExists == undefined){
      let activeSeller = this.sellersList.find((el)=>{return el.accountId==sellerAccountId});
      this.sellerBusinessNameList.push(activeSeller);
      this.initSellerDetails();        
    }
  }
  
}
