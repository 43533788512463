import { Component, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { LotModel, AuctionModelService, CustomLotField, LotImage } from '../../model/auction.model';
import { InterestType } from '../../API.service'
import { SellerService, Seller } from '../../model/seller.model';
import { FileTransferService } from '../../services/filetransfer.service';


@Component({
  selector: 'app-auction-lot-edit-home',
  templateUrl: './auction-lot-edit-home.component.html',
  styleUrls: ['./auction-lot-edit-home.component.scss']
})
export class AuctionLotEditHomeComponent implements OnInit {
  errorMsg = "";
  successMsg = "";
  processing: boolean = false;
  
  _LOT_IMPORT_CONSIGNOR_NOT_FOUND_ERROR_PART_ONE = "Consignor";
  _LOT_IMPORT_CONSIGNOR_NOT_FOUND_ERROR_PART_TWO = "not found in auction seller list";
  
  usersellercontact:boolean = true;
  sellerAccountId;
  sellerBusinessNameList = [];
  sellersList:Seller[] = [];
  lotCategories = [];
  lotSexedCategories = [];
  lotInterestCategories = [];
  lotExportableTypes = [];
  dob;
  epdtype;

  @ViewChild('lotphotos')  lotphotos;  
  @ViewChild('lotvideos')  lotvideos;  

  @Output() messageEvent = new EventEmitter<any>();
  @Input() lotToEdit:LotModel = undefined;

  constructor(public auctionModel:AuctionModelService,
              private sellerService: SellerService,
              private fileTransferService : FileTransferService) { }

  ngOnInit(): void {
    this.usersellercontact = true;
    this.loadSellers();
    this.lotCategories = LotModel.LOT_CATEGORIES;
    this.lotSexedCategories = LotModel.SEXED_CATEGORIES;
    this.lotInterestCategories = LotModel.INTEREST_CATEGORIES;
    this.lotExportableTypes = LotModel.EXPORTABLE_TYPES;
    this.epdtype = "ACTUAL";
  }
  
  /**
   * server based api calls
   **/
  async saveLot(){
    var newLot:boolean = false;
    this.processing = true;
    this.errorMsg = "";
    this.successMsg = "";
    try {
      if(this.lotToEdit.lotId == undefined ||
         this.lotToEdit.lotId.length == 0){
        await this.lotToEdit.create();
        newLot = true;
      }
      await this.saveFiles();
      await this.lotToEdit.update();
      this.loadPageData();
      this.successMsg = "Lot successfully saved.";
      this.processing = false;
      newLot ? this.sendNewLotMsg() : this.sendUpdateLotMsg();
    }
    catch(err){
      this.processing = false;
      this.errorMsg = "Error saving lot: " + err;
    }
    return this.lotToEdit;
  }

  async saveFiles(){
    await this.savePhotos();
    await this.saveVideos();
  }
  
  async savePhotos(){
    this.lotToEdit.photos = [];
    for(let item of this.lotphotos.files){

      if(item.new == 1){
        let fileURLs = await this.lotToEdit.getFileURL(item.name);
        let lotImage:LotImage = {
          "storageName": fileURLs['fileKey'],
          "fileName": item.name,
          "caption": item.caption,
          "cover": item.cover
        };
        
        this.lotToEdit.photos.push(lotImage);
        await this.uploadFile(item,fileURLs['uploadUrl']);
      }
      else {
        let lotImage:LotImage = {
          "storageName": item.storageName,
          "fileName": item.name,
          "caption": item.caption,
          "cover": item.cover
        };
        this.lotToEdit.photos.push(lotImage);
      }
    }
  }

  async saveVideos(){
    this.lotToEdit.videos = [];
    for(let item of this.lotvideos.files){
      if(item.new == 1){
        let fileURLs = await this.lotToEdit.getFileURL(item.name);
        let lotImage:LotImage = {
          "storageName": fileURLs['fileKey'],
          "fileName": item.name,
          "caption": item.caption,
          "cover": item.cover
        };
        
        this.lotToEdit.videos.push(lotImage);
        await this.uploadFile(item,fileURLs['uploadUrl']);
      }
      else {
        let lotImage:LotImage = {
          "storageName": item.storageName,
          "fileName": item.name,
          "caption": item.caption,
          "cover": item.cover
        };
        this.lotToEdit.videos.push(lotImage);
      }
    }
  }

  async uploadFile(file,signedurl){
    this.fileTransferService.uploadSignedUrl(file,signedurl)
      .subscribe(
        data => {
            return data;
      },
        (err) => {
          throw(err);
      }
    );
  }
  
  async loadSellers(){
    this.processing = true;
    try {
      this.sellersList = await this.sellerService.loadSellers();
      this.loadPageData();
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving sellers list');
    }
  }
  
  /**
   * html page handlers
   **/
  onRemoveCustomField(index){
    this.lotToEdit.customFields.splice(index,1);
  }  
  
  onAddCustomField(){
    let customField = {"customLabel":"","customValue":""};
    if(this.lotToEdit.customFields == undefined){
      this.lotToEdit.customFields = [];
    }
    this.lotToEdit.customFields.push(customField);
  }
  
  async onSave(){
    await this.saveLot();
  }
  
  onClone(){
    var eventMsg = {}
    eventMsg['message'] = 'clonelot';
    eventMsg['parms'] = this.lotToEdit;
    this.messageEvent.emit(eventMsg);
  }

  onNewLot(){
    var eventMsg = {}
    eventMsg['message'] = 'initnewlot';
    this.messageEvent.emit(eventMsg);
  }
  
  onDelete(){
    var eventMsg = {}
    eventMsg['message'] = 'deletelot';
    eventMsg['parms'] = this.lotToEdit;
    this.messageEvent.emit(eventMsg);
  }

  /**
   * other
   **/
  dropFileHandler($event) {
  }
  
  loadPageData(){
    this.loadFiles();
    this.loadAuctionSellers();
    this.setSellerContactAccount();
  }
  
  loadFiles(){
    this.lotphotos.files = [];
    if(this.lotToEdit.photos != undefined){
      for(let value of this.lotToEdit.photos){
        let item = {
          "imageUrl":this.lotToEdit.getPhotoUrl(value),
          "format":"image",
          "name":value.fileName,
          "caption":value.caption,
          "storageName":value.storageName,
          "cover":value.cover,
          "size":"",
          "new":0
        }
        this.lotphotos.files.push(item);
      }
    }

    this.lotvideos.files = [];
    if(this.lotToEdit.videos != undefined){
      for(let value of this.lotToEdit.videos){
        let item = {
          "imageUrl":this.lotToEdit.getVideoUrl(value),
          "format":"video",
          "name":value.fileName,
          "caption":value.caption,
          "storageName":value.storageName,
          "cover":value.cover,
          "size":"",
          "new":0
        }
        this.lotvideos.files.push(item);
      }
    }
    
  }  

  loadAuctionSellers(){
    let seller = {};
    this.sellerBusinessNameList = [];
    if(this.auctionModel.activeAuction.primaryBusiness != undefined){
        let seller = this.sellersList.find((el)=>{return el.accountId==this.auctionModel.activeAuction.primaryBusiness.accountId});
        this.sellerBusinessNameList.push(seller);
    }
    
    if(this.auctionModel.activeAuction.businesses != undefined){    
      for(let item of this.auctionModel.activeAuction.businesses){
        let seller = this.sellersList.find((el)=>{return el.accountId==item.accountId});
        this.sellerBusinessNameList.push(seller);
      }
    }
  }
  
  setSellerContactAccount(){

  }

  sendNewLotMsg(){
    var eventMsg = {}
    eventMsg['message'] = 'newlot';
    eventMsg['parms'] = this.lotToEdit;
    this.messageEvent.emit(eventMsg);
  }

  sendUpdateLotMsg(){
    var eventMsg = {}
    eventMsg['message'] = 'updatelot';
    eventMsg['parms'] = this.lotToEdit;
    this.messageEvent.emit(eventMsg);
  }
  
  onClearInterest(){
    this.lotToEdit.interest = InterestType.WHOLE;
  }

  onClearDOB(){
    this.lotToEdit.dob = null;
  }

  onClearSex(){
    this.lotToEdit.sexedCategory = null;
  }

  onClearExportable(){
    this.lotToEdit.exportable = null;
  }

  onClearLotCategory(){
    this.lotToEdit.category = null;
  }
  
  importLotError(){
    return this.lotToEdit.importError != undefined && 
           this.lotToEdit.importErrors != undefined && 
           this.lotToEdit.importErrors.length > 0 ? this.lotToEdit.importError : false;
  }
  
  consignorImportError(){
    var returnValue = false;
    if(this.importLotError()){
      for(let item of this.lotToEdit.importErrors){
        if(item.includes(this._LOT_IMPORT_CONSIGNOR_NOT_FOUND_ERROR_PART_ONE) && 
           item.includes(this._LOT_IMPORT_CONSIGNOR_NOT_FOUND_ERROR_PART_TWO)){
             returnValue = true;
             break
           }
      }
    }
    return returnValue;
  }
  

} /* End class  */
