<div class="loading-shade" *ngIf="processing"></div>

<div class="container-fluid">

    <div class="row mt-2 mb-4">
        <div class="col-md-12 mb-4">
            <nb-icon icon="arrow-back-outline" class="back-icon"></nb-icon>
            <a routerLink="/dashboard" class="link-text ml-1">Dashboard</a>
        </div>
    </div>

    <div class="row mb-3" [nbSpinner]="processing" nbSpinnerStatus="primary" nbSpinnerSize="giant">
        <div class="col-md-6 title-text center" style="font-size:22px">
            Manage Auctions
        </div>
        <div class="col-md-6 right-justify center">
            <button class="r-top" nbButton size="small" status="primary" (click)="onNewAuction()">+ NEW AUCTION</button>
        </div>
    </div>
    
    <div class="row mt-3 mb-3 container-horiz-padded" *ngIf="errorMsg.length > 0">
        <div class="col-md-12">
            <div><span class="errorText">{{errorMsg}}</span></div>
        </div>
    </div>
    
    <div class="row mb-3" style="height:48px">
        <div class="col-md-12">
            <nb-tabset (changeTab)="selectTab($event)" class="tabstyles">
                <nb-tab class="tabfont" tabTitle="CURRENT AUCTIONS"></nb-tab>
                <!--<nb-tab class="tabfont" tabTitle="ARCHIVED AUCTIONS"></nb-tab>-->
            </nb-tabset>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-6 r-top center">
            <table width="100%">
                <tr>
                    <th class="label-text center">SEARCH AUCTIONS</th>
                </tr>
                <tr>
                    <td>
                      <nb-form-field>
                        <nb-icon nbSuffix icon="search-outline" pack="eva" status="primary"></nb-icon>
                        <input type="text"
                              nbInput
                              placeholder="Search by Seller or Auction Name"
                              id="searchauction"
                              name="searchauction"
                              [(ngModel)]="searchauction"
                              (ngModelChange)="onUpdateFilter($event)">
                      </nb-form-field>
                    </td>
                </tr>
            </table>
        </div>

        <!-- wide -->
        <div class="col-md-6 label-text center r-top d-sm-block d-none">
            <table>
                <tr>
                    <th class="label-text" colspan="2">FILTER BY STATUS</th>
                </tr>
                <tr>
                    <td>
                        <button nbButton (click)="onFilterByStatus(1)" [appearance]="getAppearance(1)" [status]="getStatus(1)">SETUP</button>
                    </td>
                    <td>
                        <button nbButton (click)="onFilterByStatus(2)" [appearance]="getAppearance(2)" [status]="getStatus(2)">POSTED</button>
                    </td>
                    <td>
                        <button nbButton (click)="onFilterByStatus(3)" [appearance]="getAppearance(3)" [status]="getStatus(3)">LIVE</button>
                    </td>
                    <td>
                        <button nbButton (click)="onFilterByStatus(4)" [appearance]="getAppearance(4)" [status]="getStatus(4)">CLOSED</button>
                    </td>
                    <td>
                        <button nbButton (click)="onFilterByStatus(5)" [appearance]="getAppearance(5)" [status]="getStatus(5)">SETTLED</button>
                    </td>
                </tr>
            </table>
        </div>

        <!-- narrow -->
        <div class="col-md-6 label-text center r-top d-sm-none">
            <table>
                <tr>
                    <th class="label-text" colspan="2">FILTER BY STATUS</th>
                </tr>
                <tr>
                    <td>
                        <button nbButton (click)="onFilterByStatus(1)" appearance="ghost" status="basic">SETUP</button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button nbButton (click)="onFilterByStatus(2)" appearance="ghost" status="basic">POSTED</button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button nbButton (click)="onFilterByStatus(3)" appearance="ghost" status="basic">LIVE</button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button nbButton (click)="onFilterByStatus(4)" appearance="ghost" status="basic">CLOSED</button>
                    </td>
                </tr>
            </table>
        </div>
    </div>

    <div class="row mb-3 mt-5">
        <div class="col-md-6 center">
            <span class="info-text">All Results</span><span class="info-data-text ml-2" style="font-weight:700">{{ getRecordsLength() }}</span>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
              <app-auctionslist #currentlist [hidden]="!(current && openAuctionList.length > 0)" (messageEvent)="receiveMessage($event)" [filterStatus]="lastStatusFilter" type="auction"></app-auctionslist>
            <!--<app-auctionslist #archivelist *ngIf="!current && archiveAuctionList.length > 0" [rows]="archiveAuctionList" (messageEvent)="receiveMessage($event)" [filterStatus]="lastStatusFilter" type="auction"></app-auctionslist>-->
        </div>
    </div>

    <a #downloadLink style="display:none"></a>

</div> <!-- container-fluid -->
