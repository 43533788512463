<div class="upload">
  <div class="upload__box upload_box--full-width" appDnd (fileDropped)="onFileDropped($event)">
    <input type="file"
            id="fileDropRef"
            [attr.multiple]="multipleFiles"
            [attr.accept]="accept"
            #fileDropRef
            (change)="fileBrowseHandler($event.target.files)"
            style="display:none">

    <nb-icon icon="download-outline" class="upload__drag-image"></nb-icon>
    <div class="upload__text">Drag {{multipleFiles ? "File(s)" : "a File"}} Here</div>
  </div>
  <div class="upload_file-picker">
    <span class="upload__label">OR&nbsp;</span>
    <a [routerLink]="" class="link-text" (click)="fileDropRef.click()">CHOOSE A FILE</a>
  </div>
</div>

<div *ngIf="showList">
  <table class="images" style="margin-top:1.5rem">
      <tr *ngFor="let item of files;let i=index">
          <td class="images">
              <img *ngIf="item.format==='image'" [ngStyle]="getImageStyle(item)" [src]="getImageUrl(item)" (error)="imgError($event,item.id)">
              <video *ngIf="item.format==='video'" [ngStyle]="getImageStyle(item)" [src]="getImageUrl(item)" (error)="imgError($event,item.id)"></video>
              <img *ngIf="item.format!='image' && item.format!='video'" [ngStyle]="getImageStyle(item)" [src]="getImageUrl(item)" (error)="imgError($event,item.id)">
          </td>
          <td class="images">
              <span class="labelText mb-1">{{ item.name }}</span>
          </td>
          <td class="images">
              <span class="labelText mb-1">{{ item.size }}</span>
          </td>
          <td class="images">
              <a [routerLink]="" (click)= "removeFile(i)">
                  <nb-icon status="primary" icon="trash-2-outline" class="trash-icon"></nb-icon>
              </a>
          </td>
      </tr>
  </table>
</div>