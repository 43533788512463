<div class="video-player-wrapper">

    <vg-player (onPlayerReady)="videoPlayerInit($event)">
      <vg-overlay-play></vg-overlay-play>
      <vg-buffering></vg-buffering>

      <!--<vg-scrub-bar>-->
      <!--  <vg-scrub-bar-current-time></vg-scrub-bar-current-time>-->
      <!--  <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>-->
      <!--</vg-scrub-bar>-->

      <vg-controls>
        <vg-play-pause></vg-play-pause>
        <!--<vg-playback-button></vg-playback-button>-->

        <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

        <vg-scrub-bar>
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
        </vg-scrub-bar>
        
        <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
        
        <!--<vg-volume>-->
        <!--    <vg-mute-button></vg-mute-button>-->
        <!--    <vg-volume-bar></vg-volume-bar>-->
        <!--</vg-volume>-->
        
        <vg-mute></vg-mute>
        <!--<vg-volume></vg-volume>-->

        <vg-fullscreen></vg-fullscreen>
        <a [routerLink]="" (click)="previousVideo()" class="media-button"><nb-icon icon="arrow-ios-back-outline"></nb-icon></a>
        <div class="media-text">{{ activeIndex +1 }}/{{ videoItems.length }}
        <a [routerLink]="" (click)="nextVideo()" class="media-button"><nb-icon icon="arrow-ios-forward-outline"></nb-icon></a>
        </div>

      </vg-controls>

      <video #media [vgMedia]="media" [src]="currentVideo.src" id="singleVideo" preload="auto" height="295"></video>
    </vg-player>

    <!--<ul class="player-list">-->
    <!--  <li *ngFor="let vdo of videoItems; let $index = index"-->
    <!--    (click)="startPlaylistVdo(vdo, $index)" [class.selected]="vdo === currentVideo">-->
    <!--    {{ vdo.name }}-->
    <!--  </li>-->
    <!--</ul>-->

</div>