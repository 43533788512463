import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  
  statusCards = [];
  menuCards = [];

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.loadStatusCards();
    this.loadMenuCards();
  }
  
  loadStatusCards(){
    this.statusCards = [];
    var statusCard = {
      "title": "SET UP",
      "route": "/manageauctions/1"
    }
    this.statusCards.push(statusCard);
    
    var statusCard = {
      "title": "POSTED",
      "route": "/manageauctions/2"
    }
    this.statusCards.push(statusCard);

    var statusCard = {
      "title": "LIVE",
      "route": "/manageauctions/3"
    }
    this.statusCards.push(statusCard);

    var statusCard = {
      "title": "CLOSED",
      "route": "/manageauctions/4"
    }
    
    this.statusCards.push(statusCard);
    var statusCard = {
      "title": "SETTLED",
      "route": "/manageauctions/5"
    }
    this.statusCards.push(statusCard);
  }
  
  loadMenuCards(){
    this.menuCards = [];
    var menuCard = {
      "title": "+ NEW SELLER",
      "route": "/editseller/NEW/NEW/1"
    }

    this.menuCards.push(menuCard);
    var menuCard = {
      "title": "+ NEW AUCTION",
      "route": "/editauction/NEW/NEW/0/0/1/0/0"
    }
    this.menuCards.push(menuCard);
    var menuCard = {
      "title": "+ NEW USER",
      "route": "/userdetails/NEW/NEW"
    }
    this.menuCards.push(menuCard);
  }

  onClickStatusCard(status){
      this.router.navigate([status.route, status.status]);
  }

}
