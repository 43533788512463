<div class="loading-shade" *ngIf="processing"></div>

<div class="container-fluid">

    <div class="row mt-2 mb-4">
        <div class="col-md-12 mb-4">
            <nb-icon icon="arrow-back-outline" class="back-icon"></nb-icon>
            <a routerLink="/dashboard" class="link-text ml-1">Dashboard</a>
        </div> 
    </div> 

    <div class="row mb-3" [nbSpinner]="processing" nbSpinnerStatus="primary" nbSpinnerSize="giant">
        <div class="col-md-6 title-text center" style="font-size:22px">
            Manage Site
        </div>
        <div class="col-md-6 right-justify center">
            <button class="r-top" nbButton size="small" status="primary" (click)="onNewUser()">+ NEW USER</button>
        </div>
    </div>
    
    <div class="row mb-5">
        <ng-container *ngFor="let menu of menuCards">
              <div class="col-md-4 center mt-3">
                  <app-menucard [menu]="menu"></app-menucard>
              </div>
        </ng-container>  
    </div>
    
    <div class="row mb-2">
        <div class="col-md-12">
            <div *ngIf="errorMsg.length > 0"><span class="errorText">{{errorMsg}}</span></div>
        </div>
    </div>
    
    <div class="row mb-2">
        <div class="col-md-6 label-text center" style="font-size:15px;font-weight:600">
            Users
        </div>
    </div>

    <div class="row mb-1">
        <div class="col-md-6 label-text center">
            SEARCH USERS
        </div>
    </div>
    
    <div class="row mb-3">
        <div class="col-md-12 label-text center">
            <nb-select  
                        placeholder="Select Search Type"
                        name="usersearchtype"
                        id="usersearchtype"
                        (selectedChange)="onSelectedSearchChange($event)"
                        [(ngModel)]="searchType">
                <nb-option [value]="1">By last name</nb-option>
                <nb-option [value]="2">By email</nb-option>
            </nb-select>
            <button type="button" nbSuffix nbButton ghost (click)="onClearSearchType()">
               <nb-icon [icon]="'close-circle-outline'"
                        pack="eva">
               </nb-icon>
            </button>
            
            <input class="ml-3 mr-1" 
                  style="width:300px"
                  type="text"
                  nbInput
                  placeholder="Enter search value"
                  id="searchuser"
                  name="searchuser"
                  [(ngModel)]="searchUser"
                  [disabled]="searchTextFieldDisabled()">
            <button [nbSpinner]="processing" nbButton size="small" status="primary" (click)="onSearch()" [disabled]="searchDisabled()">Search</button>
        </div>

    </div>
    
    <div class="row">
        <div class="col-md-12">
            <app-userslist #myuserslist *ngIf="accountService.usersList.length > 0" [rows]="accountService.usersList" (messageEvent)="receiveMessage($event)"></app-userslist>
        </div>
    </div>
    
    <div class="row mt-2 mb-2">
        <div class="col-md-12">
            <hr>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <button nbButton size="small" shape="rectangle" (click)="onPreviousUsers()" [disabled]="!previousAvail()">
                <nb-icon *ngIf="previousAvail()" status="primary" icon="arrow-ios-back-outline"></nb-icon>
                <nb-icon *ngIf="!previousAvail()" icon="arrow-ios-back-outline"></nb-icon>
            </button>
            <button nbButton size="small" shape="rectangle" (click)="onNextUsers()" [disabled]="!nextAvail()">
                <nb-icon *ngIf="nextAvail()" status="primary" icon="arrow-ios-forward-outline"></nb-icon>
                <nb-icon *ngIf="!nextAvail()" icon="arrow-ios-forward-outline"></nb-icon>
            </button>
        </div>
    </div>

</div> <!-- container-fluid -->
