
<div class="loading-shade" *ngIf="processing"></div>
<div>
    <nb-card>
        <nb-card-body>
            <nb-icon icon="close-outline" class="close-button float-right" (click)="ref.close()"></nb-icon>
            <div class="container-fluid p-1 p-sm-5">
                <h6>Partial Payment</h6>
                <p>Partial payments to seller will incur a $xx processing fee.</p>
                <form #f="ngForm">
                  <div class="row mb-1">
                    <div class="col auction-label-text">
                        Seller Payouts To Date
                    </div>
                  </div>

                  <div class="row mb-2">
                      <div class="col-sm-6">
                        ${{ totalSellerPayoutsToDate }}
                      </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col auction-label-text">
                        Payments Collected To Date
                    </div>
                  </div>

                  <div class="row mb-2">
                      <div class="col-sm-6">
                        ${{ totalPaymentsCollectedToDate }}
                      </div>
                  </div>

                  <div class="row mb-1">
                    <div class="col auction-label-text">
                        Partial Payment Amount
                    </div>
                  </div>

                  <div class="row mb-2">
                      <div class="col-sm-8">
                        <input nbInput
                          fullWidth
                          type="number"
                          name="partial_payment"
                          [(ngModel)]="partialPaymentAmount"
                          required>
                      </div>
                  </div>

                  <div class="row mb-1">
                      <div class="col auction-label-text">
                          Fees
                      </div>
                  </div>

                  <div class="row mb-2">
                      <div class="col-sm-8">
                        <input nbInput
                          fullWidth
                          type="number"
                          name="fee"
                          [(ngModel)]="fee"
                          required>
                      </div>
                  </div>

                  <div class="row mb-1">
                      <div class="col auction-label-text">
                          Amount Paid to Seller
                      </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-sm-6">
                      <span [ngClass]="{ 'payment-invalid': paymentLargerThanCollected() }">{{ calculatedPartialPaymentAmount() | currency }}</span>
                    </div>
                  </div>

                  <div class="row">
                      <div class="col align-items-center">
                          <button type="submit" [nbSpinner]="processing" nbButton size="medium" status="basic" (click)="saveAuctionReportPartialPayment()" [disabled]="!f.valid || paymentLargerThanCollected()">Save</button>
                          <a class="back-link-text ml-2" (click)="ref.close()">Cancel</a>
                      </div>
                  </div>
                </form>
            </div>
        </nb-card-body>
    </nb-card>
</div>
