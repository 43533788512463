<div class="container-fluid">

    <div class="row no-gutters table-header-border">
	    <div class="col-md-2 d-md-block d-none mt-3 mb-2">
	      <div><span class="table-column-text"> {{ user.givenName != undefined ? user.givenName : '-' }} </span></div>
	    </div>
        
	    <div class="col-md-2 d-md-block d-none mt-3 mb-2">
	      <div><span class="table-column-text"> {{ user.familyName != undefined ? user.familyName : '-' }} </span></div>
	    </div>
    	
	    <div class="col-md-2 d-md-block d-none mt-3 mb-2">
	      <div class="table-column-text">
            <nb-toggle 
                (change)="updateAdmin($event)"
                status="primary"
                [(ngModel)]="admin">Admin                    
            </nb-toggle>
	      </div>
	    </div>

	    <div class="col-md-2 d-md-block d-none mt-3 mb-2">
	      <div class="table-column-text">
            <nb-toggle 
                (change)="updateBidder($event)"
                status="primary"
                [(ngModel)]="bidder">Bidder                   
            </nb-toggle>
	      </div>
	    </div>

	    <div class="col-md-2 d-md-block d-none mt-3 mb-2">
	      <div class="table-column-text">
            <nb-toggle 
                (change)="updateSeller($event)"
                status="primary"
                [(ngModel)]="seller">Seller                   
            </nb-toggle>
	      </div>
	    </div>

	    <div class="col-md-1 d-md-block d-none mt-3 mb-2">
	      <span class="table-column-text">
            <nb-icon icon="edit-outline" class="details-icon"></nb-icon>
            <a [routerLink]="" class="link-text ml-1" (click)="onEditUser()">Details</a>
	      </span>
	    </div>

    </div> <!-- header -->
</div>
