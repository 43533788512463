<div class="container-fluid">

    <div class="row no-gutters table-header-border">

	    <div class="col-md-1 d-md-block d-none table-header-text"></div>
    	
	    <div class="col-md-3 d-md-block d-none table-header-text">NAME </div>
	    
	    <div class="col-md-4 d-md-block d-none table-header-text">EMAIL</div>

	    <div class="col-md-4 d-md-block d-none table-header-text">PHONE NUMBER</div>

    </div> <!-- header -->

    <div class="row no-gutters">
    	<div class="col-md-12">
		  <div *ngFor="let item of rows | paginate: { id: 'playermatch_pagination',
	                                                  	   itemsPerPage: 10,
	                                                  	   currentPage: p,
	                                                  	   totalItems: rows.length }">
		     <app-select-user-detail [user]="item"></app-select-user-detail>
		  </div>
		</div>
	</div>
	
    <div class="row mt-2 mb-2 no-gutters">
        <div class="col-md-12">
          <pagination-controls id="playermatch_pagination" maxSize="10" class="list-custom-pagination" (pageChange)="p = $event" autoHide="true" previousLabel="" nextLabel=""></pagination-controls>          
        </div>
        <div class="col"></div>
    </div>          

</div> <!-- container-fluid -->
