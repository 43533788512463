<div class="container-fluid">

      <div class="row mb-5 mt-4">
        <div class="col-md-12">
            <div class="title-text center" style="font-size:22px">
                Administration Dashboard
            </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
            <div class="title-text center">
                Current Auctions
            </div>
        </div>
      </div>

      <div class="row mb-5">
          <ng-container *ngFor="let menu of statusCards">
                  <div class="col-md-2 center mt-3">
                      <app-menucard [menu]="menu" style="width:212px"></app-menucard>
                  </div>
          </ng-container>
      </div>

      <div class="row mt-2">
        <div class="col-md-12">
            <div class="title-text center">
                Create
            </div>
        </div>
      </div>

      <div class="row mb-5">
          <ng-container *ngFor="let menu of menuCards">
                  <div class="col-md-3 center mt-3">
                      <app-menucard [menu]="menu" style="width: 289px"></app-menucard>
                  </div>
          </ng-container>
      </div>


</div>
