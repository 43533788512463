<div class="container-fluid">

    <div class="row no-gutters table-header-border">

	    <div class="col-md-1 d-md-block d-none">
	      <span class="table-header-text-link"></span>
	    </div>
    	
	    <div class="col-md-3 d-md-block d-none">
	      <span class="table-header-text-link">AUCTION NAME</span>
	    </div>
	    
	    <div class="col-md-2 d-md-block d-none">
	      <span class="table-header-text-link">START DATE</span>
	    </div>
	    
	    <div class="col-md-2 d-md-block d-none">
	      <div><span class="table-header-text-link"> TOTAL </span></div>
	    </div>

	    <div class="col-md-2 d-md-block d-none">
	      <div><span class="table-header-text-link"> PAID </span></div>
	    </div>

	    <div class="col-md-2 d-md-block d-none">
	      <div><span class="table-header-text-link"> INVOICE </span></div>
	    </div>
    </div> <!-- header -->

    <div class="row no-gutters">
    	<div class="col-md-12">
		  <div *ngFor="let item of filteredList">
		     <app-user-wins-detail [user]="item" (messageEvent)="receiveMessage($event)"></app-user-wins-detail>
		     <div class="d-md-none mb-3"></div>
		  </div>
		</div>
	</div>

</div> <!-- container-fluid -->
