<div class="container-fluid">

    <div class="row mb-1">
        <div class="col-md-3 auction-label-text">
            SIRE
        </div>
        <div class="col-md-3 auction-label-text">
            REG#
        </div>
        <div class="col-md-3 auction-label-text">
            REG# LINK
        </div>
        <div class="col"></div>
    </div> 

    <div class="row mb-3">
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="sirename" 
                   name="sirename" 
                   [(ngModel)]="lot.lineageHierarchy['sire']">            
        </div>
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="sireregnum" 
                   name="sireregnum" 
                   [(ngModel)]="lot.lineageHierarchy['sireRegNum']">            
        </div>
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="sireurl" 
                   name="sireurl" 
                   [(ngModel)]="lot.lineageHierarchy['sireUrl']">            
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-1">
        <div class="col-md-3 auction-label-text">
        </div>
        <div class="col-md-3 auction-label-text">
            PATERNAL GRAND SIRE
        </div>
        <div class="col-md-3 auction-label-text">
            REG#
        </div>
        <div class="col-md-3 auction-label-text">
            REG# LINK
        </div>
    </div> 

    <div class="row mb-3">
        <div class="col-md-3"></div>
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="paternalgrandsirename" 
                   name="paternalgrandsirename" 
                   [(ngModel)]="lot.lineageHierarchy['sireParents']['sire']">            
        </div>
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="paternalgrandsireregnum" 
                   name="paternalgrandsireregnum" 
                   [(ngModel)]="lot.lineageHierarchy['sireParents']['sireRegNum']">            
        </div>
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="paternalgrandsireurl" 
                   name="paternalgrandsireurl" 
                   [(ngModel)]="lot.lineageHierarchy['sireParents']['sireUrl']">            
        </div>
    </div>

    <div class="row mb-1">
        <div class="col-md-3 auction-label-text">
        </div>
        <div class="col-md-3 auction-label-text">
            PATERNAL GRAND DAM
        </div>
        <div class="col-md-3 auction-label-text">
            REG#
        </div>
        <div class="col-md-3 auction-label-text">
            REG# LINK
        </div>
    </div> 

    <div class="row mb-3">
        <div class="col-md-3"></div>
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="paternalgranddamname" 
                   name="paternalgranddamname" 
                   [(ngModel)]="lot.lineageHierarchy['sireParents']['dam']">            
        </div>
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="paternalgranddamregnum" 
                   name="paternalgranddamregnum" 
                   [(ngModel)]="lot.lineageHierarchy['sireParents']['damRegNum']">            
        </div>
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="paternalgranddamurl" 
                   name="paternalgranddamurl" 
                   [(ngModel)]="lot.lineageHierarchy['sireParents']['damUrl']">            
        </div>
    </div>

    <div class="row mb-1">
        <div class="col-md-3 auction-label-text">
            DAM
        </div>
        <div class="col-md-3 auction-label-text">
            REG#
        </div>
        <div class="col-md-3 auction-label-text">
            REG# LINK
        </div>
        <div class="col"></div>
    </div> 

    <div class="row mb-3">
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="damname" 
                   name="damname" 
                   [(ngModel)]="lot.lineageHierarchy['dam']">            
        </div>
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="damregnum" 
                   name="damregnum" 
                   [(ngModel)]="lot.lineageHierarchy['damRegNum']">            
        </div>
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="damurl" 
                   name="damurl" 
                   [(ngModel)]="lot.lineageHierarchy['damUrl']">            
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-1">
        <div class="col-md-3 auction-label-text">
        </div>
        <div class="col-md-3 auction-label-text">
            MATERNAL GRAND SIRE
        </div>
        <div class="col-md-3 auction-label-text">
            REG#
        </div>
        <div class="col-md-3 auction-label-text">
            REG# LINK
        </div>
    </div> 
    
    <div class="row mb-3">
        <div class="col-md-3"></div>
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="maternalgrandsirename" 
                   name="maternalgrandsirename" 
                   [(ngModel)]="lot.lineageHierarchy['damParents']['sire']">            
        </div>
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="maternalgrandsireregnum" 
                   name="maternalgrandsireregnum" 
                   [(ngModel)]="lot.lineageHierarchy['damParents']['sireRegNum']">            
        </div>
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="maternalgrandsireurl" 
                   name="maternalgrandsireurl" 
                   [(ngModel)]="lot.lineageHierarchy['damParents']['sireUrl']">            
        </div>
    </div>

    <div class="row mb-1">
        <div class="col-md-3 auction-label-text">
        </div>
        <div class="col-md-3 auction-label-text">
            MATERNAL GRAND DAM
        </div>
        <div class="col-md-3 auction-label-text">
            REG#
        </div>
        <div class="col-md-3 auction-label-text">
            REG# LINK
        </div>
    </div> 

    <div class="row mb-3">
        <div class="col-md-3"></div>
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="maternalgranddamname" 
                   name="maternalgranddamname" 
                   [(ngModel)]="lot.lineageHierarchy['damParents']['dam']">            
        </div>
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="maternalgranddamregnum" 
                   name="maternalgranddamregnum" 
                   [(ngModel)]="lot.lineageHierarchy['damParents']['damRegNum']">            
        </div>
        <div class="col-md-3">
            <input nbInput 
                   fullWidth
                   style="width:100%" 
                   type="text" 
                   id="maternalgranddamurl" 
                   name="maternalgranddamurl" 
                   [(ngModel)]="lot.lineageHierarchy['damParents']['damUrl']">            
        </div>
    </div>
    

</div> <!-- container-fluid -->
