import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { AuctionReportLineItem } from '../../model/auction.model';
import { AuctionModelService } from '../../model/auction.model';
@Component({
  selector: 'app-auction-report-update-winning-buyer-dialog',
  templateUrl: './auction-report-update-winning-buyer-dialog.component.html',
  styleUrls: ['./auction-report-update-winning-buyer-dialog.component.scss']
})
export class AuctionReportUpdateWinningBuyerDialogComponent implements OnInit {
  public lineItem: AuctionReportLineItem;
  public processing: boolean = false;
  public newLineItem: AuctionReportLineItem;
  public errorMessage: string = null;

  constructor(
    public ref: NbDialogRef<AuctionReportUpdateWinningBuyerDialogComponent>,
    private auctionModel: AuctionModelService) { }

  ngOnInit(): void {
    if (this.lineItem) {
      this.newLineItem = { ...this.lineItem };
      if (!this.newLineItem.winningBidderAddress) {
        this.newLineItem.winningBidderAddress = ["", ""]
      }
    } else {
      this.newLineItem = new AuctionReportLineItem();
    }
  }

  public async saveAuctionReportLineItem() {
    this.processing = true;
    try {
      const result = await this.auctionModel.activeAuctionReport.updateWinningBuyerInfo(this.newLineItem);

      let lineItem = this.auctionModel.activeAuctionReport.lineItems.find(x => x.lotId === result.lotId);
      lineItem.winningBidderEmail = result.winningBidderEmail;
      lineItem.winningBidderAddress = result.winningBidderAddress;
      lineItem.winningBidderFirstName = result.winningBidderFirstName;
      lineItem.winningBidderLastName = result.winningBidderLastName;
      lineItem.winningBidderPhoneNumber = result.winningBidderPhoneNumber;

      this.ref.close();
    } catch (e) {
      console.log("Error saving the auction report line item.")
      console.error(e);
      if (e.errors && e.errors[0].message.includes("valid phone number")) {
        this.errorMessage = "The auction report line item could not be saved, as the phone number is not a valid/real number.";
      } else {
        this.errorMessage = "The auction report line item could not be saved, due to an unxpected error.";
      }
    } finally {
      this.processing = false;
    }
  }

  public validPhone() {
    var returnValue = false;
    if(this.newLineItem.winningBidderPhoneNumber != undefined && this.newLineItem.winningBidderPhoneNumber.length == 14){
      returnValue = /^\(\d{3}\)\s\d{3}-\d{4}/g.test(this.newLineItem.winningBidderPhoneNumber);
    }
    return returnValue;
  }
}
