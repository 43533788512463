import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

import { AccountService, PortalUser } from '../../model/portaluser.model';

@Component({
  selector: 'app-add-bidder-dialog',
  templateUrl: './add-bidder-dialog.component.html',
  styleUrls: ['./add-bidder-dialog.component.scss']
})
export class AddBidderDialogComponent implements OnInit {
  errorMsg = "";
  processing = false;
  
  searchEmail:string;
  searchLast:string;
  
  userList = [];
  
  newuser = false;
  email:string;
  firstname:string;
  lastname:string;
  phone:string;

  constructor(protected ref: NbDialogRef<AddBidderDialogComponent>,
              private accountService: AccountService){}

  /**
   * angular (ng) initializers
   **/
  ngOnInit(): void {
    this.userList = [];
  }
  
  /**
   * server (api) calls
   **/
  async searchUserByEmail(searchEmail){
    this.userList = [];
    this.processing = true;
    try {
      let localUser = new PortalUser();
      await localUser.load(this.searchEmail);
      let selectedUser = {
        "isSelected":false,
        "user":localUser
      };
      this.userList.push(selectedUser);
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving user by email',err);
    }
  }

  async searchUserByLast(searchLastName){
    this.userList = [];
    let returnValue = [];
    this.processing = true;
    try {
      let localUser = new PortalUser();
      returnValue = await localUser.searchByLastFirst(this.searchLast);
      for(let item of returnValue){
        let selectedUser = {
          "isSelected":false,
          "user":item
        };
        this.userList.push(selectedUser);
      }
      this.processing = false;
    }
    catch(err){
      this.processing = false;
      console.log('error while retrieving users by lastname',err);
    }
  }
  
  async confirmCreateNewUser(){
    this.processing = true;
    try {
      let roles = [PortalUser._BUYER];
      let returnValue = await this.accountService.createNewUser(this.email,this.firstname,this.lastname,this.phone,roles);
      this.addUser(returnValue);
      this.newuser = false;
      this.processing = false;
      return returnValue;
    }
    catch(err){
      this.processing = false;
      console.log('error while creating user',err);
      this.errorMsg = err['errors'][0]['message'];
    }
  }
  
  
  /**
   * html page handlers
   **/
  onClose(){
    this.closeDialog();
  }
  
  async onSearchName(){
    if(this.searchLast != undefined && this.searchLast.length > 0){
      await this.searchUserByLast(this.searchLast);
    }
  }

  async onSearchEmail(){
    if(this.searchEmail != undefined && this.searchEmail.length > 0){
      await this.searchUserByEmail(this.searchEmail);
    }
  }
  
  onAddSelectedUsers(){
    var selections = [];
    for(let item of this.userList){
      if(item['isSelected']){
        selections.push(item['user']);
      }
    }
    
    this.closeDialog(selections);
  }
  
  async onConfirmCreateNew(){
    await this.confirmCreateNewUser();
  }
  
  onCancelCreateNew(){
    this.newuser = false;  
  }
  
  onCreateNew(){
    this.email = "";
    this.phone = "";
    this.firstname = "";
    this.lastname = "";
    this.newuser = true;
  }
  

  /**
   * other
   **/
  closeDialog(selections=[]){
    var eventMsg = {}
    eventMsg['message'] = 'closeuserselection';
    eventMsg['parms'] = selections;
    this.ref.close(eventMsg);
  }

  addUser(item){
    let newUser = {
      "isSelected":false,
      "user":item
    };
    this.userList.push(newUser);
  }
  
  hasSelections(){
    var returnValue = false;
    for(let item of this.userList){
      if(item['isSelected']){
        returnValue = true;
        break;
      }
    }
    return returnValue;
  }
  
  validPhone(){
    var returnValue = false;
    if(this.phone != undefined && this.phone.length == 14){
      returnValue = /^\(\d{3}\)\s\d{3}-\d{4}/g.test(this.phone);
    }
    return returnValue;
  }
  
  requiredFields(){
    var returnValue = this.validPhone() && 
                      this.validEmail() &&
                      this.firstname != undefined &&
                      this.firstname.length > 0 &&
                      this.lastname != undefined &&
                      this.lastname.length > 0;
    return returnValue;
  }
  
  validEmail() {
    var returnValue = false;
    if(this.email != undefined && this.email.length > 0){
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      returnValue = re.test(String(this.email).toLowerCase());
      
    }
    return returnValue;
  }  

}
