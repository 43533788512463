/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export enum AuctionLocationType {
  ONLINE_ONLY = "ONLINE_ONLY",
  CITY_STATE = "CITY_STATE",
}


export enum AuctionType {
  HORSE_STYLE = "HORSE_STYLE",
  LIVE_CLOSEOUT = "LIVE_CLOSEOUT",
  STAGGERED_CLOSE = "STAGGERED_CLOSE",
  MARKETPLACE = "MARKETPLACE",
}


export enum ClosingExtensionStyle {
  NONE = "NONE",
  ALL_LOTS = "ALL_LOTS",
  LOT_GROUPS = "LOT_GROUPS",
}


export enum AuctionStatus {
  SETUP = "SETUP",
  CALENDAR_POSTED = "CALENDAR_POSTED",
  SALE_INFO_POSTED = "SALE_INFO_POSTED",
  LOTS_POSTED = "LOTS_POSTED",
  LIVE = "LIVE",
  CLOSED = "CLOSED",
  SETTLED = "SETTLED",
  ARCHIVED = "ARCHIVED",
}


export enum AuctionLotCategory {
  FLUSH = "FLUSH",
  EMBRYO = "EMBRYO",
  REGISTERED_BULL = "REGISTERED_BULL",
  REGISTERED_COW = "REGISTERED_COW",
  REGISTERED_HEIFER = "REGISTERED_HEIFER",
  SEMEN = "SEMEN",
  COMMERCIAL = "COMMERCIAL",
  PREGNANCY = "PREGNANCY",
  OTHER = "OTHER",
}


export enum InterestType {
  ONE_FOURTH = "ONE_FOURTH",
  ONE_THIRD = "ONE_THIRD",
  ONE_HALF = "ONE_HALF",
  TWO_THIRDS = "TWO_THIRDS",
  THREE_FOURTHS = "THREE_FOURTHS",
  WHOLE = "WHOLE",
}


export enum SexedCategory {
  BULL = "BULL",
  COW = "COW",
  HEIFER = "HEIFER",
  SEXED = "SEXED",
  UNSEXED = "UNSEXED",
  CONFIRMED_HEIFER = "CONFIRMED_HEIFER",
  MALE = "MALE",
  FEMALE = "FEMALE",
}


export enum ExportableType {
  Y = "Y",
  USA_ONLY = "USA_ONLY",
}


export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export enum EPDCategory {
  PRODUCTION = "PRODUCTION",
  MATERNAL = "MATERNAL",
  MANAGEMENT = "MANAGEMENT",
  CARCASS = "CARCASS",
  DOLLAR_VALUES = "DOLLAR_VALUES",
}


export enum BidType {
  USER = "USER",
  ADMIN = "ADMIN",
  AUTOMATED = "AUTOMATED",
}


export type UpdateAuctionLotInput = {
  lotNum?: string | null,
  title?: string | null,
  donorName?: string | null,
  donorSire?: string | null,
  donorDam?: string | null,
  breed?: string | null,
  consigner?: string | null,
  specialTerms?: string | null,
  deliveryMethods?: Array< string | null > | null,
  startBid?: number | null,
  floorPrice?: number | null,
  interest?: InterestType | null,
  quantity?: number | null,
  taxable?: boolean | null,
  tag?: string | null,
  regNum?: string | null,
  regUrl?: string | null,
  lineageHierarchy?: LineageInput | null,
  epdMeasures?: Array< EPDMeasureInput | null > | null,
  sire?: string | null,
  sireRegNum?: string | null,
  sireUrl?: string | null,
  dam?: string | null,
  damRegNum?: string | null,
  damUrl?: string | null,
  tattoo?: string | null,
  dob?: string | null,
  sexedCategory?: SexedCategory | null,
  exportable?: ExportableType | null,
  footnote?: Array< string | null > | null,
  contactInfo?: string | null,
  pictures?: boolean | null,
  photos?: Array< LotImageInput | null > | null,
  videos?: Array< LotImageInput | null > | null,
  externalVideoUrl?: string | null,
  bidIncrement?: number | null,
  currentBid?: number | null,
  nextBid?: number | null,
  bannerImageKey?: string | null,
  lotOrder?: number | null,
  businessAccountId?: string | null,
  biddingDisabled?: boolean | null,
  customFields?: Array< CustomLotFieldInput | null > | null,
  category?: AuctionLotCategory | null,
  embryoType?: string | null,
  actualEPD?: boolean | null,
  importError?: boolean | null,
  importErrors?: Array< string | null > | null,
};

export type LineageInput = {
  sire?: string | null,
  sireRegNum?: string | null,
  sireUrl?: string | null,
  dam?: string | null,
  damRegNum?: string | null,
  damUrl?: string | null,
  sireParents?: LineageInput | null,
  damParents?: LineageInput | null,
};

export type EPDMeasureInput = {
  category?: EPDCategory | null,
  measure?: string | null,
  value?: string | null,
  accuracy?: string | null,
  progeny?: string | null,
  percentage?: string | null,
  order?: string | null,
};

export type LotImageInput = {
  storageName?: string | null,
  fileName?: string | null,
  caption?: string | null,
  cover?: boolean | null,
};

export type CustomLotFieldInput = {
  customLabel?: string | null,
  customValue?: string | null,
};

export enum AuctionReportSortField {
  WINNING_BUYER = "WINNING_BUYER",
  FINAL_BID = "FINAL_BID",
}


export enum ApiResult {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}


export enum SaleStatus {
  UNSOLD = "UNSOLD",
  SOLD = "SOLD",
  REFERENCE = "REFERENCE",
}


export enum PaymentStatus {
  UNPAID = "UNPAID",
  DIRECT_PAID = "DIRECT_PAID",
  PAID_SALE_REP = "PAID_SALE_REP",
  AUTOMATED_PAID = "AUTOMATED_PAID",
  UNCOLLECTIBLE = "UNCOLLECTIBLE",
  VOIDED = "VOIDED",
}


export type UserContactDetailsInput = {
  email?: string | null,
  givenName?: string | null,
  familyName?: string | null,
  nameTitle?: string | null,
  businessName?: string | null,
  phone?: string | null,
  physicalAddress?: Array< string | null > | null,
  physicalCity?: string | null,
  physicalState?: string | null,
  physicalZip?: string | null,
  physicalCountry?: string | null,
  billingAddress?: Array< string | null > | null,
  billingCity?: string | null,
  billingState?: string | null,
  billingZip?: string | null,
  billingCountry?: string | null,
  termsOfService?: boolean | null,
  receiveInfo?: boolean | null,
  userRoles?: Array< string | null > | null,
  businesses?: Array< string | null > | null,
  notifications?: Array< string | null > | null,
};

export type CreateAuctionInput = {
  title: string,
  startTime?: string | null,
  endTime?: string | null,
  bidIncrement?: number | null,
  startBid?: number | null,
  floorPrice?: number | null,
  locationType?: AuctionLocationType | null,
  locationCity?: string | null,
  locationState?: string | null,
  description?: string | null,
  businessAccountId?: string | null,
  type?: AuctionType | null,
  closingExtensionEnabled?: boolean | null,
  closingExtensionStyle?: ClosingExtensionStyle | null,
  closingExtensionInterval?: number | null,
  status?: AuctionStatus | null,
  salesInfoBannerKeys?: Array< string | null > | null,
  salesInfoNotes?: string | null,
  sellerLocationTitle?: string | null,
  sellerContactName?: string | null,
  sellerAddress?: Array< string | null > | null,
  sellerCity?: string | null,
  sellerState?: string | null,
  sellerZip?: string | null,
  sellerEmail?: string | null,
  sellerPhoneNumbers?: Array< PhoneNumberDetailInput | null > | null,
  saleOverview?: Array< string | null > | null,
  salesRepName?: string | null,
  salesRepContact?: string | null,
  salesRepEmail?: string | null,
  welcomeMessage?: string | null,
  catalogKey?: string | null,
  introVideoUrl?: string | null,
  paymentOptions?: Array< string | null > | null,
  shippingInstructions?: string | null,
  receivingInstructions?: string | null,
  liveBidLocation?: string | null,
  bannerImageKey?: string | null,
  hasCustomInvoiceDetails?: boolean | null,
  hasStandardTermsAndConditions?: boolean | null,
  hasCustomTermsAndConditions?: boolean | null,
  customTermsAndConditions?: string | null,
  hasStandardDeliveryAndPaymentTerms?: boolean | null,
  hasCustomDeliveryAndPaymentTerms?: boolean | null,
  customDeliveryAndPaymentTerms?: string | null,
  timezone?: string | null,
  useSellerContactInfo?: boolean | null,
};

export type PhoneNumberDetailInput = {
  phone?: string | null,
  preferred?: boolean | null,
  mobile?: boolean | null,
};

export type UpdateAuctionInput = {
  title?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  bidIncrement?: number | null,
  startBid?: number | null,
  floorPrice?: number | null,
  locationType?: AuctionLocationType | null,
  locationCity?: string | null,
  locationState?: string | null,
  businessAccountId?: string | null,
  description?: string | null,
  type?: AuctionType | null,
  closingExtensionEnabled?: boolean | null,
  closingExtensionStyle?: ClosingExtensionStyle | null,
  closingExtensionInterval?: number | null,
  status?: AuctionStatus | null,
  salesInfoBannerKeys?: Array< string | null > | null,
  salesInfoNotes?: string | null,
  sellerLocationTitle?: string | null,
  sellerContactName?: string | null,
  sellerAddress?: Array< string | null > | null,
  sellerCity?: string | null,
  sellerState?: string | null,
  sellerZip?: string | null,
  sellerEmail?: string | null,
  sellerPhoneNumbers?: Array< PhoneNumberDetailInput | null > | null,
  saleOverview?: Array< string | null > | null,
  salesRepName?: string | null,
  salesRepContact?: string | null,
  salesRepEmail?: string | null,
  welcomeMessage?: string | null,
  catalogKey?: string | null,
  introVideoUrl?: string | null,
  paymentOptions?: Array< string | null > | null,
  shippingInstructions?: string | null,
  receivingInstructions?: string | null,
  liveBidLocation?: string | null,
  bannerImageKey?: string | null,
  hasCustomInvoiceDetails?: boolean | null,
  hasStandardTermsAndConditions?: boolean | null,
  hasCustomTermsAndConditions?: boolean | null,
  customTermsAndConditions?: string | null,
  hasStandardDeliveryAndPaymentTerms?: boolean | null,
  hasCustomDeliveryAndPaymentTerms?: boolean | null,
  customDeliveryAndPaymentTerms?: string | null,
  timezone?: string | null,
  useSellerContactInfo?: boolean | null,
};

export type CreateAuctionLotInput = {
  lotNum?: string | null,
  title?: string | null,
  donorName?: string | null,
  donorSire?: string | null,
  donorDam?: string | null,
  breed?: string | null,
  consigner?: string | null,
  specialTerms?: string | null,
  deliveryMethods?: Array< string | null > | null,
  startBid?: number | null,
  floorPrice?: number | null,
  interest?: InterestType | null,
  quantity?: number | null,
  taxable?: boolean | null,
  tag?: string | null,
  regNum?: string | null,
  regUrl?: string | null,
  lineageHierarchy?: LineageInput | null,
  epdMeasures?: Array< EPDMeasureInput | null > | null,
  sire?: string | null,
  sireRegNum?: string | null,
  sireUrl?: string | null,
  dam?: string | null,
  damRegNum?: string | null,
  damUrl?: string | null,
  tattoo?: string | null,
  dob?: string | null,
  sexedCategory?: SexedCategory | null,
  exportable?: ExportableType | null,
  footnote?: Array< string | null > | null,
  contactInfo?: string | null,
  pictures?: boolean | null,
  photos?: Array< LotImageInput | null > | null,
  videos?: Array< LotImageInput | null > | null,
  externalVideoUrl?: string | null,
  bidIncrement?: number | null,
  currentBid?: number | null,
  bannerImageKey?: string | null,
  lotOrder?: number | null,
  businessAccountId?: string | null,
  biddingDisabled?: boolean | null,
  customFields?: Array< CustomLotFieldInput | null > | null,
  category?: AuctionLotCategory | null,
  embryoType?: string | null,
  actualEPD?: boolean | null,
  importError?: boolean | null,
  importErrors?: Array< string | null > | null,
};

export type LotOrderInput = {
  auctionId?: string | null,
  lotId?: string | null,
  lotOrder?: number | null,
};

export type CreateUserAccountInput = {
  email: string,
  givenName?: string | null,
  familyName?: string | null,
  nameTitle?: string | null,
  businessName?: string | null,
  lastLoginDt?: string | null,
  loginCtr?: number | null,
  providerName?: string | null,
  bidderId?: string | null,
  phone?: string | null,
  physicalAddress?: Array< string | null > | null,
  physicalCity?: string | null,
  physicalState?: string | null,
  physicalZip?: string | null,
  physicalCountry?: string | null,
  billingAddress?: Array< string | null > | null,
  billingCity?: string | null,
  billingState?: string | null,
  billingZip?: string | null,
  billingCountry?: string | null,
  termsOfService?: boolean | null,
  receiveInfo?: boolean | null,
  userRoles?: Array< string | null > | null,
  businesses?: Array< string | null > | null,
  notifications?: Array< string | null > | null,
  businessPhoneNumbers?: Array< PhoneNumberDetailInput | null > | null,
  businessTimezone?: string | null,
};

export type CreateBusinessAccountInput = {
  businessName: string,
  email?: string | null,
  familyName?: string | null,
  givenName?: string | null,
  nameTitle?: string | null,
  phone?: string | null,
  physicalAddress?: Array< string | null > | null,
  physicalCity?: string | null,
  physicalState?: string | null,
  physicalZip?: string | null,
  physicalCountry?: string | null,
  billingAddress?: Array< string | null > | null,
  billingCity?: string | null,
  billingState?: string | null,
  billingZip?: string | null,
  billingCountry?: string | null,
  businessWebSite?: string | null,
  businessNotes?: string | null,
  businessPhoneNumbers?: Array< PhoneNumberDetailInput | null > | null,
  businessTimezone?: string | null,
};

export type BusinessAccountContactDetailsInput = {
  businessName?: string | null,
  email?: string | null,
  familyName?: string | null,
  givenName?: string | null,
  nameTitle?: string | null,
  phone?: string | null,
  physicalAddress?: Array< string | null > | null,
  physicalCity?: string | null,
  physicalState?: string | null,
  physicalZip?: string | null,
  physicalCountry?: string | null,
  billingAddress?: Array< string | null > | null,
  billingCity?: string | null,
  billingState?: string | null,
  billingZip?: string | null,
  billingCountry?: string | null,
  termsOfService?: boolean | null,
  receiveInfo?: boolean | null,
  businessWebSite?: string | null,
  businessNotes?: string | null,
  businessPhoneNumbers?: Array< PhoneNumberDetailInput | null > | null,
  businessTimezone?: string | null,
};

export type AuctionReportInput = {
  invoiceSentDate?: string | null,
  totalGrossSales?: number | null,
  totalBidderCount?: number | null,
  totalLotCount?: number | null,
  payments?: Array< AuctionReportPaymentInput | null > | null,
};

export type AuctionReportPaymentInput = {
  paymentDate?: string | null,
  amount?: number | null,
  fees?: number | null,
};

export type AuctionReportLineItemInput = {
  winningBidderFirstName?: string | null,
  winningBidderLastName?: string | null,
  winningBidderEmail?: string | null,
  winningBidderAddress?: Array< string | null > | null,
  winningBidderPhoneNumber?: string | null,
  quantity?: number | null,
  additionalLineItems?: Array< AdjustmentLineItemInput | null > | null,
  finalPrice?: number | null,
  saleStatus?: SaleStatus | null,
  paymentStatus?: PaymentStatus | null,
};

export type AdjustmentLineItemInput = {
  amount?: number | null,
  note?: string | null,
};

export type AuctionChangeInput = {
  auctionId?: string | null,
  eventType?: AuctionChangeEventType | null,
  auctionData?: AuctionDetailsDataInput | null,
  lotData?: AuctionLotDataInput | null,
};

export enum AuctionChangeEventType {
  AUCTION = "AUCTION",
  LOT = "LOT",
  ALL = "ALL",
}


export type AuctionDetailsDataInput = {
  closingExtensionInterval?: number | null,
  closingExtensionTriggered?: boolean | null,
  status?: AuctionStatus | null,
  startTime?: string | null,
  endTime?: string | null,
  bidIncrement?: number | null,
  floorPrice?: number | null,
};

export type AuctionLotDataInput = {
  auctionId?: string | null,
  lotId?: string | null,
  startBid?: number | null,
  currentBid?: number | null,
  nextBid?: number | null,
  bidCount?: number | null,
  bidderCount?: number | null,
  bidIncrement?: number | null,
  bidderId?: string | null,
  bidderAccountId?: string | null,
  floorPrice?: number | null,
  biddingDisabled?: boolean | null,
};

export enum BidResultStatus {
  SUCCESS = "SUCCESS",
  REJECTED = "REJECTED",
  ERROR = "ERROR",
}


export enum BidResultCode {
  BID_TOO_LOW = "BID_TOO_LOW",
  AUCTION_LOT_CLOSED = "AUCTION_LOT_CLOSED",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
  INVALID_PARAMETERS = "INVALID_PARAMETERS",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
  BID_SUCCESS = "BID_SUCCESS",
}


export enum PaymentType {
  CREDIT_CARD = "CREDIT_CARD",
  ACH = "ACH",
  CHECK = "CHECK",
}


export enum ResultStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
}


export type AuctionCustomQueryVariables = {
  auctionId: string,
  lotNextToken?: string | null,
};

export type AuctionCustomQuery = {
  auction:  {
    __typename: "Auction",
    auctionId: string | null,
    title: string | null,
    startTime: string | null,
    endTime: string | null,
    locationType: AuctionLocationType | null,
    locationCity: string | null,
    locationState: string | null,
    description: string | null,
    businessAccountId: string | null,
    type: AuctionType | null,
    closingExtensionEnabled: boolean | null,
    closingExtensionStyle: ClosingExtensionStyle | null,
    closingExtensionInterval: number | null,
    status: AuctionStatus | null,
    salesInfoBannerKeys: Array< string | null > | null,
    salesInfoNotes: string | null,
    sellerLocationTitle: string | null,
    sellerContactName: string | null,
    sellerAddress: Array< string | null > | null,
    sellerCity: string | null,
    sellerState: string | null,
    sellerZip: string | null,
    sellerEmail: string | null,
    sellerPhoneNumbers:  Array< {
      __typename: "PhoneNumberDetail",
      phone: string | null,
      preferred: boolean | null,
      mobile: boolean | null,
    } | null > | null,
    saleOverview: Array< string | null > | null,
    salesRepName: string | null,
    salesRepContact: string | null,
    salesRepEmail: string | null,
    welcomeMessage: string | null,
    bidIncrement: number | null,
    startBid: number | null,
    floorPrice: number | null,
    catalogKey: string | null,
    introVideoUrl: string | null,
    paymentOptions: Array< string | null > | null,
    shippingInstructions: string | null,
    receivingInstructions: string | null,
    liveBidLocation: string | null,
    bannerImageKey: string | null,
    hasCustomInvoiceDetails: boolean | null,
    hasStandardTermsAndConditions: boolean | null,
    hasCustomTermsAndConditions: boolean | null,
    customTermsAndConditions: string | null,
    hasStandardDeliveryAndPaymentTerms: boolean | null,
    hasCustomDeliveryAndPaymentTerms: boolean | null,
    customDeliveryAndPaymentTerms: string | null,
    timezone: string | null,
    useSellerContactInfo: boolean | null,
    version: number | null,
    lots:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
      items:  Array< {
        __typename: "AuctionLot",
        auctionId: string | null,
        lotId: string | null,
        title: string | null,
        lotOrder: number | null,
        biddingDisabled: boolean | null,
        businessAccountId: string | null,
        customFields:  Array< {
          __typename: "CustomLotField",
          customLabel: string | null,
          customValue: string | null,
        } | null > | null,
        lotNum: string | null,
        category: AuctionLotCategory | null,
        interest: InterestType | null,
        embryoType: string | null,
        donorName: string | null,
        donorSire: string | null,
        donorDam: string | null,
        breed: string | null,
        consigner: string | null,
        specialTerms: string | null,
        deliveryMethods: Array< string | null > | null,
        startBid: number | null,
        floorPrice: number | null,
        quantity: number | null,
        taxable: boolean | null,
        tag: string | null,
        regNum: string | null,
        regUrl: string | null,
        tattoo: string | null,
        dob: string | null,
        sexedCategory: SexedCategory | null,
        recipientId: string | null,
        exportable: ExportableType | null,
        footnote: Array< string | null > | null,
        contactInfo: string | null,
        pictures: boolean | null,
        photos:  Array< {
          __typename: "LotImage",
          storageName: string | null,
          fileName: string | null,
          caption: string | null,
          cover: boolean | null,
        } | null > | null,
        videos:  Array< {
          __typename: "LotImage",
          storageName: string | null,
          fileName: string | null,
          caption: string | null,
          cover: boolean | null,
        } | null > | null,
        externalVideoUrl: string | null,
        bidIncrement: number | null,
        currentBid: number | null,
        nextBid: number | null,
        bannerImageKey: string | null,
        version: number | null,
        bidCount: number | null,
        bidderId: string | null,
        bidderCount: number | null,
        bidderAccountId: string | null,
        actualEPD: boolean | null,
        importError: boolean | null,
        importErrors: Array< string | null > | null,
      } | null > | null,
    } | null,
    primaryBusiness:  {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null,
    businesses:  Array< {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null > | null,
    bidders:  {
      __typename: "BidderResult",
      nextToken: string | null,
      items:  Array< {
        __typename: "Bidder",
        auctionId: string | null,
        bidderAccountId: string | null,
        bidderFirstName: string | null,
        bidderLastName: string | null,
      } | null > | null,
    } | null,
  } | null,
};

export type AuctionAllLotBidsCustomQueryVariables = {
  auctionId: string,
  lotNextToken?: string | null,
};

export type AuctionAllLotBidsCustomQuery = {
  auction:  {
    __typename: "Auction",
    auctionId: string | null,
    lots:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
      items:  Array< {
        __typename: "AuctionLot",
        auctionId: string | null,
        lotId: string | null,
        lotNum: string | null,
        lotOrder: number | null,
        title: string | null,
        tag: string | null,
        regNum: string | null,
        regUrl: string | null,
        tattoo: string | null,
        dob: string | null,
        sexedCategory: SexedCategory | null,
        bidIncrement: number | null,
        currentBid: number | null,
        lastBidTime: string | null,
        bidCount: number | null,
        bidderId: string | null,
        bidderCount: number | null,
        bidderAccountId: string | null,
      } | null > | null,
    } | null,
  } | null,
};

export type OpenAuctionsCustomQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: SortDirection | null,
};

export type OpenAuctionsCustomQuery = {
  openAuctions:  {
    __typename: "AuctionResult",
    items:  Array< {
      __typename: "Auction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      locationType: AuctionLocationType | null,
      locationCity: string | null,
      locationState: string | null,
      description: string | null,
      businessAccountId: string | null,
      type: AuctionType | null,
      status: AuctionStatus | null,
      salesInfoBannerKeys: Array< string | null > | null,
      sellerLocationTitle: string | null,
      sellerContactName: string | null,
      sellerAddress: Array< string | null > | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerEmail: string | null,
      saleOverview: Array< string | null > | null,
      salesRepName: string | null,
      salesRepContact: string | null,
      salesRepEmail: string | null,
      welcomeMessage: string | null,
      bidIncrement: number | null,
      catalogKey: string | null,
      introVideoUrl: string | null,
      paymentOptions: Array< string | null > | null,
      shippingInstructions: string | null,
      receivingInstructions: string | null,
      liveBidLocation: string | null,
      bannerImageKey: string | null,
      timezone: string | null,
      createdAt: string | null,
      updatedAt: string | null,
      version: number | null,
      primaryBusiness:  {
        __typename: "BusinessAccount",
        accountId: string | null,
        businessName: string | null,
        email: string | null,
        familyName: string | null,
        givenName: string | null,
        providerName: string | null,
        phone: string | null,
        physicalAddress: Array< string | null > | null,
        physicalCity: string | null,
        physicalState: string | null,
        physicalZip: string | null,
        physicalCountry: string | null,
        physicalCountryStateCity: string | null,
        billingAddress: Array< string | null > | null,
        billingCity: string | null,
        billingState: string | null,
        billingZip: string | null,
        billingCountry: string | null,
        termsOfService: boolean | null,
        receiveInfo: boolean | null,
        version: number | null,
      } | null,
      businesses:  Array< {
        __typename: "BusinessAccount",
        accountId: string | null,
        businessName: string | null,
        email: string | null,
        familyName: string | null,
        givenName: string | null,
        providerName: string | null,
        phone: string | null,
        physicalAddress: Array< string | null > | null,
        physicalCity: string | null,
        physicalState: string | null,
        physicalZip: string | null,
        physicalCountry: string | null,
        physicalCountryStateCity: string | null,
        billingAddress: Array< string | null > | null,
        billingCity: string | null,
        billingState: string | null,
        billingZip: string | null,
        billingCountry: string | null,
        termsOfService: boolean | null,
        receiveInfo: boolean | null,
        version: number | null,
      } | null > | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type AllAuctionsCustomQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: SortDirection | null,
};

export type AllAuctionsCustomQuery = {
  auctions:  {
    __typename: "AuctionResult",
    items:  Array< {
      __typename: "Auction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      locationType: AuctionLocationType | null,
      locationCity: string | null,
      locationState: string | null,
      description: string | null,
      businessAccountId: string | null,
      type: AuctionType | null,
      status: AuctionStatus | null,
      salesInfoBannerKeys: Array< string | null > | null,
      sellerLocationTitle: string | null,
      sellerContactName: string | null,
      sellerAddress: Array< string | null > | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerEmail: string | null,
      saleOverview: Array< string | null > | null,
      salesRepName: string | null,
      salesRepContact: string | null,
      salesRepEmail: string | null,
      welcomeMessage: string | null,
      bidIncrement: number | null,
      catalogKey: string | null,
      introVideoUrl: string | null,
      paymentOptions: Array< string | null > | null,
      shippingInstructions: string | null,
      receivingInstructions: string | null,
      liveBidLocation: string | null,
      bannerImageKey: string | null,
      timezone: string | null,
      createdAt: string | null,
      updatedAt: string | null,
      version: number | null,
      primaryBusiness:  {
        __typename: "BusinessAccount",
        accountId: string | null,
        businessName: string | null,
        email: string | null,
        familyName: string | null,
        givenName: string | null,
        providerName: string | null,
        phone: string | null,
        physicalAddress: Array< string | null > | null,
        physicalCity: string | null,
        physicalState: string | null,
        physicalZip: string | null,
        physicalCountry: string | null,
        physicalCountryStateCity: string | null,
        billingAddress: Array< string | null > | null,
        billingCity: string | null,
        billingState: string | null,
        billingZip: string | null,
        billingCountry: string | null,
        termsOfService: boolean | null,
        receiveInfo: boolean | null,
        version: number | null,
      } | null,
      businesses:  Array< {
        __typename: "BusinessAccount",
        accountId: string | null,
        businessName: string | null,
        email: string | null,
        familyName: string | null,
        givenName: string | null,
        providerName: string | null,
        phone: string | null,
        physicalAddress: Array< string | null > | null,
        physicalCity: string | null,
        physicalState: string | null,
        physicalZip: string | null,
        physicalCountry: string | null,
        physicalCountryStateCity: string | null,
        billingAddress: Array< string | null > | null,
        billingCity: string | null,
        billingState: string | null,
        billingZip: string | null,
        billingCountry: string | null,
        termsOfService: boolean | null,
        receiveInfo: boolean | null,
        version: number | null,
      } | null > | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type AuctionLotCustomQueryVariables = {
  auctionId: string,
  lotId: string,
};

export type AuctionLotCustomQuery = {
  auctionLot:  {
    __typename: "AuctionLot",
    auctionId: string | null,
    lotId: string | null,
    title: string | null,
    lotOrder: number | null,
    lotNum: string | null,
    biddingDisabled: boolean | null,
    businessAccountId: string | null,
    customFields:  Array< {
      __typename: "CustomLotField",
      customLabel: string | null,
      customValue: string | null,
    } | null > | null,
    category: AuctionLotCategory | null,
    interest: InterestType | null,
    embryoType: string | null,
    donorName: string | null,
    donorSire: string | null,
    donorDam: string | null,
    breed: string | null,
    consigner: string | null,
    specialTerms: string | null,
    deliveryMethods: Array< string | null > | null,
    startBid: number | null,
    floorPrice: number | null,
    quantity: number | null,
    taxable: boolean | null,
    tag: string | null,
    regNum: string | null,
    regUrl: string | null,
    lineageHierarchy:  {
      __typename: "Lineage",
      sire: string | null,
      sireRegNum: string | null,
      sireUrl: string | null,
      dam: string | null,
      damRegNum: string | null,
      damUrl: string | null,
      sireParents:  {
        __typename: "Lineage",
        sire: string | null,
        sireRegNum: string | null,
        sireUrl: string | null,
        dam: string | null,
        damRegNum: string | null,
        damUrl: string | null,
      } | null,
      damParents:  {
        __typename: "Lineage",
        sire: string | null,
        sireRegNum: string | null,
        sireUrl: string | null,
        dam: string | null,
        damRegNum: string | null,
        damUrl: string | null,
      } | null,
    } | null,
    epdMeasures:  Array< {
      __typename: "EPDMeasure",
      category: EPDCategory | null,
      measure: string | null,
      value: string | null,
      accuracy: string | null,
      progeny: string | null,
      percentage: string | null,
      order: string | null,
    } | null > | null,
    tattoo: string | null,
    dob: string | null,
    sexedCategory: SexedCategory | null,
    recipientId: string | null,
    exportable: ExportableType | null,
    footnote: Array< string | null > | null,
    contactInfo: string | null,
    pictures: boolean | null,
    photos:  Array< {
      __typename: "LotImage",
      storageName: string | null,
      fileName: string | null,
      caption: string | null,
      cover: boolean | null,
    } | null > | null,
    videos:  Array< {
      __typename: "LotImage",
      storageName: string | null,
      fileName: string | null,
      caption: string | null,
      cover: boolean | null,
    } | null > | null,
    externalVideoUrl: string | null,
    bidIncrement: number | null,
    currentBid: number | null,
    nextBid: number | null,
    bannerImageKey: string | null,
    version: number | null,
    bidCount: number | null,
    bidderAccountId: string | null,
    bidderId: string | null,
    bidderCount: number | null,
    actualEPD: boolean | null,
    importError: boolean | null,
    importErrors: Array< string | null > | null,
  } | null,
};

export type AuctionLotVersionCustomQueryVariables = {
  auctionId: string,
  lotId: string,
};

export type AuctionLotVersionCustomQuery = {
  auctionLot:  {
    __typename: "AuctionLot",
    version: number | null,
  } | null,
};

export type AuctionLotCountsCustomQueryVariables = {
  auctionId: string,
  lotId: string,
};

export type AuctionLotCountsCustomQuery = {
  auctionLot:  {
    __typename: "AuctionLot",
    bidCount: number | null,
    bidderCount: number | null,
  } | null,
};

export type AuctionLotBidsCustomQueryVariables = {
  auctionId: string,
  lotId: string,
  bidLimit?: number | null,
  bidNextToken?: string | null,
};

export type AuctionLotBidsCustomQuery = {
  auctionLot:  {
    __typename: "AuctionLot",
    auctionId: string | null,
    lotId: string | null,
    bids:  {
      __typename: "BidsResult",
      nextToken: string | null,
      items:  Array< {
        __typename: "Bid",
        auctionId: string | null,
        lotId: string | null,
        bidType: BidType | null,
        bidderFirstName: string | null,
        bidderLastName: string | null,
        price: number | null,
        bidTime: string | null,
        bidderAccountId: string | null,
        bidderId: string | null,
      } | null > | null,
    } | null,
  } | null,
};

export type BusinessesCustomQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type BusinessesCustomQuery = {
  businesses:  {
    __typename: "BusinessesResult",
    items:  Array< {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessPhoneNumbers:  Array< {
        __typename: "PhoneNumberDetail",
        phone: string | null,
        preferred: boolean | null,
        mobile: boolean | null,
      } | null > | null,
      businessTimezone: string | null,
      version: number | null,
    } | null > | null,
    nextToken: string | null,
    scannedCount: number | null,
  } | null,
};

export type UpdateAuctionLotPriceParmsCustomMutationVariables = {
  auctionId: string,
  lotId: string,
  expectedVersion: number,
  input: UpdateAuctionLotInput,
};

export type UpdateAuctionLotPriceParmsCustomMutation = {
  updateAuctionLot:  {
    __typename: "AuctionLot",
    floorPrice: number | null,
    bidIncrement: number | null,
    nextBid: number | null,
    version: number | null,
  } | null,
};

export type AuctionBiddersCustomQueryVariables = {
  auctionId: string,
};

export type AuctionBiddersCustomQuery = {
  auction:  {
    __typename: "Auction",
    auctionId: string | null,
    version: number | null,
    bidders:  {
      __typename: "BidderResult",
      nextToken: string | null,
      items:  Array< {
        __typename: "Bidder",
        auctionId: string | null,
        bidderAccountId: string | null,
        bidderFirstName: string | null,
        bidderLastName: string | null,
      } | null > | null,
    } | null,
  } | null,
};

export type AuctionLotsBidPriceCustomQueryVariables = {
  auctionId: string,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: SortDirection | null,
};

export type AuctionLotsBidPriceCustomQuery = {
  auctionLotsBidPrice:  {
    __typename: "AuctionLotResult",
    items:  Array< {
      __typename: "AuctionLot",
      auctionId: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      tag: string | null,
      regNum: string | null,
      regUrl: string | null,
      tattoo: string | null,
      dob: string | null,
      sexedCategory: SexedCategory | null,
      bidIncrement: number | null,
      currentBid: number | null,
      lastBidTime: string | null,
      bidCount: number | null,
      bidderId: string | null,
      bidderCount: number | null,
      bidderAccountId: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type AuctionLotsBidTimeCustomQueryVariables = {
  auctionId: string,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: SortDirection | null,
};

export type AuctionLotsBidTimeCustomQuery = {
  auctionLotsBidTime:  {
    __typename: "AuctionLotResult",
    items:  Array< {
      __typename: "AuctionLot",
      auctionId: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      tag: string | null,
      regNum: string | null,
      regUrl: string | null,
      tattoo: string | null,
      dob: string | null,
      sexedCategory: SexedCategory | null,
      bidIncrement: number | null,
      currentBid: number | null,
      lastBidTime: string | null,
      bidCount: number | null,
      bidderId: string | null,
      bidderCount: number | null,
      bidderAccountId: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type UpdateAuctionLotNumCustomMutationVariables = {
  auctionId: string,
  lotId: string,
  expectedVersion: number,
  input: UpdateAuctionLotInput,
};

export type UpdateAuctionLotNumCustomMutation = {
  updateAuctionLot:  {
    __typename: "AuctionLot",
    lotNum: string | null,
    version: number | null,
  } | null,
};

export type AuctionReportCustomQueryVariables = {
  auctionId: string,
  limit?: number | null,
  nextToken?: string | null,
  sortField?: AuctionReportSortField | null,
  sortDirection?: SortDirection | null,
};

export type AuctionReportCustomQuery = {
  auctionReport:  {
    __typename: "AuctionReport",
    status: ApiResult | null,
    message: string | null,
    auctionId: string | null,
    auctionName: string | null,
    auctionStartDate: string | null,
    auctionClosingDate: string | null,
    totalGrossSales: number | null,
    totalBidderCount: number | null,
    totalLotCount: number | null,
    invoiceSentDate: string | null,
    version: number | null,
    payments:  Array< {
      __typename: "AuctionReportPayment",
      paymentDate: string | null,
      amount: number | null,
      fees: number | null,
    } | null > | null,
    lineItems:  Array< {
      __typename: "AuctionReportLineItem",
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      businessAccountId: string | null,
      winningBidderFirstName: string | null,
      winningBidderLastName: string | null,
      winningBidderAddress: Array< string | null > | null,
      winningBidderPhoneNumber: string | null,
      winningBidderEmail: string | null,
      finalBid: number | null,
      quantity: number | null,
      interest: InterestType | null,
      finalPrice: number | null,
      saleStatus: SaleStatus | null,
      paymentStatus: PaymentStatus | null,
      invoiceId: string | null,
      invoice:  {
        __typename: "Invoice",
        auctionId: string | null,
        auctionName: string | null,
        auctionStartDate: string | null,
        invoiceId: string | null,
        invoiceNumber: string | null,
        invoiceDate: string | null,
        invoiceSentDate: string | null,
        paymentDueDate: string | null,
        bannerImageKey: string | null,
        buyerName: string | null,
        buyerAddress: Array< string | null > | null,
        buyerPhoneNumber: string | null,
        buyerEmail: string | null,
        saleAuctionName: string | null,
        saleAuctionClosingDate: string | null,
        sellerName: string | null,
        sellerPhoneNumber: string | null,
        sellerEmail: string | null,
        sellerWebsite: string | null,
        sellerAddress: Array< string | null > | null,
        sellerContactName: string | null,
        sellerCity: string | null,
        sellerState: string | null,
        sellerZip: string | null,
        sellerNotes: string | null,
        totalAmountPaid: number | null,
        totalAmountDue: number | null,
        datePaid: string | null,
        paid: boolean | null,
        paymentMethod: string | null,
        paymentInvoiceId: string | null,
        paymentCustomerId: string | null,
        paymentInvoicePdf: string | null,
        paymentUrl: string | null,
      } | null,
      sellerEmail: string | null,
      sellerPhoneNumber: string | null,
      sellerName: string | null,
      additionalLineItems:  Array< {
        __typename: "AdjustmentLineItem",
        amount: number | null,
        note: string | null,
      } | null > | null,
      version: number | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type UpdateUserEmailCustomMutationVariables = {
  accountId: string,
  newemail: string,
};

export type UpdateUserEmailCustomMutation = {
  updateUserEmail:  {
    __typename: "UserAccount",
    accountId: string | null,
    email: string | null,
    version: number | null,
  } | null,
};

export type UpdateUserRolesCustomMutationVariables = {
  accountId: string,
  expectedVersion: number,
  input?: UserContactDetailsInput | null,
};

export type UpdateUserRolesCustomMutation = {
  updateUserContactDetails:  {
    __typename: "UserAccount",
    userRoles: Array< string | null > | null,
    version: number | null,
  } | null,
};

export type AccountByEmailVersionCustomQueryVariables = {
  email: string,
};

export type AccountByEmailVersionCustomQuery = {
  accountByEmail:  {
    __typename: "UserAccount",
    accountId: string | null,
    version: number | null,
  } | null,
};

export type CreateAuctionMutationVariables = {
  input?: CreateAuctionInput | null,
};

export type CreateAuctionMutation = {
  createAuction:  {
    __typename: "Auction",
    auctionId: string | null,
    title: string | null,
    startTime: string | null,
    endTime: string | null,
    locationType: AuctionLocationType | null,
    locationCity: string | null,
    locationState: string | null,
    description: string | null,
    businessAccountId: string | null,
    type: AuctionType | null,
    closingExtensionEnabled: boolean | null,
    closingExtensionStyle: ClosingExtensionStyle | null,
    closingExtensionInterval: number | null,
    closingExtensionTriggered: boolean | null,
    status: AuctionStatus | null,
    salesInfoBannerKeys: Array< string | null > | null,
    salesInfoNotes: string | null,
    sellerLocationTitle: string | null,
    sellerContactName: string | null,
    sellerAddress: Array< string | null > | null,
    sellerCity: string | null,
    sellerState: string | null,
    sellerZip: string | null,
    sellerEmail: string | null,
    sellerPhoneNumbers:  Array< {
      __typename: "PhoneNumberDetail",
      phone: string | null,
      preferred: boolean | null,
      mobile: boolean | null,
    } | null > | null,
    saleOverview: Array< string | null > | null,
    salesRepName: string | null,
    salesRepContact: string | null,
    salesRepEmail: string | null,
    welcomeMessage: string | null,
    bidIncrement: number | null,
    startBid: number | null,
    floorPrice: number | null,
    catalogKey: string | null,
    introVideoUrl: string | null,
    paymentOptions: Array< string | null > | null,
    shippingInstructions: string | null,
    receivingInstructions: string | null,
    liveBidLocation: string | null,
    bannerImageKey: string | null,
    hasCustomInvoiceDetails: boolean | null,
    hasStandardTermsAndConditions: boolean | null,
    hasCustomTermsAndConditions: boolean | null,
    customTermsAndConditions: string | null,
    hasStandardDeliveryAndPaymentTerms: boolean | null,
    hasCustomDeliveryAndPaymentTerms: boolean | null,
    customDeliveryAndPaymentTerms: string | null,
    timezone: string | null,
    useSellerContactInfo: boolean | null,
    delisted: boolean | null,
    createdAt: string | null,
    updatedAt: string | null,
    version: number | null,
    lots:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
    lot:  {
      __typename: "AuctionLot",
      auctionId: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      lotOrder: number | null,
      biddingDisabled: boolean | null,
      embryoType: string | null,
      category: AuctionLotCategory | null,
      donorName: string | null,
      donorSire: string | null,
      donorDam: string | null,
      breed: string | null,
      consigner: string | null,
      specialTerms: string | null,
      deliveryMethods: Array< string | null > | null,
      startBid: number | null,
      floorPrice: number | null,
      interest: InterestType | null,
      quantity: number | null,
      taxable: boolean | null,
      tag: string | null,
      regNum: string | null,
      regUrl: string | null,
      tattoo: string | null,
      dob: string | null,
      sexedCategory: SexedCategory | null,
      recipientId: string | null,
      exportable: ExportableType | null,
      footnote: Array< string | null > | null,
      contactInfo: string | null,
      pictures: boolean | null,
      externalVideoUrl: string | null,
      bidIncrement: number | null,
      currentBid: number | null,
      nextBid: number | null,
      bannerImageKey: string | null,
      businessAccountId: string | null,
      lastBidTime: string | null,
      version: number | null,
      bidCount: number | null,
      bidderAccountId: string | null,
      bidderId: string | null,
      bidderCount: number | null,
      actualEPD: boolean | null,
      importError: boolean | null,
      importErrors: Array< string | null > | null,
      currentMaxBid: number | null,
      currentMaxBidAccountId: string | null,
    } | null,
    primaryBusiness:  {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null,
    businesses:  Array< {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null > | null,
    bidders:  {
      __typename: "BidderResult",
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateAuctionMutationVariables = {
  auctionId: string,
  expectedVersion: number,
  input?: UpdateAuctionInput | null,
};

export type UpdateAuctionMutation = {
  updateAuction:  {
    __typename: "Auction",
    auctionId: string | null,
    title: string | null,
    startTime: string | null,
    endTime: string | null,
    locationType: AuctionLocationType | null,
    locationCity: string | null,
    locationState: string | null,
    description: string | null,
    businessAccountId: string | null,
    type: AuctionType | null,
    closingExtensionEnabled: boolean | null,
    closingExtensionStyle: ClosingExtensionStyle | null,
    closingExtensionInterval: number | null,
    closingExtensionTriggered: boolean | null,
    status: AuctionStatus | null,
    salesInfoBannerKeys: Array< string | null > | null,
    salesInfoNotes: string | null,
    sellerLocationTitle: string | null,
    sellerContactName: string | null,
    sellerAddress: Array< string | null > | null,
    sellerCity: string | null,
    sellerState: string | null,
    sellerZip: string | null,
    sellerEmail: string | null,
    sellerPhoneNumbers:  Array< {
      __typename: "PhoneNumberDetail",
      phone: string | null,
      preferred: boolean | null,
      mobile: boolean | null,
    } | null > | null,
    saleOverview: Array< string | null > | null,
    salesRepName: string | null,
    salesRepContact: string | null,
    salesRepEmail: string | null,
    welcomeMessage: string | null,
    bidIncrement: number | null,
    startBid: number | null,
    floorPrice: number | null,
    catalogKey: string | null,
    introVideoUrl: string | null,
    paymentOptions: Array< string | null > | null,
    shippingInstructions: string | null,
    receivingInstructions: string | null,
    liveBidLocation: string | null,
    bannerImageKey: string | null,
    hasCustomInvoiceDetails: boolean | null,
    hasStandardTermsAndConditions: boolean | null,
    hasCustomTermsAndConditions: boolean | null,
    customTermsAndConditions: string | null,
    hasStandardDeliveryAndPaymentTerms: boolean | null,
    hasCustomDeliveryAndPaymentTerms: boolean | null,
    customDeliveryAndPaymentTerms: string | null,
    timezone: string | null,
    useSellerContactInfo: boolean | null,
    delisted: boolean | null,
    createdAt: string | null,
    updatedAt: string | null,
    version: number | null,
    lots:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
    lot:  {
      __typename: "AuctionLot",
      auctionId: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      lotOrder: number | null,
      biddingDisabled: boolean | null,
      embryoType: string | null,
      category: AuctionLotCategory | null,
      donorName: string | null,
      donorSire: string | null,
      donorDam: string | null,
      breed: string | null,
      consigner: string | null,
      specialTerms: string | null,
      deliveryMethods: Array< string | null > | null,
      startBid: number | null,
      floorPrice: number | null,
      interest: InterestType | null,
      quantity: number | null,
      taxable: boolean | null,
      tag: string | null,
      regNum: string | null,
      regUrl: string | null,
      tattoo: string | null,
      dob: string | null,
      sexedCategory: SexedCategory | null,
      recipientId: string | null,
      exportable: ExportableType | null,
      footnote: Array< string | null > | null,
      contactInfo: string | null,
      pictures: boolean | null,
      externalVideoUrl: string | null,
      bidIncrement: number | null,
      currentBid: number | null,
      nextBid: number | null,
      bannerImageKey: string | null,
      businessAccountId: string | null,
      lastBidTime: string | null,
      version: number | null,
      bidCount: number | null,
      bidderAccountId: string | null,
      bidderId: string | null,
      bidderCount: number | null,
      actualEPD: boolean | null,
      importError: boolean | null,
      importErrors: Array< string | null > | null,
      currentMaxBid: number | null,
      currentMaxBidAccountId: string | null,
    } | null,
    primaryBusiness:  {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null,
    businesses:  Array< {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null > | null,
    bidders:  {
      __typename: "BidderResult",
      nextToken: string | null,
    } | null,
  },
};

export type DeleteAuctionMutationVariables = {
  auctionId: string,
};

export type DeleteAuctionMutation = {
  deleteAuction:  {
    __typename: "Auction",
    auctionId: string | null,
    title: string | null,
    startTime: string | null,
    endTime: string | null,
    locationType: AuctionLocationType | null,
    locationCity: string | null,
    locationState: string | null,
    description: string | null,
    businessAccountId: string | null,
    type: AuctionType | null,
    closingExtensionEnabled: boolean | null,
    closingExtensionStyle: ClosingExtensionStyle | null,
    closingExtensionInterval: number | null,
    closingExtensionTriggered: boolean | null,
    status: AuctionStatus | null,
    salesInfoBannerKeys: Array< string | null > | null,
    salesInfoNotes: string | null,
    sellerLocationTitle: string | null,
    sellerContactName: string | null,
    sellerAddress: Array< string | null > | null,
    sellerCity: string | null,
    sellerState: string | null,
    sellerZip: string | null,
    sellerEmail: string | null,
    sellerPhoneNumbers:  Array< {
      __typename: "PhoneNumberDetail",
      phone: string | null,
      preferred: boolean | null,
      mobile: boolean | null,
    } | null > | null,
    saleOverview: Array< string | null > | null,
    salesRepName: string | null,
    salesRepContact: string | null,
    salesRepEmail: string | null,
    welcomeMessage: string | null,
    bidIncrement: number | null,
    startBid: number | null,
    floorPrice: number | null,
    catalogKey: string | null,
    introVideoUrl: string | null,
    paymentOptions: Array< string | null > | null,
    shippingInstructions: string | null,
    receivingInstructions: string | null,
    liveBidLocation: string | null,
    bannerImageKey: string | null,
    hasCustomInvoiceDetails: boolean | null,
    hasStandardTermsAndConditions: boolean | null,
    hasCustomTermsAndConditions: boolean | null,
    customTermsAndConditions: string | null,
    hasStandardDeliveryAndPaymentTerms: boolean | null,
    hasCustomDeliveryAndPaymentTerms: boolean | null,
    customDeliveryAndPaymentTerms: string | null,
    timezone: string | null,
    useSellerContactInfo: boolean | null,
    delisted: boolean | null,
    createdAt: string | null,
    updatedAt: string | null,
    version: number | null,
    lots:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
    lot:  {
      __typename: "AuctionLot",
      auctionId: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      lotOrder: number | null,
      biddingDisabled: boolean | null,
      embryoType: string | null,
      category: AuctionLotCategory | null,
      donorName: string | null,
      donorSire: string | null,
      donorDam: string | null,
      breed: string | null,
      consigner: string | null,
      specialTerms: string | null,
      deliveryMethods: Array< string | null > | null,
      startBid: number | null,
      floorPrice: number | null,
      interest: InterestType | null,
      quantity: number | null,
      taxable: boolean | null,
      tag: string | null,
      regNum: string | null,
      regUrl: string | null,
      tattoo: string | null,
      dob: string | null,
      sexedCategory: SexedCategory | null,
      recipientId: string | null,
      exportable: ExportableType | null,
      footnote: Array< string | null > | null,
      contactInfo: string | null,
      pictures: boolean | null,
      externalVideoUrl: string | null,
      bidIncrement: number | null,
      currentBid: number | null,
      nextBid: number | null,
      bannerImageKey: string | null,
      businessAccountId: string | null,
      lastBidTime: string | null,
      version: number | null,
      bidCount: number | null,
      bidderAccountId: string | null,
      bidderId: string | null,
      bidderCount: number | null,
      actualEPD: boolean | null,
      importError: boolean | null,
      importErrors: Array< string | null > | null,
      currentMaxBid: number | null,
      currentMaxBidAccountId: string | null,
    } | null,
    primaryBusiness:  {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null,
    businesses:  Array< {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null > | null,
    bidders:  {
      __typename: "BidderResult",
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteAuctionAndAssetsMutationVariables = {
  auctionId: string,
};

export type DeleteAuctionAndAssetsMutation = {
  deleteAuctionAndAssets:  {
    __typename: "Auction",
    auctionId: string | null,
    title: string | null,
    startTime: string | null,
    endTime: string | null,
    locationType: AuctionLocationType | null,
    locationCity: string | null,
    locationState: string | null,
    description: string | null,
    businessAccountId: string | null,
    type: AuctionType | null,
    closingExtensionEnabled: boolean | null,
    closingExtensionStyle: ClosingExtensionStyle | null,
    closingExtensionInterval: number | null,
    closingExtensionTriggered: boolean | null,
    status: AuctionStatus | null,
    salesInfoBannerKeys: Array< string | null > | null,
    salesInfoNotes: string | null,
    sellerLocationTitle: string | null,
    sellerContactName: string | null,
    sellerAddress: Array< string | null > | null,
    sellerCity: string | null,
    sellerState: string | null,
    sellerZip: string | null,
    sellerEmail: string | null,
    sellerPhoneNumbers:  Array< {
      __typename: "PhoneNumberDetail",
      phone: string | null,
      preferred: boolean | null,
      mobile: boolean | null,
    } | null > | null,
    saleOverview: Array< string | null > | null,
    salesRepName: string | null,
    salesRepContact: string | null,
    salesRepEmail: string | null,
    welcomeMessage: string | null,
    bidIncrement: number | null,
    startBid: number | null,
    floorPrice: number | null,
    catalogKey: string | null,
    introVideoUrl: string | null,
    paymentOptions: Array< string | null > | null,
    shippingInstructions: string | null,
    receivingInstructions: string | null,
    liveBidLocation: string | null,
    bannerImageKey: string | null,
    hasCustomInvoiceDetails: boolean | null,
    hasStandardTermsAndConditions: boolean | null,
    hasCustomTermsAndConditions: boolean | null,
    customTermsAndConditions: string | null,
    hasStandardDeliveryAndPaymentTerms: boolean | null,
    hasCustomDeliveryAndPaymentTerms: boolean | null,
    customDeliveryAndPaymentTerms: string | null,
    timezone: string | null,
    useSellerContactInfo: boolean | null,
    delisted: boolean | null,
    createdAt: string | null,
    updatedAt: string | null,
    version: number | null,
    lots:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
    lot:  {
      __typename: "AuctionLot",
      auctionId: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      lotOrder: number | null,
      biddingDisabled: boolean | null,
      embryoType: string | null,
      category: AuctionLotCategory | null,
      donorName: string | null,
      donorSire: string | null,
      donorDam: string | null,
      breed: string | null,
      consigner: string | null,
      specialTerms: string | null,
      deliveryMethods: Array< string | null > | null,
      startBid: number | null,
      floorPrice: number | null,
      interest: InterestType | null,
      quantity: number | null,
      taxable: boolean | null,
      tag: string | null,
      regNum: string | null,
      regUrl: string | null,
      tattoo: string | null,
      dob: string | null,
      sexedCategory: SexedCategory | null,
      recipientId: string | null,
      exportable: ExportableType | null,
      footnote: Array< string | null > | null,
      contactInfo: string | null,
      pictures: boolean | null,
      externalVideoUrl: string | null,
      bidIncrement: number | null,
      currentBid: number | null,
      nextBid: number | null,
      bannerImageKey: string | null,
      businessAccountId: string | null,
      lastBidTime: string | null,
      version: number | null,
      bidCount: number | null,
      bidderAccountId: string | null,
      bidderId: string | null,
      bidderCount: number | null,
      actualEPD: boolean | null,
      importError: boolean | null,
      importErrors: Array< string | null > | null,
      currentMaxBid: number | null,
      currentMaxBidAccountId: string | null,
    } | null,
    primaryBusiness:  {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null,
    businesses:  Array< {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null > | null,
    bidders:  {
      __typename: "BidderResult",
      nextToken: string | null,
    } | null,
  } | null,
};

export type CloneAuctionAndAssetsMutationVariables = {
  auctionId: string,
};

export type CloneAuctionAndAssetsMutation = {
  cloneAuctionAndAssets:  {
    __typename: "Auction",
    auctionId: string | null,
    title: string | null,
    startTime: string | null,
    endTime: string | null,
    locationType: AuctionLocationType | null,
    locationCity: string | null,
    locationState: string | null,
    description: string | null,
    businessAccountId: string | null,
    type: AuctionType | null,
    closingExtensionEnabled: boolean | null,
    closingExtensionStyle: ClosingExtensionStyle | null,
    closingExtensionInterval: number | null,
    closingExtensionTriggered: boolean | null,
    status: AuctionStatus | null,
    salesInfoBannerKeys: Array< string | null > | null,
    salesInfoNotes: string | null,
    sellerLocationTitle: string | null,
    sellerContactName: string | null,
    sellerAddress: Array< string | null > | null,
    sellerCity: string | null,
    sellerState: string | null,
    sellerZip: string | null,
    sellerEmail: string | null,
    sellerPhoneNumbers:  Array< {
      __typename: "PhoneNumberDetail",
      phone: string | null,
      preferred: boolean | null,
      mobile: boolean | null,
    } | null > | null,
    saleOverview: Array< string | null > | null,
    salesRepName: string | null,
    salesRepContact: string | null,
    salesRepEmail: string | null,
    welcomeMessage: string | null,
    bidIncrement: number | null,
    startBid: number | null,
    floorPrice: number | null,
    catalogKey: string | null,
    introVideoUrl: string | null,
    paymentOptions: Array< string | null > | null,
    shippingInstructions: string | null,
    receivingInstructions: string | null,
    liveBidLocation: string | null,
    bannerImageKey: string | null,
    hasCustomInvoiceDetails: boolean | null,
    hasStandardTermsAndConditions: boolean | null,
    hasCustomTermsAndConditions: boolean | null,
    customTermsAndConditions: string | null,
    hasStandardDeliveryAndPaymentTerms: boolean | null,
    hasCustomDeliveryAndPaymentTerms: boolean | null,
    customDeliveryAndPaymentTerms: string | null,
    timezone: string | null,
    useSellerContactInfo: boolean | null,
    delisted: boolean | null,
    createdAt: string | null,
    updatedAt: string | null,
    version: number | null,
    lots:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
    lot:  {
      __typename: "AuctionLot",
      auctionId: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      lotOrder: number | null,
      biddingDisabled: boolean | null,
      embryoType: string | null,
      category: AuctionLotCategory | null,
      donorName: string | null,
      donorSire: string | null,
      donorDam: string | null,
      breed: string | null,
      consigner: string | null,
      specialTerms: string | null,
      deliveryMethods: Array< string | null > | null,
      startBid: number | null,
      floorPrice: number | null,
      interest: InterestType | null,
      quantity: number | null,
      taxable: boolean | null,
      tag: string | null,
      regNum: string | null,
      regUrl: string | null,
      tattoo: string | null,
      dob: string | null,
      sexedCategory: SexedCategory | null,
      recipientId: string | null,
      exportable: ExportableType | null,
      footnote: Array< string | null > | null,
      contactInfo: string | null,
      pictures: boolean | null,
      externalVideoUrl: string | null,
      bidIncrement: number | null,
      currentBid: number | null,
      nextBid: number | null,
      bannerImageKey: string | null,
      businessAccountId: string | null,
      lastBidTime: string | null,
      version: number | null,
      bidCount: number | null,
      bidderAccountId: string | null,
      bidderId: string | null,
      bidderCount: number | null,
      actualEPD: boolean | null,
      importError: boolean | null,
      importErrors: Array< string | null > | null,
      currentMaxBid: number | null,
      currentMaxBidAccountId: string | null,
    } | null,
    primaryBusiness:  {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null,
    businesses:  Array< {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null > | null,
    bidders:  {
      __typename: "BidderResult",
      nextToken: string | null,
    } | null,
  } | null,
};

export type AddAuctionBusinessMutationVariables = {
  auctionId: string,
  businessAccountId: string,
};

export type AddAuctionBusinessMutation = {
  addAuctionBusiness:  {
    __typename: "AuctionBusinessResult",
    businessAccountId: string | null,
  } | null,
};

export type DeleteAuctionBusinessMutationVariables = {
  auctionId: string,
  businessAccountId: string,
};

export type DeleteAuctionBusinessMutation = {
  deleteAuctionBusiness:  {
    __typename: "AuctionBusinessResult",
    businessAccountId: string | null,
  } | null,
};

export type CreateAuctionLotMutationVariables = {
  auctionId: string,
  input?: CreateAuctionLotInput | null,
};

export type CreateAuctionLotMutation = {
  createAuctionLot:  {
    __typename: "AuctionLot",
    auctionId: string | null,
    lotId: string | null,
    lotNum: string | null,
    title: string | null,
    lotOrder: number | null,
    biddingDisabled: boolean | null,
    customFields:  Array< {
      __typename: "CustomLotField",
      customLabel: string | null,
      customValue: string | null,
    } | null > | null,
    embryoType: string | null,
    category: AuctionLotCategory | null,
    donorName: string | null,
    donorSire: string | null,
    donorDam: string | null,
    breed: string | null,
    consigner: string | null,
    specialTerms: string | null,
    deliveryMethods: Array< string | null > | null,
    startBid: number | null,
    floorPrice: number | null,
    interest: InterestType | null,
    quantity: number | null,
    taxable: boolean | null,
    tag: string | null,
    regNum: string | null,
    regUrl: string | null,
    lineageHierarchy:  {
      __typename: "Lineage",
      sire: string | null,
      sireRegNum: string | null,
      sireUrl: string | null,
      dam: string | null,
      damRegNum: string | null,
      damUrl: string | null,
    } | null,
    epdMeasures:  Array< {
      __typename: "EPDMeasure",
      category: EPDCategory | null,
      measure: string | null,
      value: string | null,
      accuracy: string | null,
      progeny: string | null,
      percentage: string | null,
      order: string | null,
    } | null > | null,
    tattoo: string | null,
    dob: string | null,
    sexedCategory: SexedCategory | null,
    recipientId: string | null,
    exportable: ExportableType | null,
    footnote: Array< string | null > | null,
    contactInfo: string | null,
    pictures: boolean | null,
    photos:  Array< {
      __typename: "LotImage",
      storageName: string | null,
      fileName: string | null,
      caption: string | null,
      cover: boolean | null,
    } | null > | null,
    videos:  Array< {
      __typename: "LotImage",
      storageName: string | null,
      fileName: string | null,
      caption: string | null,
      cover: boolean | null,
    } | null > | null,
    externalVideoUrl: string | null,
    bidIncrement: number | null,
    currentBid: number | null,
    nextBid: number | null,
    bannerImageKey: string | null,
    businessAccountId: string | null,
    lastBidTime: string | null,
    version: number | null,
    bidCount: number | null,
    bidderAccountId: string | null,
    bidderId: string | null,
    bidderCount: number | null,
    bids:  {
      __typename: "BidsResult",
      nextToken: string | null,
    } | null,
    actualEPD: boolean | null,
    importError: boolean | null,
    importErrors: Array< string | null > | null,
    currentMaxBid: number | null,
    currentMaxBidAccountId: string | null,
  } | null,
};

export type UpdateAuctionLotMutationVariables = {
  auctionId: string,
  lotId: string,
  expectedVersion: number,
  input: UpdateAuctionLotInput,
};

export type UpdateAuctionLotMutation = {
  updateAuctionLot:  {
    __typename: "AuctionLot",
    auctionId: string | null,
    lotId: string | null,
    lotNum: string | null,
    title: string | null,
    lotOrder: number | null,
    biddingDisabled: boolean | null,
    customFields:  Array< {
      __typename: "CustomLotField",
      customLabel: string | null,
      customValue: string | null,
    } | null > | null,
    embryoType: string | null,
    category: AuctionLotCategory | null,
    donorName: string | null,
    donorSire: string | null,
    donorDam: string | null,
    breed: string | null,
    consigner: string | null,
    specialTerms: string | null,
    deliveryMethods: Array< string | null > | null,
    startBid: number | null,
    floorPrice: number | null,
    interest: InterestType | null,
    quantity: number | null,
    taxable: boolean | null,
    tag: string | null,
    regNum: string | null,
    regUrl: string | null,
    lineageHierarchy:  {
      __typename: "Lineage",
      sire: string | null,
      sireRegNum: string | null,
      sireUrl: string | null,
      dam: string | null,
      damRegNum: string | null,
      damUrl: string | null,
    } | null,
    epdMeasures:  Array< {
      __typename: "EPDMeasure",
      category: EPDCategory | null,
      measure: string | null,
      value: string | null,
      accuracy: string | null,
      progeny: string | null,
      percentage: string | null,
      order: string | null,
    } | null > | null,
    tattoo: string | null,
    dob: string | null,
    sexedCategory: SexedCategory | null,
    recipientId: string | null,
    exportable: ExportableType | null,
    footnote: Array< string | null > | null,
    contactInfo: string | null,
    pictures: boolean | null,
    photos:  Array< {
      __typename: "LotImage",
      storageName: string | null,
      fileName: string | null,
      caption: string | null,
      cover: boolean | null,
    } | null > | null,
    videos:  Array< {
      __typename: "LotImage",
      storageName: string | null,
      fileName: string | null,
      caption: string | null,
      cover: boolean | null,
    } | null > | null,
    externalVideoUrl: string | null,
    bidIncrement: number | null,
    currentBid: number | null,
    nextBid: number | null,
    bannerImageKey: string | null,
    businessAccountId: string | null,
    lastBidTime: string | null,
    version: number | null,
    bidCount: number | null,
    bidderAccountId: string | null,
    bidderId: string | null,
    bidderCount: number | null,
    bids:  {
      __typename: "BidsResult",
      nextToken: string | null,
    } | null,
    actualEPD: boolean | null,
    importError: boolean | null,
    importErrors: Array< string | null > | null,
    currentMaxBid: number | null,
    currentMaxBidAccountId: string | null,
  } | null,
};

export type DeleteAuctionLotMutationVariables = {
  auctionId: string,
  lotId: string,
};

export type DeleteAuctionLotMutation = {
  deleteAuctionLot:  {
    __typename: "AuctionLot",
    auctionId: string | null,
    lotId: string | null,
    lotNum: string | null,
    title: string | null,
    lotOrder: number | null,
    biddingDisabled: boolean | null,
    customFields:  Array< {
      __typename: "CustomLotField",
      customLabel: string | null,
      customValue: string | null,
    } | null > | null,
    embryoType: string | null,
    category: AuctionLotCategory | null,
    donorName: string | null,
    donorSire: string | null,
    donorDam: string | null,
    breed: string | null,
    consigner: string | null,
    specialTerms: string | null,
    deliveryMethods: Array< string | null > | null,
    startBid: number | null,
    floorPrice: number | null,
    interest: InterestType | null,
    quantity: number | null,
    taxable: boolean | null,
    tag: string | null,
    regNum: string | null,
    regUrl: string | null,
    lineageHierarchy:  {
      __typename: "Lineage",
      sire: string | null,
      sireRegNum: string | null,
      sireUrl: string | null,
      dam: string | null,
      damRegNum: string | null,
      damUrl: string | null,
    } | null,
    epdMeasures:  Array< {
      __typename: "EPDMeasure",
      category: EPDCategory | null,
      measure: string | null,
      value: string | null,
      accuracy: string | null,
      progeny: string | null,
      percentage: string | null,
      order: string | null,
    } | null > | null,
    tattoo: string | null,
    dob: string | null,
    sexedCategory: SexedCategory | null,
    recipientId: string | null,
    exportable: ExportableType | null,
    footnote: Array< string | null > | null,
    contactInfo: string | null,
    pictures: boolean | null,
    photos:  Array< {
      __typename: "LotImage",
      storageName: string | null,
      fileName: string | null,
      caption: string | null,
      cover: boolean | null,
    } | null > | null,
    videos:  Array< {
      __typename: "LotImage",
      storageName: string | null,
      fileName: string | null,
      caption: string | null,
      cover: boolean | null,
    } | null > | null,
    externalVideoUrl: string | null,
    bidIncrement: number | null,
    currentBid: number | null,
    nextBid: number | null,
    bannerImageKey: string | null,
    businessAccountId: string | null,
    lastBidTime: string | null,
    version: number | null,
    bidCount: number | null,
    bidderAccountId: string | null,
    bidderId: string | null,
    bidderCount: number | null,
    bids:  {
      __typename: "BidsResult",
      nextToken: string | null,
    } | null,
    actualEPD: boolean | null,
    importError: boolean | null,
    importErrors: Array< string | null > | null,
    currentMaxBid: number | null,
    currentMaxBidAccountId: string | null,
  } | null,
};

export type CloneAuctionLotAndAssetsMutationVariables = {
  auctionId: string,
  lotId: string,
};

export type CloneAuctionLotAndAssetsMutation = {
  cloneAuctionLotAndAssets:  {
    __typename: "AuctionLot",
    auctionId: string | null,
    lotId: string | null,
    lotNum: string | null,
    title: string | null,
    lotOrder: number | null,
    biddingDisabled: boolean | null,
    customFields:  Array< {
      __typename: "CustomLotField",
      customLabel: string | null,
      customValue: string | null,
    } | null > | null,
    embryoType: string | null,
    category: AuctionLotCategory | null,
    donorName: string | null,
    donorSire: string | null,
    donorDam: string | null,
    breed: string | null,
    consigner: string | null,
    specialTerms: string | null,
    deliveryMethods: Array< string | null > | null,
    startBid: number | null,
    floorPrice: number | null,
    interest: InterestType | null,
    quantity: number | null,
    taxable: boolean | null,
    tag: string | null,
    regNum: string | null,
    regUrl: string | null,
    lineageHierarchy:  {
      __typename: "Lineage",
      sire: string | null,
      sireRegNum: string | null,
      sireUrl: string | null,
      dam: string | null,
      damRegNum: string | null,
      damUrl: string | null,
    } | null,
    epdMeasures:  Array< {
      __typename: "EPDMeasure",
      category: EPDCategory | null,
      measure: string | null,
      value: string | null,
      accuracy: string | null,
      progeny: string | null,
      percentage: string | null,
      order: string | null,
    } | null > | null,
    tattoo: string | null,
    dob: string | null,
    sexedCategory: SexedCategory | null,
    recipientId: string | null,
    exportable: ExportableType | null,
    footnote: Array< string | null > | null,
    contactInfo: string | null,
    pictures: boolean | null,
    photos:  Array< {
      __typename: "LotImage",
      storageName: string | null,
      fileName: string | null,
      caption: string | null,
      cover: boolean | null,
    } | null > | null,
    videos:  Array< {
      __typename: "LotImage",
      storageName: string | null,
      fileName: string | null,
      caption: string | null,
      cover: boolean | null,
    } | null > | null,
    externalVideoUrl: string | null,
    bidIncrement: number | null,
    currentBid: number | null,
    nextBid: number | null,
    bannerImageKey: string | null,
    businessAccountId: string | null,
    lastBidTime: string | null,
    version: number | null,
    bidCount: number | null,
    bidderAccountId: string | null,
    bidderId: string | null,
    bidderCount: number | null,
    bids:  {
      __typename: "BidsResult",
      nextToken: string | null,
    } | null,
    actualEPD: boolean | null,
    importError: boolean | null,
    importErrors: Array< string | null > | null,
    currentMaxBid: number | null,
    currentMaxBidAccountId: string | null,
  } | null,
};

export type UpdateLotOrderMutationVariables = {
  auctionId: string,
  lots?: Array< LotOrderInput | null > | null,
};

export type UpdateLotOrderMutation = {
  updateLotOrder:  Array< {
    __typename: "LotOrder",
    auctionId: string | null,
    lotId: string | null,
    lotOrder: number | null,
  } | null > | null,
};

export type CreateUserMutationVariables = {
  input?: CreateUserAccountInput | null,
};

export type CreateUserMutation = {
  createUser:  {
    __typename: "UserAccount",
    accountId: string | null,
    email: string | null,
    familyName: string | null,
    givenName: string | null,
    nameTitle: string | null,
    businessName: string | null,
    lastLoginDt: string | null,
    loginCtr: number | null,
    phone: string | null,
    physicalAddress: Array< string | null > | null,
    physicalCity: string | null,
    physicalState: string | null,
    physicalZip: string | null,
    physicalCountry: string | null,
    physicalCountryStateCity: string | null,
    billingAddress: Array< string | null > | null,
    billingCity: string | null,
    billingState: string | null,
    billingZip: string | null,
    billingCountry: string | null,
    termsOfService: boolean | null,
    receiveInfo: boolean | null,
    userRoles: Array< string | null > | null,
    businesses: Array< string | null > | null,
    notifications: Array< string | null > | null,
    cognitoUserStatus: string | null,
    version: number | null,
    activeBidderIds:  Array< {
      __typename: "AuctionBidderInfo",
      auctionId: string | null,
      lotId: string | null,
      bidderId: string | null,
    } | null > | null,
    associatedBusinesses:  Array< {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null > | null,
    outstandingInvoices:  Array< {
      __typename: "Invoice",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      invoiceId: string | null,
      invoiceNumber: string | null,
      invoiceDate: string | null,
      invoiceSentDate: string | null,
      paymentDueDate: string | null,
      bannerImageKey: string | null,
      buyerName: string | null,
      buyerAddress: Array< string | null > | null,
      buyerPhoneNumber: string | null,
      buyerEmail: string | null,
      saleAuctionName: string | null,
      saleAuctionClosingDate: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      sellerWebsite: string | null,
      sellerAddress: Array< string | null > | null,
      sellerContactName: string | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerNotes: string | null,
      totalAmountPaid: number | null,
      totalAmountDue: number | null,
      datePaid: string | null,
      paid: boolean | null,
      paymentMethod: string | null,
      paymentInvoiceId: string | null,
      paymentCustomerId: string | null,
      paymentInvoicePdf: string | null,
      paymentUrl: string | null,
    } | null > | null,
    activeAuctions:  Array< {
      __typename: "ActiveAuction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      type: AuctionType | null,
      status: AuctionStatus | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      bannerImageKey: string | null,
    } | null > | null,
    wins:  Array< {
      __typename: "AuctionReportLineItem",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      businessAccountId: string | null,
      winningBidderFirstName: string | null,
      winningBidderLastName: string | null,
      winningBidderAddress: Array< string | null > | null,
      winningBidderPhoneNumber: string | null,
      winningBidderEmail: string | null,
      finalBid: number | null,
      quantity: number | null,
      interest: InterestType | null,
      finalPrice: number | null,
      saleStatus: SaleStatus | null,
      paymentStatus: PaymentStatus | null,
      invoiceId: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      version: number | null,
    } | null > | null,
    auctionsAsSeller:  {
      __typename: "AuctionResult",
      nextToken: string | null,
    } | null,
    auctionAsSellerWithLots:  {
      __typename: "Auction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      locationType: AuctionLocationType | null,
      locationCity: string | null,
      locationState: string | null,
      description: string | null,
      businessAccountId: string | null,
      type: AuctionType | null,
      closingExtensionEnabled: boolean | null,
      closingExtensionStyle: ClosingExtensionStyle | null,
      closingExtensionInterval: number | null,
      closingExtensionTriggered: boolean | null,
      status: AuctionStatus | null,
      salesInfoBannerKeys: Array< string | null > | null,
      salesInfoNotes: string | null,
      sellerLocationTitle: string | null,
      sellerContactName: string | null,
      sellerAddress: Array< string | null > | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerEmail: string | null,
      saleOverview: Array< string | null > | null,
      salesRepName: string | null,
      salesRepContact: string | null,
      salesRepEmail: string | null,
      welcomeMessage: string | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      catalogKey: string | null,
      introVideoUrl: string | null,
      paymentOptions: Array< string | null > | null,
      shippingInstructions: string | null,
      receivingInstructions: string | null,
      liveBidLocation: string | null,
      bannerImageKey: string | null,
      hasCustomInvoiceDetails: boolean | null,
      hasStandardTermsAndConditions: boolean | null,
      hasCustomTermsAndConditions: boolean | null,
      customTermsAndConditions: string | null,
      hasStandardDeliveryAndPaymentTerms: boolean | null,
      hasCustomDeliveryAndPaymentTerms: boolean | null,
      customDeliveryAndPaymentTerms: string | null,
      timezone: string | null,
      useSellerContactInfo: boolean | null,
      delisted: boolean | null,
      createdAt: string | null,
      updatedAt: string | null,
      version: number | null,
    } | null,
    auctionAsSellerLotBidPrices:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
    auctionAsSellerLotBidTimes:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateUserContactDetailsMutationVariables = {
  accountId: string,
  expectedVersion: number,
  input?: UserContactDetailsInput | null,
};

export type UpdateUserContactDetailsMutation = {
  updateUserContactDetails:  {
    __typename: "UserAccount",
    accountId: string | null,
    email: string | null,
    familyName: string | null,
    givenName: string | null,
    nameTitle: string | null,
    businessName: string | null,
    lastLoginDt: string | null,
    loginCtr: number | null,
    phone: string | null,
    physicalAddress: Array< string | null > | null,
    physicalCity: string | null,
    physicalState: string | null,
    physicalZip: string | null,
    physicalCountry: string | null,
    physicalCountryStateCity: string | null,
    billingAddress: Array< string | null > | null,
    billingCity: string | null,
    billingState: string | null,
    billingZip: string | null,
    billingCountry: string | null,
    termsOfService: boolean | null,
    receiveInfo: boolean | null,
    userRoles: Array< string | null > | null,
    businesses: Array< string | null > | null,
    notifications: Array< string | null > | null,
    cognitoUserStatus: string | null,
    version: number | null,
    activeBidderIds:  Array< {
      __typename: "AuctionBidderInfo",
      auctionId: string | null,
      lotId: string | null,
      bidderId: string | null,
    } | null > | null,
    associatedBusinesses:  Array< {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null > | null,
    outstandingInvoices:  Array< {
      __typename: "Invoice",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      invoiceId: string | null,
      invoiceNumber: string | null,
      invoiceDate: string | null,
      invoiceSentDate: string | null,
      paymentDueDate: string | null,
      bannerImageKey: string | null,
      buyerName: string | null,
      buyerAddress: Array< string | null > | null,
      buyerPhoneNumber: string | null,
      buyerEmail: string | null,
      saleAuctionName: string | null,
      saleAuctionClosingDate: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      sellerWebsite: string | null,
      sellerAddress: Array< string | null > | null,
      sellerContactName: string | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerNotes: string | null,
      totalAmountPaid: number | null,
      totalAmountDue: number | null,
      datePaid: string | null,
      paid: boolean | null,
      paymentMethod: string | null,
      paymentInvoiceId: string | null,
      paymentCustomerId: string | null,
      paymentInvoicePdf: string | null,
      paymentUrl: string | null,
    } | null > | null,
    activeAuctions:  Array< {
      __typename: "ActiveAuction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      type: AuctionType | null,
      status: AuctionStatus | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      bannerImageKey: string | null,
    } | null > | null,
    wins:  Array< {
      __typename: "AuctionReportLineItem",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      businessAccountId: string | null,
      winningBidderFirstName: string | null,
      winningBidderLastName: string | null,
      winningBidderAddress: Array< string | null > | null,
      winningBidderPhoneNumber: string | null,
      winningBidderEmail: string | null,
      finalBid: number | null,
      quantity: number | null,
      interest: InterestType | null,
      finalPrice: number | null,
      saleStatus: SaleStatus | null,
      paymentStatus: PaymentStatus | null,
      invoiceId: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      version: number | null,
    } | null > | null,
    auctionsAsSeller:  {
      __typename: "AuctionResult",
      nextToken: string | null,
    } | null,
    auctionAsSellerWithLots:  {
      __typename: "Auction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      locationType: AuctionLocationType | null,
      locationCity: string | null,
      locationState: string | null,
      description: string | null,
      businessAccountId: string | null,
      type: AuctionType | null,
      closingExtensionEnabled: boolean | null,
      closingExtensionStyle: ClosingExtensionStyle | null,
      closingExtensionInterval: number | null,
      closingExtensionTriggered: boolean | null,
      status: AuctionStatus | null,
      salesInfoBannerKeys: Array< string | null > | null,
      salesInfoNotes: string | null,
      sellerLocationTitle: string | null,
      sellerContactName: string | null,
      sellerAddress: Array< string | null > | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerEmail: string | null,
      saleOverview: Array< string | null > | null,
      salesRepName: string | null,
      salesRepContact: string | null,
      salesRepEmail: string | null,
      welcomeMessage: string | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      catalogKey: string | null,
      introVideoUrl: string | null,
      paymentOptions: Array< string | null > | null,
      shippingInstructions: string | null,
      receivingInstructions: string | null,
      liveBidLocation: string | null,
      bannerImageKey: string | null,
      hasCustomInvoiceDetails: boolean | null,
      hasStandardTermsAndConditions: boolean | null,
      hasCustomTermsAndConditions: boolean | null,
      customTermsAndConditions: string | null,
      hasStandardDeliveryAndPaymentTerms: boolean | null,
      hasCustomDeliveryAndPaymentTerms: boolean | null,
      customDeliveryAndPaymentTerms: string | null,
      timezone: string | null,
      useSellerContactInfo: boolean | null,
      delisted: boolean | null,
      createdAt: string | null,
      updatedAt: string | null,
      version: number | null,
    } | null,
    auctionAsSellerLotBidPrices:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
    auctionAsSellerLotBidTimes:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  accountId: string,
};

export type DeleteUserMutation = {
  deleteUser:  {
    __typename: "UserAccount",
    accountId: string | null,
    email: string | null,
    familyName: string | null,
    givenName: string | null,
    nameTitle: string | null,
    businessName: string | null,
    lastLoginDt: string | null,
    loginCtr: number | null,
    phone: string | null,
    physicalAddress: Array< string | null > | null,
    physicalCity: string | null,
    physicalState: string | null,
    physicalZip: string | null,
    physicalCountry: string | null,
    physicalCountryStateCity: string | null,
    billingAddress: Array< string | null > | null,
    billingCity: string | null,
    billingState: string | null,
    billingZip: string | null,
    billingCountry: string | null,
    termsOfService: boolean | null,
    receiveInfo: boolean | null,
    userRoles: Array< string | null > | null,
    businesses: Array< string | null > | null,
    notifications: Array< string | null > | null,
    cognitoUserStatus: string | null,
    version: number | null,
    activeBidderIds:  Array< {
      __typename: "AuctionBidderInfo",
      auctionId: string | null,
      lotId: string | null,
      bidderId: string | null,
    } | null > | null,
    associatedBusinesses:  Array< {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null > | null,
    outstandingInvoices:  Array< {
      __typename: "Invoice",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      invoiceId: string | null,
      invoiceNumber: string | null,
      invoiceDate: string | null,
      invoiceSentDate: string | null,
      paymentDueDate: string | null,
      bannerImageKey: string | null,
      buyerName: string | null,
      buyerAddress: Array< string | null > | null,
      buyerPhoneNumber: string | null,
      buyerEmail: string | null,
      saleAuctionName: string | null,
      saleAuctionClosingDate: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      sellerWebsite: string | null,
      sellerAddress: Array< string | null > | null,
      sellerContactName: string | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerNotes: string | null,
      totalAmountPaid: number | null,
      totalAmountDue: number | null,
      datePaid: string | null,
      paid: boolean | null,
      paymentMethod: string | null,
      paymentInvoiceId: string | null,
      paymentCustomerId: string | null,
      paymentInvoicePdf: string | null,
      paymentUrl: string | null,
    } | null > | null,
    activeAuctions:  Array< {
      __typename: "ActiveAuction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      type: AuctionType | null,
      status: AuctionStatus | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      bannerImageKey: string | null,
    } | null > | null,
    wins:  Array< {
      __typename: "AuctionReportLineItem",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      businessAccountId: string | null,
      winningBidderFirstName: string | null,
      winningBidderLastName: string | null,
      winningBidderAddress: Array< string | null > | null,
      winningBidderPhoneNumber: string | null,
      winningBidderEmail: string | null,
      finalBid: number | null,
      quantity: number | null,
      interest: InterestType | null,
      finalPrice: number | null,
      saleStatus: SaleStatus | null,
      paymentStatus: PaymentStatus | null,
      invoiceId: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      version: number | null,
    } | null > | null,
    auctionsAsSeller:  {
      __typename: "AuctionResult",
      nextToken: string | null,
    } | null,
    auctionAsSellerWithLots:  {
      __typename: "Auction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      locationType: AuctionLocationType | null,
      locationCity: string | null,
      locationState: string | null,
      description: string | null,
      businessAccountId: string | null,
      type: AuctionType | null,
      closingExtensionEnabled: boolean | null,
      closingExtensionStyle: ClosingExtensionStyle | null,
      closingExtensionInterval: number | null,
      closingExtensionTriggered: boolean | null,
      status: AuctionStatus | null,
      salesInfoBannerKeys: Array< string | null > | null,
      salesInfoNotes: string | null,
      sellerLocationTitle: string | null,
      sellerContactName: string | null,
      sellerAddress: Array< string | null > | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerEmail: string | null,
      saleOverview: Array< string | null > | null,
      salesRepName: string | null,
      salesRepContact: string | null,
      salesRepEmail: string | null,
      welcomeMessage: string | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      catalogKey: string | null,
      introVideoUrl: string | null,
      paymentOptions: Array< string | null > | null,
      shippingInstructions: string | null,
      receivingInstructions: string | null,
      liveBidLocation: string | null,
      bannerImageKey: string | null,
      hasCustomInvoiceDetails: boolean | null,
      hasStandardTermsAndConditions: boolean | null,
      hasCustomTermsAndConditions: boolean | null,
      customTermsAndConditions: string | null,
      hasStandardDeliveryAndPaymentTerms: boolean | null,
      hasCustomDeliveryAndPaymentTerms: boolean | null,
      customDeliveryAndPaymentTerms: string | null,
      timezone: string | null,
      useSellerContactInfo: boolean | null,
      delisted: boolean | null,
      createdAt: string | null,
      updatedAt: string | null,
      version: number | null,
    } | null,
    auctionAsSellerLotBidPrices:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
    auctionAsSellerLotBidTimes:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateUserEmailMutationVariables = {
  accountId: string,
  newemail: string,
};

export type UpdateUserEmailMutation = {
  updateUserEmail:  {
    __typename: "UserAccount",
    accountId: string | null,
    email: string | null,
    familyName: string | null,
    givenName: string | null,
    nameTitle: string | null,
    businessName: string | null,
    lastLoginDt: string | null,
    loginCtr: number | null,
    phone: string | null,
    physicalAddress: Array< string | null > | null,
    physicalCity: string | null,
    physicalState: string | null,
    physicalZip: string | null,
    physicalCountry: string | null,
    physicalCountryStateCity: string | null,
    billingAddress: Array< string | null > | null,
    billingCity: string | null,
    billingState: string | null,
    billingZip: string | null,
    billingCountry: string | null,
    termsOfService: boolean | null,
    receiveInfo: boolean | null,
    userRoles: Array< string | null > | null,
    businesses: Array< string | null > | null,
    notifications: Array< string | null > | null,
    cognitoUserStatus: string | null,
    version: number | null,
    activeBidderIds:  Array< {
      __typename: "AuctionBidderInfo",
      auctionId: string | null,
      lotId: string | null,
      bidderId: string | null,
    } | null > | null,
    associatedBusinesses:  Array< {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null > | null,
    outstandingInvoices:  Array< {
      __typename: "Invoice",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      invoiceId: string | null,
      invoiceNumber: string | null,
      invoiceDate: string | null,
      invoiceSentDate: string | null,
      paymentDueDate: string | null,
      bannerImageKey: string | null,
      buyerName: string | null,
      buyerAddress: Array< string | null > | null,
      buyerPhoneNumber: string | null,
      buyerEmail: string | null,
      saleAuctionName: string | null,
      saleAuctionClosingDate: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      sellerWebsite: string | null,
      sellerAddress: Array< string | null > | null,
      sellerContactName: string | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerNotes: string | null,
      totalAmountPaid: number | null,
      totalAmountDue: number | null,
      datePaid: string | null,
      paid: boolean | null,
      paymentMethod: string | null,
      paymentInvoiceId: string | null,
      paymentCustomerId: string | null,
      paymentInvoicePdf: string | null,
      paymentUrl: string | null,
    } | null > | null,
    activeAuctions:  Array< {
      __typename: "ActiveAuction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      type: AuctionType | null,
      status: AuctionStatus | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      bannerImageKey: string | null,
    } | null > | null,
    wins:  Array< {
      __typename: "AuctionReportLineItem",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      businessAccountId: string | null,
      winningBidderFirstName: string | null,
      winningBidderLastName: string | null,
      winningBidderAddress: Array< string | null > | null,
      winningBidderPhoneNumber: string | null,
      winningBidderEmail: string | null,
      finalBid: number | null,
      quantity: number | null,
      interest: InterestType | null,
      finalPrice: number | null,
      saleStatus: SaleStatus | null,
      paymentStatus: PaymentStatus | null,
      invoiceId: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      version: number | null,
    } | null > | null,
    auctionsAsSeller:  {
      __typename: "AuctionResult",
      nextToken: string | null,
    } | null,
    auctionAsSellerWithLots:  {
      __typename: "Auction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      locationType: AuctionLocationType | null,
      locationCity: string | null,
      locationState: string | null,
      description: string | null,
      businessAccountId: string | null,
      type: AuctionType | null,
      closingExtensionEnabled: boolean | null,
      closingExtensionStyle: ClosingExtensionStyle | null,
      closingExtensionInterval: number | null,
      closingExtensionTriggered: boolean | null,
      status: AuctionStatus | null,
      salesInfoBannerKeys: Array< string | null > | null,
      salesInfoNotes: string | null,
      sellerLocationTitle: string | null,
      sellerContactName: string | null,
      sellerAddress: Array< string | null > | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerEmail: string | null,
      saleOverview: Array< string | null > | null,
      salesRepName: string | null,
      salesRepContact: string | null,
      salesRepEmail: string | null,
      welcomeMessage: string | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      catalogKey: string | null,
      introVideoUrl: string | null,
      paymentOptions: Array< string | null > | null,
      shippingInstructions: string | null,
      receivingInstructions: string | null,
      liveBidLocation: string | null,
      bannerImageKey: string | null,
      hasCustomInvoiceDetails: boolean | null,
      hasStandardTermsAndConditions: boolean | null,
      hasCustomTermsAndConditions: boolean | null,
      customTermsAndConditions: string | null,
      hasStandardDeliveryAndPaymentTerms: boolean | null,
      hasCustomDeliveryAndPaymentTerms: boolean | null,
      customDeliveryAndPaymentTerms: string | null,
      timezone: string | null,
      useSellerContactInfo: boolean | null,
      delisted: boolean | null,
      createdAt: string | null,
      updatedAt: string | null,
      version: number | null,
    } | null,
    auctionAsSellerLotBidPrices:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
    auctionAsSellerLotBidTimes:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
  } | null,
};

export type DeleteUserAccountMutationVariables = {
  accountId: string,
};

export type DeleteUserAccountMutation = {
  deleteUserAccount:  {
    __typename: "DeleteResult",
    status: ApiResult | null,
    message: string | null,
  } | null,
};

export type CreateBusinessMutationVariables = {
  input?: CreateBusinessAccountInput | null,
};

export type CreateBusinessMutation = {
  createBusiness:  {
    __typename: "BusinessAccount",
    accountId: string | null,
    businessName: string | null,
    email: string | null,
    familyName: string | null,
    givenName: string | null,
    nameTitle: string | null,
    providerName: string | null,
    phone: string | null,
    physicalAddress: Array< string | null > | null,
    physicalCity: string | null,
    physicalState: string | null,
    physicalZip: string | null,
    physicalCountry: string | null,
    physicalCountryStateCity: string | null,
    billingAddress: Array< string | null > | null,
    billingCity: string | null,
    billingState: string | null,
    billingZip: string | null,
    billingCountry: string | null,
    termsOfService: boolean | null,
    receiveInfo: boolean | null,
    businessWebSite: string | null,
    businessNotes: string | null,
    businessPhoneNumbers:  Array< {
      __typename: "PhoneNumberDetail",
      phone: string | null,
      preferred: boolean | null,
      mobile: boolean | null,
    } | null > | null,
    proxyUsers:  Array< {
      __typename: "UserAccount",
      accountId: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      businessName: string | null,
      lastLoginDt: string | null,
      loginCtr: number | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      userRoles: Array< string | null > | null,
      businesses: Array< string | null > | null,
      notifications: Array< string | null > | null,
      cognitoUserStatus: string | null,
      version: number | null,
    } | null > | null,
    businessTimezone: string | null,
    version: number | null,
  } | null,
};

export type UpdateBusinessContactDetailsMutationVariables = {
  accountId: string,
  expectedVersion: number,
  input?: BusinessAccountContactDetailsInput | null,
};

export type UpdateBusinessContactDetailsMutation = {
  updateBusinessContactDetails:  {
    __typename: "BusinessAccount",
    accountId: string | null,
    businessName: string | null,
    email: string | null,
    familyName: string | null,
    givenName: string | null,
    nameTitle: string | null,
    providerName: string | null,
    phone: string | null,
    physicalAddress: Array< string | null > | null,
    physicalCity: string | null,
    physicalState: string | null,
    physicalZip: string | null,
    physicalCountry: string | null,
    physicalCountryStateCity: string | null,
    billingAddress: Array< string | null > | null,
    billingCity: string | null,
    billingState: string | null,
    billingZip: string | null,
    billingCountry: string | null,
    termsOfService: boolean | null,
    receiveInfo: boolean | null,
    businessWebSite: string | null,
    businessNotes: string | null,
    businessPhoneNumbers:  Array< {
      __typename: "PhoneNumberDetail",
      phone: string | null,
      preferred: boolean | null,
      mobile: boolean | null,
    } | null > | null,
    proxyUsers:  Array< {
      __typename: "UserAccount",
      accountId: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      businessName: string | null,
      lastLoginDt: string | null,
      loginCtr: number | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      userRoles: Array< string | null > | null,
      businesses: Array< string | null > | null,
      notifications: Array< string | null > | null,
      cognitoUserStatus: string | null,
      version: number | null,
    } | null > | null,
    businessTimezone: string | null,
    version: number | null,
  } | null,
};

export type DeleteBusinessMutationVariables = {
  accountId: string,
};

export type DeleteBusinessMutation = {
  deleteBusiness:  {
    __typename: "BusinessAccount",
    accountId: string | null,
    businessName: string | null,
    email: string | null,
    familyName: string | null,
    givenName: string | null,
    nameTitle: string | null,
    providerName: string | null,
    phone: string | null,
    physicalAddress: Array< string | null > | null,
    physicalCity: string | null,
    physicalState: string | null,
    physicalZip: string | null,
    physicalCountry: string | null,
    physicalCountryStateCity: string | null,
    billingAddress: Array< string | null > | null,
    billingCity: string | null,
    billingState: string | null,
    billingZip: string | null,
    billingCountry: string | null,
    termsOfService: boolean | null,
    receiveInfo: boolean | null,
    businessWebSite: string | null,
    businessNotes: string | null,
    businessPhoneNumbers:  Array< {
      __typename: "PhoneNumberDetail",
      phone: string | null,
      preferred: boolean | null,
      mobile: boolean | null,
    } | null > | null,
    proxyUsers:  Array< {
      __typename: "UserAccount",
      accountId: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      businessName: string | null,
      lastLoginDt: string | null,
      loginCtr: number | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      userRoles: Array< string | null > | null,
      businesses: Array< string | null > | null,
      notifications: Array< string | null > | null,
      cognitoUserStatus: string | null,
      version: number | null,
    } | null > | null,
    businessTimezone: string | null,
    version: number | null,
  } | null,
};

export type AddBusinessUserMutationVariables = {
  accountId: string,
  businessAccountId: string,
};

export type AddBusinessUserMutation = {
  addBusinessUser:  {
    __typename: "BusinessUserResult",
    accountId: string | null,
  } | null,
};

export type DeleteBusinessUserMutationVariables = {
  accountId: string,
  businessAccountId: string,
};

export type DeleteBusinessUserMutation = {
  deleteBusinessUser:  {
    __typename: "BusinessUserResult",
    accountId: string | null,
  } | null,
};

export type AddNewUserMutationVariables = {
  email: string,
  givenName: string,
  familyName: string,
  phone: string,
  userRoles?: Array< string | null > | null,
};

export type AddNewUserMutation = {
  addNewUser:  {
    __typename: "UserAccount",
    accountId: string | null,
    email: string | null,
    familyName: string | null,
    givenName: string | null,
    nameTitle: string | null,
    businessName: string | null,
    lastLoginDt: string | null,
    loginCtr: number | null,
    phone: string | null,
    physicalAddress: Array< string | null > | null,
    physicalCity: string | null,
    physicalState: string | null,
    physicalZip: string | null,
    physicalCountry: string | null,
    physicalCountryStateCity: string | null,
    billingAddress: Array< string | null > | null,
    billingCity: string | null,
    billingState: string | null,
    billingZip: string | null,
    billingCountry: string | null,
    termsOfService: boolean | null,
    receiveInfo: boolean | null,
    userRoles: Array< string | null > | null,
    businesses: Array< string | null > | null,
    notifications: Array< string | null > | null,
    cognitoUserStatus: string | null,
    version: number | null,
    activeBidderIds:  Array< {
      __typename: "AuctionBidderInfo",
      auctionId: string | null,
      lotId: string | null,
      bidderId: string | null,
    } | null > | null,
    associatedBusinesses:  Array< {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null > | null,
    outstandingInvoices:  Array< {
      __typename: "Invoice",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      invoiceId: string | null,
      invoiceNumber: string | null,
      invoiceDate: string | null,
      invoiceSentDate: string | null,
      paymentDueDate: string | null,
      bannerImageKey: string | null,
      buyerName: string | null,
      buyerAddress: Array< string | null > | null,
      buyerPhoneNumber: string | null,
      buyerEmail: string | null,
      saleAuctionName: string | null,
      saleAuctionClosingDate: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      sellerWebsite: string | null,
      sellerAddress: Array< string | null > | null,
      sellerContactName: string | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerNotes: string | null,
      totalAmountPaid: number | null,
      totalAmountDue: number | null,
      datePaid: string | null,
      paid: boolean | null,
      paymentMethod: string | null,
      paymentInvoiceId: string | null,
      paymentCustomerId: string | null,
      paymentInvoicePdf: string | null,
      paymentUrl: string | null,
    } | null > | null,
    activeAuctions:  Array< {
      __typename: "ActiveAuction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      type: AuctionType | null,
      status: AuctionStatus | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      bannerImageKey: string | null,
    } | null > | null,
    wins:  Array< {
      __typename: "AuctionReportLineItem",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      businessAccountId: string | null,
      winningBidderFirstName: string | null,
      winningBidderLastName: string | null,
      winningBidderAddress: Array< string | null > | null,
      winningBidderPhoneNumber: string | null,
      winningBidderEmail: string | null,
      finalBid: number | null,
      quantity: number | null,
      interest: InterestType | null,
      finalPrice: number | null,
      saleStatus: SaleStatus | null,
      paymentStatus: PaymentStatus | null,
      invoiceId: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      version: number | null,
    } | null > | null,
    auctionsAsSeller:  {
      __typename: "AuctionResult",
      nextToken: string | null,
    } | null,
    auctionAsSellerWithLots:  {
      __typename: "Auction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      locationType: AuctionLocationType | null,
      locationCity: string | null,
      locationState: string | null,
      description: string | null,
      businessAccountId: string | null,
      type: AuctionType | null,
      closingExtensionEnabled: boolean | null,
      closingExtensionStyle: ClosingExtensionStyle | null,
      closingExtensionInterval: number | null,
      closingExtensionTriggered: boolean | null,
      status: AuctionStatus | null,
      salesInfoBannerKeys: Array< string | null > | null,
      salesInfoNotes: string | null,
      sellerLocationTitle: string | null,
      sellerContactName: string | null,
      sellerAddress: Array< string | null > | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerEmail: string | null,
      saleOverview: Array< string | null > | null,
      salesRepName: string | null,
      salesRepContact: string | null,
      salesRepEmail: string | null,
      welcomeMessage: string | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      catalogKey: string | null,
      introVideoUrl: string | null,
      paymentOptions: Array< string | null > | null,
      shippingInstructions: string | null,
      receivingInstructions: string | null,
      liveBidLocation: string | null,
      bannerImageKey: string | null,
      hasCustomInvoiceDetails: boolean | null,
      hasStandardTermsAndConditions: boolean | null,
      hasCustomTermsAndConditions: boolean | null,
      customTermsAndConditions: string | null,
      hasStandardDeliveryAndPaymentTerms: boolean | null,
      hasCustomDeliveryAndPaymentTerms: boolean | null,
      customDeliveryAndPaymentTerms: string | null,
      timezone: string | null,
      useSellerContactInfo: boolean | null,
      delisted: boolean | null,
      createdAt: string | null,
      updatedAt: string | null,
      version: number | null,
    } | null,
    auctionAsSellerLotBidPrices:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
    auctionAsSellerLotBidTimes:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
  } | null,
};

export type UpdateAuctionReportMutationVariables = {
  auctionId: string,
  expectedVersion: number,
  input: AuctionReportInput,
};

export type UpdateAuctionReportMutation = {
  updateAuctionReport:  {
    __typename: "AuctionReport",
    status: ApiResult | null,
    message: string | null,
    auctionId: string | null,
    auctionName: string | null,
    auctionStartDate: string | null,
    auctionClosingDate: string | null,
    totalGrossSales: number | null,
    totalBidderCount: number | null,
    totalLotCount: number | null,
    invoiceSentDate: string | null,
    payments:  Array< {
      __typename: "AuctionReportPayment",
      paymentDate: string | null,
      amount: number | null,
      fees: number | null,
    } | null > | null,
    lineItems:  Array< {
      __typename: "AuctionReportLineItem",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      businessAccountId: string | null,
      winningBidderFirstName: string | null,
      winningBidderLastName: string | null,
      winningBidderAddress: Array< string | null > | null,
      winningBidderPhoneNumber: string | null,
      winningBidderEmail: string | null,
      finalBid: number | null,
      quantity: number | null,
      interest: InterestType | null,
      finalPrice: number | null,
      saleStatus: SaleStatus | null,
      paymentStatus: PaymentStatus | null,
      invoiceId: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      version: number | null,
    } | null > | null,
    nextToken: string | null,
    version: number | null,
  } | null,
};

export type UpdateAuctionReportLineItemMutationVariables = {
  auctionId: string,
  lotId: string,
  input: AuctionReportLineItemInput,
};

export type UpdateAuctionReportLineItemMutation = {
  updateAuctionReportLineItem:  {
    __typename: "AuctionReportLineItem",
    auctionId: string | null,
    auctionName: string | null,
    auctionStartDate: string | null,
    lotId: string | null,
    lotNum: string | null,
    title: string | null,
    businessAccountId: string | null,
    winningBidderFirstName: string | null,
    winningBidderLastName: string | null,
    winningBidderAddress: Array< string | null > | null,
    winningBidderPhoneNumber: string | null,
    winningBidderEmail: string | null,
    finalBid: number | null,
    quantity: number | null,
    interest: InterestType | null,
    finalPrice: number | null,
    saleStatus: SaleStatus | null,
    paymentStatus: PaymentStatus | null,
    invoiceId: string | null,
    invoice:  {
      __typename: "Invoice",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      invoiceId: string | null,
      invoiceNumber: string | null,
      invoiceDate: string | null,
      invoiceSentDate: string | null,
      paymentDueDate: string | null,
      bannerImageKey: string | null,
      buyerName: string | null,
      buyerAddress: Array< string | null > | null,
      buyerPhoneNumber: string | null,
      buyerEmail: string | null,
      saleAuctionName: string | null,
      saleAuctionClosingDate: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      sellerWebsite: string | null,
      sellerAddress: Array< string | null > | null,
      sellerContactName: string | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerNotes: string | null,
      totalAmountPaid: number | null,
      totalAmountDue: number | null,
      datePaid: string | null,
      paid: boolean | null,
      paymentMethod: string | null,
      paymentInvoiceId: string | null,
      paymentCustomerId: string | null,
      paymentInvoicePdf: string | null,
      paymentUrl: string | null,
    } | null,
    sellerName: string | null,
    sellerPhoneNumber: string | null,
    sellerEmail: string | null,
    additionalLineItems:  Array< {
      __typename: "AdjustmentLineItem",
      amount: number | null,
      note: string | null,
    } | null > | null,
    version: number | null,
  } | null,
};

export type SendInvoicesMutationVariables = {
  auctionId: string,
};

export type SendInvoicesMutation = {
  sendInvoices:  {
    __typename: "SendInvoicesResult",
    auctionId: string | null,
    invoiceSentDate: string | null,
    invoices:  Array< {
      __typename: "Invoice",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      invoiceId: string | null,
      invoiceNumber: string | null,
      invoiceDate: string | null,
      invoiceSentDate: string | null,
      paymentDueDate: string | null,
      bannerImageKey: string | null,
      buyerName: string | null,
      buyerAddress: Array< string | null > | null,
      buyerPhoneNumber: string | null,
      buyerEmail: string | null,
      saleAuctionName: string | null,
      saleAuctionClosingDate: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      sellerWebsite: string | null,
      sellerAddress: Array< string | null > | null,
      sellerContactName: string | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerNotes: string | null,
      totalAmountPaid: number | null,
      totalAmountDue: number | null,
      datePaid: string | null,
      paid: boolean | null,
      paymentMethod: string | null,
      paymentInvoiceId: string | null,
      paymentCustomerId: string | null,
      paymentInvoicePdf: string | null,
      paymentUrl: string | null,
    } | null > | null,
  } | null,
};

export type SendInvoiceMutationVariables = {
  auctionId: string,
  sellerEmail: string,
  buyerEmail: string,
};

export type SendInvoiceMutation = {
  sendInvoice:  {
    __typename: "SendInvoiceResult",
    auctionId: string | null,
    invoice:  {
      __typename: "Invoice",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      invoiceId: string | null,
      invoiceNumber: string | null,
      invoiceDate: string | null,
      invoiceSentDate: string | null,
      paymentDueDate: string | null,
      bannerImageKey: string | null,
      buyerName: string | null,
      buyerAddress: Array< string | null > | null,
      buyerPhoneNumber: string | null,
      buyerEmail: string | null,
      saleAuctionName: string | null,
      saleAuctionClosingDate: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      sellerWebsite: string | null,
      sellerAddress: Array< string | null > | null,
      sellerContactName: string | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerNotes: string | null,
      totalAmountPaid: number | null,
      totalAmountDue: number | null,
      datePaid: string | null,
      paid: boolean | null,
      paymentMethod: string | null,
      paymentInvoiceId: string | null,
      paymentCustomerId: string | null,
      paymentInvoicePdf: string | null,
      paymentUrl: string | null,
    } | null,
  } | null,
};

export type PublishAuctionChangedMutationVariables = {
  auctionId: string,
  data: AuctionChangeInput,
};

export type PublishAuctionChangedMutation = {
  publishAuctionChanged:  {
    __typename: "AuctionChangeData",
    auctionId: string | null,
    eventType: AuctionChangeEventType | null,
    auctionData:  {
      __typename: "AuctionDetailsData",
      closingExtensionInterval: number | null,
      closingExtensionTriggered: boolean | null,
      status: AuctionStatus | null,
      startTime: string | null,
      endTime: string | null,
      bidIncrement: number | null,
      floorPrice: number | null,
    } | null,
    lotData:  {
      __typename: "AuctionLotData",
      auctionId: string | null,
      lotId: string | null,
      startBid: number | null,
      currentBid: number | null,
      nextBid: number | null,
      bidCount: number | null,
      bidIncrement: number | null,
      bidderCount: number | null,
      bidderId: string | null,
      bidderAccountId: string | null,
      floorPrice: number | null,
      biddingDisabled: boolean | null,
    } | null,
  } | null,
};

export type SubmitBidMutationVariables = {
  auctionId: string,
  lotId: string,
  bidPrice: number,
  maxBidPrice?: number | null,
  targetAccountId?: string | null,
};

export type SubmitBidMutation = {
  submitBid:  {
    __typename: "BidResult",
    status: BidResultStatus | null,
    code: BidResultCode | null,
    message: string | null,
    data:  {
      __typename: "BidResultData",
      auctionId: string | null,
      lotId: string | null,
      currentBid: number | null,
      nextBid: number | null,
      bidCount: number | null,
      bidIncrement: number | null,
      bidderCount: number | null,
      bidderId: string | null,
      isNewBidderId: boolean | null,
    } | null,
  } | null,
};

export type SubmitMaxBidMutationVariables = {
  auctionId: string,
  lotId: string,
  maxBidPrice?: number | null,
  targetAccountId?: string | null,
};

export type SubmitMaxBidMutation = {
  submitMaxBid:  {
    __typename: "BidResult",
    status: BidResultStatus | null,
    code: BidResultCode | null,
    message: string | null,
    data:  {
      __typename: "BidResultData",
      auctionId: string | null,
      lotId: string | null,
      currentBid: number | null,
      nextBid: number | null,
      bidCount: number | null,
      bidIncrement: number | null,
      bidderCount: number | null,
      bidderId: string | null,
      isNewBidderId: boolean | null,
    } | null,
  } | null,
};

export type DeleteBidMutationVariables = {
  auctionId: string,
  lotId: string,
  bidTime: string,
};

export type DeleteBidMutation = {
  deleteBid:  {
    __typename: "DeleteResult",
    status: ApiResult | null,
    message: string | null,
  } | null,
};

export type ManageFileMutationVariables = {
  itemId: string,
  itemKey: string,
  public: boolean,
};

export type ManageFileMutation = {
  manageFile:  {
    __typename: "FileObject",
    fileKey: string | null,
    downloadUrl: string | null,
    uploadUrl: string | null,
  } | null,
};

export type CreatePaymentIntentMutationVariables = {
  invoiceId: string,
  paymentType: PaymentType,
};

export type CreatePaymentIntentMutation = {
  createPaymentIntent:  {
    __typename: "PaymentIntentResult",
    code: string | null,
  } | null,
};

export type InitiateTaskMutationVariables = {
  taskKey: string,
  payload?: string | null,
};

export type InitiateTaskMutation = {
  initiateTask:  {
    __typename: "Result",
    id: string | null,
    status: ResultStatus | null,
    payload: string | null,
  } | null,
};

export type PublishResultMutationVariables = {
  id: string,
  status?: ResultStatus | null,
  output?: string | null,
};

export type PublishResultMutation = {
  publishResult:  {
    __typename: "Result",
    id: string | null,
    status: ResultStatus | null,
    payload: string | null,
  } | null,
};

export type AuctionQueryVariables = {
  auctionId: string,
};

export type AuctionQuery = {
  auction:  {
    __typename: "Auction",
    auctionId: string | null,
    title: string | null,
    startTime: string | null,
    endTime: string | null,
    locationType: AuctionLocationType | null,
    locationCity: string | null,
    locationState: string | null,
    description: string | null,
    businessAccountId: string | null,
    type: AuctionType | null,
    closingExtensionEnabled: boolean | null,
    closingExtensionStyle: ClosingExtensionStyle | null,
    closingExtensionInterval: number | null,
    closingExtensionTriggered: boolean | null,
    status: AuctionStatus | null,
    salesInfoBannerKeys: Array< string | null > | null,
    salesInfoNotes: string | null,
    sellerLocationTitle: string | null,
    sellerContactName: string | null,
    sellerAddress: Array< string | null > | null,
    sellerCity: string | null,
    sellerState: string | null,
    sellerZip: string | null,
    sellerEmail: string | null,
    sellerPhoneNumbers:  Array< {
      __typename: "PhoneNumberDetail",
      phone: string | null,
      preferred: boolean | null,
      mobile: boolean | null,
    } | null > | null,
    saleOverview: Array< string | null > | null,
    salesRepName: string | null,
    salesRepContact: string | null,
    salesRepEmail: string | null,
    welcomeMessage: string | null,
    bidIncrement: number | null,
    startBid: number | null,
    floorPrice: number | null,
    catalogKey: string | null,
    introVideoUrl: string | null,
    paymentOptions: Array< string | null > | null,
    shippingInstructions: string | null,
    receivingInstructions: string | null,
    liveBidLocation: string | null,
    bannerImageKey: string | null,
    hasCustomInvoiceDetails: boolean | null,
    hasStandardTermsAndConditions: boolean | null,
    hasCustomTermsAndConditions: boolean | null,
    customTermsAndConditions: string | null,
    hasStandardDeliveryAndPaymentTerms: boolean | null,
    hasCustomDeliveryAndPaymentTerms: boolean | null,
    customDeliveryAndPaymentTerms: string | null,
    timezone: string | null,
    useSellerContactInfo: boolean | null,
    delisted: boolean | null,
    createdAt: string | null,
    updatedAt: string | null,
    version: number | null,
    lots:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
    lot:  {
      __typename: "AuctionLot",
      auctionId: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      lotOrder: number | null,
      biddingDisabled: boolean | null,
      embryoType: string | null,
      category: AuctionLotCategory | null,
      donorName: string | null,
      donorSire: string | null,
      donorDam: string | null,
      breed: string | null,
      consigner: string | null,
      specialTerms: string | null,
      deliveryMethods: Array< string | null > | null,
      startBid: number | null,
      floorPrice: number | null,
      interest: InterestType | null,
      quantity: number | null,
      taxable: boolean | null,
      tag: string | null,
      regNum: string | null,
      regUrl: string | null,
      tattoo: string | null,
      dob: string | null,
      sexedCategory: SexedCategory | null,
      recipientId: string | null,
      exportable: ExportableType | null,
      footnote: Array< string | null > | null,
      contactInfo: string | null,
      pictures: boolean | null,
      externalVideoUrl: string | null,
      bidIncrement: number | null,
      currentBid: number | null,
      nextBid: number | null,
      bannerImageKey: string | null,
      businessAccountId: string | null,
      lastBidTime: string | null,
      version: number | null,
      bidCount: number | null,
      bidderAccountId: string | null,
      bidderId: string | null,
      bidderCount: number | null,
      actualEPD: boolean | null,
      importError: boolean | null,
      importErrors: Array< string | null > | null,
      currentMaxBid: number | null,
      currentMaxBidAccountId: string | null,
    } | null,
    primaryBusiness:  {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null,
    businesses:  Array< {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null > | null,
    bidders:  {
      __typename: "BidderResult",
      nextToken: string | null,
    } | null,
  } | null,
};

export type AuctionsQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: SortDirection | null,
};

export type AuctionsQuery = {
  auctions:  {
    __typename: "AuctionResult",
    items:  Array< {
      __typename: "Auction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      locationType: AuctionLocationType | null,
      locationCity: string | null,
      locationState: string | null,
      description: string | null,
      businessAccountId: string | null,
      type: AuctionType | null,
      closingExtensionEnabled: boolean | null,
      closingExtensionStyle: ClosingExtensionStyle | null,
      closingExtensionInterval: number | null,
      closingExtensionTriggered: boolean | null,
      status: AuctionStatus | null,
      salesInfoBannerKeys: Array< string | null > | null,
      salesInfoNotes: string | null,
      sellerLocationTitle: string | null,
      sellerContactName: string | null,
      sellerAddress: Array< string | null > | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerEmail: string | null,
      saleOverview: Array< string | null > | null,
      salesRepName: string | null,
      salesRepContact: string | null,
      salesRepEmail: string | null,
      welcomeMessage: string | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      catalogKey: string | null,
      introVideoUrl: string | null,
      paymentOptions: Array< string | null > | null,
      shippingInstructions: string | null,
      receivingInstructions: string | null,
      liveBidLocation: string | null,
      bannerImageKey: string | null,
      hasCustomInvoiceDetails: boolean | null,
      hasStandardTermsAndConditions: boolean | null,
      hasCustomTermsAndConditions: boolean | null,
      customTermsAndConditions: string | null,
      hasStandardDeliveryAndPaymentTerms: boolean | null,
      hasCustomDeliveryAndPaymentTerms: boolean | null,
      customDeliveryAndPaymentTerms: string | null,
      timezone: string | null,
      useSellerContactInfo: boolean | null,
      delisted: boolean | null,
      createdAt: string | null,
      updatedAt: string | null,
      version: number | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type OpenAuctionsQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: SortDirection | null,
};

export type OpenAuctionsQuery = {
  openAuctions:  {
    __typename: "AuctionResult",
    items:  Array< {
      __typename: "Auction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      locationType: AuctionLocationType | null,
      locationCity: string | null,
      locationState: string | null,
      description: string | null,
      businessAccountId: string | null,
      type: AuctionType | null,
      closingExtensionEnabled: boolean | null,
      closingExtensionStyle: ClosingExtensionStyle | null,
      closingExtensionInterval: number | null,
      closingExtensionTriggered: boolean | null,
      status: AuctionStatus | null,
      salesInfoBannerKeys: Array< string | null > | null,
      salesInfoNotes: string | null,
      sellerLocationTitle: string | null,
      sellerContactName: string | null,
      sellerAddress: Array< string | null > | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerEmail: string | null,
      saleOverview: Array< string | null > | null,
      salesRepName: string | null,
      salesRepContact: string | null,
      salesRepEmail: string | null,
      welcomeMessage: string | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      catalogKey: string | null,
      introVideoUrl: string | null,
      paymentOptions: Array< string | null > | null,
      shippingInstructions: string | null,
      receivingInstructions: string | null,
      liveBidLocation: string | null,
      bannerImageKey: string | null,
      hasCustomInvoiceDetails: boolean | null,
      hasStandardTermsAndConditions: boolean | null,
      hasCustomTermsAndConditions: boolean | null,
      customTermsAndConditions: string | null,
      hasStandardDeliveryAndPaymentTerms: boolean | null,
      hasCustomDeliveryAndPaymentTerms: boolean | null,
      customDeliveryAndPaymentTerms: string | null,
      timezone: string | null,
      useSellerContactInfo: boolean | null,
      delisted: boolean | null,
      createdAt: string | null,
      updatedAt: string | null,
      version: number | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type AuctionsBySellerQueryVariables = {
  sellerId: string,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: SortDirection | null,
};

export type AuctionsBySellerQuery = {
  auctionsBySeller:  {
    __typename: "AuctionResult",
    items:  Array< {
      __typename: "Auction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      locationType: AuctionLocationType | null,
      locationCity: string | null,
      locationState: string | null,
      description: string | null,
      businessAccountId: string | null,
      type: AuctionType | null,
      closingExtensionEnabled: boolean | null,
      closingExtensionStyle: ClosingExtensionStyle | null,
      closingExtensionInterval: number | null,
      closingExtensionTriggered: boolean | null,
      status: AuctionStatus | null,
      salesInfoBannerKeys: Array< string | null > | null,
      salesInfoNotes: string | null,
      sellerLocationTitle: string | null,
      sellerContactName: string | null,
      sellerAddress: Array< string | null > | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerEmail: string | null,
      saleOverview: Array< string | null > | null,
      salesRepName: string | null,
      salesRepContact: string | null,
      salesRepEmail: string | null,
      welcomeMessage: string | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      catalogKey: string | null,
      introVideoUrl: string | null,
      paymentOptions: Array< string | null > | null,
      shippingInstructions: string | null,
      receivingInstructions: string | null,
      liveBidLocation: string | null,
      bannerImageKey: string | null,
      hasCustomInvoiceDetails: boolean | null,
      hasStandardTermsAndConditions: boolean | null,
      hasCustomTermsAndConditions: boolean | null,
      customTermsAndConditions: string | null,
      hasStandardDeliveryAndPaymentTerms: boolean | null,
      hasCustomDeliveryAndPaymentTerms: boolean | null,
      customDeliveryAndPaymentTerms: string | null,
      timezone: string | null,
      useSellerContactInfo: boolean | null,
      delisted: boolean | null,
      createdAt: string | null,
      updatedAt: string | null,
      version: number | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type BidderReportQueryVariables = {
  auctionId: string,
};

export type BidderReportQuery = {
  bidderReport:  {
    __typename: "BidderReportResult",
    status: ApiResult | null,
    message: string | null,
    fileKey: string | null,
    downloadUrl: string | null,
  } | null,
};

export type WinsByEmailQueryVariables = {
  email: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type WinsByEmailQuery = {
  winsByEmail:  {
    __typename: "AuctionReportLineItemResult",
    items:  Array< {
      __typename: "AuctionReportLineItem",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      businessAccountId: string | null,
      winningBidderFirstName: string | null,
      winningBidderLastName: string | null,
      winningBidderAddress: Array< string | null > | null,
      winningBidderPhoneNumber: string | null,
      winningBidderEmail: string | null,
      finalBid: number | null,
      quantity: number | null,
      interest: InterestType | null,
      finalPrice: number | null,
      saleStatus: SaleStatus | null,
      paymentStatus: PaymentStatus | null,
      invoiceId: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      version: number | null,
    } | null > | null,
    nextToken: string | null,
    scannedCount: number | null,
  } | null,
};

export type AuctionLotQueryVariables = {
  auctionId: string,
  lotId: string,
};

export type AuctionLotQuery = {
  auctionLot:  {
    __typename: "AuctionLot",
    auctionId: string | null,
    lotId: string | null,
    lotNum: string | null,
    title: string | null,
    lotOrder: number | null,
    biddingDisabled: boolean | null,
    customFields:  Array< {
      __typename: "CustomLotField",
      customLabel: string | null,
      customValue: string | null,
    } | null > | null,
    embryoType: string | null,
    category: AuctionLotCategory | null,
    donorName: string | null,
    donorSire: string | null,
    donorDam: string | null,
    breed: string | null,
    consigner: string | null,
    specialTerms: string | null,
    deliveryMethods: Array< string | null > | null,
    startBid: number | null,
    floorPrice: number | null,
    interest: InterestType | null,
    quantity: number | null,
    taxable: boolean | null,
    tag: string | null,
    regNum: string | null,
    regUrl: string | null,
    lineageHierarchy:  {
      __typename: "Lineage",
      sire: string | null,
      sireRegNum: string | null,
      sireUrl: string | null,
      dam: string | null,
      damRegNum: string | null,
      damUrl: string | null,
    } | null,
    epdMeasures:  Array< {
      __typename: "EPDMeasure",
      category: EPDCategory | null,
      measure: string | null,
      value: string | null,
      accuracy: string | null,
      progeny: string | null,
      percentage: string | null,
      order: string | null,
    } | null > | null,
    tattoo: string | null,
    dob: string | null,
    sexedCategory: SexedCategory | null,
    recipientId: string | null,
    exportable: ExportableType | null,
    footnote: Array< string | null > | null,
    contactInfo: string | null,
    pictures: boolean | null,
    photos:  Array< {
      __typename: "LotImage",
      storageName: string | null,
      fileName: string | null,
      caption: string | null,
      cover: boolean | null,
    } | null > | null,
    videos:  Array< {
      __typename: "LotImage",
      storageName: string | null,
      fileName: string | null,
      caption: string | null,
      cover: boolean | null,
    } | null > | null,
    externalVideoUrl: string | null,
    bidIncrement: number | null,
    currentBid: number | null,
    nextBid: number | null,
    bannerImageKey: string | null,
    businessAccountId: string | null,
    lastBidTime: string | null,
    version: number | null,
    bidCount: number | null,
    bidderAccountId: string | null,
    bidderId: string | null,
    bidderCount: number | null,
    bids:  {
      __typename: "BidsResult",
      nextToken: string | null,
    } | null,
    actualEPD: boolean | null,
    importError: boolean | null,
    importErrors: Array< string | null > | null,
    currentMaxBid: number | null,
    currentMaxBidAccountId: string | null,
  } | null,
};

export type AuctionLotsQueryVariables = {
  auctionId: string,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: SortDirection | null,
};

export type AuctionLotsQuery = {
  auctionLots:  {
    __typename: "AuctionLotResult",
    items:  Array< {
      __typename: "AuctionLot",
      auctionId: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      lotOrder: number | null,
      biddingDisabled: boolean | null,
      embryoType: string | null,
      category: AuctionLotCategory | null,
      donorName: string | null,
      donorSire: string | null,
      donorDam: string | null,
      breed: string | null,
      consigner: string | null,
      specialTerms: string | null,
      deliveryMethods: Array< string | null > | null,
      startBid: number | null,
      floorPrice: number | null,
      interest: InterestType | null,
      quantity: number | null,
      taxable: boolean | null,
      tag: string | null,
      regNum: string | null,
      regUrl: string | null,
      tattoo: string | null,
      dob: string | null,
      sexedCategory: SexedCategory | null,
      recipientId: string | null,
      exportable: ExportableType | null,
      footnote: Array< string | null > | null,
      contactInfo: string | null,
      pictures: boolean | null,
      externalVideoUrl: string | null,
      bidIncrement: number | null,
      currentBid: number | null,
      nextBid: number | null,
      bannerImageKey: string | null,
      businessAccountId: string | null,
      lastBidTime: string | null,
      version: number | null,
      bidCount: number | null,
      bidderAccountId: string | null,
      bidderId: string | null,
      bidderCount: number | null,
      actualEPD: boolean | null,
      importError: boolean | null,
      importErrors: Array< string | null > | null,
      currentMaxBid: number | null,
      currentMaxBidAccountId: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type AuctionLotsBidPriceQueryVariables = {
  auctionId: string,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: SortDirection | null,
};

export type AuctionLotsBidPriceQuery = {
  auctionLotsBidPrice:  {
    __typename: "AuctionLotResult",
    items:  Array< {
      __typename: "AuctionLot",
      auctionId: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      lotOrder: number | null,
      biddingDisabled: boolean | null,
      embryoType: string | null,
      category: AuctionLotCategory | null,
      donorName: string | null,
      donorSire: string | null,
      donorDam: string | null,
      breed: string | null,
      consigner: string | null,
      specialTerms: string | null,
      deliveryMethods: Array< string | null > | null,
      startBid: number | null,
      floorPrice: number | null,
      interest: InterestType | null,
      quantity: number | null,
      taxable: boolean | null,
      tag: string | null,
      regNum: string | null,
      regUrl: string | null,
      tattoo: string | null,
      dob: string | null,
      sexedCategory: SexedCategory | null,
      recipientId: string | null,
      exportable: ExportableType | null,
      footnote: Array< string | null > | null,
      contactInfo: string | null,
      pictures: boolean | null,
      externalVideoUrl: string | null,
      bidIncrement: number | null,
      currentBid: number | null,
      nextBid: number | null,
      bannerImageKey: string | null,
      businessAccountId: string | null,
      lastBidTime: string | null,
      version: number | null,
      bidCount: number | null,
      bidderAccountId: string | null,
      bidderId: string | null,
      bidderCount: number | null,
      actualEPD: boolean | null,
      importError: boolean | null,
      importErrors: Array< string | null > | null,
      currentMaxBid: number | null,
      currentMaxBidAccountId: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type AuctionLotsBidTimeQueryVariables = {
  auctionId: string,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: SortDirection | null,
};

export type AuctionLotsBidTimeQuery = {
  auctionLotsBidTime:  {
    __typename: "AuctionLotResult",
    items:  Array< {
      __typename: "AuctionLot",
      auctionId: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      lotOrder: number | null,
      biddingDisabled: boolean | null,
      embryoType: string | null,
      category: AuctionLotCategory | null,
      donorName: string | null,
      donorSire: string | null,
      donorDam: string | null,
      breed: string | null,
      consigner: string | null,
      specialTerms: string | null,
      deliveryMethods: Array< string | null > | null,
      startBid: number | null,
      floorPrice: number | null,
      interest: InterestType | null,
      quantity: number | null,
      taxable: boolean | null,
      tag: string | null,
      regNum: string | null,
      regUrl: string | null,
      tattoo: string | null,
      dob: string | null,
      sexedCategory: SexedCategory | null,
      recipientId: string | null,
      exportable: ExportableType | null,
      footnote: Array< string | null > | null,
      contactInfo: string | null,
      pictures: boolean | null,
      externalVideoUrl: string | null,
      bidIncrement: number | null,
      currentBid: number | null,
      nextBid: number | null,
      bannerImageKey: string | null,
      businessAccountId: string | null,
      lastBidTime: string | null,
      version: number | null,
      bidCount: number | null,
      bidderAccountId: string | null,
      bidderId: string | null,
      bidderCount: number | null,
      actualEPD: boolean | null,
      importError: boolean | null,
      importErrors: Array< string | null > | null,
      currentMaxBid: number | null,
      currentMaxBidAccountId: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type AuctionReportQueryVariables = {
  auctionId: string,
  limit?: number | null,
  nextToken?: string | null,
  sortField?: AuctionReportSortField | null,
  sortDirection?: SortDirection | null,
};

export type AuctionReportQuery = {
  auctionReport:  {
    __typename: "AuctionReport",
    status: ApiResult | null,
    message: string | null,
    auctionId: string | null,
    auctionName: string | null,
    auctionStartDate: string | null,
    auctionClosingDate: string | null,
    totalGrossSales: number | null,
    totalBidderCount: number | null,
    totalLotCount: number | null,
    invoiceSentDate: string | null,
    payments:  Array< {
      __typename: "AuctionReportPayment",
      paymentDate: string | null,
      amount: number | null,
      fees: number | null,
    } | null > | null,
    lineItems:  Array< {
      __typename: "AuctionReportLineItem",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      businessAccountId: string | null,
      winningBidderFirstName: string | null,
      winningBidderLastName: string | null,
      winningBidderAddress: Array< string | null > | null,
      winningBidderPhoneNumber: string | null,
      winningBidderEmail: string | null,
      finalBid: number | null,
      quantity: number | null,
      interest: InterestType | null,
      finalPrice: number | null,
      saleStatus: SaleStatus | null,
      paymentStatus: PaymentStatus | null,
      invoiceId: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      version: number | null,
    } | null > | null,
    nextToken: string | null,
    version: number | null,
  } | null,
};

export type AccountByEmailQueryVariables = {
  email: string,
};

export type AccountByEmailQuery = {
  accountByEmail:  {
    __typename: "UserAccount",
    accountId: string | null,
    email: string | null,
    familyName: string | null,
    givenName: string | null,
    nameTitle: string | null,
    businessName: string | null,
    lastLoginDt: string | null,
    loginCtr: number | null,
    phone: string | null,
    physicalAddress: Array< string | null > | null,
    physicalCity: string | null,
    physicalState: string | null,
    physicalZip: string | null,
    physicalCountry: string | null,
    physicalCountryStateCity: string | null,
    billingAddress: Array< string | null > | null,
    billingCity: string | null,
    billingState: string | null,
    billingZip: string | null,
    billingCountry: string | null,
    termsOfService: boolean | null,
    receiveInfo: boolean | null,
    userRoles: Array< string | null > | null,
    businesses: Array< string | null > | null,
    notifications: Array< string | null > | null,
    cognitoUserStatus: string | null,
    version: number | null,
    activeBidderIds:  Array< {
      __typename: "AuctionBidderInfo",
      auctionId: string | null,
      lotId: string | null,
      bidderId: string | null,
    } | null > | null,
    associatedBusinesses:  Array< {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null > | null,
    outstandingInvoices:  Array< {
      __typename: "Invoice",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      invoiceId: string | null,
      invoiceNumber: string | null,
      invoiceDate: string | null,
      invoiceSentDate: string | null,
      paymentDueDate: string | null,
      bannerImageKey: string | null,
      buyerName: string | null,
      buyerAddress: Array< string | null > | null,
      buyerPhoneNumber: string | null,
      buyerEmail: string | null,
      saleAuctionName: string | null,
      saleAuctionClosingDate: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      sellerWebsite: string | null,
      sellerAddress: Array< string | null > | null,
      sellerContactName: string | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerNotes: string | null,
      totalAmountPaid: number | null,
      totalAmountDue: number | null,
      datePaid: string | null,
      paid: boolean | null,
      paymentMethod: string | null,
      paymentInvoiceId: string | null,
      paymentCustomerId: string | null,
      paymentInvoicePdf: string | null,
      paymentUrl: string | null,
    } | null > | null,
    activeAuctions:  Array< {
      __typename: "ActiveAuction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      type: AuctionType | null,
      status: AuctionStatus | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      bannerImageKey: string | null,
    } | null > | null,
    wins:  Array< {
      __typename: "AuctionReportLineItem",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      businessAccountId: string | null,
      winningBidderFirstName: string | null,
      winningBidderLastName: string | null,
      winningBidderAddress: Array< string | null > | null,
      winningBidderPhoneNumber: string | null,
      winningBidderEmail: string | null,
      finalBid: number | null,
      quantity: number | null,
      interest: InterestType | null,
      finalPrice: number | null,
      saleStatus: SaleStatus | null,
      paymentStatus: PaymentStatus | null,
      invoiceId: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      version: number | null,
    } | null > | null,
    auctionsAsSeller:  {
      __typename: "AuctionResult",
      nextToken: string | null,
    } | null,
    auctionAsSellerWithLots:  {
      __typename: "Auction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      locationType: AuctionLocationType | null,
      locationCity: string | null,
      locationState: string | null,
      description: string | null,
      businessAccountId: string | null,
      type: AuctionType | null,
      closingExtensionEnabled: boolean | null,
      closingExtensionStyle: ClosingExtensionStyle | null,
      closingExtensionInterval: number | null,
      closingExtensionTriggered: boolean | null,
      status: AuctionStatus | null,
      salesInfoBannerKeys: Array< string | null > | null,
      salesInfoNotes: string | null,
      sellerLocationTitle: string | null,
      sellerContactName: string | null,
      sellerAddress: Array< string | null > | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerEmail: string | null,
      saleOverview: Array< string | null > | null,
      salesRepName: string | null,
      salesRepContact: string | null,
      salesRepEmail: string | null,
      welcomeMessage: string | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      catalogKey: string | null,
      introVideoUrl: string | null,
      paymentOptions: Array< string | null > | null,
      shippingInstructions: string | null,
      receivingInstructions: string | null,
      liveBidLocation: string | null,
      bannerImageKey: string | null,
      hasCustomInvoiceDetails: boolean | null,
      hasStandardTermsAndConditions: boolean | null,
      hasCustomTermsAndConditions: boolean | null,
      customTermsAndConditions: string | null,
      hasStandardDeliveryAndPaymentTerms: boolean | null,
      hasCustomDeliveryAndPaymentTerms: boolean | null,
      customDeliveryAndPaymentTerms: string | null,
      timezone: string | null,
      useSellerContactInfo: boolean | null,
      delisted: boolean | null,
      createdAt: string | null,
      updatedAt: string | null,
      version: number | null,
    } | null,
    auctionAsSellerLotBidPrices:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
    auctionAsSellerLotBidTimes:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
  } | null,
};

export type UserQueryVariables = {
  accountId: string,
};

export type UserQuery = {
  user:  {
    __typename: "UserAccount",
    accountId: string | null,
    email: string | null,
    familyName: string | null,
    givenName: string | null,
    nameTitle: string | null,
    businessName: string | null,
    lastLoginDt: string | null,
    loginCtr: number | null,
    phone: string | null,
    physicalAddress: Array< string | null > | null,
    physicalCity: string | null,
    physicalState: string | null,
    physicalZip: string | null,
    physicalCountry: string | null,
    physicalCountryStateCity: string | null,
    billingAddress: Array< string | null > | null,
    billingCity: string | null,
    billingState: string | null,
    billingZip: string | null,
    billingCountry: string | null,
    termsOfService: boolean | null,
    receiveInfo: boolean | null,
    userRoles: Array< string | null > | null,
    businesses: Array< string | null > | null,
    notifications: Array< string | null > | null,
    cognitoUserStatus: string | null,
    version: number | null,
    activeBidderIds:  Array< {
      __typename: "AuctionBidderInfo",
      auctionId: string | null,
      lotId: string | null,
      bidderId: string | null,
    } | null > | null,
    associatedBusinesses:  Array< {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null > | null,
    outstandingInvoices:  Array< {
      __typename: "Invoice",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      invoiceId: string | null,
      invoiceNumber: string | null,
      invoiceDate: string | null,
      invoiceSentDate: string | null,
      paymentDueDate: string | null,
      bannerImageKey: string | null,
      buyerName: string | null,
      buyerAddress: Array< string | null > | null,
      buyerPhoneNumber: string | null,
      buyerEmail: string | null,
      saleAuctionName: string | null,
      saleAuctionClosingDate: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      sellerWebsite: string | null,
      sellerAddress: Array< string | null > | null,
      sellerContactName: string | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerNotes: string | null,
      totalAmountPaid: number | null,
      totalAmountDue: number | null,
      datePaid: string | null,
      paid: boolean | null,
      paymentMethod: string | null,
      paymentInvoiceId: string | null,
      paymentCustomerId: string | null,
      paymentInvoicePdf: string | null,
      paymentUrl: string | null,
    } | null > | null,
    activeAuctions:  Array< {
      __typename: "ActiveAuction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      type: AuctionType | null,
      status: AuctionStatus | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      bannerImageKey: string | null,
    } | null > | null,
    wins:  Array< {
      __typename: "AuctionReportLineItem",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      businessAccountId: string | null,
      winningBidderFirstName: string | null,
      winningBidderLastName: string | null,
      winningBidderAddress: Array< string | null > | null,
      winningBidderPhoneNumber: string | null,
      winningBidderEmail: string | null,
      finalBid: number | null,
      quantity: number | null,
      interest: InterestType | null,
      finalPrice: number | null,
      saleStatus: SaleStatus | null,
      paymentStatus: PaymentStatus | null,
      invoiceId: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      version: number | null,
    } | null > | null,
    auctionsAsSeller:  {
      __typename: "AuctionResult",
      nextToken: string | null,
    } | null,
    auctionAsSellerWithLots:  {
      __typename: "Auction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      locationType: AuctionLocationType | null,
      locationCity: string | null,
      locationState: string | null,
      description: string | null,
      businessAccountId: string | null,
      type: AuctionType | null,
      closingExtensionEnabled: boolean | null,
      closingExtensionStyle: ClosingExtensionStyle | null,
      closingExtensionInterval: number | null,
      closingExtensionTriggered: boolean | null,
      status: AuctionStatus | null,
      salesInfoBannerKeys: Array< string | null > | null,
      salesInfoNotes: string | null,
      sellerLocationTitle: string | null,
      sellerContactName: string | null,
      sellerAddress: Array< string | null > | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerEmail: string | null,
      saleOverview: Array< string | null > | null,
      salesRepName: string | null,
      salesRepContact: string | null,
      salesRepEmail: string | null,
      welcomeMessage: string | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      catalogKey: string | null,
      introVideoUrl: string | null,
      paymentOptions: Array< string | null > | null,
      shippingInstructions: string | null,
      receivingInstructions: string | null,
      liveBidLocation: string | null,
      bannerImageKey: string | null,
      hasCustomInvoiceDetails: boolean | null,
      hasStandardTermsAndConditions: boolean | null,
      hasCustomTermsAndConditions: boolean | null,
      customTermsAndConditions: string | null,
      hasStandardDeliveryAndPaymentTerms: boolean | null,
      hasCustomDeliveryAndPaymentTerms: boolean | null,
      customDeliveryAndPaymentTerms: string | null,
      timezone: string | null,
      useSellerContactInfo: boolean | null,
      delisted: boolean | null,
      createdAt: string | null,
      updatedAt: string | null,
      version: number | null,
    } | null,
    auctionAsSellerLotBidPrices:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
    auctionAsSellerLotBidTimes:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
  } | null,
};

export type AccountByFirstLastQueryVariables = {
  givenName?: string | null,
  familyName: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type AccountByFirstLastQuery = {
  accountByFirstLast:  {
    __typename: "UsersResult",
    items:  Array< {
      __typename: "UserAccount",
      accountId: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      businessName: string | null,
      lastLoginDt: string | null,
      loginCtr: number | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      userRoles: Array< string | null > | null,
      businesses: Array< string | null > | null,
      notifications: Array< string | null > | null,
      cognitoUserStatus: string | null,
      version: number | null,
    } | null > | null,
    nextToken: string | null,
    scannedCount: number | null,
  } | null,
};

export type BusinessQueryVariables = {
  accountId: string,
};

export type BusinessQuery = {
  business:  {
    __typename: "BusinessAccount",
    accountId: string | null,
    businessName: string | null,
    email: string | null,
    familyName: string | null,
    givenName: string | null,
    nameTitle: string | null,
    providerName: string | null,
    phone: string | null,
    physicalAddress: Array< string | null > | null,
    physicalCity: string | null,
    physicalState: string | null,
    physicalZip: string | null,
    physicalCountry: string | null,
    physicalCountryStateCity: string | null,
    billingAddress: Array< string | null > | null,
    billingCity: string | null,
    billingState: string | null,
    billingZip: string | null,
    billingCountry: string | null,
    termsOfService: boolean | null,
    receiveInfo: boolean | null,
    businessWebSite: string | null,
    businessNotes: string | null,
    businessPhoneNumbers:  Array< {
      __typename: "PhoneNumberDetail",
      phone: string | null,
      preferred: boolean | null,
      mobile: boolean | null,
    } | null > | null,
    proxyUsers:  Array< {
      __typename: "UserAccount",
      accountId: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      businessName: string | null,
      lastLoginDt: string | null,
      loginCtr: number | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      userRoles: Array< string | null > | null,
      businesses: Array< string | null > | null,
      notifications: Array< string | null > | null,
      cognitoUserStatus: string | null,
      version: number | null,
    } | null > | null,
    businessTimezone: string | null,
    version: number | null,
  } | null,
};

export type BusinessesQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type BusinessesQuery = {
  businesses:  {
    __typename: "BusinessesResult",
    items:  Array< {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null > | null,
    nextToken: string | null,
    scannedCount: number | null,
  } | null,
};

export type UsersQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersQuery = {
  users:  {
    __typename: "UsersResult",
    items:  Array< {
      __typename: "UserAccount",
      accountId: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      businessName: string | null,
      lastLoginDt: string | null,
      loginCtr: number | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      userRoles: Array< string | null > | null,
      businesses: Array< string | null > | null,
      notifications: Array< string | null > | null,
      cognitoUserStatus: string | null,
      version: number | null,
    } | null > | null,
    nextToken: string | null,
    scannedCount: number | null,
  } | null,
};

export type SellersQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type SellersQuery = {
  sellers:  {
    __typename: "UsersResult",
    items:  Array< {
      __typename: "UserAccount",
      accountId: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      businessName: string | null,
      lastLoginDt: string | null,
      loginCtr: number | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      userRoles: Array< string | null > | null,
      businesses: Array< string | null > | null,
      notifications: Array< string | null > | null,
      cognitoUserStatus: string | null,
      version: number | null,
    } | null > | null,
    nextToken: string | null,
    scannedCount: number | null,
  } | null,
};

export type BuyersQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type BuyersQuery = {
  buyers:  {
    __typename: "UsersResult",
    items:  Array< {
      __typename: "UserAccount",
      accountId: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      businessName: string | null,
      lastLoginDt: string | null,
      loginCtr: number | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      userRoles: Array< string | null > | null,
      businesses: Array< string | null > | null,
      notifications: Array< string | null > | null,
      cognitoUserStatus: string | null,
      version: number | null,
    } | null > | null,
    nextToken: string | null,
    scannedCount: number | null,
  } | null,
};

export type UserAccountsQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type UserAccountsQuery = {
  userAccounts:  {
    __typename: "UsersResult",
    items:  Array< {
      __typename: "UserAccount",
      accountId: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      businessName: string | null,
      lastLoginDt: string | null,
      loginCtr: number | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      userRoles: Array< string | null > | null,
      businesses: Array< string | null > | null,
      notifications: Array< string | null > | null,
      cognitoUserStatus: string | null,
      version: number | null,
    } | null > | null,
    nextToken: string | null,
    scannedCount: number | null,
  } | null,
};

export type InvoiceQueryVariables = {
  invoiceId: string,
};

export type InvoiceQuery = {
  invoice:  {
    __typename: "Invoice",
    auctionId: string | null,
    auctionName: string | null,
    auctionStartDate: string | null,
    invoiceId: string | null,
    invoiceNumber: string | null,
    invoiceDate: string | null,
    invoiceSentDate: string | null,
    paymentDueDate: string | null,
    bannerImageKey: string | null,
    buyerName: string | null,
    buyerAddress: Array< string | null > | null,
    buyerPhoneNumber: string | null,
    buyerEmail: string | null,
    saleAuctionName: string | null,
    saleAuctionClosingDate: string | null,
    sellerName: string | null,
    sellerPhoneNumber: string | null,
    sellerEmail: string | null,
    sellerWebsite: string | null,
    sellerAddress: Array< string | null > | null,
    sellerContactName: string | null,
    sellerCity: string | null,
    sellerState: string | null,
    sellerZip: string | null,
    sellerNotes: string | null,
    totalAmountPaid: number | null,
    totalAmountDue: number | null,
    datePaid: string | null,
    paid: boolean | null,
    paymentMethod: string | null,
    paymentInvoiceId: string | null,
    paymentCustomerId: string | null,
    paymentInvoicePdf: string | null,
    paymentUrl: string | null,
    lineItems:  Array< {
      __typename: "InvoiceLineItem",
      auctionId: string | null,
      invoiceLineItemId: string | null,
      lotNum: string | null,
      lotName: string | null,
      lotCategory: string | null,
      quantity: number | null,
      unitPrice: number | null,
      totalPrice: number | null,
      additionalInfo: Array< string | null > | null,
    } | null > | null,
  } | null,
};

export type DownloadInvoiceQueryVariables = {
  invoiceId: string,
};

export type DownloadInvoiceQuery = {
  downloadInvoice:  {
    __typename: "DownloadResult",
    status: ApiResult | null,
    downloadUrl: string | null,
  } | null,
};

export type DownloadInvoicesQueryVariables = {
  auctionId: string,
};

export type DownloadInvoicesQuery = {
  downloadInvoices:  {
    __typename: "DownloadResult",
    status: ApiResult | null,
    downloadUrl: string | null,
  } | null,
};

export type MeQuery = {
  me:  {
    __typename: "UserAccount",
    accountId: string | null,
    email: string | null,
    familyName: string | null,
    givenName: string | null,
    nameTitle: string | null,
    businessName: string | null,
    lastLoginDt: string | null,
    loginCtr: number | null,
    phone: string | null,
    physicalAddress: Array< string | null > | null,
    physicalCity: string | null,
    physicalState: string | null,
    physicalZip: string | null,
    physicalCountry: string | null,
    physicalCountryStateCity: string | null,
    billingAddress: Array< string | null > | null,
    billingCity: string | null,
    billingState: string | null,
    billingZip: string | null,
    billingCountry: string | null,
    termsOfService: boolean | null,
    receiveInfo: boolean | null,
    userRoles: Array< string | null > | null,
    businesses: Array< string | null > | null,
    notifications: Array< string | null > | null,
    cognitoUserStatus: string | null,
    version: number | null,
    activeBidderIds:  Array< {
      __typename: "AuctionBidderInfo",
      auctionId: string | null,
      lotId: string | null,
      bidderId: string | null,
    } | null > | null,
    associatedBusinesses:  Array< {
      __typename: "BusinessAccount",
      accountId: string | null,
      businessName: string | null,
      email: string | null,
      familyName: string | null,
      givenName: string | null,
      nameTitle: string | null,
      providerName: string | null,
      phone: string | null,
      physicalAddress: Array< string | null > | null,
      physicalCity: string | null,
      physicalState: string | null,
      physicalZip: string | null,
      physicalCountry: string | null,
      physicalCountryStateCity: string | null,
      billingAddress: Array< string | null > | null,
      billingCity: string | null,
      billingState: string | null,
      billingZip: string | null,
      billingCountry: string | null,
      termsOfService: boolean | null,
      receiveInfo: boolean | null,
      businessWebSite: string | null,
      businessNotes: string | null,
      businessTimezone: string | null,
      version: number | null,
    } | null > | null,
    outstandingInvoices:  Array< {
      __typename: "Invoice",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      invoiceId: string | null,
      invoiceNumber: string | null,
      invoiceDate: string | null,
      invoiceSentDate: string | null,
      paymentDueDate: string | null,
      bannerImageKey: string | null,
      buyerName: string | null,
      buyerAddress: Array< string | null > | null,
      buyerPhoneNumber: string | null,
      buyerEmail: string | null,
      saleAuctionName: string | null,
      saleAuctionClosingDate: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      sellerWebsite: string | null,
      sellerAddress: Array< string | null > | null,
      sellerContactName: string | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerNotes: string | null,
      totalAmountPaid: number | null,
      totalAmountDue: number | null,
      datePaid: string | null,
      paid: boolean | null,
      paymentMethod: string | null,
      paymentInvoiceId: string | null,
      paymentCustomerId: string | null,
      paymentInvoicePdf: string | null,
      paymentUrl: string | null,
    } | null > | null,
    activeAuctions:  Array< {
      __typename: "ActiveAuction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      type: AuctionType | null,
      status: AuctionStatus | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      bannerImageKey: string | null,
    } | null > | null,
    wins:  Array< {
      __typename: "AuctionReportLineItem",
      auctionId: string | null,
      auctionName: string | null,
      auctionStartDate: string | null,
      lotId: string | null,
      lotNum: string | null,
      title: string | null,
      businessAccountId: string | null,
      winningBidderFirstName: string | null,
      winningBidderLastName: string | null,
      winningBidderAddress: Array< string | null > | null,
      winningBidderPhoneNumber: string | null,
      winningBidderEmail: string | null,
      finalBid: number | null,
      quantity: number | null,
      interest: InterestType | null,
      finalPrice: number | null,
      saleStatus: SaleStatus | null,
      paymentStatus: PaymentStatus | null,
      invoiceId: string | null,
      sellerName: string | null,
      sellerPhoneNumber: string | null,
      sellerEmail: string | null,
      version: number | null,
    } | null > | null,
    auctionsAsSeller:  {
      __typename: "AuctionResult",
      nextToken: string | null,
    } | null,
    auctionAsSellerWithLots:  {
      __typename: "Auction",
      auctionId: string | null,
      title: string | null,
      startTime: string | null,
      endTime: string | null,
      locationType: AuctionLocationType | null,
      locationCity: string | null,
      locationState: string | null,
      description: string | null,
      businessAccountId: string | null,
      type: AuctionType | null,
      closingExtensionEnabled: boolean | null,
      closingExtensionStyle: ClosingExtensionStyle | null,
      closingExtensionInterval: number | null,
      closingExtensionTriggered: boolean | null,
      status: AuctionStatus | null,
      salesInfoBannerKeys: Array< string | null > | null,
      salesInfoNotes: string | null,
      sellerLocationTitle: string | null,
      sellerContactName: string | null,
      sellerAddress: Array< string | null > | null,
      sellerCity: string | null,
      sellerState: string | null,
      sellerZip: string | null,
      sellerEmail: string | null,
      saleOverview: Array< string | null > | null,
      salesRepName: string | null,
      salesRepContact: string | null,
      salesRepEmail: string | null,
      welcomeMessage: string | null,
      bidIncrement: number | null,
      startBid: number | null,
      floorPrice: number | null,
      catalogKey: string | null,
      introVideoUrl: string | null,
      paymentOptions: Array< string | null > | null,
      shippingInstructions: string | null,
      receivingInstructions: string | null,
      liveBidLocation: string | null,
      bannerImageKey: string | null,
      hasCustomInvoiceDetails: boolean | null,
      hasStandardTermsAndConditions: boolean | null,
      hasCustomTermsAndConditions: boolean | null,
      customTermsAndConditions: string | null,
      hasStandardDeliveryAndPaymentTerms: boolean | null,
      hasCustomDeliveryAndPaymentTerms: boolean | null,
      customDeliveryAndPaymentTerms: string | null,
      timezone: string | null,
      useSellerContactInfo: boolean | null,
      delisted: boolean | null,
      createdAt: string | null,
      updatedAt: string | null,
      version: number | null,
    } | null,
    auctionAsSellerLotBidPrices:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
    auctionAsSellerLotBidTimes:  {
      __typename: "AuctionLotResult",
      nextToken: string | null,
    } | null,
  } | null,
};

export type OnTaskProgressUpdatedSubscriptionVariables = {
  id: string,
};

export type OnTaskProgressUpdatedSubscription = {
  onTaskProgressUpdated:  {
    __typename: "Result",
    id: string | null,
    status: ResultStatus | null,
    payload: string | null,
  } | null,
};

export type OnAuctionChangedSubscriptionVariables = {
  auctionId: string,
};

export type OnAuctionChangedSubscription = {
  onAuctionChanged:  {
    __typename: "AuctionChangeData",
    auctionId: string | null,
    eventType: AuctionChangeEventType | null,
    auctionData:  {
      __typename: "AuctionDetailsData",
      closingExtensionInterval: number | null,
      closingExtensionTriggered: boolean | null,
      status: AuctionStatus | null,
      startTime: string | null,
      endTime: string | null,
      bidIncrement: number | null,
      floorPrice: number | null,
    } | null,
    lotData:  {
      __typename: "AuctionLotData",
      auctionId: string | null,
      lotId: string | null,
      startBid: number | null,
      currentBid: number | null,
      nextBid: number | null,
      bidCount: number | null,
      bidIncrement: number | null,
      bidderCount: number | null,
      bidderId: string | null,
      bidderAccountId: string | null,
      floorPrice: number | null,
      biddingDisabled: boolean | null,
    } | null,
  } | null,
};
