import { Component, OnInit, Input } from '@angular/core';
import { PortalUser } from '../../model/portaluser.model';

@Component({
  selector: 'app-select-users-list',
  templateUrl: './select-users-list.component.html',
  styleUrls: ['./select-users-list.component.scss']
})
export class SelectUsersListComponent implements OnInit {

  @Input() rows = [];
  p:number = 1; 

  constructor() { }

  ngOnInit(): void {
  }

}
