import { Injectable } from '@angular/core';
import { PortalUser } from '../model/portaluser.model';

import API, { graphqlOperation } from '@aws-amplify/api';
import {  
         BusinessQuery,
         BusinessesQuery,
         UpdateBusinessContactDetailsMutation,
         CreateBusinessMutation,
         DeleteBusinessMutation,
         PhoneNumberDetailInput, 
         AddBusinessUserMutation, 
         DeleteBusinessUserMutation,
         DeleteUserMutation,
         AccountByFirstLastQuery,
         AddNewUserMutation } from '../API.service';

import { business, 
         businesses,
         accountByFirstLast
       } from '../../graphql/queries';
       
import { updateBusinessContactDetails,
         createBusiness,
         deleteBusiness,
         addBusinessUser,
         deleteBusinessUser,
         deleteUser,
         addNewUser } from '../../graphql/mutations';
import { businessesCustom } from '../../graphql/custom';

import * as moment from 'moment';

export interface AuctionBidderInfo {
  auctionId: string;
  lotId: string;
  bidderId: string;
}

@Injectable()
export class SellerService {
  sellersList:Seller[] = [];
  activeSeller:Seller;
  
  async loadSellers(forcereload=true, sortDirection="ASC") {
    if(this.sellersList.length == 0 || forcereload == true) {
      this.sellersList = []; 
      let queryVars = {};
      queryVars['limit'] = 100;
      queryVars['sortDirection'] = sortDirection;
      let continueLoop = true;
      
      while(continueLoop){
        try{
          const response = (await API.graphql(graphqlOperation(businessesCustom,queryVars))) as {
            data: BusinessesQuery;
          };
          // console.log("graphql businesses",response);
          for(let item of response.data.businesses.items){
            let seller = new Seller();
            seller.loadResults(item);
            this.sellersList.push(seller);
          }
          continueLoop = response.data.businesses.nextToken != undefined;
          queryVars['nextToken'] = response.data.businesses.nextToken;
        }
        catch(err){
          console.log('error loading sellers list',err);
          throw(err)
        }
      }
    }      
    return this.sellersList;
  }
  
  async createNewUser(email:string,first:string,last:string,phone:string){
      let queryVars = {};
      queryVars['email'] = email;
      queryVars['givenName'] = first;
      queryVars['familyName'] = last;
      queryVars['phone'] = phone;
      queryVars['userRoles'] = [PortalUser._BUYER]; 
      
      try{
        const response = (await API.graphql(graphqlOperation(addNewUser,queryVars))) as {
          data: AddNewUserMutation;
        };
        // console.log('createNewUser',response);
        let account = new PortalUser();
        account.loadResults(response.data.addNewUser);
        return account;
      }
      catch(err){
        console.log('error creating new user',err);
        throw(err)
      }
  }
  
}

@Injectable()
export class Seller {

    public accountId:string;
    public businessName:string;
    public email:string;
    public familyName:string;
    public givenName:string;
    public nameTitle:string;
    public phone:string;
    public physicalAddress: string[];
    public physicalCity:string;
    public physicalState:string;
    public physicalZip:string;
    public physicalCountry:string;
    public physicalCountryStateCity:string;
    public billingAddress: string[];
    public billingCity:string;
    public billingState:string;
    public billingZip:string;
    public billingCountry:string;
    public termsOfService:boolean;
    public receiveInfo:boolean;
    public businessWebSite:string;
    public businessNotes:string;
    public businessTimezone:string;
    public businessPhoneNumbers:PhoneNumberDetailInput[];
    public version:number;
    public proxyUsers:PortalUser[];
    public associatedBusinesses:PortalUser[];

    public modifiedProxyUserIds:string[];
    public proxyUserIds:string[];
    
    public associatedBusinessIds:string[];
    public modifiedAssociatedBusinessIds:string[];

    constructor() {
      this.accountId = "";
    }

    async loadBusiness(accountId:string) {
        let queryVars = {};
        queryVars['accountId'] = accountId;

        try{
          const response = (await API.graphql(graphqlOperation(business,queryVars))) as {
            data: BusinessQuery;
          };
          // console.log('loadBusiness',response);
          if(response.data.business == undefined){
            throw new Error('Error retrieving business. Contact your administrator.');
          }
          this.loadResults(response.data.business);
        }
        catch(err){
          console.log('error loading business',err);
          throw(err)
        }
      
      return this;
    }

    async updateBusiness(){
      if(this.accountId == undefined || this.accountId.length == 0){
        return this.createBusiness();
      }
      
      let updateVars = this.getUpdateVars();
      // console.log('updateVars',updateVars);
      try{
        await this.saveProxyUsers();
        const response = (await API.graphql(graphqlOperation(updateBusinessContactDetails,{accountId:this.accountId,expectedVersion:this.version,input:updateVars}))) as {
          data: UpdateBusinessContactDetailsMutation;
        };

        if(response.data == undefined || response.data.updateBusinessContactDetails == undefined){
          throw new Error('Error updating business. Contact your administrator.');
        }
        return await this.loadBusiness(this.accountId);
        // this.loadResults(response.data.updateBusinessContactDetails);
      }
      catch(err){
        var errorMsg = err.errors[0].message;
        console.log('error updating business',err);
        throw(errorMsg)
      }
      return this;
    }
    
    async createBusiness(){   
      try{
        const response = (await API.graphql(graphqlOperation(createBusiness,{input:this.getUpdateVars()}))) as {
          data: CreateBusinessMutation;
        };
  
        if(response.data == undefined || response.data.createBusiness == undefined){
          throw new Error('Error creating business. Contact your administrator.');
        }
        // console.log('create auction',response);
        this.accountId = response.data.createBusiness['accountId']; 
        await this.saveProxyUsers();
        await this.loadBusiness(this.accountId);
        return this;
      }
      catch(err){
        var errorMsg = err.errors != undefined ? err.errors[0].message : err;
        console.log('error creating business',err);
        throw(errorMsg)
      }
    }

    async deleteBusiness(){    
      try{
        await this.deleteProxyUsers();
        const response = (await API.graphql(graphqlOperation(deleteBusiness,{accountId:this.accountId}))) as {
          data: DeleteBusinessMutation;
        };
  
        if(response.data == undefined || response.data.deleteBusiness == undefined){
          throw new Error('Error deleting business. Contact your administrator.');
        }
        return;
      }
      catch(err){
        var errorMsg = err.errors != undefined ? err.errors[0].message : err;
        console.log('error deleting business',err);
        throw(errorMsg)
      }
    }
    
    getUpdateVars(){
      var updateVars = {};
      updateVars['email'] = this.email;
      updateVars['familyName'] = this.familyName;
      updateVars['givenName'] = this.givenName;
      updateVars['businessName'] = this.businessName;
      updateVars['phone'] = this.phone;
      updateVars['physicalAddress'] = this.physicalAddress;
      updateVars['physicalCity'] = this.physicalCity;
      updateVars['physicalState'] = this.physicalState;
      updateVars['physicalZip'] = this.physicalZip;
      updateVars['physicalCountry'] = this.physicalCountry;
      updateVars['billingAddress'] = this.billingAddress;
      updateVars['billingCity'] = this.billingCity;
      updateVars['billingState'] = this.billingState;
      updateVars['billingZip'] = this.billingZip;
      updateVars['billingCountry'] = this.billingCountry;
      updateVars['termsOfService'] = this.termsOfService;
      updateVars['receiveInfo'] = this.receiveInfo;
      updateVars['nameTitle'] = this.nameTitle;
      updateVars['businessWebSite'] = this.businessWebSite != undefined && this.businessWebSite.trim().length > 0 ? this.businessWebSite : null;
      updateVars['businessNotes'] = this.businessNotes;
      updateVars['businessTimezone'] = this.businessTimezone;
      updateVars['businessPhoneNumbers'] = this.businessPhoneNumbers;
      return updateVars;
    }
    
    loadResults(returnResults){
      this.accountId = returnResults.accountId != undefined ? returnResults.accountId : "";
      this.email = returnResults.email != undefined ? returnResults.email : undefined;
      this.familyName = returnResults.familyName != undefined ? returnResults.familyName : undefined;
      this.givenName = returnResults.givenName != undefined ? returnResults.givenName : undefined;
      this.businessName = returnResults.businessName != undefined ? returnResults.businessName : undefined;
      
      this.phone = returnResults.phone != undefined ? returnResults.phone : undefined;
      this.physicalAddress = returnResults.physicalAddress != undefined ? returnResults.physicalAddress : undefined;
      this.physicalCity = returnResults.physicalCity != undefined ? returnResults.physicalCity : undefined;
      this.physicalState = returnResults.physicalState != undefined ? returnResults.physicalState : undefined;
      this.physicalZip = returnResults.physicalZip != undefined ? returnResults.physicalZip : undefined;
      this.physicalCountry = returnResults.physicalCountry != undefined ? returnResults.physicalCountry : undefined;
      this.physicalCountryStateCity = returnResults.physicalCountryStateCity != undefined ? returnResults.physicalCountryStateCity : undefined;
      this.billingAddress = returnResults.billingAddress != undefined ? returnResults.billingAddress : undefined;
      this.billingCity = returnResults.billingCity != undefined ? returnResults.billingCity : undefined;
      this.billingState = returnResults.billingState != undefined ? returnResults.billingState : undefined;
      this.billingZip = returnResults.billingZip != undefined ? returnResults.billingZip : undefined;
      this.billingCountry = returnResults.billingCountry != undefined ? returnResults.billingCountry : undefined;
      this.termsOfService = returnResults.termsOfService != undefined ? returnResults.termsOfService : undefined;
      this.receiveInfo = returnResults.receiveInfo != undefined ? returnResults.receiveInfo : undefined;
      this.nameTitle = returnResults.nameTitle != undefined ? returnResults.nameTitle : undefined;
      this.businessWebSite = returnResults.businessWebSite != undefined ? returnResults.businessWebSite : undefined;
      this.businessNotes = returnResults.businessNotes != undefined ? returnResults.businessNotes : undefined;
      this.businessTimezone = returnResults.businessTimezone != undefined ? returnResults.businessTimezone : undefined;
      this.version = returnResults.version != undefined ? returnResults.version : 0;

      this.businessPhoneNumbers = returnResults.businessPhoneNumbers != undefined ? returnResults.businessPhoneNumbers : [];
      if(this.businessPhoneNumbers.length == 0 ){
        let phone = {"phone":"","preferred":true,"mobile":false};
        this.businessPhoneNumbers = [];
        this.businessPhoneNumbers.push(phone);  
      }

      this.proxyUsers = returnResults.proxyUsers != undefined ? returnResults.proxyUsers : [];
      this.proxyUserIds = [];
      this.modifiedProxyUserIds = [];
      for(let item of this.proxyUsers){
        this.proxyUserIds.push(item['accountId']);
        this.modifiedProxyUserIds.push(item['accountId']);
      }

      this.associatedBusinesses = returnResults.associatedBusinesses != undefined ? returnResults.associatedBusinesses : [];
      this.associatedBusinessIds = [];
      this.modifiedAssociatedBusinessIds = [];
      for(let item of this.associatedBusinesses){
        this.associatedBusinessIds.push(item['accountId']);
        this.modifiedAssociatedBusinessIds.push(item['accountId']);
      }
    }

    getFullName():string {
      var returnValue = "";
      if(this.givenName.length===0 && this.familyName.length===0){
        returnValue = this.email;
      }
      else{
        var separator = (this.givenName.length > 0) ? ' ' : ''
        returnValue =  this.givenName + separator + this.familyName;
      }
      return returnValue;
    }
    
    getDisplayablePhysicalLocation():string{
      let city = this.physicalCity != undefined ? this.physicalCity + ", " : "";
      let state = this.physicalState != undefined ? this.physicalState + " ": "";
      let zip = this.physicalZip != undefined ? this.physicalZip : "";
      return city + state + zip;
    }
    
    initNewBusiness(){
      let phone = {"phone":"","preferred":true,"mobile":false};
      this.businessPhoneNumbers = [];
      this.businessPhoneNumbers.push(phone);  
      this.proxyUserIds = [];
      this.modifiedProxyUserIds = [];
      this.associatedBusinessIds = [];
      this.modifiedAssociatedBusinessIds = [];
    }
  
  async saveProxyUsers(){

    /* Add proxy users that do not exist*/
    for(let item of this.modifiedProxyUserIds){
      if(!this.proxyUserIds.includes(item)){
        await this.addProxyUser(item);
      }
    }
    
    /* Delete proxy users */
    for(let item of this.proxyUserIds){
      if(!this.modifiedProxyUserIds.includes(item)){
        await this.deleteProxyUser(item,this.accountId);
      }
    }
  }

  async deleteProxyUsers(){
    /* Delete proxy users */
    for(let item of this.proxyUserIds){
        await this.deleteProxyUser(item,this.accountId);
    }
  }

  async deleteBusinessAssociatons(){
    /* Delete business associations */
    for(let item of this.associatedBusinessIds){
        await this.deleteProxyUser(this.accountId,item);
    }
  }
  
  async addProxyUser(userAccountId:string){    
    try{
      const response = (await API.graphql(graphqlOperation(addBusinessUser,{accountId:userAccountId,businessAccountId:this.accountId}))) as {
        data: AddBusinessUserMutation;
      };

      if(response.data == undefined || response.data.addBusinessUser == undefined){
        throw new Error('Error adding proxy user to business. Contact your administrator.');
      }
      await this.giveProxyUserSellerRole(userAccountId);
      return;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error adding proxy user to business',err);
      throw(errorMsg)
    }
  }
  
  async giveProxyUserSellerRole(userAccountId:string){
    var user = new PortalUser();
    try{
      await user.get(userAccountId);
      if(!user.isSeller()){
        user.setSeller(true);
        await user.updateRoles();
      }
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error giving proxy user seller role',err);
      throw(errorMsg)
    }
    
  }

  async removeProxyUserSellerRole(userAccountId:string){
    var user = new PortalUser();
    try{
      await user.get(userAccountId);
      if(user.isSeller() && user.associatedBusinessIds.length == 0){
        user.setSeller(false);
        await user.updateRoles();
      }
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error removing proxy user seller role',err);
      throw(errorMsg)
    }
  }
    
  async deleteProxyUser(userAccountId:string,businessAccountId:string){  
    // console.log(userAccountId," ",businessAccountId);
    try{
      const response = (await API.graphql(graphqlOperation(deleteBusinessUser,{accountId:userAccountId,businessAccountId:businessAccountId}))) as {
        data: DeleteBusinessUserMutation;
      };
      
      // console.log(response);
      if(response.data == undefined || response.data.deleteBusinessUser == undefined){
        throw new Error('Error deleting proxy user from business. Contact your administrator.');
      }
      await this.removeProxyUserSellerRole(userAccountId);
      return;
    }
    catch(err){
      var errorMsg = err.errors != undefined ? err.errors[0].message : err;
      console.log('error deleting proxy user from business',err);
      throw(errorMsg)
    }
  }
    
}
