<div class="loading-shade" *ngIf="processing"></div>
<div class="container-fluid">
    
        <div class="row mb-2">
            <div class="col-md-12" [ngClass]="getActiveStateStyle(1)">
                <a [routerLink]="" (click)="setActive(1)" [ngClass]="getActiveStateStyle(1)">Account Settings</a>
            </div>
        </div> 

        <div class="row mb-2">
            <div class="col-md-12" [ngClass]="getActiveStateStyle(2)">
                <a [routerLink]="" (click)="setActive(2)" [ngClass]="getActiveStateStyle(2)">My Bids & Saves</a>
            </div>
        </div> 
        
        <div class="row mb-2">
            <div class="col-md-12" [ngClass]="getActiveStateStyle(3)">
                <a [routerLink]="" (click)="setActive(3)" [ngClass]="getActiveStateStyle(3)">Following</a>
            </div>
        </div> 
        
        <div class="row mb-2">
            <div class="col-md-12" [ngClass]="getActiveStateStyle(4)">
                <a [routerLink]="" (click)="setActive(4)" [ngClass]="getActiveStateStyle(4)">My Sales</a>
            </div>
        </div> 

</div>