<div class="loading-shade" *ngIf="processing"></div>

<div class="container-fluid">

    <div class="row mb-2">
        <div class="col-md-8 title-text">
            Account Settings
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-1">
        <div class="col-md-8">
            <hr>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-8 label-text">
            Account
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-8 label-text">
            PASSWORD
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-8 right-justify">
            <button nbButton outline size="tiny" status="primary" (click)="changePassword()">CHANGE PASSWORD</button>
        </div>
    </div>

    <div class="row mb-1">
        <div class="col-md-8">
            <hr>
        </div>
        <div class="col"></div>
    </div>

    <div class="row mb-2">
        <div class="col-md-8 label-text">
            Contact Info
        </div>
    </div>

    <div class="row">
        <div class="col-md-8 label-text">
            YOUR PHONE NUMBER
        </div>
    </div>

    <ng-container *ngIf="!changephonenumber">
        <div class="row mb-4">
            <div class="col-md-4 info-text">
                {{ getPhoneNumber() }}
            </div>
            <div class="col-md-4 right-justify">
                <button nbButton outline size="tiny" status="primary" (click)="changePhoneNumber()">CHANGE PHONE NUMBER</button>
            </div>
            <div class="col"></div>
        </div>
    </ng-container>

    <ng-container *ngIf="changephonenumber">
      <div class="row mt-1 mb-2">
          <div class="col-md-1 phone-number-field">
              <input mask="(000) 000-0000"
                     [dropSpecialCharacters]="false"
                     type="text"
                     nbInput
                     fullWidth
                     id="phone"
                     name="phone"
                     #phoneentry="ngModel"
                     [ngClass]="{'inputError':!validPhone()}"
                     maxlength="14"
                     [(ngModel)]="phone">
          </div>
      </div>
      <div class="row mb-2" *ngIf="!validPhone()">
        <div class="col">
          <div class="errorText">Enter a 10-digit phone number.</div>
        </div>
      </div>
      <div class="row mb-3">
          <div class="col-md-8">
              <button nbButton [nbSpinner]="processing" nbSpinnerStatus="control" outline size="small" status="primary" (click)="updatePhoneNumber()" [disabled]="!validPhone()">UPDATE</button>
              <button class="ml-2" nbButton ghost size="small" status="primary" (click)="cancelPhoneNumber()">CANCEL</button>
          </div>
      </div>

    </ng-container>

    <div class="row">
        <div class="col-md-8 label-text">
            YOUR ADDRESS
        </div>
    </div>

    <ng-container *ngIf="!changeaddress">
        <div class="row mb-1">
            <div class="col-md-4 info-text">
                {{ getAddress() }}
            </div>
            <div class="col"></div>
        </div>
        <div class="row mb-3">
            <div class="col-md-4 info-text">
                {{ getLocation() }}
            </div>
            <div class="col-md-4 right-justify">
                <button nbButton outline size="tiny" status="primary" (click)="changeAddress()">UPDATE ADDRESS</button>
            </div>
            <div class="col"></div>
        </div>
    </ng-container>

    <ng-container *ngIf="changeaddress">
        <div class="row mb-2">
            <div class="col-md-4">
                <input #addressLine1Input
                       type="text"
                       nbInput
                       fullWidth
                       id="addressLine1"
                       name="addressLine1"
                       placeholder="Address Line 1"
                       [(ngModel)]="addressLine1">
            </div>
            <div class="col"></div>
        </div>

        <div class="row mb-2">
            <div class="col-md-4">
                <input type="text"
                       nbInput
                       fullWidth
                       id="addressLine2"
                       name="addressLine2"
                       placeholder="Address Line 2"
                       [(ngModel)]="addressLine2">
            </div>
            <div class="col"></div>
        </div>

        <div class="row mb-2">
            <div class="col-md-4">
                <input type="text"
                       nbInput
                       fullWidth
                       id="city"
                       name="city"
                       placeholder="Type your city"
                       [(ngModel)]="city">
            </div>
            <div class="col"></div>
        </div>

        <div class="row mb-2">
          <div class="col-md-4">
              <nb-select  placeholder="Select State"
                          name="state"
                          id="state"
                          [(ngModel)]="state">
                  <nb-option *ngFor="let item of states" [value]="item">{{ item }}</nb-option>
              </nb-select>
          </div>
          <div class="col"></div>
        </div>

        <div class="row mb-2">
            <div class="col-md-1">
                <input type="text"
                       nbInput
                       fullWidth
                       id="zip"
                       name="zip"
                       placeholder="Type your zip code"
                       [ngClass]="{'inputError':!validZip()}"
                       maxlength="5"
                       [(ngModel)]="zip">
                <div class="errorText" *ngIf="!validZip()">Please enter a 5-digit zip code.</div>
            </div>
            <div class="col"></div>
        </div>

        <div class="row mb-3">
            <div class="col-md-4">
                <button nbButton [nbSpinner]="processing" nbSpinnerStatus="control" outline size="tiny" status="primary" (click)="updateAddress()" [disabled]="!validZip() || !validState()">UPDATE</button>
                <button class="ml-3" nbButton outline size="tiny" status="primary" (click)="cancelAddress()">CANCEL</button>
            </div>
        </div>

    </ng-container>

    <div class="row mb-1">
        <div class="col-md-8">
            <hr>
        </div>
        <div class="col"></div>
    </div>

</div>