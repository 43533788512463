import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';
import { PasswordCheck } from '../passwordcheck';
import { States } from '../states';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignupComponent implements OnInit {
  errorMsg = "";
  processing = false;
  linearMode:boolean = true;
  filteredOptions$: Observable<string[]>;  
  
  showPassword:boolean = false;
  showConfirmPasswordText:boolean = false;
  
  firstname:string = "";
  lastname:string = "";
  email:string = "";
  password:string = "";
  confirmpassword:string = "";
  businessname:string = "";
  addressline1:string = "";
  addressline2:string = "";
  city:string = "";
  state:string = "";
  zip:string = "";
  phone:string = "";
  termsofservice:boolean = false;
  receiveinformation:boolean = true;
  verificationcode:string = "";
  signincompleted:boolean = false;
  states:string[] = [];

  @Output() messageEvent = new EventEmitter<any>();
  @ViewChild('stepper') stepper;
  @ViewChild('laststep') laststep;
  @ViewChild('stateautoinput') stateInput;

  constructor(private authService: AuthService){}

  ngOnInit(): void {
    this.states = States.states;
    this.filteredOptions$ = of(this.states);      
  }
  
  passwordCharLength(){
    return PasswordCheck.passwordCharLength(this.password);
  }

  passwordUpperCase(){
    return PasswordCheck.passwordUpperCase(this.password);
  }

  passwordLowerCase(){
    return PasswordCheck.passwordLowerCase(this.password);
  }

  passwordSpecialChars(){
    return PasswordCheck.passwordSpecialChars(this.password);
  }

  passwordNumber(){
    return PasswordCheck.passwordNumber(this.password);
  }
  
  validPassword(){
    return PasswordCheck.validPassword(this.password);
  }
  
  validEmail() {
    var returnValue = false;
    if(this.email != undefined && this.email.length > 0){
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      returnValue = re.test(String(this.email).toLowerCase());
      
    }
    return returnValue;
  }  
  
  validZip(){
    var returnValue = true;
    if(this.zip != undefined && this.zip.length > 0){
      returnValue = /^\d{5}/g.test(this.zip);
    }
    return returnValue;
  }

  validPhone(){
    var returnValue = false;
    if(this.phone != undefined && this.phone.length == 14){
      returnValue = /^\(\d{3}\)\s\d{3}-\d{4}/g.test(this.phone);
    }
    return returnValue;
  }
  
  validBusinessInfo(){
    return this.validPhone() && this.validZip() && this.validState();
  }
  
  validState(){
    var returnValue = true;
    if(this.state != undefined && this.state.length > 0){
      return this.states.includes(this.state);
    }
    return returnValue;
  }
  
  confirmPasswordCheck(){
    return (this.password.length > 0) && (this.password == this.confirmpassword);
  }

  onToggleShowPassword(){
    this.showPassword = !this.showPassword;
  }

  onToggleShowConfirmPassword(){
    this.showConfirmPasswordText = !this.showConfirmPasswordText;
  }
  
  close(){
    var eventMsg = {}
    eventMsg['message'] = 'closelogin';
    this.messageEvent.emit(eventMsg);
  }
  
  onSignIn(){
    var eventMsg = {}
    eventMsg['message'] = 'signin';
    this.messageEvent.emit(eventMsg);
  }
  
  onDone(){
    var eventMsg = {}
    eventMsg['message'] = 'signupcomplete';
    this.messageEvent.emit(eventMsg);
  }
  
  onAppleSignin(){
    this.authService.loginApple();
  }

  onFacebookSignin(){
    this.authService.loginFacebook();
  }

  onGoogleSignin(){
    this.authService.loginGoogle();
  }
  
  onSignupOneNext(){
    this.errorMsg = "";
    if(!this.confirmPasswordCheck()){
      this.errorMsg = "Password and confirmation password do not match";
      return;
    }
    
    this.onNext();
  }

  onPrevious(){
    this.stepper.previous();
    this.scrollToSectionHook();
  }

  onNext(){
    this.stepper.next();
    this.scrollToSectionHook();
  }
  
  scrollToSectionHook() { 
      setTimeout(() => {
        const element = document.getElementById('cardHeader'); 
        if(element){
          element.scrollIntoView(true); 
        }        
      },30);
  }  

  onSignup(){
    this.processing = true;
    this.authService.signupUser(this.email,this.password, this.firstname, this.lastname).then(response => {
        this.processing = false;
        this.errorMsg = "";
        this.onNext();
    }, error => {
        this.processing = false;
        this.errorMsg = error.message;
    });
    
  }

  onConfirm(){
    this.errorMsg = "";
    this.processing = true;
    
    this.authService.confirmUser(this.email,this.verificationcode).then(response => {
        this.errorMsg = "";
        this._login();
    }, error => {
        console.log('error confirming',error);
        this.errorMsg = error.message;
        this.processing = false;
        return;
    });
    
  }
  
  _login() {
    this.errorMsg = "";
    this.authService.signinUser(this.email, this.password).then(response => {
        this.processing = false;
        this._onUpdateProfile();
    }, error => {
        this.errorMsg = error.message;
        this.processing = false;
    });
  }
  
  async _onUpdateProfile(){
    this.processing = true;
    this.authService.activeUser.businessName = this.businessname.length > 0 ? this.businessname : undefined;
    this.authService.activeUser.physicalAddress[0] = this.addressline1;
    this.authService.activeUser.physicalAddress[1] = this.addressline2;
    this.authService.activeUser.physicalCity = this.city.length > 0 ? this.city : undefined;
    this.authService.activeUser.physicalState = this.state.length > 0 ? this.state : undefined;
    this.authService.activeUser.physicalZip = this.zip.length > 0 ? this.zip : undefined;
    this.authService.activeUser.physicalCountry = "US";
    this.authService.activeUser.phone = this.phone.length > 0 ? this.phone : undefined;
    this.authService.activeUser.termsOfService = this.termsofservice;
    this.authService.activeUser.receiveInfo = this.receiveinformation;
    
    try {      
      await this.authService.activeUser.update();
      this.processing = false;
      this._logout();
      this.onNext();
      this.laststep.completed = true;
      this.signincompleted = true;
    }
    catch(err){
      this.processing = false;
      this.errorMsg = err;
    }    
  }
  
  _logout() {
    this.authService.logout();
  }
  
  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.states.filter(optionValue => optionValue.toLowerCase().includes(filterValue));
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(
      map(filterString => this.filter(filterString)),
    );
  }

  onChange() {
    this.filteredOptions$ = this.getFilteredOptions(this.stateInput.nativeElement.value);
  }

  onStateSelectedChange($event) {
    this.filteredOptions$ = this.getFilteredOptions($event);
  }  
  
  
}
