import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LotModel } from '../../model/auction.model';

@Component({
  selector: 'app-auction-lot-detail',
  templateUrl: './auction-lot-detail.component.html',
  styleUrls: ['./auction-lot-detail.component.scss']
})
export class AuctionLotDetailComponent implements OnInit {

  @Input() lot:LotModel;
  @Output() messageEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }
  
  onEditLot(){
    var eventMsg = {}
    eventMsg['message'] = 'editlot';
    eventMsg['parms'] = this.lot;
    this.messageEvent.emit(eventMsg);
  }
  
  onDeleteLot(){
    var eventMsg = {}
    eventMsg['message'] = 'deletelot';
    eventMsg['parms'] = this.lot;
    this.messageEvent.emit(eventMsg);
  }
  
  onCloneLot(){
    var eventMsg = {}
    eventMsg['message'] = 'clonelot';
    eventMsg['parms'] = this.lot;
    this.messageEvent.emit(eventMsg);
  }
  
  notifyLotNumChange($event){
    var eventMsg = {}
    eventMsg['message'] = 'lotnumchanged';
    eventMsg['parms'] = this.lot;
    this.messageEvent.emit(eventMsg);
  }
  
  importLotError(){
    return this.lot.importError != undefined ? this.lot.importError : false;
  }

}
